import './index.css';
import React, { useEffect, useState, useRef, useReducer } from "react";
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, Link } from "@mui/material";
import axios from "axios";
import config from "../../../../../Config/Config";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getFuelConsumptionV2, getEnginePerformanceV2 } from "../../../../../Helpers/ApplicationHelpers";
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import "../../../../AppV2.css";
import { AccountCircle, CollectionsBookmarkOutlined, Key, Login, Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { LineChart } from '@mui/x-charts/LineChart';
import FMSTopBar from "../../../../../Components/v2/FMSTopBar";
import FMSTheme from "../../../../FMSTheme";
import FMSSidebar from '../../../../../Components/v2/FMSSidebar';
import EnhancedTable from '../../../../../Components/v2/FMSTableForVessel';
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../../../Components/v2/FMSNavBarMUI";

import { Container, Button, Form, Modal, Row, Col, InputGroup } from 'react-bootstrap';

import Chart from 'react-apexcharts';

import Stack from 'react-bootstrap/Stack';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import DropDownForm from '../../../../../Components/DropDownForm';
import { ClipboardData } from 'react-bootstrap-icons';

import PropTypes from 'prop-types';

import FMSAuthenticateToken from '../../../../../Components/v2/FMSAuthenticateToken';
import moment from "moment";

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import DynamicLineChartDoubleAxis from "../../../../../Components/v2/FMSChart"


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function reducer(state, action) {
    switch (action.type) {
        case "RESET":
            return { ...state, isShowData: false, isEnginePerformanceLoaded: false, isFuelConsumptionLoaded: false };
        case "SET_SHOW_DATA":
            return {...state, isShowData: true, isEnginePerformanceLoaded: false, isFuelConsumptionLoaded: false };
        case "SET_ENGINE_LOADED":
            return { ...state, isEnginePerformanceLoaded: true };
        case "SET_FUEL_LOADED":
            return { ...state, isFuelConsumptionLoaded: true };
        default:
            return state;
    }
}

export default function FMSEnginePerformancePage() {
    const dateTimeRef = useRef(null);
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const vesselId = "533ed8f4-3b4a-43d9-870e-584a383f705e"

    const [enginePerformance, setEnginePerformance] = useState({});

    const [fuelConsumption, setFuelConsumption] = useState({});

    const datetimeFormat = "yyyy-MM-DD HH:mm:ssZ"
    const utcOffset = moment().utcOffset();

    const [selectStartDate, setSelectStartDate] = useState(
        moment("2024-11-14 01:00:00+03:00")
    );

    const [selectEndDate, setSelectEndDate] = useState(
        moment("2024-11-15 05:00:00+03:00")
    );

    

    const [isDataLoaded, setIsDataLoaded] = useState(true);
    

    const [mainEnginePerformance, setMainEnginePerformance] = useState({});
    const [auxiliaryEnginePerformance, setAuxiliaryEnginePerformance] = useState({});

    const [durationValue, setDurationValue] = useState(0);

    const initialState = {
        isShowData: false,
        isEnginePerformanceLoaded: false,
        isFuelConsumptionLoaded: false,
    };

    const [states, dispatch] = useReducer(reducer, initialState);

    const durationOption = [
        {
            label: "1 Hour Ago",
            value: 1,
            duration: {
                number: 60,
                unit: "minutes",
            }
        },
        {
            label: "1 Day Ago",
            value: 2,
            duration: {
                number: 1,
                unit: "day",
            }
        },
        {
            label: "1 Week Ago",
            value: 3,
            duration: {
                number: 1,
                unit: "week",
            }
        },
        {
            label: "1 Month Ago",
            value: 4,
            duration: {
                number: 1,
                unit: "month",
            }
        },
        {
            label: "3 Months Ago",
            value: 5,
            duration: {
                number: 3,
                unit: "months",
            }  
        },
    ];

    useEffect(() => {
        loadEnginePerformance();
    }, []);

    useEffect(() => {
        console.log("Setting engine performance");
        if(enginePerformance && !objectIsEmpty(enginePerformance)){
            const readMainEnginePort = enginePerformance.port.me;
            const readMainEngineStarboard = enginePerformance.starboard.me;
            const readAuxiliaryEnginePort = enginePerformance.port.ae;
            const readAuxiliaryEngineStarboard = enginePerformance.port.me;
    
            // console.log(readAuxiliaryEnginePort.length);
            // console.log(readAuxiliaryEngineStarboard.length);
    
            {
                console.log("Processsiiing");
                const portData = processEngineData(readMainEnginePort);
                const starboardData = processEngineData(readMainEngineStarboard);
                
                setMainEnginePerformance({
                    port: portData,
                    starboard: starboardData,
                });
            }
    
            {
                const portData = processEngineData(readAuxiliaryEnginePort);
                const starboardData = processEngineData(readAuxiliaryEngineStarboard);
            
                setAuxiliaryEnginePerformance({
                    port: portData,
                    starboard: starboardData,
                });
            }
        }

        
        
    }, [enginePerformance]);


    useEffect(() => {

    }, [fuelConsumption]);

    useEffect(() => {
        if(durationValue){
            try {
                let dateTime = new Date();
                let startDate = "";
                let endDate = "";
                const selectedOption = durationOption.find(option => option.value == durationValue);
                if(selectedOption){
                    const { number, unit } = selectedOption.duration;
                    startDate = moment(dateTime).subtract(number, unit);
                    endDate = moment(dateTime);
                    setSelectStartDate(startDate);
                    setSelectEndDate(endDate);
                }
            } catch (exception) {
                console.log(exception);
            }
        }
        
    }, [durationValue]);

    useEffect(() => {
        if(!isDataLoaded) return;
        dispatch({ type: "RESET" });
    }, [selectStartDate]);

    useEffect(() => {
        if(!isDataLoaded) return;
        dispatch({ type: "RESET" });
    }, [selectEndDate]);

    useEffect(() => {
        if(!isDataLoaded){

        }
    }, [isDataLoaded]);

    useEffect(() => {
        if(states.isShowData){
            loadDataBasedOnTab();
        }
    }, [states.isShowData]);



    const objectIsEmpty = (obj) =>
        Object.keys(obj).length === 0 && obj.constructor === Object;

    const processEngineData = (engineData) => {
        const result = {};
        for (const partName in engineData) {
            const partEngineData = engineData[partName];
            const { min, max, unit, data }= partEngineData;
            const readDates = data.map(({ read_date }) => moment(read_date).format("DD/MM/YY HH:mm:ss"));
            const readValues = data.map(({ reading_value }) => reading_value);
    
            result[partName] = {
                min: min,
                max: max,
                unit: unit,
                dates: readDates,
                values: readValues,
            };
        }
        return result;
    };

    const loadEnginePerformance = async () => {
        try {
            console.log("LOAD ENGINE PERFORMANCE");
            console.log(selectStartDate.format(datetimeFormat));
            console.log(selectEndDate.format(datetimeFormat));

            let result = await getEnginePerformanceV2(cookies.token, 
                cookies.languageId, vesselId, selectStartDate.format(datetimeFormat), selectEndDate.format(datetimeFormat));
            setEnginePerformance(result);
            
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadFuelConsumption = async () => {
        try {
            let result = await getFuelConsumptionV2(cookies.token, 
                cookies.languageId, selectStartDate, selectEndDate);
            setFuelConsumption(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const onClickShowData = async() => {
        if(!isDataLoaded){
            return; // prevent spam
        }


        setMainEnginePerformance({});
        setAuxiliaryEnginePerformance({});

        dispatch({ type: "SET_SHOW_DATA" });
    }

    const loadDataBasedOnTab = async() => {
        setIsDataLoaded(false);
        console.log("Tab value");
        console.log(tabValue);
        switch(tabValue){
            case 0:
            case 1:
                if(!states.isEnginePerformanceLoaded){
                    await loadEnginePerformance();
                    dispatch({ type: "SET_ENGINE_LOADED" });
                }
                break;
            case 2:
                console.log("LOAD FUEL CONSUMPTION");
                if(!states.isFuelConsumptionLoaded){
                    await loadFuelConsumption();
                    dispatch({ type: "SET_FUEL_LOADED" });
                }
                break;
            case 3:
                break;
            case 4:
                break;
            default:
                break;
            
        }
        setIsDataLoaded(true);
        console.log("STATES");
        console.log(states);
    }

    const onChangeSelectStartDate = async(date) => {
        if (date && date.isValid()) {
            setSelectStartDate(date); // Keep as a Moment object
            setDurationValue(0);
        }
    }

    const onChangeSelectEndDate = async(date) => {
        if (date && date.isValid()) {
            setSelectEndDate(date); // Keep as a Moment object
            setDurationValue(0);
        }
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);  
        loadDataBasedOnTab();
    };

    const durationSelectHandler = (e) => {
        setDurationValue(e.target.value);
    }

    const renderGraph = (data1, data2, firstLabel, secondLabel) => {
        let min1 = Number(data1?.min || 0);
        let max1 = Number(data1?.max || 0);
        if(min1 == 0 && max1 == 0){
            max1 = 100;
        }
        let stepSize1 = Math.max(Math.round((max1 - min1)/5), 1);

        let min2 = Number(data2?.min || 0);
        let max2 = Number(data2?.max || 0);
        if(min2 == 0 && max2 == 0){
            max2 = 100;
        }
        let stepSize2 = Math.max(Math.round((max2 - min2)/5), 1);
        
        const data1Length = data1?.dates?.length || 0;
        const data2Length = data2?.dates?.length || 0;
        const minLength = Math.min(data1Length, data2Length);

        const synchronizedData1 = {
            values: (data1?.values || []).slice(0, minLength),
            dates: (data1?.dates || []).slice(0, minLength),
        };
    
        const synchronizedData2 = {
            values: (data2?.values || []).slice(0, minLength),
            dates: (data2?.dates || []).slice(0, minLength),
        };

        const unit = data1?.unit || "";
        const unitText = (unit == "")? "%" : `${unit}`;

        const label1 = `${firstLabel} (${unitText})`;
        const label2 = `${secondLabel} (${unitText})`;

        

        return (<DynamicLineChartDoubleAxis
            chartData={
                {
                    firstDataset: synchronizedData1?.values || [],
                    secondDataset: synchronizedData2?.values || []
                }
            }

            xAxisLabels={ synchronizedData1?.dates || []}

            chartLimits={{
                y1: { min: min1, max: max1, stepSize: stepSize1 },
                y2: { min: min2, max: max2, stepSize: stepSize2 },
            }}

            labels= {{
                firstLabel: label1,
                secondLabel: label2
            }}
        />)
    }
    
    const mainEnginePerformanceStarboard = mainEnginePerformance?.starboard;
    const mainEnginePerformancePort = mainEnginePerformance?.port;

    const auxiliaryEnginePerformanceStarboard = auxiliaryEnginePerformance?.starboard;
    const auxiliaryEnginePerformancePort = auxiliaryEnginePerformance?.port;



    return (
        <>
            <ThemeProvider theme={FMSTheme}>
                <FMSAuthenticateToken>
                    <div className='mainContainerFMS'>
                        <NavBar />
                        <div className='childMainContainerFMS'>
                            <div className='childMainContainerFMS-TOP'>
                                <div className='childMainContainerFMS-TOP-Left'><p className='ptitleFMSDashboard'>ENGINE PERFORMANCE</p></div>
                                <div className='childMainContainerFMS-TOP-Mid'></div>
                                <div className='childMainContainerFMS-TOP-Right'></div>
                            </div>
                            <div className='childMainContainerFMS-BODY overflow-auto'>
                                <Box
                                    sx={{ bgcolor: 'background.paper', display: 'flex', width: '100%', border: 1, borderColor: 'divider'}}
                                >
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={tabValue}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        <Tab label="Main Engine Performance" {...a11yProps(0)} />
                                        <Tab label="Auxiliary Engine Performance" {...a11yProps(1)} />
                                        <Tab label="Fuel Usage" {...a11yProps(2)} />
                                        <Tab label="Navigation" {...a11yProps(3)} />
                                        <Tab label="Tank Level" {...a11yProps(4)} />
                                    </Tabs>
                                    <Container fluid={true} className="">
                                        <Stack 
                                            direction={{ xs: "column", sm: "row" }} // Column for small screens, row for larger screens
                                            spacing={2} // Adjust spacing between items
                                            alignItems="center"
                                            className="pb-2 pl-3"
                                        >
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Row xs={6} className="align-items-center">
                                                    <Col>
                                                        <DateTimePicker 
                                                            onChange={onChangeSelectStartDate}
                                                            value={selectStartDate}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <DateTimePicker 
                                                            onChange={onChangeSelectEndDate}
                                                            value={selectEndDate}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <FormControl fullWidth>
                                                            <Select 
                                                                id="select-duration"
                                                                value={durationValue}
                                                                onChange={durationSelectHandler}
                                                                displayEmpty
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                                <MenuItem value='0'>Select Time...</MenuItem>
                                                                {durationOption.map((option, index) => (
                                                                    <MenuItem key={index} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>  
                                                        </FormControl>
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            style={{ width: '200px', height: "40px" }}
                                                            onClick={onClickShowData}
                                                        >
                                                            <div className="btnContainer">
                                                                <div className="btnContainerLeft" ><ClipboardData size={25} /></div>
                                                                <div className="btnContainerRight">{'Show Data'}</div>
                                                            </div>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                
                                            </LocalizationProvider>
                                        </Stack>                
                                        <TabPanel value={tabValue} index={0}>
                                            <Col xs={12} className="pt-5">
                                                <Row xs={6} className="">
                                                    <Col xs={12} sm={6} className="d-flex flex-column justify-content-center align-items-center">
                                                        <h1 class="text-dark">RPM</h1>
                                                        {renderGraph(mainEnginePerformancePort?.rpm || {}, 
                                                            mainEnginePerformanceStarboard?.rpm || {}, 
                                                            "Port RPM", 
                                                            "Starboard RPM"
                                                        )}
                                                    </Col>
                                                    <Col xs={12} sm={6} className="d-flex flex-column justify-content-center align-items-center">
                                                        <h1 class="text-dark">Oil Pressure</h1>
                                                        {renderGraph(
                                                            mainEnginePerformancePort?.oilPressure || {}, 
                                                            mainEnginePerformanceStarboard?.oilPressure || {}, 
                                                            "Port Oil Pressure", 
                                                            "Starboard Oil Pressure",
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row xs={6} className="">
                                                    <Col xs={12} sm={6} className="d-flex flex-column justify-content-center align-items-center">
                                                        <h1 class="text-dark">Boost Pressure</h1>
                                                        {renderGraph(mainEnginePerformancePort?.boostPressure || {}, 
                                                            mainEnginePerformanceStarboard?.boostPressure || {}, 
                                                            "Port Boost Pressure", 
                                                            "Starboard Boost Pressure"
                                                        )}
                                                    </Col>
                                                    <Col xs={12} sm={6} className="d-flex flex-column justify-content-center align-items-center">
                                                        <h1 class="text-dark">Clutch Oil Pressure</h1>
                                                        {renderGraph(
                                                            mainEnginePerformancePort?.clutchOilPressure || {}, 
                                                            mainEnginePerformanceStarboard?.clutchOilPressure || {}, 
                                                            "Port Clutch Oil Pressure", 
                                                            "Starboard Clutch Oil Pressure"
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row xs={6} className="">
                                                    <Col xs={12} sm={6} className="d-flex flex-column justify-content-center align-items-center">
                                                        <h1 class="text-dark">Cooling Water</h1>
                                                        {renderGraph(mainEnginePerformancePort?.coolingWater || {}, 
                                                            mainEnginePerformanceStarboard?.coolingWater || {}, 
                                                            "Port Cooling Water", 
                                                            "Starboard Cooling Water"
                                                        )}
                                                    </Col>
                                                    <Col xs={12} sm={6} className="d-flex flex-column justify-content-center align-items-center">
                                                        <h1 class="text-dark">Fuel Rack</h1>
                                                        {renderGraph(
                                                            mainEnginePerformancePort?.fuelRack || {}, 
                                                            mainEnginePerformanceStarboard?.fuelRack || {}, 
                                                            "Port Fuel Rack", 
                                                            "Starboard Fuel Rack"
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row xs={6} className="">
                                                    <Col xs={12} sm={6} className="d-flex flex-column justify-content-center align-items-center">
                                                        <h1 class="text-dark">Exhaust Temperature</h1>
                                                        {renderGraph(mainEnginePerformancePort?.exhaustTemperature || {}, 
                                                            mainEnginePerformanceStarboard?.exhaustTemperature || {}, 
                                                            "Port Exhaust Temperature", 
                                                            "Starboard Exhaust Temperature"
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </TabPanel>
                                    
                                    
                                    
                                        <TabPanel value={tabValue} index={1}>
                                            <Col xs={12} className="pt-5">
                                                <Row xs={6} className="">
                                                    <Col xs={12} sm={6} className="d-flex flex-column justify-content-center align-items-center">
                                                        <h1 class="text-dark">RPM</h1>
                                                        {renderGraph(auxiliaryEnginePerformancePort?.rpm || {}, 
                                                            auxiliaryEnginePerformanceStarboard?.rpm || {}, 
                                                            "Port RPM", 
                                                            "Starboard RPM"
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </TabPanel>
                                        <TabPanel value={tabValue} index={2}>
                                            <Col xs={12} className="pt-5">
                                                <Row xs={6} className="">
                                                    <Col xs={12} sm={6} className="d-flex flex-column justify-content-center align-items-center">
                                                        <h1 class="text-dark">RPM</h1>
                                                        {renderGraph(auxiliaryEnginePerformancePort?.rpm || {}, 
                                                            auxiliaryEnginePerformanceStarboard?.rpm || {}, 
                                                            "Port RPM", 
                                                            "Starboard RPM"
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </TabPanel>
                                        <TabPanel value={tabValue} index={3}>
                                            Item Four
                                        </TabPanel>
                                        <TabPanel value={tabValue} index={4}>
                                            Item Five
                                        </TabPanel>
                                    </Container>
                                </Box>
                                
                                
                                    
                                {/* <EnhancedTable data={listVessel} columns={columnsForTable} /> */}
                                
                            </div>
                        </div>
                    </div>
                </FMSAuthenticateToken>
            </ThemeProvider>
        </>
    );
}