import React, { useEffect } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function LinearProgressWithLabel({ value, height, color, valueUnit }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          width: "100%",
          mr: 1,
          position: "relative",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
          borderRadius: "5px",
        }}
      >
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            height: height,
            borderRadius: "5px",
            backgroundColor: "#f8f8f8",
            "& .MuiLinearProgress-bar": {
              borderRadius: "5px",
              backgroundColor: color,
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 50, mr: 2 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.primary", fontSize: 15 }}
        >
          {Math.round((value))} {valueUnit}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  valueUnit: PropTypes.string,
};

LinearProgressWithLabel.defaultProps = {
  valueUnit: "",
};

export default function LinearWithValueLabel({
  value = 0,
  height = 16,
  minValue = 0,
  maxValue = 100,
  redRange = [95, 100],
  yellowRange = [80, 94],
  greenRange = [0, 79],
  valueUnit = "%",
}) {
  let normalizedValue = 0;


  if (value > maxValue){
    value = 0;
  }

  if (maxValue !== minValue) {
    normalizedValue = ((value - minValue) / (maxValue - minValue)) * 100;
  }
  else {
    normalizedValue = 0;
  }


  let color;
  if (normalizedValue >= redRange[0] && normalizedValue <= redRange[1]) {
    color = "#ea0808";
  } else if (
    normalizedValue >= yellowRange[0] &&
    normalizedValue <= yellowRange[1]
  ) {
    color = "#eab308";
  } else if (
    normalizedValue >= greenRange[0] &&
    normalizedValue <= greenRange[1]
  ) {
    color = "#2563eb";
  } else {
    color = "#CCCCCC";
  }

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel
        value={normalizedValue}
        height={height}
        color={color}
        valueUnit={valueUnit}
      />
    </Box>
  );
}

LinearWithValueLabel.propTypes = {
  value: PropTypes.number.isRequired,
  height: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  redRange: PropTypes.arrayOf(PropTypes.number),
  yellowRange: PropTypes.arrayOf(PropTypes.number),
  greenRange: PropTypes.arrayOf(PropTypes.number),
  valueUnit: PropTypes.string,
};

LinearWithValueLabel.defaultProps = {
  valueUnit: "%",
};
