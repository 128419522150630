import "./NewBitLamp.css"

export default function BitLamp({ text, alarm, bitlampColor = "" , fontSize=16, isGreen = false}) {
    const renderBitlamp = () => {
        let elementToRender;
        // if(!isGreen){
        //     if(alarm === "true"){
                
        //         elementToRender = <div className="innerBitLampContainerLeftOn"></div>  
        //     } else {
        //         elementToRender = <div className="innerBitLampContainerLeftOff" ></div>
        //     }
        // } else {
        //     if(alarm === "true"){
                
        //         elementToRender = <div className="innerBitLampContainerLeftOnGreen"></div>  
        //     } else {
        //         elementToRender = <div className="innerBitLampContainerLeftOff" ></div>
        //     }
        // }

        if(alarm === "true"){
            elementToRender = <div className="innerBitLampContainerLeftOn"></div>  
        } else if(alarm === "false"){
            elementToRender = <div className="innerBitLampContainerLeftOff" ></div>
        }
        return elementToRender;
    }
    return (
        // <div className="bitLamp">
            // {
            //     alarm === "true" ?
            //         <div className="bitLampOn"></div> : <div className="bitlampBlack" ></div>
            // }

        //     <div className="textBitLamp" style={{ fontSize }}>{text}</div>
        // </div>

        <div className="bitLampNewMSB">
            <div className="innerBitLampContainerLeft">
            {
                renderBitlamp()
                
            }
            </div>
            <div className="innerBitLampContainerRight">
                <p className="textBitLamp">{text}</p>
            </div>
        </div>
    )
}