import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import FMSAuthenticate from '../FMSAuthenticate';
import { isTokenValid } from '../../../Helpers/ApplicationHelpers';
const FMSAuthenticateToken = ({ children }) => {
    const [cookies] = useCookies(["token"]);

    const params = {
        validationFunc: isTokenValid, // Function to validate the token
        validationData: cookies.token, // Token retrieved from cookies
        navigateURL: "/FMS" // Redirect URL if validation fails
    };
    
    return (
        <FMSAuthenticate params={params}>
            {children}
        </FMSAuthenticate>
    );
};

export default FMSAuthenticateToken;