// import "../../SMSApp.css";
import "./index.css";
import { Col, Row, Form, Modal } from "react-bootstrap";
import BarGraph from "../../../Components/BarGraph/BarGraph";
import { Anchor, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { InfoCircle, XSquareFill } from "react-bootstrap-icons";
import { isTokenValid, getConvertedTanksReadingValues, findAllTrimLevelForTankLevel, findAllTank, getSMSCoordinateByCoordinateType, getPLCReadingValueForTankLevel,getReadingValueNew, convertTankLevelIntegerValue } from "../../../Helpers/ApplicationHelpers";
import TankLevel from "../../../Components/NewTankLevel/NewTankLevel";
import Paginations from "../../../Components/Pagination";
import Loading from "../../../Components/LoadingForSMSDashboard/Loading";
import DropDownForm from "../../../Components/DropDownForm";
import DynamicLineChart from "../../../Components/DynamicLineChart";
import moment from "moment";
import Select from 'react-select';
import arrowRight from '../../../Assets/Images/arrow_tank_trim.png'
import arrowLeft from '../../../Assets/Images/arrow_tank_trim_left.png'

export default function CustomerSMSTankLevelPage(props) {
    const { smsSerialNumber, vesselId } = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [cookies, setCookie] = useCookies(["token"]);
    const [listTankAndValue, setListTankAndValue] = useState();
    const [isDataReady, setIsDataReady] = useState(false);
    const [trimLevel, setTrimLevel] = useState([]);
    const [selectedTrim, setSelectedTrim] = useState();
    const [clickTrimPos, setClickTrimPos] = useState('');


    useEffect(() => {
        if (listTankAndValue) {
            if(listTankAndValue.length > 0) {
                console.log('listTankAndValue ->', listTankAndValue);
                console.log('isDataReady', isDataReady);
                setIsDataReady(true);
            }
        }
    }, [listTankAndValue]);

    useEffect(() => {
        if (isDataReady) {
            setLoading(false);
        } else{
            loadCoordinate();
        }
        
    }, [isDataReady]);

    useEffect(() => {
        loadTrimLevel();
        loadCoordinate();
    }, []);

    useEffect(() => {
        console.log('TRIM LEVEL LIST', trimLevel);
        let findTrim = trimLevel.find(p => p.trim_order === 6);
        setSelectedTrim(findTrim);
    }, [trimLevel]);

    const loadCoordinate = async () => {
        try {
            console.log('selectedTrim', selectedTrim);
            let response = await getConvertedTanksReadingValues(cookies.token, smsSerialNumber, vesselId, selectedTrim !== undefined ? selectedTrim.trim_id : 0);
                if (response) {
					let receivedDate = moment(response[0]?.received_date).format("yyyy-MM-DD HH:mm:ss");
					setLastReceivedDate(receivedDate);
					setListTankAndValue(response);
				}
				setIsDataReady(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadTrimLevel = async () => {
        try {
            let response = await findAllTrimLevelForTankLevel(cookies.token);
            setTrimLevel(response);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const changeTrimPosition = async (click) => {
        setLoading(true);
        if (selectedTrim !== undefined && (selectedTrim.trim_order > 0 && selectedTrim.trim_order < 12)) {
            if (click === 'left') {
                let selectedTrimTmp = selectedTrim.trim_order - 1;
                let findTrim = trimLevel.find(p => p.trim_order === selectedTrimTmp)
                setSelectedTrim(findTrim);
            } else if (click === 'right') {
                let selectedTrimTmp = selectedTrim.trim_order + 1;
                let findTrim = trimLevel.find(p => p.trim_order === selectedTrimTmp)
                setSelectedTrim(findTrim);
            }
        } else if (selectedTrim && selectedTrim.trim_order === 0) {
            if (click === 'left') {
                let findTrim = trimLevel.find(p => p.trim_order === 0);
                setSelectedTrim(findTrim);
            } else if (click === 'right') {
                let selectedTrimTmp = selectedTrim.trim_order + 1;
                let findTrim = trimLevel.find(p => p.trim_order === selectedTrimTmp);
                setSelectedTrim(findTrim);
            }
        } else if (selectedTrim && selectedTrim.trim_order === 12) {
            if (click === 'left') {
                let selectedTrimTmp = selectedTrim.trim_order - 1;
                let findTrim = trimLevel.find(p => p.trim_order === selectedTrimTmp);
                setSelectedTrim(findTrim);
            } else if (click === 'right') {
                let findTrim = trimLevel.find(p => p.trim_order === 12);
                setSelectedTrim(findTrim);
            }
        }
    }

    
    return (
        <>
            <div className="tankNewRes">
                <div className="trimContainerTankLevel">
                    <div className="trimContainerTankLevelLEFT">
                        <img style={{ width: '60%' }} src={arrowLeft} onClick={() => {
                            changeTrimPosition('left');
                        }} />
                    </div>
                    <div className="trimContainerTankLevelMID">
                        <p className="txtforLastReceivedData" style={{ fontSize: 'large', fontWeight: 'bold', margin: '0px' }}>{'TRIM'}</p>
                        <p className="txtforLastReceivedData" style={{ fontSize: 'large', fontWeight: 'bold', margin: '0px' }}>{selectedTrim ? selectedTrim.trim_level : '0.00'}</p>
                    </div>
                    <div className="trimContainerTankLevelRIGHT">
                        <img style={{ width: '60%' }} src={arrowRight} onClick={() => {
                            changeTrimPosition('right');
                        }} />
                    </div>
                </div>
                <div className="mainPageTank">
                    {
                        listTankAndValue && listTankAndValue.map((data, index) => {
                            return (
                                <div key={index} className="innerTank">
                                    <TankLevel text={data.tank_full_name ? data.tank_full_name : ""} value={data.value_percentage ? (data.value_percentage).toFixed(2) : 0} wireBreak={data.wirebreak} m3Value={data.capacity_m3 ? (data.capacity_m3).toFixed(2) : 0} heightReading={data.height_reading ? (data.height_reading * 100).toFixed(2) : 0} />
                                </div>
                            )
                        })

                    }
                    <div className="lastReceivedDateContainerNew">
                        <p className="txtforLastReceivedData" style={{ margin: '20px' }}>Last Received Data on {lastReceivedDate}</p>
                    </div>

                </div>

            </div>
            <Loading
                loading={loading}
            />
        </>
    );
}