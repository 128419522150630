// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerBoxBodyFMS {
    width: 100%;
    padding: 10px;
}

/* * { border: 1px solid red; } */`, "",{"version":3,"sources":["webpack://./src/v2/Pages/FMSVessel/VesselSMS/EnginePerformance/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA,iCAAiC","sourcesContent":[".containerBoxBodyFMS {\n    width: 100%;\n    padding: 10px;\n}\n\n/* * { border: 1px solid red; } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
