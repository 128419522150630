import "./BitLampFireAlarm.css"
import SmokeGreen from "../../Assets/Images/CustomerDashboard/symbol_smoke_green.png"
import SmokeRed from "../../Assets/Images/CustomerDashboard/symbol_smoke_red.png"
import TempGreen from "../../Assets/Images/CustomerDashboard/symbol_temperature_green.png"
import TempRed from "../../Assets/Images/CustomerDashboard/symbol_temperature_red.png"
import MCPGreen from "../../Assets/Images/CustomerDashboard/symbol_mcp_green.png"
import MCPRed from "../../Assets/Images/CustomerDashboard/symbole_mcp_red.png"

export default function BitLampForFireAlarm({ type, alarm}) {
    return (
        <div className="BitLampFireAlarmMainContainer">
            {
                type === "smoke" ? (
                    alarm === "false" ? <img style={{ width: '100%' }} src={SmokeGreen} /> : <img style={{ width: '100%' }} src={SmokeRed} />
                ):
                type === "temp" ? (
                    alarm === "false" ? <img style={{ width: '100%' }} src={TempGreen} /> : <img style={{ width: '100%' }} src={TempRed} />
                ):
                type === "mcp" ? (
                    alarm === "false" ? <img style={{ width: '100%' }} src={MCPGreen} /> : <img style={{ width: '100%' }} src={MCPRed} />
                ):
                (<></>)
            }
        </div>
    )
}