import "./index.css";
import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Modal, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getUserAccessById, insertUpdateVesselXDocument, getCountMasterDocument, getCountEquipmentDocument,
    findEquipmentDocumentById, findMasterDocumentById, findSelectedById, deleteEquipmentDocument, getVesselXEquipmentPhotoCount, getVesselxEquipmentPhotoById
} from "../../../Helpers/ApplicationHelpers";
import {getSelectedDocumentById, deleteInventoryDocument} from "../../../Helpers/InventoryHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Paginations from "../../../Components/Pagination";
import Modals from "../../../Components/modal";
import {
    Trash, ArrowLeft, Download, FilePlusFill, EyeFill,XSquareFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill, Search, ArrowClockwise
} from "react-bootstrap-icons";
import ContainerBox from "../../../Components/ContainerBox";
import Loading from "../../../Components/Loading";
import TextBox from "../../../Components/Textbox";
import FormSelect from "../../../Components/FormSelect";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../Helpers/Base64Downloader";
import moment from 'moment';
import { Trans, useTranslation } from "react-i18next";
import Sidebar from "../../../Components/SideBar";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



export default function CustomerVesselEquipmentDocumentPage() {
    const inputFile = useRef(null);
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [listDocument, setListDocument] = useState([]);
    const [listDocumentMaster, setListDocumentMaster]= useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [documentId, setDocumentId] = useState("");
    const [listDocumentSparepart, setListDocumentSparepart] = useState([]);
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const [sparepartCatalogId, setSparepartCatalogId] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [descending, setDescending] = useState(false);
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState();
    const [vesselEquipmentDocumentId, setVesselEquipmentDocumentId] = useState("");
    const [sparepartCatalogDocumentId, setSparepartCatalogDocumentId] = useState("");
    const [downloadSparepartCatalogDocumentId, setdownloadSparepartCatalogDocumentId] = useState("")
    const [downloadVesselEquipmentDocumentId, setDownloadVesselEquipmentDocumentId] = useState("")
    const [listDocumentToBeView, setListDocumentToBeView] = useState([]);
    const [vesselEquipmentId, setVesselEquipmentId] = useState("")
    const [vesselName, setVesselName] = useState("")
    const [equipmentName, setEquipmentName] = useState("")
    const [equipmentCategoryId, setEquipmentCategoryId] = useState("")
    const [vesselId, setVesselId] = useState("")
    const [selectedDownloadIdMaster, setSelectedDownloadIdMaster] = useState();
    const [listinventoryMasterId, setListInventoryMasterId] = useState([]);
    const [selectedDocumentIdMaster, setSelectedDocumentIdMaster] = useState();
    const [selectedDocumentIdEquipment, setSelectedDocumentIdEquipment] = useState();
    const [selectedDocumentDownloadIdEquipment, setSelectedDocumentDownloadIdEquipment] = useState();
    const [selectedDeleteIdEquipment, setSelectedDeleteIdEquipment] = useState();
    const [zoomFactor, setZoomFactor] = useState(0.5);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [vesselEquipmentPhotoCount, setVesselEquipmentPhotoCount] = useState(0);
    const [listPhoto, setListPhoto] = useState([]);
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [description, setDescription] = useState(null);
    const [orderBy, setOrderBy] = useState({
        label: `Created Date`,
        value: "created_date"
    });
    const orderByList = [{
        label: "Created Date",
        value: "created_date"
    }];
    const [newDocument, setNewDocument] = useState({
        id: 0,
        document_base64: "",
        document_name: "",
        description: "",
        vessel_x_equipment_id: "",
        is_active: true,
        done: false,
        reference_number: ""
    });
    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        document_base64: "",
        document_name: "",
        description: "",
        inventory_master_id: "",
        is_active: true,
        created_date: "",
        created_by: "",
        updated_date: "",
        updated_by: "",
        reference_number: "",
    });

    // const [dataInventoryMaster, setDataInventoryMaster] = useState({
    //     id: 0,
    //     inventory_master_name: "",
    //     is_active: true,
    // });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                setShowDocumentDetailModal(false);
                loadAccess();
                loadDocumentData();
                loadPhoto();
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     if (!showDocumentDetailModal) {
    //         setDocumentToBeViewed({
    //             id: 0,
    //             document_base64: "",
    //             document_name: "",
    //             reference_number: "",
    //             description: "",
    //             vessel_x_equipment_id: "",
    //             is_active: true
    //         });
    //         setSparepartCatalogDocumentId("");
    //         setVesselEquipmentDocumentId("");
    //     }
    // }, [showDocumentDetailModal]);

    useEffect(() => {
        if (selectedDocumentIdMaster !== "")
            console.log('selectedDocumentIdMaster',selectedDocumentIdMaster);
            loadDocumentByIdMaster();
    }, [selectedDocumentIdMaster])

    useEffect(() => {
        if (selectedDocumentIdEquipment !== "")
            console.log('selectedDocumentIdEquipment',selectedDocumentIdEquipment);
            loadDocumentByIdEquipment();
    }, [selectedDocumentIdEquipment])

    useEffect(() => {
        if (selectedDocumentDownloadIdEquipment !== "")
            downloadData();
    }, [selectedDocumentDownloadIdEquipment])

    useEffect(() => {
        if (selectedDeleteIdEquipment !== "")
            removeDocument();
    }, [selectedDeleteIdEquipment])

    useEffect(() => {
        if (selectedDownloadIdMaster !== "")
            removeInventoryDocument();
    }, [selectedDownloadIdMaster])

    // useEffect(() => {
    //     if (vesselEquipmentDocumentId !== "")
    //         loadVesselEquipmentDocumentById();
    // }, [vesselEquipmentDocumentId])

    // useEffect(() => {
    //     if (downloadVesselEquipmentDocumentId !== "")
    //         downloadVesselEquipmentDocument()
    // }, [downloadVesselEquipmentDocumentId])

    const resetUploadForm = async () => {
        let newDocument = {
            id: 0,
            document_base64: "",
            document_name: "",
            reference_number: "",
            description: "",
            vessel_x_equipment_id: vesselEquipmentId,
            is_active: true,
        };
        setNewDocument({ ...newDocument, newDocument });
    }

    // useEffect(() => {
    //     loadDocumentData();
    // }, [page]);

    // useEffect(() => {
    //     loadDocumentData();
    // }, [itemPerPage]);

    // useEffect(() => {
    //     loadDocumentData();
    // }, [orderBy]);

    // useEffect(() => {
    //     // console.log(sparepartCatalogId);
    //     // if (sparepartCatalogId !== "") {
    //     //     console.log('tes');
    //     //     loadDocumentDataSparepart()
    //     // }
    //     async function loadSparepartDoc() {
    //         if (sparepartCatalogId !== "") {
    //             await loadDocumentDataSparepart();
    //         }
    //     }
    //     loadSparepartDoc();
    //     forceUpdate();
    // }, [sparepartCatalogId]);

    // useEffect(() => {

    //     if (listDocument.length > 0) {
    //         console.log(sparepartCatalogId);
    //         loadDocumentDataSparepart()
    //     }
    //     // forceUpdate();
    // }, [listDocument])

    // useEffect(() => {
    //     if (searchQuery === "")
    //         loadDocumentData();
    // }, [searchQuery]);

    // useEffect(() => {
    //     setLoading(false);
    //     loadDocumentData()
    // }, [descending]);
    const [documentFile, setDocumentFile] = useState("");



    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    


    // useEffect(() => {
    //     async function submitNewDocument() {
    //         if (newDocument.done) {
    //             await uploadDocument();
    //         }
    //     }
    //     submitNewDocument();
    // }, [newDocument]);

    useEffect(() => {

        console.log(newDocument);
    }, [newDocument])

    useEffect(() => {

        forceUpdate();
        console.log('LIST DOCUMENT EQUIPMENT',listDocument)
    }, [listDocument])

    useEffect(() => {

        // forceUpdate();
        console.log('LIST DOCUMENT MASTER', listDocumentMaster)
    }, [listDocumentMaster])

    useEffect(() => {
        console.log('showDocumentDetailModal', showDocumentDetailModal);
        
    }, [showDocumentDetailModal])

    useEffect(() => {
        console.log(documentToBeViewed);
        if (!showDocumentDetailModal){
            setShowDocumentDetailModal(true);
        }
    }, [documentToBeViewed]);

    // useEffect(() => {
    //     // console.log(listinventoryMasterId);
    //     setDataInventoryMaster({
    //         id: listinventoryMasterId.id,
    //         inventory_master_name: listinventoryMasterId.inventory_master_name,
    //         is_active: listinventoryMasterId.is_active,
    //     })
    //     console.log('DATA INVENTORY MASTER', dataInventoryMaster)
    // }, [listinventoryMasterId]);



    

    // useEffect(() => {
    //     setNewDocument({ ...newDocument, vessel_x_equipment_id: vesselEquipmentId });

    //     // setLoading(false);
    //     // loadDocumentData();
    // }, [vesselEquipmentId])

    const loadDocumentByIdMaster = async () => {
        try {
            // let response = await getSelectedDocumentById(cookies.token, selectedDocumentIdMaster);
            let response = await getSelectedDocumentById(cookies.token, selectedDocumentIdMaster);
            console.log(response);
            setDocumentToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadDocumentByIdEquipment = async () => {
        try {
            let response = await findSelectedById(cookies.token, selectedDocumentIdEquipment);
            console.log('document response when select',response);
            setDocumentToBeViewed(response);
            setLoading(false);
        } catch (exception) {
            console.log(exception)
        }
    }

    const downloadData = async () => {
        try {
            let response = await findSelectedById(cookies.token, selectedDocumentDownloadIdEquipment);
            triggerBase64Download(response.document_base64, response.document_name);
            // setLoading(false);
            setSelectedDeleteIdEquipment("");
        } catch (exception) {
            console.log(exception)
        }
    }

    const uploadDocument = async () => {

        try {
            console.log(newDocument);
            let response = await insertUpdateVesselXDocument(cookies.token, newDocument);
            if (response) {
                alert(`${t("vessel_equipment_document.alert_success_save_data")}`);
                setShowDocumentUploadModal(false);
                resetUploadForm();
                loadDocumentData();
                setDisabledButton(false);
            }
            
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const removeDocument = async () => {
        let response = await deleteEquipmentDocument(cookies.token, selectedDeleteIdEquipment);
        if (response === 0) {
            // window.location.reload();
            // loadInventoryDocuments();
            loadDocumentData();
        }
    }

    const removeInventoryDocument = async () => {
        let response = await deleteInventoryDocument(cookies.token, selectedDownloadIdMaster);
        if (response === 0) {
            // window.location.reload();
            loadDocumentData();
        }
    }

    // const loadInventoryMasterId = async () => {
    //     try{
    //         console.log('loadinventorymasterid')
    //         let getInventoryMasterId = await findMasterIdFromEquipment(cookies.token, location.state.vesselEquipmentId);
    //         console.log(getInventoryMasterId);
    //         setListInventoryMasterId(getInventoryMasterId);
    //     }catch (exception) {
    //         console.log(exception);
    //     }
    // }

    const loadDocumentData = async () => {
        try {
            // setShowDocumentDetailModal(false);
            let countMaster = await getCountMasterDocument(cookies.token, searchParams.get("inventoryMasterId"));
            console.log(countMaster);
            let countEquipment = await getCountEquipmentDocument(cookies.token, searchParams.get("vesselEquipmentId"));
            // console.log(countEquipment);
            let count = countMaster + countEquipment
            let totalPage = count / itemPerPage;
            setTotalPage(totalPage);

            if (countMaster > 0){
                let responseMaster = await findMasterDocumentById(cookies.token, searchParams.get("inventoryMasterId"), page, itemPerPage, orderBy.value, descending);
                // console.log(responseMaster);
                setListDocumentMaster(responseMaster);
            }
            let responseEqp = await findEquipmentDocumentById(cookies.token, searchParams.get("vesselEquipmentId"), page, itemPerPage, orderBy.value, descending);
            // console.log(responseEqp);
            setListDocument(responseEqp);
            

            setLoading(false);
            // setLoading(false);
        } catch (exception) {
            setLoading(false);
            console.log(exception);
        }
    }

    const loadPhoto = async () => {
        try {
            let vesselEquipmentPhotoCount = await getVesselXEquipmentPhotoCount(cookies.token, vesselEquipmentId);
            setVesselEquipmentPhotoCount(vesselEquipmentPhotoCount);
            let totalPage = vesselEquipmentPhotoCount / itemPerPage;
            setTotalPage(totalPage);
            let listPhoto = await getVesselxEquipmentPhotoById(cookies.token, vesselEquipmentId, page, itemPerPage, orderBy.value);
            setListPhoto(listPhoto);
        }


        catch (exception) {
            console.log(exception);
        }

    }

    

    // const loadSparepartCatalogDocumentById = async () => {
    //     try {
    //         let response = await getSparepartCatalogDocumentById(cookies.token, sparepartCatalogDocumentId);
    //         setDocumentToBeViewed(response);
    //         setLoading(false);
    //     } catch (exception) {
    //         console.log(exception)
    //     }
    // }

    // const loadVesselEquipmentDocumentById = async () => {
    //     try {
    //         let response = await getVesselEquipmentDocumentById(cookies.token, vesselEquipmentDocumentId);
    //         setDocumentToBeViewed(response);
    //         setLoading(false);
    //     } catch (exception) {
    //         console.log(exception)
    //     }
    // }

    // const downloadSparepartCatalogDocument = async () => {
    //     try {
    //         let response = await getSparepartCatalogDocumentById(cookies.token, downloadSparepartCatalogDocumentId);
    //         triggerBase64Download(response.document_base64, response.document_name);
    //         setLoading(false);
    //         setdownloadSparepartCatalogDocumentId("");
    //     } catch (exception) {
    //         console.log(exception)
    //     }
    // }

    // const downloadVesselEquipmentDocument = async () => {
    //     try {
    //         let response = await getVesselEquipmentDocumentById(cookies.token, downloadVesselEquipmentDocumentId);
    //         triggerBase64Download(response.document_base64, response.document_name);
    //         setLoading(false);
    //         setDownloadVesselEquipmentDocumentId("");
    //     } catch (exception) {
    //         console.log(exception)
    //     }
    // }

    const loadAccess = async()=>{
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, "/Vessel");
            if(response){
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.base64_img);
        setDescription(item.description)
    };


    return (
        <>
        <div className="mainPartPartCatalogNew">
            <Sidebar companyName={searchParams.get("customerName")} smsSerialNumber={searchParams.get("smsSerialnumber")} vesselId={vesselId} vesselName={searchParams.get("vesselName")} customerName={cookies.userName} />
            <Container fluid className="mainContainerPartCatalogNew">
                <div className="childMainContainerPartCatalogNew">
                <ContainerBox
                    titleCaption={` ${searchParams.get("vesselName")} ${searchParams.get("vesselEquipmentName")} Documents`}
                    useActionContainer={cookies.logingAs=== 'Customer' && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            {/* <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { setShowDocumentUploadModal(true) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_equipment_document.add_button")}</div>
                                </div>
                            </Button> */}
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            {/* <div className="master-table-searchbar-container">
                            <div className="master-table-searchbar-textbox">
                                <Form.Control value={searchQuery} type="text" placeholder="Kata Kunci (Nama Dokumen)"
                                    onKeyPress={async (e) => {
                                        if (e.charCode === 13) {
                                            setLoading(true);
                                            await loadDocumentData();
                                        }
                                    }}
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value);
                                    }} />
                            </div>
                            <div className="master-table-searchbar-button">
                                <div style={{ paddingRight: 5 }}>
                                    <Button onClick={async () => {
                                        setLoading(true);
                                        await loadDocumentData();
                                    }}><Search /></Button>
                                </div>
                                <div style={{ paddingLeft: 5 }}>
                                    <Button onClick={async () => {
                                        setLoading(true);
                                        setSearchQuery("");
                                        await loadDocumentData();
                                    }}><ArrowClockwise /></Button>
                                </div>
                            </div>
                        </div> */}
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            {/* <div style={{ display: "flex" }}>
                                                <Button style={{width:130}} onClick={() => {
                                                    setShowEquipmentDetail(true)
                                                }}><InfoCircleFill/> {t("vessel_equipment_photo.button_detail")}</Button>
                                            </div>

                                            <div style={{ display: "flex" }}>
                                                <Button disabled={cookies.logingAs=== 'Customer' && cookies.extCustomerId !== "null" ? true :!updatedButton} style={{width:130}} className="cancel" onClick={() => {
                                                    showFormPhoto();
                                                    setvesselPhotoEquipment({
                                                        base64_img: '',
                                                        title: '',
                                                        description: '',
                                                        taken_date: ''

                                                    });
                                                    initVesselEquipment();
                                                    setFileImg("");

                                                }}>{t("vessel_equipment_photo.button_add_photo")}</Button>
                                            </div> */}
                                        </div>

                                        <br /><br />
                                        <Col md={12}>
                                            <Row>
                                                {
                                                    listPhoto.map((photo, index) => {
                                                        return (

                                                            <Col md={3}>
                                                                <div key={index} style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignSelf: "center",
                                                                    flexWrap: "nowrap",
                                                                    flexDirection: "column",
                                                                    padding: 10,
                                                                    borderWidth: 1,
                                                                    margin: 5,
                                                                    borderStyle: "solid",
                                                                    borderColor: "#2f71bd",
                                                                    borderRadius: 5,
                                                                    height: 400,

                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flex: 5,
                                                                        alignContent: "flex-start",
                                                                        alignItems: "flex-start",
                                                                        justifyContent: "center",
                                                                        alignSelf: "center",
                                                                        flexWrap: "wrap",

                                                                    }}>
                                                                        <img src={photo.base64_img} onClick={() => {
                                                                            handleClick(photo, index)
                                                                        }} style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                                    </div>
                                                                    <div style={{
                                                                        flex: 1,
                                                                        alignContent: "flex-end",
                                                                        alignItems: "flex-end",
                                                                        justifyContent: "flex-end",
                                                                        alignSelf: "flex-end",
                                                                        flexWrap: "nowrap",
                                                                        flexDirection: "row",
                                                                        width: "100%",
                                                                    }} >
                                                                        <div style={{
                                                                            flex: 1,
                                                                            flexDirection: "column",
                                                                            alignContent: "flex-end",
                                                                            alignItems: "flex-end",
                                                                            justifyContent: "flex-end",
                                                                            alignSelf: "flex-end",
                                                                            textAlign: "center"
                                                                        }}><p>{!photo.title ? "" : photo.title}</p></div>
                                                                        <div style={{
                                                                            flex: 1,
                                                                            flexDirection: "column",
                                                                            alignContent: "flex-end",
                                                                            alignItems: "flex-end",
                                                                            justifyContent: "flex-end",
                                                                            alignSelf: "flex-end",
                                                                            textAlign: "center"
                                                                        }}><p>{!photo.taken_date ? "" : photo.taken_date}</p>
                                                                            <div style={{
                                                                                flex: 1,
                                                                                flexDirection: "column",
                                                                                alignContent: "flex-end",
                                                                                alignItems: "flex-end",
                                                                                justifyContent: "flex-end",
                                                                                alignSelf: "flex-end",
                                                                                textAlign: "center"
                                                                            }}>
                                                                                {/* <Button disabled={cookies.logingAs=== 'Customer' && cookies.extCustomerId !== "null" ? true : !deletedButton} style={{}} onClick={() => {
                                                                                    if (window.confirm(`${t("vessel_equipment_photo.delete_confirmation")}?`)) {
                                                                                        setRemoveId(photo.photo_id)

                                                                                    }
                                                                                }} variant="danger"><Trash /></Button> */}

                                                                            </div>

                                                                        </div>

                                                                        {/* {
                                                                            photo.is_cover === true ?

                                                                                <Form.Check onClick={() => {
                                                                                    setVesselEquipmentPhotoId(photo.photo_id);
                                                                                }} type="checkbox" defaultChecked={true} label={`${t("vessel_equipment_photo.cover_label")}`} />
                                                                                :
                                                                                <>
                                                                                    <input onClick={() => {
                                                                                        setVesselEquipmentPhotoId(photo.photo_id);
                                                                                    }} type="checkbox" />
                                                                                    <label>&nbsp; {t("vessel_equipment_photo.cover_label")}</label>
                                                                                </>
                                                                        } */}

                                                                    </div>

                                                                </div>

                                                            </Col>

                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Col><br /><br />
                                    </div>
                                </div>



                            </div>
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("vessel_equipment_document.table_name1")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("vessel_equipment_document.table_name2")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("vessel_equipment_document.table_name3")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_equipment_document.table_name4")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_equipment_document.table_name5")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("vessel_equipment_document.table_name6")}</div>
                                </div>
                                {
                                    listDocument.map((document, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{document.document_name}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{document.reference_number}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{moment(document.created_date).format("DD/MM/yyyy")}</p></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        // console.log('clicked');
                                                        setSelectedDocumentIdEquipment(document.id);
                                                    }}><EyeFill />
                                                    </Button>
                                                </div></div>

                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setSelectedDocumentDownloadIdEquipment(document.id);
                                                    }}><Download />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    {/* <Button variant="danger" disabled={document.type === 'sparepart' ||  (cookies.extCustomerId !== "null" && cookies.logingAs === 'Customer') ? true : !deletedButton} onClick={() => {
                                                        if (window.confirm(`${t("vessel_equipment_document.delete_confirmation")}  ${document.document_name}?`)) {
                                                            let documentId = document.id
                                                            setSelectedDeleteIdEquipment(documentId);
                                                        }
                                                    }}><Trash />
                                                    </Button> */}
                                                </div></div>
                                        </div>)
                                    }
                                    )
                                }
                                {
                                    listDocumentMaster.map((master, index) => {
                                        return (
                                            <div key={index} className="table-body">
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{listDocument.length + index + 1}</p></div>
                                                <div style={{ flex: 4 }} className="table-body-content"><p>{master.document_name}</p></div>
                                                <div style={{ flex: 3 }} className="table-body-content"><p>{master.reference_number}</p></div>
                                                <div style={{ flex: 3 }} className="table-body-content"><p>{moment(master.created_date).format("DD/MM/yyyy")}</p></div>
                                                <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        // console.log('clicked');
                                                        setSelectedDocumentIdMaster(master.id);
                                                    }}><EyeFill />
                                                    </Button>
                                                </div></div>

                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setSelectedDownloadIdMaster(master.id)
                                                    }}><Download />
                                                    </Button>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    {/* <Button disabled variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("vessel_equipment_document.delete_confirmation")}  ${document.document_name}?`)) {
                                                            setDocumentId(master.id);
                                                        }
                                                    }}><Trash />
                                                    </Button> */}
                                                </div></div>
                                            </div>
                                        )
                                    }
                                )
                                }

                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>
                            {/* <Paginations
                            itemPerPage={itemPerPage}
                            totalPage={totalPage}
                            page={page}
                            setPage={setPage}
                            setItemPerPage={setItemPerPage}
                            itemPerPageSelection={itemPerPageSelection}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            orderBySelection={orderByList}
                            isDescActive={descending}
                            setIsDescActive={setDescending}
                        /> */}


                            <Loading
                                loading={loading}
                            />
                        </div>


                    } />
                    <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                    // if (showDocumentDetailModal){
                    //     setShowDocumentDetailModal(false);
                    // }
                }}>
                    <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.document_name} 
                    useActionContainer={true}
                    actionContainerChild={
                        <div>
                            <Button variant="secondary" style={{
    
                            }} onClick={() => { 
                                setShowDocumentDetailModal(false);
                                }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><XSquareFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_equipment_document.button_closed")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column"
                        }}>
                            <div>
                                <Document
                                    file={documentToBeViewed.document_base64}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    loading={() => {

                                    }}
                                ><div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            overflow: "scroll",
                                        }}>
                                            <Page scale={zoomFactor} pageNumber={pageNumber} />
                                        </div>
                                    </div>
                                </Document>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                    <p>
                                    {t("vessel_equipment_document.page_text")} {pageNumber} {t("vessel_equipment_document.from_text")} {numPages}
                                    </p>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    flexWrap: "wrap",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor <= 0.2}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 0.8);
                                            }}
                                        >

                                            <ZoomOut size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={false}
                                            onClick={() => {
                                                setZoomFactor(0.5);
                                            }}
                                        >
                                            <AspectRatioFill size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5,
                                        flexWrap: "wrap",
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={zoomFactor >= 10}
                                            onClick={() => {
                                                setZoomFactor(zoomFactor * 2);
                                            }}
                                        >
                                            <ZoomIn size={28} />
                                        </Button>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >

                                            <ChevronDoubleLeft size={28} />
                                        </Button>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            <ChevronDoubleRight size={28} />
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    } />

                </Modal>
                </div>
            </Container>
        </div>
        </>
    );

}