// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-container {
    height: 100vh;
    width: 100%;
}

.newMapContainer{
    display: flex;
    width: 100%;
    height: 100vh;
}

`, "",{"version":3,"sources":["webpack://./src/Components/OpenSeaMap.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,WAAW;IACX,aAAa;AACjB","sourcesContent":[".leaflet-container {\n    height: 100vh;\n    width: 100%;\n}\n\n.newMapContainer{\n    display: flex;\n    width: 100%;\n    height: 100vh;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
