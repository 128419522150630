import "./ProgressBar.css";
import ReloadPict from "../../Assets/Images/reload-pict.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import BitLamp from "../BitLamp/BitLamp";
// import ProgressBar from 'react-animated-progress-bar';

const CustomProgressBar = ({ now, min, max, colorRanges }) => {
    const progressBarStyle = {
        height: '1rem',
        backgroundColor: 'black',
        position: 'relative',
    };
    const percentage = ((now - min) / (max - min)) * 100;

    const getColor = () => {
        const sortedRanges = colorRanges.sort((a, b) => a.min - b.min);
        for (let range of sortedRanges) {
            if (percentage >= ((range.min - min) / (max - min)) * 100 &&
                percentage <= ((range.max - min) / (max - min)) * 100) {
                return range.color;
            }
        }
        return 'gray'; 
    };

    const fillStyle = {
        height: '100%',
        width: `${percentage}%`,
        backgroundColor: getColor(),
    };

    return (
        <div style={progressBarStyle}>
            <div style={fillStyle}></div>
        </div>
    );
};

export default function TankLevel({
    text,
    value,
    min = 0,
    max = 100,
    greenRange = { min: 70, max: 100 },
    yellowRange = { min: 30, max: 69 },
    redRange = { min: 0, max: 29 },
    unit,
    highValue,
    lowValue, }) {

        const adjustedValue = value > max || value < 0 ? 0 : value;

        const colorRanges = [
            { min: redRange.min, max: redRange.max, color: '#e11d48' },
            { min: yellowRange.min, max: yellowRange.max, color: '#f59e0b' },
            { min: greenRange.min, max: greenRange.max, color: '#008000' },
        ];


    return (

        <div className="mainProgressbarNew">
            <div className="leftSide">
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <p className="txtforBoxWithText">{text}</p>
                    <p className="txtforBoxWithText">{adjustedValue}{unit}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <CustomProgressBar
                        now={adjustedValue}
                        min={min}
                        max={max}
                        colorRanges={colorRanges}
                    />
                </div>
            </div>
            <div className="rightSide">
                {highValue === '1' ? (
                    <div className="hiContainerOn">
                        <p className="txtforBoxWithText">HI</p>
                    </div>
                ) : null}
                {lowValue === '1' ? (
                    <div className="loContainerOn">
                        <p className="txtforBoxWithText">LO</p>
                    </div>
                ) : null}
            </div>
        </div>
    );
}