import React, { useState, useEffect } from "react";
import "./index.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import CustomerNavigatonBar from "../../Components/CustomerNavBar";
import { Container, Button } from 'react-bootstrap';
import Clock from 'react-live-clock';
import ContainerBox from "../../Components/ContainerBox";
import { isTokenValid, getVesselLocationForCustomer, getVesselForCustomer, findVesselByIdForCustomerDashboard } from "../../Helpers/ApplicationHelpers";
import { Download, EyeFill } from "react-bootstrap-icons";
import Quantum from "../../../src/Assets/Images/tugboatdamen.png";
import GoogleMapContainer from "../../Components/GoogleMapContainerCustomer";
import OpenSeaMap from "../../Components/OpenSeaMapContainer";
import moment from "moment";
import Sidebar from "../../Components/NewSideBarForCustomerPage/NewSideBar";
import vesselImg from "../../Assets/Images/new_arrow_pointer.png";
// import QuantumLogo from "../Assets/Images/ship-solid-red-resized.png";
import { MapContainer, Marker, Popup, TileLayer, useMap, rotatedMarker } from "react-leaflet";
import 'leaflet-rotatedmarker';
import L from 'leaflet';
import RotatedMarker from "../../Components/RotatedMarker";
import DmsCoordinates from "dms-conversion";

export default function CustomerDasboardPage() {
    const navigate = useNavigate();
    const [smsSerialNumber, setSmsSerialNumber] = useState('SMS-000-000001');
    const [vesselId, setVesselId] = useState('2d965d55-1311-4214-8383-4b9fb2ee0516');
    const [cookies, setCookie] = useCookies(["token"]);
    const [gpsData, setGpsData] = useState([]);
    const [markerData, setMarkerData] = useState({});
    const [isDataReady, setIsDataReady] = useState(false);
    const [listLineData, setListLineData] = useState([[]]);
    const [listVesselData, setListVesselData] = useState([]);
    const [detailVesselData, setDetailVesselData] = useState({
        vessel_type_name: "",
        country_name: "",
        customer_name: "",
        id: "",
        vessel_name: "",
        company_id: "",
        vessel_type_id: "",
        imo_number: "",
        serial_number: "",
        built_date: "",
        built_shipyard: "",
        created_date: "",
        updated_date: "",
        created_by: "",
        updated_by: "",
        is_active: true,
        vessel_code: "",
        vessel_project_counter: 0,
        gross_register_tonnage: 0,
        deadweight_tonnage: 0,
        lbp: 0,
        bmld: 0,
        hmld: 0,
        flag: "",
        callsign: "",
        class_register_number: "",
        customer_id: "",
        ext_customer_id: "",
        sms_serial_number: "",
        shift_session_id: "",
        part_number: ""
    });

    let vesselIcon = L.icon({
        iconUrl: vesselImg,
        iconRetinaUrl: vesselImg,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                loadDetailVesselDataForSidebar();

            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (listVesselData) {
            if (listVesselData.length > 0) {
                console.log(listVesselData);
                let listLineData = Array(listVesselData.length);
                for(let i=0;i<listVesselData.length; i++){
                    listLineData[i] = [];
                }
                setListLineData(listLineData);
                loadCoordinate();
            }
        }

    }, [listVesselData]);

    useEffect(() => {
        loadDetailVesselData();
    }, [detailVesselData])

    useEffect(() => {
        if (!isDataReady) {
            //loadCoordinate();
            setTimeout(() => loadCoordinate(), 1000);
        }
    }, [isDataReady])

    useEffect(() => {
        console.log(markerData);
    }, [markerData])

    useEffect(() => {
        console.log("STATE LINE", listLineData);
    }, [listLineData])

    useEffect(() => {
        if (gpsData) {
            console.log(gpsData);
            if (gpsData.length > 0) {
                let listMarker = [];
                let listVessel = listVesselData;

                // let listTimestamp = [];
                // const currentTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                let idx = 0;
                let listLineData2 = listLineData.slice();
                
                gpsData.forEach(data => {
                    const dmsCoords = new DmsCoordinates(parseFloat(data.latitude), parseFloat(data.longitude));
                    // const timeStampGPS = data.timestamp_gps;

                    let markerEl =
                        <RotatedMarker key={idx} position={[data.latitude, data.longitude]} timestamp_gps={data.timestamp_gps} icon={vesselIcon} rotationAngle={
                            (parseFloat(data.course) + parseFloat(data.cogCorrection)).toFixed(1) > 359.9 ? ((parseFloat(data.course) + parseFloat(data.cogCorrection)) - 359.9).toFixed(1)
                                : (parseFloat(data.course) + parseFloat(data.cogCorrection)).toFixed(1)
                        } rotationOrigin='center'>
                            <Popup>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                                    <div style={{ border: '1px white solid', width: '250px', height: '1px', backgroundColor: 'white', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%' }}>
                                        <p style={{ fontSize: 'large', margin: '0px', fontWeight: 'bold' }}>{data.vesselName}</p>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%' }}>
                                        <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Position'}</p>
                                        <p style={{ fontSize: 'smaller', margin: '0px' }}>{dmsCoords.latitude.toString()}</p>
                                        <p style={{ fontSize: 'smaller', margin: '0px' }}>{dmsCoords.longitude.toString()}</p>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%' }}>
                                        <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Time'}</p>
                                        <p style={{ fontSize: 'smaller', margin: '0px' }}>{data.timestamp_gps}</p>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Speed'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.speed)).toFixed(1)} knot</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'COG'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>
                                                {
                                                    (parseFloat(data.course) + parseFloat(data.cogCorrection)).toFixed(1) > 359.9 ? ((parseFloat(data.course) + parseFloat(data.cogCorrection)) - 359.9).toFixed(1)
                                                        : (parseFloat(data.course) + parseFloat(data.cogCorrection)).toFixed(1)
                                                } °
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Main Engine'}</p>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Port'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.rpmPort)).toFixed(1)}</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Starboard'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.rpmStarboard)).toFixed(1)}</p>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Propeller'}</p>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Port'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(data.rpmGearboxPort).toFixed(1)}</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Starboard'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(data.rpmGearboxStarboard).toFixed(1)}</p>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Throttle Port'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.throttlePort)).toFixed(1)}%</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Throttle Starboard'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.throttleStarboard)).toFixed(1)}%</p>
                                        </div>
                                    </div>
                                </div>
                            </Popup>
                        </RotatedMarker>;
                    try {
                        listVessel.find(p => p.vessel_id === data.vessel_id).latitude = data.latitude;
                        listVessel.find(p => p.vessel_id === data.vessel_id).longitude = data.longitude;
                    }
                    catch (exception) {
                        listVessel.find(p => p.vessel_id === data.vessel_id).latitude = 0;
                        listVessel.find(p => p.vessel_id === data.vessel_id).longitude = 0;
                    }

                    console.log(markerEl.props);
                    if (listLineData2) {
                        let listLineDataIdx = listVessel.findIndex(p => p.vessel_id === data.vessel_id);
                        let listNewLineData = listLineData2[listLineDataIdx].slice();
                        if (listLineData2[listLineDataIdx]) {
                            if (listLineData2[listLineDataIdx].length > 0) {
                                let listExpiredLine = listLineData2[listLineDataIdx].filter(p => moment.duration(moment(new Date()).diff(moment(p.props.timestamp_gps))).asMinutes() > 10);
                                if (listExpiredLine) {
                                    listExpiredLine.forEach((line, idx) => {
                                        let toBeRemovedIdx = listNewLineData.findIndex(p =>p.props.timestamp_gps === line.props.timestamp_gps);
                                        if (toBeRemovedIdx > -1) {
                                            listNewLineData.splice(toBeRemovedIdx, 1);
                                        }
                                    });
                                }
                                listLineData2[listLineDataIdx] = listNewLineData;
                                listLineData2[listLineDataIdx].push(markerEl);
                            }else {
                                listLineData2[listLineDataIdx].push(markerEl);
                            }
                        } 
                    }
                    // listLine.push(markerEl);
                    // listTimestamp.push(timeStampGPS);
                    listMarker.push(markerEl);
                    idx++;
                });
                setListLineData(listLineData2);
                let markerObj = {
                    listVessel: listVessel,
                    listMarker: listMarker,
                    listLine: listLineData2,
                }
                setMarkerData(markerObj);
                console.log('Coordinate Data ->', gpsData);
                setIsDataReady(true);
            }
        }
    }, [gpsData])


    const loadCoordinate = async () => {
        try {
            const response = await getVesselLocationForCustomer(cookies.token, cookies.extCustomerId);
            console.log(response);
            if (response) {
                let actualGpsData = response.map((a) => {
                    return {
                        vesselName: a.vessel_name,
                        latitude: a.latitude,
                        longitude: a.longitude,
                        timestamp_gps: moment(a.received_date).format("yyyy-MM-DD HH:mm:ss"),
                        course: a.course,
                        speed: a.speed,
                        rpmPort: a.rpm_port,
                        rpmStarboard: a.rpm_stbd,
                        throttlePort: a.throttle_port,
                        throttleStarboard: a.throttle_stbd,
                        rpmGearboxPort: a.rpm_gearbox_port,
                        rpmGearboxStarboard: a.rpm_gearbox_stbd,
                        cogCorrection: a.cog_correction
                    };
                });
                setGpsData(actualGpsData);
            }
            setIsDataReady(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadDetailVesselDataForSidebar = async () => {
        try {
            let response = await findVesselByIdForCustomerDashboard(cookies.token, vesselId);
            // console.log(response);
            setDetailVesselData({
                vessel_type_name: response[0].vessel_type_name,
                country_name: response[0].country_name,
                customer_name: response[0].customer_name,
                id: response[0].id,
                vessel_name: response[0].vessel_name,
                company_id: response[0].company_id,
                vessel_type_id: response[0].vessel_type_id,
                imo_number: response[0].imo_number,
                serial_number: response[0].serial_number,
                built_date: response[0].built_date,
                built_shipyard: response[0].built_shipyard,
                created_date: response[0].created_date,
                updated_date: response[0].updated_date,
                created_by: response[0].created_by,
                updated_by: response[0].updated_by,
                is_active: response[0].is_activeue,
                vessel_code: response[0].vessel_code,
                vessel_project_counter: response[0].vessel_project_counter,
                gross_register_tonnage: response[0].gross_register_tonnage,
                deadweight_tonnage: response[0].deadweight_tonnage,
                lbp: response[0].lbp,
                bmld: response[0].bmld,
                hmld: response[0].hmld,
                flag: response[0].flag,
                callsign: response[0].callsign,
                class_register_number: response[0].class_register_number,
                customer_id: response[0].customer_id,
                ext_customer_id: response[0].ext_customer_id,
                sms_serial_number: response[0].sms_serial_number,
                shift_session_id: response[0].shift_session_id,
                part_number: response[0].part_number
            });
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadDetailVesselData = async () => {
        try {
            let response = await getVesselForCustomer(cookies.token, cookies.extCustomerId);
            console.log(response);
            setListVesselData(response);
        } catch (exception) {
            console.log(exception);
        }
    }
    return (
        <>
            <div className="main">
                <Sidebar companyName={detailVesselData.customer_name} smsSerialNumber={detailVesselData.sms_serial_number} vesselId={detailVesselData.id} vesselName={detailVesselData.vessel_name} customerName={cookies.userName} />
                <Container fluid className="mainContainerVL">
                    <div className="childMainContainerVL">
                        <div style={{ width: '100%', backgroundColor: 'rgba(3, 30, 103, 1)', borderRadius: '12px' }}>
                            <ContainerBox
                                titleCaption={'Vessel Location'}
                            />
                        </div>

                        <div style={{ width: '100%' }}>

                            <OpenSeaMap markerChild={markerData} isTracking={false} />
                        </div>
                    </div>

                </Container>
            </div>
        </>
    )
}
