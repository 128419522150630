import axios from "axios";
import Config from "../Config/Config";

export const getAuthorizedMenu = async (token, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/UserAccount/getAuthorizedMenu`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getAuthorizedMenuV2 = async (token, userId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/UserAccount/v2/getUserMenu`, {
            headers: {
                token: token
            },
            params: {
                user_id: userId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getAuthorizedAdminMenuV2 = async (token, userId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/UserAccount/v2/getAdminMenu`, {
            headers: {
                token: token
            },
            params: {
                user_id: userId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}