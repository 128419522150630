// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerBitLampForMotorPump{
    border: 1px #172554 solid;
    width: 40px;
    height: 20px;
    margin: 8px 5px;
}

.bitLampMotorPumpContainerOff {
    border: 1px #e11d48 solid;
    background-color: #e11d48;
    width: 40px;
    height: 20px;
    margin: 8px 5px;
}

.bitLampMotorPumpContainerOn {
    border: 1px #008000  solid;
    background-color: #008000;
    width: 40px;
    height: 20px;
    margin: 8px 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewBitLampForMotorPump/NewBitLamp.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".containerBitLampForMotorPump{\n    border: 1px #172554 solid;\n    width: 40px;\n    height: 20px;\n    margin: 8px 5px;\n}\n\n.bitLampMotorPumpContainerOff {\n    border: 1px #e11d48 solid;\n    background-color: #e11d48;\n    width: 40px;\n    height: 20px;\n    margin: 8px 5px;\n}\n\n.bitLampMotorPumpContainerOn {\n    border: 1px #008000  solid;\n    background-color: #008000;\n    width: 40px;\n    height: 20px;\n    margin: 8px 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
