import "./index.css";
import React, { useState, useEffect, useRef, useSyncExternalStore } from "react";
import { Modal, Button, Form, Container, Col, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getUserAccessById, insertUpdateVesselXDocument, getCountMasterDocument, getCountEquipmentDocument,
    findEquipmentDocumentById, findMasterDocumentById, findSelectedById, deleteEquipmentDocument, getVesselXEquipmentPhotoCount,
    convertBase64, insertUpdateVesselxEquipmentPhoto, getVesselxEquipmentById, findSelectedByMasterId,
    deleteVesselEquipmentPhoto, getVesselxEquipmentPhotoById, checkIsCover, getParameter
} from "../../../../Helpers/ApplicationHelpers";
import {
    getCountDueJobPhoto, findDueJobPhotoById, getCountDueJobDocument, findDueJobDocumentById,
    insertUpdateDueJobPhoto, insertUpdateDueJobDocument, deleteDocumentPhoto, findDueJobPhotoByPhotoId
} from "../../../../Helpers/CustomerMaintenanceHelpers";
import { getSelectedDocumentById, deleteInventoryDocument } from "../../../../Helpers/InventoryHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import Paginations from "../../../../Components/Pagination";
import Modals from "../../../../Components/modal";
import {
    Trash, ArrowLeft, Download, FilePlusFill, EyeFill, XSquareFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill, Search, ArrowClockwise,
    CameraFill,
    FileCheck,
    FileCheckFill
} from "react-bootstrap-icons";
import ContainerBox from "../../../../Components/ContainerBox";
import Loading from "../../../../Components/Loading";
import TextBox from "../../../../Components/Textbox";
import FormSelect from "../../../../Components/FormSelect";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../../Helpers/Base64Downloader";
import moment from 'moment';
import Sidebar from "../../../../Components/NewSideBarForCustomerPage/NewSideBar";
import { omit } from 'lodash';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function CustomerMaintenanceDuejobPhotoAndDocumentPage() {
    const inputFile = useRef(null);
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [listDocument, setListDocument] = useState([]);
    const [listDocumentMaster, setListDocumentMaster] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [documentId, setDocumentId] = useState("");
    const [listDocumentSparepart, setListDocumentSparepart] = useState([]);
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const [sparepartCatalogId, setSparepartCatalogId] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [descending, setDescending] = useState(false);
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState(false);
    const [vesselEquipmentDocumentId, setVesselEquipmentDocumentId] = useState("");
    const [sparepartCatalogDocumentId, setSparepartCatalogDocumentId] = useState("");
    const [downloadSparepartCatalogDocumentId, setdownloadSparepartCatalogDocumentId] = useState("")
    const [downloadVesselEquipmentDocumentId, setDownloadVesselEquipmentDocumentId] = useState("")
    const [listDocumentToBeView, setListDocumentToBeView] = useState([]);
    const [vesselEquipmentId, setVesselEquipmentId] = useState("")
    const [vesselName, setVesselName] = useState("")
    const [equipmentName, setEquipmentName] = useState("")
    const [equipmentCategoryId, setEquipmentCategoryId] = useState("")
    const [vesselId, setVesselId] = useState("")
    const [selectedDownloadIdMaster, setSelectedDownloadIdMaster] = useState();
    const [listinventoryMasterId, setListInventoryMasterId] = useState([]);
    const [selectedDocumentIdMaster, setSelectedDocumentIdMaster] = useState();
    const [selectedDocumentIdToShow, setSelectedDocumentIdToShow] = useState(null);
    const [selectedDocumentDownloadIdEquipment, setSelectedDocumentDownloadIdEquipment] = useState();
    const [selectedDeleteIdEquipment, setSelectedDeleteIdEquipment] = useState();
    const [zoomFactor, setZoomFactor] = useState(0.5);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [vesselEquipmentPhotoCount, setVesselEquipmentPhotoCount] = useState(0);
    const [listPhotoAndDocument, setListPhotoAndDocument] = useState([]);
    const [listPhoto, setListPhoto] = useState([]);
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [description, setDescription] = useState(null);
    const [showModalAddPhoto, setShowModalAddPhoto] = useState(false);
    const [showModalAddDocument, setShowModalAddDocument] = useState(false);
    const [isFirstTimeLoad, setIsFirstTimeLoad] = useState(true);
    const [shouldUpdateToDB, setShouldUpdateToDB] = useState(false);
    const [documentFile, setDocumentFile] = useState("");
    const [orderBy, setOrderBy] = useState({
        label: `Created Date`,
        value: "created_date"
    });
    const orderByList = [{
        label: "Created Date",
        value: "created_date"
    }];
    const [dueJobDocument, setDueJobDocument] = useState({
        id: 0,
        maintenance_due_job_id: "",
        doc_name: "",
        doc_base64: "",
        created_time: "",
        created_by: "",
        updated_time: "",
        updated_by: "",
        is_active: "",
    });
    const [documentToBeViewed, setDocumentToBeViewed] = useState({
        id: 0,
        maintenance_due_job_id: "",
        doc_name: "",
        doc_base64: "",
        created_time: "",
        created_by: "",
        updated_time: "",
        updated_by: "",
        is_active: "",
    });
    const [dueJobPhoto, setDueJobPhoto] = useState({});
    const [fileImg, setFileImg] = useState("");
    const [vesselEquipmentPhotoId, setVesselEquipmentPhotoId] = useState("");
    const [listVesselEquipment, setListVesselEqipment] = useState([]);
    const [listParameter, setListParameter] = useState([]);
    const [removeId, setRemoveId] = useState(null);
    const [show, setShow] = useState(false);
    const showFormPhoto = () => setShow(true);
    const [maintenanceDueJobId, setMaintenanceDuejobId] = useState("");

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                setMaintenanceDuejobId(searchParams.get('maintenanceDueJobId'));
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        //forceUpdate();
        console.log('LIST DOCUMENT EQUIPMENT->', listDocument)
    }, [listDocument])

    useEffect(() => {
        // let filterPhoto = ;
        // let filterDocument = ;
        if (listPhotoAndDocument) {
            setListPhoto(listPhotoAndDocument.filter(p => p.maintenance_due_job_document_type_id === "648672cd-db3d-46ae-ab8b-4fc3c42c84af"));
            setListDocument(listPhotoAndDocument.filter(p => p.maintenance_due_job_document_type_id === "df72ea7a-b84f-4215-9149-3067e25c06df"));
        }
    }, [listPhotoAndDocument])

    // useEffect(() => {
    //     if (selectedDocumentDownloadIdEquipment !== "")
    //         downloadDataEqp();
    // }, [selectedDocumentDownloadIdEquipment])

    useEffect(() => {
        // setvesselPhotoEquipment({...vesselPhotoEquipment, equipment_id: equipmentCategoryId, vessel_id: vesselId, vessel_x_equipment_id: vesselEquipmentId})
        if (maintenanceDueJobId) {
            setIsFirstTimeLoad(false);
            console.log('maintenanceDueJobId', maintenanceDueJobId);
            setDueJobPhoto({ ...dueJobPhoto, maintenance_due_job_id: maintenanceDueJobId });
        }
    }, [maintenanceDueJobId])

    useEffect(() => {
        if (removeId) {
            console.log(removeId)
            removePhotoAndDocument();
        }
    }, [removeId])

    useEffect(() => {
        if (selectedDocumentDownloadIdEquipment) {
            downloadDataEqp();
        }
    }, [selectedDocumentDownloadIdEquipment])

    useEffect(() => {
        if(selectedDocumentIdToShow){
            console.log(selectedDocumentIdToShow);
            showModalDocument();
            // setShowDocumentDetailModal(true);
        }
    }, [selectedDocumentIdToShow])

    useEffect(() => {
        if(documentToBeViewed){
            if(documentToBeViewed.id !== 0){
                setShowDocumentDetailModal(true);
            }
            else {
                setSelectedDocumentIdToShow(null);
            }
        }
    }, [documentToBeViewed])

    useEffect(() => {
        if(!showDocumentDetailModal){
            setDocumentToBeViewed({...documentToBeViewed, id: 0});
        }
    }, [showDocumentDetailModal])

    useEffect(() => {
        console.log('dueJobPhoto', dueJobPhoto);
        if (dueJobPhoto) {
            if (shouldUpdateToDB && !isFirstTimeLoad) {
                if (dueJobPhoto.doc_base64) {
                    console.log("Upload Photo");
                    saveDueJobDocument();
                }
            }
            loadPhotoAndDocument();
        }
    }, [dueJobPhoto])

    const saveDueJobDocument = async () => {
        try {
            console.log('before save photo', dueJobPhoto)
            let response = await insertUpdateDueJobDocument(cookies.token, dueJobPhoto);
            console.log(response);
            if (response.error_code === 0) {
                alert(`${t("vessel_equipment_photo.alert_success_save_data")}`);
                setShowModalAddPhoto(false);
                setShowModalAddDocument(false);
                loadPhotoAndDocument();
            }
            else {
                alert('Failed to save Data');
            }
            setDisabledButton(false);
            setShouldUpdateToDB(false);
            setDueJobPhoto({ ...dueJobPhoto, doc_base64: null });

        } catch (exception) {
            console.log(exception);
        }
    }

    const downloadDataEqp = async () => {
        try {
            let response = await findDueJobPhotoByPhotoId(cookies.token, selectedDocumentDownloadIdEquipment);
            console.log('download data reeponse', response);
            triggerBase64Download(response[0].doc_base64, response[0].doc_name);
            setSelectedDocumentDownloadIdEquipment(null);
        } catch (exception) {
            console.log(exception)
        }
    }

    const showModalDocument = async () => {
        try {
            let response = await findDueJobPhotoByPhotoId(cookies.token, selectedDocumentIdToShow);
            setDocumentToBeViewed(response[0])
            // setSelectedDocumentDownloadIdEquipment(null);
        } catch (exception) {
            console.log(exception)
        }
    }

    const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.doc_base64);
        setDescription(item.doc_name);
    };

    const handelRotationRight = () => {
        const totalLength = listPhoto.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = listPhoto[0].doc_base64;
            const descUrl = listPhoto[0].doc_name;
            setClickedImg(newUrl);
            setDescription(descUrl)
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        });
        const descUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        })
        const newItem = newUrl[0].base64_img;
        const descNew = descUrl[0].description;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(descNew);
    };

    const handelRotationLeft = () => {
        const totalLength = listPhoto.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = listPhoto[totalLength - 1].base64_img;
            const descUrl = listPhoto[totalLength - 1].description;

            setClickedImg(newUrl);
            setDescription(descUrl);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        });
        const descUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].base64_img;
        const descNew = descUrl[0].description;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(descNew);
    };


    const loadPhotoAndDocument = async () => {
        try {
            if (maintenanceDueJobId) {
                // let count = await getCountDueJobPhoto(cookies.token, maintenanceDueJobId);
                // console.log(count);
                // let totalPage = count / itemPerPage;
                // setTotalPage(totalPage);
                let result = await findDueJobPhotoById(cookies.token, maintenanceDueJobId);
                console.log(result);
                setListPhotoAndDocument(result);
            }
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const removePhotoAndDocument = async () => {
        try {
            let result = await deleteDocumentPhoto(cookies.token, { id: removeId });
            console.log(result);
            if (result.error_code === 0) {
                alert('Successfully deleted Photo');
            }
            else {
                alert('Failed to delete');
            }
            setRemoveId(null);
            loadPhotoAndDocument();
        }
        catch (exception) {
            console.log(exception);
        }

    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    return (
        <>
            <div className="mainPartPhotoDocCatalogNew">
                <Sidebar />
                <Container fluid className="mainContainerPhotoDocCatalogNew">
                    <div className="childMainContainerPhotoDocCatalogNew">
                        <ContainerBox
                            titleCaption={'Photo and Document'}
                            useActionContainer={true}
                            actionContainerChild={
                                <div>
                                    <Button disabled={false} variant="secondary" style={{
                                    }} onClick={() => { setShowDocumentUploadModal(true); }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "row",
                                            alignSelf: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                flex: 1,
                                            }}><FileCheckFill size={32} /></div>
                                            <div style={{
                                                display: "flex",
                                                flex: 8,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                                fontSize: 18,
                                            }}>{t("vessel_equipment_document.add_button")}</div>
                                        </div>
                                    </Button>

                                    <Button disabled={false} variant="secondary" style={{
                                        marginLeft: '10px'
                                    }} onClick={() => { setShowModalAddPhoto(true) }}>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "row",
                                            alignSelf: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                flex: 1,
                                            }}><CameraFill size={32} /></div>
                                            <div style={{
                                                display: "flex",
                                                flex: 8,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                paddingLeft: 10,
                                                fontWeight: "bold",
                                                fontSize: 18,
                                            }}>{'Add Photo'}</div>
                                        </div>
                                    </Button>
                                </div>
                            }
                            childContent={
                                <>
                                    <div className="master-table-inner-container">
                                        <h4 style={{ color: 'black' }}>{"Photos"}</h4>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            padding: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                            border: '2px black solid',
                                            borderRadius: '5px'
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                paddingRight: 5,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                    </div>

                                                    <br /><br />
                                                    <Col md={12}>
                                                        <Row>
                                                            {
                                                                listPhoto.map((photo, index) => {
                                                                    return (

                                                                        <Col md={3}>
                                                                            <div key={index} style={{
                                                                                display: "flex",
                                                                                flex: 1,
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                flexWrap: "nowrap",
                                                                                flexDirection: "column",
                                                                                padding: 10,
                                                                                borderWidth: 1,
                                                                                margin: 5,
                                                                                borderStyle: "solid",
                                                                                borderColor: "#2f71bd",
                                                                                borderRadius: 5,
                                                                                height: 400,

                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flex: 5,
                                                                                    alignContent: "flex-start",
                                                                                    alignItems: "flex-start",
                                                                                    justifyContent: "center",
                                                                                    alignSelf: "center",
                                                                                    flexWrap: "wrap",

                                                                                }}>
                                                                                    <img src={photo.doc_base64} onClick={() => {
                                                                                        handleClick(photo, index)
                                                                                    }} style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                                                </div>
                                                                                <div style={{
                                                                                    flex: 1,
                                                                                    alignContent: "flex-end",
                                                                                    alignItems: "flex-end",
                                                                                    justifyContent: "flex-end",
                                                                                    alignSelf: "flex-end",
                                                                                    flexWrap: "nowrap",
                                                                                    flexDirection: "row",
                                                                                    width: "100%",
                                                                                }} >
                                                                                    <div style={{
                                                                                        flex: 1,
                                                                                        flexDirection: "column",
                                                                                        alignContent: "flex-end",
                                                                                        alignItems: "flex-end",
                                                                                        justifyContent: "flex-end",
                                                                                        alignSelf: "flex-end",
                                                                                        textAlign: "center"
                                                                                    }}><p>{!photo.doc_name ? "" : photo.doc_name}</p></div>
                                                                                    <div style={{
                                                                                        flex: 1,
                                                                                        flexDirection: "column",
                                                                                        alignContent: "flex-end",
                                                                                        alignItems: "flex-end",
                                                                                        justifyContent: "flex-end",
                                                                                        alignSelf: "flex-end",
                                                                                        textAlign: "center"
                                                                                    }}><p>{!photo.created_time ? "" : moment(photo.created_time).local().format("YYYY-MM-DD")}</p></div>
                                                                                    <Button disabled={false} style={{ width: '100%' }} onClick={() => {
                                                                                        if (window.confirm(`${t("vessel_equipment_photo.delete_confirmation")}?`)) {
                                                                                            setRemoveId(photo.id);

                                                                                        }
                                                                                    }} variant="danger"><Trash /></Button>

                                                                                </div>

                                                                            </div>

                                                                        </Col>

                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    </Col><br /><br />
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div className="master-table-inner-container">
                                        <h4 style={{ color: 'black' }}>{t("vessel_equipment_document.text_title_documents")}</h4>
                                        <div className="table-container">
                                            <div className="table-header">
                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                <div style={{ flex: 4 }} className="table-header-content">{t("vessel_equipment_document.table_name1")}</div>
                                                <div style={{ flex: 3 }} className="table-header-content">{t("vessel_equipment_document.table_name3")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("vessel_equipment_document.table_name4")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("vessel_equipment_document.table_name5")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("vessel_equipment_document.table_name6")}</div>
                                            </div>
                                            {
                                                listDocument.map((document, index) => {
                                                    return (
                                                        <div key={index} className="table-body">
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                                            <div style={{ flex: 4 }} className="table-body-content"><p>{document.doc_name}</p></div>
                                                            <div style={{ flex: 3 }} className="table-body-content"><p>{moment(document.created_time).local().format("YYYY-MM-DD")}</p></div>
                                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                <div style={{ padding: 5 }}>
                                                                    <Button variant="primary" onClick={() => {
                                                                        setSelectedDocumentIdToShow(document.id);
                                                                    }}><EyeFill />
                                                                    </Button>
                                                                </div></div>

                                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                <div style={{ padding: 5 }}>
                                                                    <Button variant="primary" onClick={() => {
                                                                        setSelectedDocumentDownloadIdEquipment(document.id);
                                                                    }}><Download />
                                                                    </Button>
                                                                </div></div>
                                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                <div style={{ padding: 5 }}>
                                                                    <Button variant="danger" disabled={false} onClick={() => {
                                                                        if (window.confirm(`${t("vessel_equipment_document.delete_confirmation")}  ${document.doc_name}?`)) {
                                                                            setRemoveId(document.id);
                                                                        }
                                                                    }}><Trash />
                                                                    </Button>
                                                                </div></div>
                                                        </div>)
                                                }
                                                )
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    </div>
                </Container>

                {
                    clickedImg && (
                        <Modals
                            clickedImg={clickedImg}
                            handelRotationRight={handelRotationRight}
                            setClickedImg={setClickedImg}
                            handelRotationLeft={handelRotationLeft}
                            image={listPhoto}
                            description={description}
                            setDescription={setDescription}
                        />
                    )}

                <Modal show={showModalAddPhoto} >
                    <Modal.Header closeButton onClick={() => {
                        setShowModalAddPhoto(false);
                        // handleResets();
                    }}>
                        <Modal.Title>{t("index_inventory_photo.modal_add_photo")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setShouldUpdateToDB(true);
                            setDueJobPhoto({ ...dueJobPhoto, id: "", maintenance_due_job_document_type_id: "648672cd-db3d-46ae-ab8b-4fc3c42c84af" });
                        }}>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_inventory_photo.field_inventory_photo")}</Form.Label>
                                <Form.Control onChange={async (e) => {
                                    if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                                        setFileImg(e.target.value)
                                        let base64Img = await convertBase64(e.target.files[0]);
                                        const base64ImgString = base64Img.toString();
                                        setDueJobPhoto({ ...dueJobPhoto, doc_base64: base64ImgString });
                                        setUploadFileImageError("");
                                        setDisabledButton(false)

                                    } else {
                                        setFileImg("")
                                        setDueJobPhoto({ ...dueJobPhoto, doc_base64: "" });
                                        setUploadFileImageError(`${t("index_inventory_photo.error_image_validation")}`);
                                        setDisabledButton(true)
                                    }



                                }} type="file" value={fileImg} required></Form.Control>
                                {
                                    uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_inventory_photo.field_photo_label")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setDueJobPhoto({ ...dueJobPhoto, doc_name: e.target.value })

                                }} value={dueJobPhoto.doc_name} type="text" placeholder="" required></Form.Control>
                                {
                                    // errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                                }
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                            <Form.Label>{t("index_inventory_photo.field_description")}</Form.Label>
                            <Form.Control onChange={(e) => {
                                // setVesselPhoto({ ...vesselPhoto, description: e.target.value })
                            }} value={'desc'} as="textarea" rows={3} placeholder=""></Form.Control>
                        </Form.Group> */}
                            <Button style={{ width: 100 }} variant="primary" disabled={disabledButton} type="submit">
                                {t("index_inventory_photo.save_button")}
                            </Button>
                            <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                handleResets();
                                handleClose();
                            }}>
                                {t("index_inventory_photo.cancel_button")}
                            </Button>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                </Modal>

                <Modal show={showDocumentUploadModal}
                    size={"lg"}
                    onHide={() => {
                        // handleResetsDoc();
                        setShowDocumentUploadModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("index_document_inventory.modal_upload_document")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            setShouldUpdateToDB(true);
                            setDueJobPhoto({ ...dueJobPhoto, id: "", maintenance_due_job_document_type_id: "df72ea7a-b84f-4215-9149-3067e25c06df" });
                        }} style={{ padding: 10 }}>



                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_document_inventory.field_document_file")}</Form.Label>
                                <Form.Control onChange={async (e) => {
                                    // if (e.target.files[0].type === "application/pdf") {
                                    setDocumentFile(e.target.value)
                                    let base64Doc = await convertBase64(e.target.files[0]);
                                    setDueJobPhoto({ ...dueJobPhoto, doc_base64: base64Doc.toString() });
                                    // setUploadFileImageError("");
                                    // setDisabledButton(false)

                                    // } else {
                                    //     setDocumentFile("")
                                    //     setNewDocument({ ...newDocument, document_base64: "" });
                                    //     setUploadFileImageError("Hanya Bisa File Pdf")
                                    //     setDisabledButton(true)
                                    // }

                                }} type="file" required></Form.Control>
                                {/* {
                                        uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                    } */}
                            </Form.Group>




                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_document_inventory.field_document_name")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setDueJobPhoto({ ...dueJobPhoto, doc_name: e.target.value })
                                }} value={dueJobPhoto.doc_name} type="text" placeholder="" required></Form.Control>
                                {/* {
                                errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                            } */}
                            </Form.Group>


                            {/* <Form.Group className="mb-3">
                                <Form.Label>{t("index_document_inventory.table_name3")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setInventoryDocument({ ...inventoryDocument, reference_number: e.target.value })
                                }} value={inventoryDocument.reference_number} type="text" placeholder="" ></Form.Control>

                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_document_inventory.table_name4")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setInventoryDocument({ ...inventoryDocument, created_date: e.target.value })
                                }} value={inventoryDocument.created_date} type="date" placeholder="" required></Form.Control>
                            {
                                errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                            }
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_document_inventory.field_description")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setInventoryDocument({ ...inventoryDocument, description: e.target.value })
                                }} value={inventoryDocument.description} as="textarea" rows={3} placeholder=""></Form.Control>

                            </Form.Group> */}

                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-start",
                                flex: 1,
                            }}>
                                <Button style={{ width: 100 }} variant="primary" type="submit" disabled={disabledButton}>
                                    {t("index_document_technician.save_button")}
                                </Button>
                                <Button style={{ width: 100 }} className="cancel" variant="danger" onClick={() => {
                                    // handleResetsDoc();
                                    setShowDocumentUploadModal(false);
                                }}>
                                    {t("index_document_technician.cancel_button")}
                                </Button>
                            </div>
                        </Form>
                    </div>

                </Modal>

                <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                    setShowDocumentDetailModal(false);
                    // if (showDocumentDetailModal){
                    //     
                    // }
                }}>
                    <ContainerBox containerPos="inner" titleCaption={showDocumentDetailModal ? documentToBeViewed.doc_name : ""}
                        useActionContainer={true}
                        actionContainerChild={
                            <div>
                                <Button variant="secondary" style={{

                                }} onClick={() => {
                                    setShowDocumentDetailModal(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquareFill size={32} /></div>
                                        <div style={{
                                            display: "flex",
                                            flex: 8,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 18,
                                        }}>{t("vessel_equipment_document.button_closed")}</div>
                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column"
                            }}>
                                <div>
                                    <Document
                                        file={showDocumentDetailModal ? documentToBeViewed.doc_base64: ""}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        loading={() => {

                                        }}
                                    ><div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                overflow: "scroll",
                                            }}>
                                                <Page scale={zoomFactor} pageNumber={pageNumber} />
                                            </div>
                                        </div>
                                    </Document>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}>
                                        <p>
                                            {t("vessel_equipment_document.page_text")} {pageNumber} {t("vessel_equipment_document.from_text")} {numPages}
                                        </p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        flexWrap: "wrap",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5,
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={zoomFactor <= 0.2}
                                                onClick={() => {
                                                    setZoomFactor(zoomFactor * 0.8);
                                                }}
                                            >

                                                <ZoomOut size={28} />
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={false}
                                                onClick={() => {
                                                    setZoomFactor(0.5);
                                                }}
                                            >
                                                <AspectRatioFill size={28} />
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5,
                                            flexWrap: "wrap",
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={zoomFactor >= 10}
                                                onClick={() => {
                                                    setZoomFactor(zoomFactor * 2);
                                                }}
                                            >
                                                <ZoomIn size={28} />
                                            </Button>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={pageNumber <= 1}
                                                onClick={previousPage}
                                            >

                                                <ChevronDoubleLeft size={28} />
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={pageNumber >= numPages}
                                                onClick={nextPage}
                                            >
                                                <ChevronDoubleRight size={28} />
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        } />

                </Modal>
            </div>
        </>
    )
}