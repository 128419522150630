import './index.css'
import { Box, Typography } from '@mui/material/';

export default function FMSBitlampComponent({alarmValue, bitLampTitle}) {
    return (
        <Box
            component="section"
            sx={{
                p: 2,
                width: '100%',
                border: '1px solid grey',
                borderRadius: '5px',
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: "center",
            }}>
                {
                    alarmValue ? (
                        alarmValue === 'False' ? (<div className='bitLampBoxFMSFalse'></div>) : (
                            alarmValue === 'True' ? (<div className='bitLampBoxFMSTrue'></div>) : (<div className='bitLampBoxFMSFalse'></div>)
                        )
                    ) : (<div className='bitLampBoxFMSFalse'></div>)
                }
            <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                {bitLampTitle}
            </Typography>
        </Box>
    )
}