import "./index.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect, useCallback } from "react";
import { isTokenValid, getPLCReadingValue } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
import SMSNavContainerFireAlarm from "../../../Components/NewSMSNavContainerFireAlarm/NewSMSNavContanerFireAlarm";
import CustomerSMSFireAlarmWheelhouse from "../SMSFireAlarmWheelhouse";
import CustomerSMSFireAlarmMainDeck from "../SMSFireAlarmMainDeck";
import CustomerSMSFireAlarmBelowMainDeck from "../SMSFireAlarmBelowMainDeck";
// import BitLamp from "../../../Components/NewBitLampSquare/NewBitLampSquare";
import BitLamp from "../../../Components/BitLampForFireAlarm/BitLampFireAlarm";
import moment from "moment";




export default function CustomerSMSFireAlarmPage(props) {
  const { smsSerialNumber, vesselId } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookie] = useCookies(["token"]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [currentChild, setCurrentChild] = useState(null);
  // const [smsSerialNumber, setSmsSerialNumber] = useState("");
  const [titleBar, setTitleBar] = useState("");
  const [idCoordinateGroup, setIdCoordinateGroup] = useState([21, 20, 4]);
  const [coordinateType, setCoordinateType] = useState('M');
  const [lastReceivedDate, setLastReceivedDate] = useState();
  const [width, setWidth] = useState(1366);
  const [height, setHeight] = useState(768);
  const [bitlampList, setBitlampList] = useState({

    M32:
    {
      text: "Bridge 1",
      alarm: true,
      coordinate: "M32",
      value: "false"
    },
    M34: {
      text: "MCP Wheelhouse 1",
      alarm: true,
      coordinate: "M34",
      value: "false"
    },
    M36: {
      text: "MCP Wheelhouse 2",
      alarm: true,
      coordinate: "M36",
      value: "false"
    },
    M50:
    {
      text: "Gang/Way",
      alarm: true,
      coordinate: "M50",
      value: "false"
    },
    M52: {
      text: "Deck Store",
      alarm: true,
      coordinate: "M52",
      value: "false"
    },
    M40: {
      text: "Saloon",
      alarm: true,
      coordinate: "M40",
      value: "false"
    },
    M38: {
      text: "Manual Call Point Laundry",
      alarm: true,
      coordinate: "M38",
      value: "false"
    },
    M42: {
      text: "Crew Room",
      alarm: true,
      coordinate: "M42",
      value: "false"
    },
    M44: {
      text: "Captain",
      alarm: true,
      coordinate: "M44",
      value: "false"
    },
    M46: {
      text: "Chief Engineer",
      alarm: true,
      coordinate: "M46",
      value: "false"
    },
    M48: {
      text: "Galley Heat",
      alarm: true,
      coordinate: "M48",
      value: "false"
    },
    M296:
    {
      text: "MCP E/R ENT",
      alarm: true,
      coordinate: "M296",
      value: "false"
    },
    M298:
    {
      text: "Smoke E/R ENT",
      alarm: true,
      coordinate: "M298",
      value: "false"
    },
    M300:
    {
      text: "Heat E/R ENT",
      alarm: true,
      coordinate: "M300",
      value: "false"
    },
    M302:
    {
      text: "Smoke 1 ER",
      alarm: true,
      coordinate: "M302",
      value: "false"
    },
    M304:
    {
      text: "MCP 1 ER",
      alarm: true,
      coordinate: "M302",
      value: "false"
    },
    M306:
    {
      text: "Heat 1 65C",
      alarm: true,
      coordinate: "M306",
      value: "false"
    },
    M308:
    {
      text: "MCP 2 ER",
      alarm: true,
      coordinate: "M308",
      value: "false"
    },
    M310:
    {
      text: "Smoke 2 ER",
      alarm: true,
      coordinate: "M310",
      value: "false"
    },
    M312:
    {
      text: "Heat 2 65C",
      alarm: true,
      coordinate: "M312",
      value: "false"
    },
    M314:
    {
      text: "Smoke Steering Gear",
      alarm: true,
      coordinate: "M314",
      value: "false"
    },
    M316:
    {
      text: "MCP Crew Below Main Deck",
      alarm: true,
      coordinate: "M316",
      value: "false"
    },
    M318:
    {
      text: "Smoke Crew room",
      alarm: true,
      coordinate: "M318",
      value: "false"
    },
  });

  useEffect(() => {
    console.log(width);
  }, [width]);

  useEffect(() => {
    console.log(height);
  }, [height]);


  // useEffect(() => {
  //   loadCoordinate();
  //   if (smsSerialNumber !== "") {
  //     setCurrentChild(<CustomerSMSFireAlarmWheelhouse smsSerialNumber={smsSerialNumber} vesselId={vesselId} />);
  //   }
  // }, []);

  useEffect(() => {
    let cb = function (e) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    // window.onresize = cb;
    window.addEventListener('resize', cb);
    return () => window.removeEventListener('resize', cb);
    const interval = setInterval(async () => {
      //console.log("page refreshed", refreshChecked);
      //console.log('SMS Serial Number: ', smsSerialNumber);
      loadCoordinate();
      console.log("Width %d, Height %d", width, height);
    }, 250);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
        //console.log("page refreshed", refreshChecked);
        //console.log('SMS Serial Number: ', smsSerialNumber);
        loadCoordinate();
    }, 250);
    return () => clearInterval(interval);
}, []);

  // const handleMenuClick = (val) => {
  //     console.log(val);
  //     setActiveTab(val);
  //   }

  useEffect(() => {
    if (smsSerialNumber !== "") {
      setCurrentChild(<CustomerSMSFireAlarmWheelhouse smsSerialNumber={smsSerialNumber} vesselId={vesselId}/>);
    }
  }, [smsSerialNumber]);


  useEffect(() => {
    if (smsSerialNumber !== "") {
      switch (activeTab) {
        case 0:
          setCurrentChild(<CustomerSMSFireAlarmWheelhouse smsSerialNumber={smsSerialNumber} vesselId={vesselId} />);
          break;
        case 1:
          setCurrentChild(<CustomerSMSFireAlarmMainDeck smsSerialNumber={smsSerialNumber} vesselId={vesselId} />);
          break;
        case 2:
          setCurrentChild(<CustomerSMSFireAlarmBelowMainDeck smsSerialNumber={smsSerialNumber} vesselId={vesselId} />);
          break;
        default:
          return (null);
          break;
      }
    }
  }, [activeTab]);

  useEffect(() => {
    console.log('bitLampList',bitlampList);
    // console.log(bitlampList["M38"].value);
  }, [bitlampList]);

  const loadCoordinate = async () => {
    try {
      setLoading(true);
      const response = await getPLCReadingValue(cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId)
      let receivedDate = moment(response[0].read_date).format("yyyy-MM-DD HH:mm:ss");
      setLastReceivedDate(receivedDate);
      let bitlampListTmp = bitlampList;
      for (let bl in bitlampList) {
        let find = response.find(p => p.coordinate_type === "M" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
        if (find) {
          bitlampListTmp[bl].value = find.reading_value;
        }
      }
      setBitlampList(bitlampListTmp);
      setLoading(false);
    } catch (exception) {
      console.log(exception);
    }
  }

  return (
    <>
      <div className="mainPageFireAlarmRes">
        <div className="allDeckImgContainer">
          {/*----------------------------- MAIN DECK -----------------------------*/}
          <div className="topFireAlarmPosMAINDECKimage">
            <div style={{ position: "relative", left: 373, top: 40, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'mcp'} alarm={bitlampList["M38"].value} />{/*Laundry*/}
            </div>
            <div style={{ position: "relative", left: 455, top: 5, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'smoke'} alarm={bitlampList["M40"].value} />{/*Saloon*/}
            </div>
            <div style={{ position: "relative", left: 523, top: 0, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'smoke'} alarm={bitlampList["M42"].value} />{/*Crew Room*/}
            </div>
            <div style={{ position: "relative", left: 600, top: 0, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'smoke'} alarm={bitlampList["M44"].value} />{/*Captain*/}
            </div>
            <div style={{ position: "relative", left: 380, top: 20, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'smoke'} alarm={bitlampList["M298"].value} />{/*Engine Store Smoke E/R ENT*/}
            </div>
            <div style={{ position: "relative", left: 380, top: 30, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'temp'} alarm={bitlampList["M300"].value} />{/*ER/ENT Smoke Heat E/R ENT*/}
            </div>
            <div style={{ position: "relative", left: 380, top: 40, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'mcp'} alarm={bitlampList["M296"].value} />{/*CO2 Room MCP E/R ENT*/}
            </div>
            <div style={{ position: "relative", left: 445, top: -25, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'temp'} alarm={bitlampList["M48"].value} />{/*Galley Heat*/}
            </div>
            <div style={{ position: "relative", left: 528, top: -45, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'smoke'} alarm={bitlampList["M52"].value} />{/*Deck Store*/}
            </div>
            <div style={{ position: "relative", left: 550, top: -120, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'smoke'} alarm={bitlampList["M50"].value} />{/*Gang/Way Stairs*/}
            </div>
            <div style={{ position: "relative", left: 590, top: -90, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'smoke'} alarm={bitlampList["M46"].value} /> {/*Chief Engineer Room*/}
            </div>
          </div>
          {/*----------------------------- WHEELHOUSE -----------------------------*/}
          <div className="topFireAlarmPosWHimage">
            <div style={{ position: "relative", left: 190, top: 100, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'smoke'} alarm={bitlampList["M32"].value} /> {/*Bridge 1*/}
            </div>
            <div style={{ position: "relative", left: 256, top: 20, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'mcp'} alarm={bitlampList["M34"].value} /> {/*MCP Wheelhouse 1*/}
            </div>
            <div style={{ position: "relative", left: 256, top: 60, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'mcp'} alarm={bitlampList["M36"].value} /> {/*MCP Wheelhouse 2*/}
            </div>
          </div>
          {/*----------------------------- BELOW MAIN DECK -----------------------------*/}
          <div className="bottomFireAlarmPosBELOWMAINDECKimage">
            <div style={{ position: "relative", left: 340, top: 170, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'temp'} alarm={bitlampList["M312"].value} /> {/* heat 2 65 c heat stbd */}
            </div>
            <div style={{ position: "relative", left: 205, top: 135, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'smoke'} alarm={bitlampList["M310"].value} /> {/* smoke 2 E/R smoke stbd */}
            </div>
            <div style={{ position: "relative", left: 355, top: 63, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'mcp'} alarm={bitlampList["M304"].value} /> {/*below main deck mcp 1 engine room*/}
            </div>
            <div style={{ position: "relative", left: 205, top: 133, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'mcp'} alarm={bitlampList["M308"].value} /> {/*below main deck mcp 2 engine room*/}
            </div>
            <div style={{ position: "relative", left: 355, top: -30, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'smoke'} alarm={bitlampList["M302"].value} /> {/*smoke 1 E/R heat port */}
            </div>
            <div style={{ position: "relative", left: 205, top: -40, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'temp'} alarm={bitlampList["M306"].value} /> {/* heat 1 65 c heat port */}
            </div>
            <div style={{ position: "relative", left: 567, top: -80, resize: "none", height: "25px", width: "25px" }}>
              <BitLamp type={'smoke'} alarm={bitlampList["M318"].value} /> {/*below main deck Crew Room*/}
            </div>
          </div>
          <div className="lastReceivedDateContainerNewForFireAlarm">
            <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDate}</p>
          </div>
        </div>
      </div>


    </>
  );
}