import { Line } from "react-chartjs-2";
import { Chart} from "chart.js";
import BitLamp from "../Components/NewBitLampSquare/NewBitLampSquare";
import BitLamp2 from "./BitLamp2/BitLamp2"
import { useState, useEffect } from "react";
import NewNavLightContainer from "./NewContainerForMotorPump/NewNavLightContainer";

export default function DynamicLineChart(props) {
    const {graphTitle, dataToShow, minValue, maxValue, stepSize, lastValue} = props;

  //   useEffect(() => {

  // }, []);

    const xAxisValues = dataToShow ? dataToShow.map(
        data => ""):"";
        // data => new Date(data.received_date).getHours().toString()+":"
        // + new Date(data.received_date).getMinutes().toString()+":" 
        // + new Date(data.received_date).getSeconds().toString()):0;
    const yAxisValues = dataToShow ? dataToShow.map(data => Number(data.reading_value)) : 3000;

    const data = {
        labels: xAxisValues,
        datasets: [
          {
            label: graphTitle,
            data: yAxisValues,
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderColor: 'green',
            borderWidth: 1,
          }
        ]
      };

      const options = {
          plugins: {
            legend: {
                display: false 
            }
        },
        scales: {
          y: {
            min: minValue,
            max: maxValue,
            ticks: {
              stepSize: stepSize,
              color: 'white'
            },
          },
          x: {
            ticks: {
              color: 'white'
            }
          }
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
        animation: false
      };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', padding: '10px 10px', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
      <div style={{ width: '90%' }}>
        <Line
          data={data}
          options={options}
        />
      </div>
      <div style={{ width: '10%' }}>
        <p style={{ color: 'white', fontFamily: 'Disket Mono' }}>{lastValue}%</p>
      </div>
    </div>
  );
}