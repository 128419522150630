import "./index.css"
import { useState, useEffect, useMemo, useRef } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { isTokenValid, findSmsVesselPosition, getSMSCoordinateByCoordinateType, getPLCReadingValueForEngines } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/LoadingForSMSDashboard/Loading";
import { Form, Button } from "react-bootstrap";
import DynamicLineChart from "../../../Components/DynamicLineChart";
import DropDownForm from "../../../Components/DropDownForm";
import moment from "moment";
import ProgressBar from "../../../Components/ProgressBar/ProgressBar";
import ThrottleBar from "../../../Components/ThrottleBar/ThrottleBar";
import GaugeComponent from 'react-gauge-component'
import NewGaugeComponent from "../../../Components/GaugeComponent/index";
import BoxWithText from "../../../Components/BoxWithText/BoxWithText";
// import ProgressBar from 'react-animated-progress-bar';
import { Trans, useTranslation } from "react-i18next";

export default function CustomerSMSMainEnginePage(props) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { smsSerialNumber, vesselId } = props;
    const [cookies, setCookie] = useCookies(["token"]);
    const [loading, setLoading] = useState(true);
    const [lastReceivedDateMEP, setLastReceivedDateMEP] = useState();
    const [lastReceivedDateMES, setLastReceivedDateMES] = useState();
    const [selectStartDate, setSelectStartDate] = useState(moment(new Date()).utc().subtract(30, "minutes").format("yyyy-MM-DD HH:mm:ss"));
    const [selectEndDate, setSelectEndDate] = useState(moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss"));
    const [refreshChecked, setRefreshChecked] = useState(false);
    const [dataShowPort, setDataShowPort] = useState();
    const [dataShowSTBD, setDataShowSTBD] = useState();
    const [dataCreatedDate, setDataCreatedDate] = useState();
    const [isDataReady, setIsDataReady] = useState(false);
    const [coordinateList, setCoordinateList] = useState();
    const [mepOilPress, setMepOilPress] = useState(0);
    const [coordinateValueMEP, setCoordinateValueMEP] = useState({
        boostPressure: {
            coordinate_desc: "Boost Pressure",
            coordinate_number: 1,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "KPa"
        },
        engineSpeed: {
            coordinate_desc: "Engine Speed",
            coordinate_number: 10,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "RPm"
        },
        railPressure: {
            coordinate_desc: "Rail Pressure",
            coordinate_number: 2,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: null
        },
        vehicleSpeed: {
            coordinate_desc: "Vehicle Speed",
            coordinate_number: 9,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "RPm"
        },
        railPressure: {
            coordinate_desc: "Rail Pressure",
            coordinate_number: 2,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: null
        },
        ambientPressure: {
            coordinate_desc: "Ambient Pressure",
            coordinate_number: 3,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "KPa"
        },
        freshWaterTemperature: {
            coordinate_desc: "Freshwater Temperature",
            coordinate_number: 5,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        batteryVoltage: {
            coordinate_desc: "Battery Voltage",
            coordinate_number: 7,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "V"
        },
        instantaneousFuelEconomy: {
            coordinate_desc: "Instantaneous Fuel Economy",
            coordinate_number: 13,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: null
        },
        fueltemperature: {
            coordinate_desc: "Fuel Temperature",
            coordinate_number: 6,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        oilPressure: {
            coordinate_desc: "Oil Pressure",
            coordinate_number: 14,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "MPa"
        },
        dailyRunningHours: {
            coordinate_desc: "Daily Running Hour",
            coordinate_number: 16,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "hours"
        },
        dcdcVoltage: {
            coordinate_desc: "DCDC Voltage",
            coordinate_number: 8,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "V"
        },
        totalRunningHours: {
            coordinate_desc: "Total Running Hour",
            coordinate_number: 15,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "hours"
        },
        oilTemperature: {
            coordinate_desc: "Oil Temperature",
            coordinate_number: 12,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        acceleratorPedalPosition: {
            coordinate_desc: "Accelerator Pedal Position",
            coordinate_number: 0,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "%"
        },
        ambientTemperature: {
            coordinate_desc: "Ambient Temperature",
            coordinate_number: 11,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        boostAirTemp: {
            coordinate_desc: "Boost Air Temperature",
            coordinate_number: 4,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        }
    });

    const prevCoordinateValueMEP = useRef({
        boostPressure: {
            coordinate_desc: "Boost Pressure",
            coordinate_number: 1,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "KPa"
        },
        engineSpeed: {
            coordinate_desc: "Engine Speed",
            coordinate_number: 10,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "RPm"
        },
        railPressure: {
            coordinate_desc: "Rail Pressure",
            coordinate_number: 2,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: null
        },
        vehicleSpeed: {
            coordinate_desc: "Vehicle Speed",
            coordinate_number: 9,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "RPm"
        },
        railPressure: {
            coordinate_desc: "Rail Pressure",
            coordinate_number: 2,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: null
        },
        ambientPressure: {
            coordinate_desc: "Ambient Pressure",
            coordinate_number: 3,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "KPa"
        },
        freshWaterTemperature: {
            coordinate_desc: "Freshwater Temperature",
            coordinate_number: 5,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        batteryVoltage: {
            coordinate_desc: "Battery Voltage",
            coordinate_number: 7,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "V"
        },
        instantaneousFuelEconomy: {
            coordinate_desc: "Instantaneous Fuel Economy",
            coordinate_number: 13,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: null
        },
        fueltemperature: {
            coordinate_desc: "Fuel Temperature",
            coordinate_number: 6,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        oilPressure: {
            coordinate_desc: "Oil Pressure",
            coordinate_number: 14,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "MPa"
        },
        dailyRunningHours: {
            coordinate_desc: "Daily Running Hour",
            coordinate_number: 16,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "hours"
        },
        dcdcVoltage: {
            coordinate_desc: "DCDC Voltage",
            coordinate_number: 8,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "V"
        },
        totalRunningHours: {
            coordinate_desc: "Total Running Hour",
            coordinate_number: 15,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "hours"
        },
        oilTemperature: {
            coordinate_desc: "Oil Temperature",
            coordinate_number: 12,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        acceleratorPedalPosition: {
            coordinate_desc: "Accelerator Pedal Position",
            coordinate_number: 0,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "%"
        },
        ambientTemperature: {
            coordinate_desc: "Ambient Temperature",
            coordinate_number: 11,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        boostAirTemp: {
            coordinate_desc: "Boost Air Temperature",
            coordinate_number: 4,
            coordinate_type: "MEP",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        }
    });


    const [coordinateValueMES, setCoordinateValueMES] = useState({
        boostPressure: {
            coordinate_desc: "Boost Pressure",
            coordinate_number: 1,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "KPa"
        },
        engineSpeed: {
            coordinate_desc: "Engine Speed",
            coordinate_number: 10,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "RPm"
        },
        railPressure: {
            coordinate_desc: "Rail Pressure",
            coordinate_number: 2,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: null
        },
        vehicleSpeed: {
            coordinate_desc: "Vehicle Speed",
            coordinate_number: 9,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "RPm"
        },
        railPressure: {
            coordinate_desc: "Rail Pressure",
            coordinate_number: 2,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: null
        },
        ambientPressure: {
            coordinate_desc: "Ambient Pressure",
            coordinate_number: 3,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "KPa"
        },
        freshWaterTemperature: {
            coordinate_desc: "Freshwater Temperature",
            coordinate_number: 5,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        batteryVoltage: {
            coordinate_desc: "Battery Voltage",
            coordinate_number: 7,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "V"
        },
        instantaneousFuelEconomy: {
            coordinate_desc: "Instantaneous Fuel Economy",
            coordinate_number: 13,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: null
        },
        fueltemperature: {
            coordinate_desc: "Fuel Temperature",
            coordinate_number: 6,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        oilPressure: {
            coordinate_desc: "Oil Pressure",
            coordinate_number: 14,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "MPa"
        },
        dailyRunningHours: {
            coordinate_desc: "Daily Running Hour",
            coordinate_number: 16,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "hours"
        },
        dcdcVoltage: {
            coordinate_desc: "DCDC Voltage",
            coordinate_number: 8,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "V"
        },
        totalRunningHours: {
            coordinate_desc: "Total Running Hour",
            coordinate_number: 15,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "hours"
        },
        oilTemperature: {
            coordinate_desc: "Oil Temperature",
            coordinate_number: 12,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        acceleratorPedalPosition: {
            coordinate_desc: "Accelerator Pedal Position",
            coordinate_number: 0,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "%"
        },
        ambientTemperature: {
            coordinate_desc: "Ambient Temperature",
            coordinate_number: 11,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        boostAirTemp: {
            coordinate_desc: "Boost Air Temperature",
            coordinate_number: 4,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        totalFuelUsage: {
                coordinate_desc: "Total Fuel Usage",
                value_unit: null,
                is_alarm: false,
                coordinate_type: "MEP",
                coordinate_number: 19,
                reading_value: "47.73",
                received_date: "2024-08-31T04:29:50.098Z"
        },
        averageFuelEconomy: {
            coordinate_desc: "Average Fuel Economy",
            value_unit: "L/h",
            is_alarm: false,
            coordinate_type: "MEP",
            coordinate_number: 17,
            reading_value: "6.67",
            received_date: "2024-08-31T04:29:50.098Z"
        }
    });
    const prevCoordinateValueMES = useRef({
        boostPressure: {
            coordinate_desc: "Boost Pressure",
            coordinate_number: 1,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "KPa"
        },
        engineSpeed: {
            coordinate_desc: "Engine Speed",
            coordinate_number: 10,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "RPm"
        },
        railPressure: {
            coordinate_desc: "Rail Pressure",
            coordinate_number: 2,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: null
        },
        vehicleSpeed: {
            coordinate_desc: "Vehicle Speed",
            coordinate_number: 9,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "RPm"
        },
        railPressure: {
            coordinate_desc: "Rail Pressure",
            coordinate_number: 2,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: null
        },
        ambientPressure: {
            coordinate_desc: "Ambient Pressure",
            coordinate_number: 3,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "KPa"
        },
        freshWaterTemperature: {
            coordinate_desc: "Freshwater Temperature",
            coordinate_number: 5,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        batteryVoltage: {
            coordinate_desc: "Battery Voltage",
            coordinate_number: 7,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "V"
        },
        instantaneousFuelEconomy: {
            coordinate_desc: "Instantaneous Fuel Economy",
            coordinate_number: 13,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: null
        },
        fueltemperature: {
            coordinate_desc: "Fuel Temperature",
            coordinate_number: 6,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        oilPressure: {
            coordinate_desc: "Oil Pressure",
            coordinate_number: 14,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "MPa"
        },
        dailyRunningHours: {
            coordinate_desc: "Daily Running Hour",
            coordinate_number: 16,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "hours"
        },
        dcdcVoltage: {
            coordinate_desc: "DCDC Voltage",
            coordinate_number: 8,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "V"
        },
        totalRunningHours: {
            coordinate_desc: "Total Running Hour",
            coordinate_number: 15,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "hours"
        },
        oilTemperature: {
            coordinate_desc: "Oil Temperature",
            coordinate_number: 12,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        acceleratorPedalPosition: {
            coordinate_desc: "Accelerator Pedal Position",
            coordinate_number: 0,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "%"
        },
        ambientTemperature: {
            coordinate_desc: "Ambient Temperature",
            coordinate_number: 11,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        boostAirTemp: {
            coordinate_desc: "Boost Air Temperature",
            coordinate_number: 4,
            coordinate_type: "MES",
            is_alarm: false,
            reading_value: "0",
            received_date: "2024-08-20T08:15:58.739Z",
            value_unit: "°C"
        },
        totalFuelUsage: {
                coordinate_desc: "Total Fuel Usage",
                value_unit: null,
                is_alarm: false,
                coordinate_type: "MES",
                coordinate_number: 19,
                reading_value: "47.73",
                received_date: "2024-08-31T04:29:50.098Z"
        },
        averageFuelEconomy: {
            coordinate_desc: "Average Fuel Economy",
            value_unit: "L/h",
            is_alarm: false,
            coordinate_type: "MES",
            coordinate_number: 17,
            reading_value: "6.67",
            received_date: "2024-08-31T04:29:50.098Z"
        }
    });

    const durationOption = [
        {
            label: "1 Jam",
            value: 1,
        },
        {
            label: "1 Hari",
            value: 2,
        },
        {
            label: "1 Minggu",
            value: 3,
        },
    ];
    // useEffect(() => {
    //     loadCoordinate();
    //     setRefreshChecked(true);
    // }, []);

    // useEffect(() => {
    //     window.addEventListener('error', e => {
    //         if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
    //             const resizeObserverErrDiv = document.getElementById(
    //                 'webpack-dev-server-client-overlay-div'
    //             );
    //             const resizeObserverErr = document.getElementById(
    //                 'webpack-dev-server-client-overlay'
    //             );
    //             if (resizeObserverErr) {
    //                 resizeObserverErr.setAttribute('style', 'display: none');
    //             }
    //             if (resizeObserverErrDiv) {
    //                 resizeObserverErrDiv.setAttribute('style', 'display: none');
    //             }
    //         }
    //     });
    // }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            window.addEventListener('error', e => {
                if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                    const resizeObserverErrDiv = document.getElementById(
                        'webpack-dev-server-client-overlay-div'
                    );
                    const resizeObserverErr = document.getElementById(
                        'webpack-dev-server-client-overlay'
                    );
                    if (resizeObserverErr) {
                        resizeObserverErr.setAttribute('style', 'display: none');
                    }
                    if (resizeObserverErrDiv) {
                        resizeObserverErrDiv.setAttribute('style', 'display: none');
                    }
                }
            });
            //console.log("page refreshed", refreshChecked);
            //console.log('SMS Serial Number: ', smsSerialNumber);
            loadCoordinate();
        }, 250);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        console.log(isDataReady);
    }, [isDataReady]);

    useEffect(() => {
        console.log("MEP ", coordinateValueMEP);
        if (coordinateValueMEP !== undefined) {
            setLoading(false);
            if (coordinateValueMES) {
                setIsDataReady(true);
            }
            prevCoordinateValueMEP.current = coordinateValueMEP;
        }
    }, [coordinateValueMEP]);

    useEffect(() => {
        console.log("MES ", coordinateValueMES);
        if (coordinateValueMES !== undefined) {
            setLoading(false);
            if (coordinateValueMEP) {
                setIsDataReady(true);
            }
            prevCoordinateValueMES.current = coordinateValueMES;
        }
    }, [coordinateValueMES])

    const MEPComponent = ({ mepValue }) => {
        const memoizedChild = useMemo(() => {
            return (
                <>
                <div className="gaugeComponentContainerNew">
                    <div style={{ width: '90%' }}><NewGaugeComponent value={parseFloat(mepValue.engineSpeed ? mepValue.engineSpeed['reading_value'] : "0").toFixed(0)} unit={'RPM'} /></div>
                </div>
                <div className="mainEngineNewResLeftBOTTOM">
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <ThrottleBar text={"THROTTLE"} value={parseFloat(mepValue.acceleratorPedalPosition['reading_value'])} unit={'%'} wireBreak={'false'} />
                    </div>
                    <div style={{ width: '100%', padding: '10px' }}>
                        <p style={{ color: 'white', fontSize: 'large', fontWeight: 'lighter', margin: '0 0', fontFamily: 'Disket Mono' }}>{"Fuel Usage"}</p>
                        <div className="fuelUsageContainerBox">
                            <BoxWithText title={'TOTAL'} value={mepValue.totalFuelUsage ? mepValue.totalFuelUsage['reading_value'] : "0"} unit={' L'} />
                            <BoxWithText title={'AVG'} value={mepValue.averageFuelEconomy ? mepValue.averageFuelEconomy['reading_value'] : "0"} unit={' L/H'} />
                            <BoxWithText title={'CURRENT'} value={mepValue.instantaneousFuelEconomy ? mepValue.instantaneousFuelEconomy['reading_value'] : "0"} unit={' L/H'} /> {/*Instantaneous Fuel Economy*/}
                        </div>
                    </div>
                    <div style={{ width: '100%', padding: '10px' }}>
                        <p style={{ color: 'white', fontSize: 'large', fontWeight: 'lighter', margin: '0 0', fontFamily: 'Disket Mono' }}>{"Running Hour"}</p>
                        <div className="fuelUsageContainerBox">
                            <BoxWithText title={'TOTAL'} value={(parseFloat(mepValue.totalRunningHours ? mepValue.totalRunningHours['reading_value'] : "0") + 10).toFixed(2)} unit={' H'} />
                            <BoxWithText title={'AFTER GO'} value={(parseFloat(mepValue.totalRunningHours ? mepValue.totalRunningHours['reading_value'] : "0") + 10).toFixed(2)} unit={' H'} />
                            <BoxWithText title={'DAILY'} value={mepValue.dailyRunningHours ? mepValue.dailyRunningHours['reading_value'] : "0"} unit={' H'} />
                        </div>
                    </div>
                    <div className="progressBarContainerNew">
                        <div className="progressBarContainerInner">
                            <ProgressBar
                              text="L. O. Pressure"
                              value={parseFloat(mepValue.oilPressure ? mepValue.oilPressure['reading_value'] : "0")}
                              min={0}
                              max={2}
                              redRange={{ min: 0, max: 0.1 }}
                              yellowRange={{ min: 0.2, max: 0.34 }}
                              greenRange={{ min: 0.35, max: 2 }}
                              unit=" MPA"
                              highValue="0"
                              lowValue="0"
                            />
                        </div>
                        <div className="progressBarContainerInner">
                            <ProgressBar
                              text="Fuel Temp"
                              value={parseFloat(mepValue.fueltemperature ? mepValue.fueltemperature['reading_value'] : "0").toFixed(0)}
                              min={0}
                              max={120}
                              redRange={{ min: 110, max: 120 }}
                              yellowRange={{ min: 100, max: 109 }}
                              greenRange={{ min: 0, max: 99 }}
                              unit=" °C"
                              highValue="0"
                              lowValue="0"
                            />
                        </div>
                        <div className="progressBarContainerInner">
                            <ProgressBar
                              text="L. O. Temperature"
                              value={parseFloat(mepValue.oilTemperature ? mepValue.oilTemperature['reading_value'] : "0").toFixed(0)}
                              min={0}
                              max={120}
                              redRange={{ min: 110, max: 120 }}
                              yellowRange={{ min: 90, max: 109 }}
                              greenRange={{ min: 0, max: 89 }}
                              unit=" °C"
                              highValue="0"
                              lowValue="0"
                            />
                        </div>
                        <div className="progressBarContainerInner">
                            <ProgressBar
                              text="Boost Air Press"
                              value={parseFloat(mepValue.boostPressure ? mepValue.boostPressure['reading_value'] : "0").toFixed(0)}
                              min={0}
                              max={500}
                              redRange={{ min: 300, max: 500 }}
                              yellowRange={{ min: 200, max: 299 }}
                              greenRange={{ min: 0, max: 199 }}
                              unit=" KPA"
                              highValue="0"
                              lowValue="0"
                            />
                        </div>
                        <div className="progressBarContainerInner">
                            <ProgressBar
                              text="F. W Temperature"
                              value={parseFloat(mepValue.freshWaterTemperature ? mepValue.freshWaterTemperature['reading_value'] : "0").toFixed(0)}
                              min={0}
                              max={100}
                              redRange={{ min: 96, max: 100 }}
                              yellowRange={{ min: 91, max: 95 }}
                              greenRange={{ min: 0, max: 90 }}
                              unit=" °C"
                              highValue="0"
                              lowValue="0"
                            />
                        </div>
                        <div className="progressBarContainerInner">
                        <ProgressBar
                              text="Battery Voltage"
                              value={parseFloat(mepValue.batteryVoltage ? mepValue.batteryVoltage['reading_value'] : "0").toFixed(0)}
                              min={0}
                              max={30}
                              redRange={{ min: 0, max: 23.3 }}
                              yellowRange={{ min: 23.4, max: 23.9 }}
                              greenRange={{ min: 23.9, max: 30 }}
                              unit=" V"
                              highValue="0"
                              lowValue="0"
                            />
                        </div>
                    </div>
                    <div className="lastReceivedDateContainerNew">
                        <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDateMEP}</p>
                    </div>
                </div>
            </>
            );

        }, [mepValue]);

        return <div className="bgForMainEngineNEWCONTAINERLeftBottom">
            {memoizedChild}
        </div>;
    }

    const MESComponent = ({ mesValue }) => {
        const memoizedChild = useMemo(() => {
            return <div>
                <div className="gaugeComponentContainerNew">
                    <div style={{ width: '90%' }}><NewGaugeComponent value={parseFloat(mesValue.engineSpeed ? mesValue.engineSpeed['reading_value'] : "0").toFixed(0)} unit={'RPM'} /></div>
                </div>
                <div className="mainEngineNewResLeftBOTTOM">
                    <div className="throttleContainerNew">
                        <ThrottleBar text={"THROTTLE"} value={parseFloat(mesValue.acceleratorPedalPosition['reading_value'])} unit={'%'} wireBreak={'false'}/>
                    </div>
                    <div style={{ width: '100%', padding: '10px' }}>
                        <p style={{ color: 'white', fontSize: 'large', fontWeight: 'lighter', margin: '0 0', fontFamily: 'Disket Mono' }}>{"Fuel Usage"}</p>
                        <div className="fuelUsageContainerBox">
                            <BoxWithText title={'TOTAL'} value={mesValue.totalFuelUsage ? mesValue.totalFuelUsage['reading_value'] : "0"} unit={' L'} />
                            <BoxWithText title={'AVG'} value={mesValue.averageFuelEconomy ? mesValue.averageFuelEconomy['reading_value'] : "0"} unit={' L/H'} />
                            <BoxWithText title={'CURRENT'} value={mesValue.instantaneousFuelEconomy ? mesValue.instantaneousFuelEconomy['reading_value'] : "0"} unit={' L/H'} /> {/*Instantaneous Fuel Economy*/}
                        </div>
                    </div>
                    <div style={{ width: '100%', padding: '10px' }}>
                        <p style={{ color: 'white', fontSize: 'large', fontWeight: 'lighter', margin: '0 0', fontFamily: 'Disket Mono' }}>{"Running Hour"}</p>
                        <div className="fuelUsageContainerBox">
                            <BoxWithText title={'TOTAL'} value={(parseFloat(mesValue.totalRunningHours ? mesValue.totalRunningHours['reading_value'] : "0") + 7).toFixed(2)} unit={' H'} />
                            <BoxWithText title={'AFTER GO'} value={(parseFloat(mesValue.totalRunningHours ? mesValue.totalRunningHours['reading_value'] : "0") + 7).toFixed(2)} unit={' H'} />
                            <BoxWithText title={'DAILY'} value={mesValue.dailyRunningHours ? mesValue.dailyRunningHours['reading_value'] : "0"} unit={' H'} />
                        </div>
                    </div>
                    <div className="progressBarContainerNew">
                    <div className="progressBarContainerInner">
                            <ProgressBar
                              text="L. O Pressure"
                              value={parseFloat(mesValue.oilPressure ? mesValue.oilPressure['reading_value'] : "0")}
                              min={0}
                              max={2}
                              redRange={{ min: 0, max: 0.1 }}
                              yellowRange={{ min: 0.2, max: 0.34 }}
                              greenRange={{ min: 0.35, max: 2 }}
                              unit=" MPA"
                              highValue="0"
                              lowValue="0"
                            />
                        </div>
                        <div className="progressBarContainerInner">
                            <ProgressBar
                              text="Fuel Temp"
                              value={parseFloat(mesValue.fueltemperature ? mesValue.fueltemperature['reading_value'] : "0").toFixed(0)}
                              min={0}
                              max={120}
                              redRange={{ min: 110, max: 120 }}
                              yellowRange={{ min: 100, max: 109 }}
                              greenRange={{ min: 0, max: 99 }}
                              unit=" °C"
                              highValue="0"
                              lowValue="0"
                            />
                        </div>
                        <div className="progressBarContainerInner">
                            <ProgressBar
                              text="L. O Temperature"
                              value={parseFloat(mesValue.oilTemperature ? mesValue.oilTemperature['reading_value'] : "0").toFixed(0)}
                              min={0}
                              max={120}
                              redRange={{ min: 110, max: 120 }}
                              yellowRange={{ min: 90, max: 109 }}
                              greenRange={{ min: 0, max: 89 }}
                              unit=" °C"
                              highValue="0"
                              lowValue="0"
                            />
                        </div>
                        <div className="progressBarContainerInner">
                            <ProgressBar
                              text="Boost Air Press"
                              value={parseFloat(mesValue.boostPressure ? mesValue.boostPressure['reading_value'] : "0").toFixed(0)}
                              min={0}
                              max={500}
                              redRange={{ min: 300, max: 500 }}
                              yellowRange={{ min: 200, max: 299 }}
                              greenRange={{ min: 0, max: 199 }}
                              unit=" KPA"
                              highValue="0"
                              lowValue="0"
                            />
                        </div>
                        <div className="progressBarContainerInner">
                            <ProgressBar
                              text="F. W Temperature"
                              value={parseFloat(mesValue.freshWaterTemperature ? mesValue.freshWaterTemperature['reading_value'] : "0").toFixed(0)}
                              min={0}
                              max={100}
                              redRange={{ min: 96, max: 100 }}
                              yellowRange={{ min: 91, max: 95 }}
                              greenRange={{ min: 0, max: 90 }}
                              unit=" °C"
                              highValue="0"
                              lowValue="0"
                            />
                        </div>
                        <div className="progressBarContainerInner">
                        <ProgressBar
                              text="Battery Voltage"
                              value={parseFloat(mesValue.batteryVoltage ? mesValue.batteryVoltage['reading_value'] : "0").toFixed(0)}
                              min={0}
                              max={30}
                              redRange={{ min: 0, max: 23.3 }}
                              yellowRange={{ min: 23.4, max: 23.9 }}
                              greenRange={{ min: 23.9, max: 30 }}
                              unit=" V"
                              highValue="0"
                              lowValue="0"
                            />
                        </div>
                    </div>
                    <div className="lastReceivedDateContainerNew">
                        <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDateMES}</p>
                    </div>
                </div>
            </div>;

        }, [mesValue]);

        return <div className="bgForMainEngineNEWCONTAINERRightBottom">
            {memoizedChild}
        </div>;
    }


    const loadCoordinate = async () => {
        try {
            let coordinateTypePort = 'MEP';
            let coordinateTypeSTBD = 'MEP';
            let responseMEP = await getPLCReadingValueForEngines(cookies.token, smsSerialNumber, vesselId, coordinateTypePort, coordinateTypeSTBD);
            if (responseMEP !== undefined) {
                if (responseMEP != []) {
                    let coordValueObjMEP = {};
                    coordValueObjMEP.boostPressure = responseMEP.filter(p => p.coordinate_number == 1)[0];
                    coordValueObjMEP.engineSpeed = responseMEP.filter(p => p.coordinate_number == 10)[0];
                    coordValueObjMEP.railPressure = responseMEP.filter(p => p.coordinate_number == 2)[0];
                    coordValueObjMEP.vehicleSpeed = responseMEP.filter(p => p.coordinate_number == 9)[0];
                    coordValueObjMEP.railPressure = responseMEP.filter(p => p.coordinate_number == 2)[0];
                    coordValueObjMEP.ambientPressure = responseMEP.filter(p => p.coordinate_number == 3)[0];
                    coordValueObjMEP.freshWaterTemperature = responseMEP.filter(p => p.coordinate_number == 5)[0];
                    coordValueObjMEP.batteryVoltage = responseMEP.filter(p => p.coordinate_number == 7)[0];
                    coordValueObjMEP.instantaneousFuelEconomy = responseMEP.filter(p => p.coordinate_number == 13)[0];
                    coordValueObjMEP.fueltemperature = responseMEP.filter(p => p.coordinate_number == 6)[0];
                    coordValueObjMEP.oilPressure = responseMEP.filter(p => p.coordinate_number == 14)[0];
                    coordValueObjMEP.dailyRunningHours = responseMEP.filter(p => p.coordinate_number == 16)[0];
                    coordValueObjMEP.dcdcVoltage = responseMEP.filter(p => p.coordinate_number == 8)[0];
                    coordValueObjMEP.totalRunningHours = responseMEP.filter(p => p.coordinate_number == 15)[0];
                    coordValueObjMEP.oilTemperature = responseMEP.filter(p => p.coordinate_number == 12)[0];
                    coordValueObjMEP.acceleratorPedalPosition = responseMEP.filter(p => p.coordinate_number == 0)[0];
                    coordValueObjMEP.ambientTemperature = responseMEP.filter(p => p.coordinate_number == 11)[0];
                    coordValueObjMEP.boostAirTemp = responseMEP.filter(p => p.coordinate_number == 4)[0];
                    coordValueObjMEP.totalFuelUsage = responseMEP.filter(p => p.coordinate_number == 19)[0];
                    coordValueObjMEP.averageFuelEconomy = responseMEP.filter(p => p.coordinate_number == 17)[0];
                    if (coordValueObjMEP.batteryVoltage !== undefined) {
                        setCoordinateValueMEP(coordValueObjMEP);
                        let receivedDate = moment(responseMEP[0].received_date).format("yyyy-MM-DD HH:mm:ss");
                        setLastReceivedDateMEP(receivedDate);
                    }
                }
            }

            coordinateTypePort = 'MES';
            coordinateTypeSTBD = 'MES';
            let responseMES = await getPLCReadingValueForEngines(cookies.token, smsSerialNumber, vesselId, coordinateTypePort, coordinateTypeSTBD);
            if (responseMES !== undefined) {
                if (responseMES != []) {
                    let coordValueObjMES = {};
                    coordValueObjMES.boostPressure = responseMES.filter(p => p.coordinate_number == 1)[0];
                    coordValueObjMES.engineSpeed = responseMES.filter(p => p.coordinate_number == 10)[0];
                    coordValueObjMES.railPressure = responseMES.filter(p => p.coordinate_number == 2)[0];
                    coordValueObjMES.vehicleSpeed = responseMES.filter(p => p.coordinate_number == 9)[0];
                    coordValueObjMES.railPressure = responseMES.filter(p => p.coordinate_number == 2)[0];
                    coordValueObjMES.ambientPressure = responseMES.filter(p => p.coordinate_number == 3)[0];
                    coordValueObjMES.freshWaterTemperature = responseMES.filter(p => p.coordinate_number == 5)[0];
                    coordValueObjMES.batteryVoltage = responseMES.filter(p => p.coordinate_number == 7)[0];
                    coordValueObjMES.instantaneousFuelEconomy = responseMES.filter(p => p.coordinate_number == 13)[0];
                    coordValueObjMES.fueltemperature = responseMES.filter(p => p.coordinate_number == 6)[0];
                    coordValueObjMES.oilPressure = responseMES.filter(p => p.coordinate_number == 14)[0];
                    coordValueObjMES.dailyRunningHours = responseMES.filter(p => p.coordinate_number == 16)[0];
                    coordValueObjMES.dcdcVoltage = responseMES.filter(p => p.coordinate_number == 8)[0];
                    coordValueObjMES.totalRunningHours = responseMES.filter(p => p.coordinate_number == 15)[0];
                    coordValueObjMES.oilTemperature = responseMES.filter(p => p.coordinate_number == 12)[0];
                    coordValueObjMES.acceleratorPedalPosition = responseMES.filter(p => p.coordinate_number == 0)[0];
                    coordValueObjMES.ambientTemperature = responseMES.filter(p => p.coordinate_number == 11)[0];
                    coordValueObjMES.boostAirTemp = responseMES.filter(p => p.coordinate_number == 4)[0];
                    coordValueObjMES.totalFuelUsage = responseMES.filter(p => p.coordinate_number == 19)[0];
                    coordValueObjMES.averageFuelEconomy = responseMES.filter(p => p.coordinate_number == 17)[0];
                    if (coordValueObjMES.batteryVoltage !== undefined) {
                        setCoordinateValueMES(coordValueObjMES);
                        let receivedDate = moment(responseMES[0].received_date).format("yyyy-MM-DD HH:mm:ss");
                        setLastReceivedDateMES(receivedDate);
                    }

                }
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <div className="mainEngineNEWCONTAINER">
                <div className="mainEngineNEWCONTAINERLeft">
                    {/* <div className="titleGaugeLeft">
                        <h1 className="txtforTitleOnMainEngine">PORT</h1>
                        <h1 className="txtforDetailOnMainEngine">SDEC SC38W1000.12CA1</h1>
                        <h1 className="txtforDetailOnMainEngine">S/N: W9239000695</h1>
                    </div> */}
                    <MEPComponent mepValue={coordinateValueMEP !== undefined ? coordinateValueMEP : prevCoordinateValueMEP.current} />
                </div>
                <div className="mainEngineNEWCONTAINERRight">
                    {/* <div className="titleGaugeRight">
                        <h1 className="txtforTitleOnMainEngine">STARBOARD</h1>
                        <h1 className="txtforDetailOnMainEngine">SDEC SC38W1000.12CA1</h1>
                        <h1 className="txtforDetailOnMainEngine">S/N: W9239000697</h1>
                    </div> */}
                    <MESComponent mesValue={coordinateValueMES !== undefined ? coordinateValueMES : prevCoordinateValueMES.current} />
                </div>
            </div>
            <Loading
                loading={loading}
            />
        </>
    )
}