// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bitLampBoxFMSFalse{
    width: 50px;
    height: 20px;
    border: 1px #1E3A8A solid;
    background-color: none;
    margin: 0 10px;
}

.bitLampBoxFMSTrue{
    width: 50px;
    height: 20px;
    border: 1px #1E3A8A solid;
    background-color: #ea0808;
    margin: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/v2/FMSBitLamp/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".bitLampBoxFMSFalse{\n    width: 50px;\n    height: 20px;\n    border: 1px #1E3A8A solid;\n    background-color: none;\n    margin: 0 10px;\n}\n\n.bitLampBoxFMSTrue{\n    width: 50px;\n    height: 20px;\n    border: 1px #1E3A8A solid;\n    background-color: #ea0808;\n    margin: 0 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
