import "./index.css"
import { useState, useEffect, useRef, useMemo } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { isTokenValid, findSmsVesselPosition, getPLCReadingValue, getPLCReadingValueForGearboxVibration, getSMSCoordinateByCoordinateType, getPLCReadingValueForGearboxNew, getPLCReadingValueForEngines, getPLCReadingValueForMotorPump } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
import { Form, Button } from "react-bootstrap";
// import DynamicLineChart from "../../../Components/DynamicLineChart2";
import DynamicLineChart from "../../../Components/DynamicLineChart2";
import DropDownForm from "../../../Components/DropDownForm";
import moment from "moment";
import ProgressBar from "../../../Components/ProgressBar/ProgressBar";
import ThrottleBar from "../../../Components/ThrottleBar/ThrottleBar";
import GaugeComponent from 'react-gauge-component'
import NewGaugeComponent from "../../../Components/GaugeComponent3/index";
import BoxWithText from "../../../Components/BoxWithText/BoxWithText";
import { Trans, useTranslation } from "react-i18next";

export default function CustomerSMSGearBoxPage(props) {
     const navigate = useNavigate();
     const { t, i18n } = useTranslation();
     const { smsSerialNumber, vesselId } = props;
     const [cookies, setCookie] = useCookies(["token"]);
     const [loading, setLoading] = useState(false);
     const [lastReceivedDatePort, setLastReceivedDatePort] = useState();
     const [lastReceivedDateStarboard, setLastReceivedDateStarboard] = useState();
     const [selectStartDate, setSelectStartDate] = useState(moment(new Date()).utc().subtract(30, "minutes").format("yyyy-MM-DD HH:mm:ss"));
     const [selectEndDate, setSelectEndDate] = useState(moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss"));
     const [refreshChecked, setRefreshChecked] = useState(false);
     const [dataShowPort, setDataShowPort] = useState([]);
     const [dataShowSTBD, setDataShowSTBD] = useState([]);
     const [dataCreatedDate, setDataCreatedDate] = useState();
     const [isDataReady, setIsDataReady]= useState(false);

     const [coordinateAheadAsternValueGB, setCooordinateAheadAsternValueGB] = useState({
        M54: {
            coordinate_desc: "GB Port Clutch Ahead",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "M",
            coordinate_number: 54,
            reading_value: "",
            received_date: ""
        },
        M58: {
            coordinate_desc: "GB Stbd Clutch Ahead",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "M",
            coordinate_number: 58,
            reading_value: "",
            received_date: ""
        },
        M56: {
            coordinate_desc: "GB Port Clutch Astern",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "M",
            coordinate_number: 56,
            reading_value: "",
            received_date: ""
        },
        M60: {
            coordinate_desc: "GB Stbd Clutch Astern",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "M",
            coordinate_number: 60,
            reading_value: "",
            received_date: ""
        },
     })


     const [coordinateValueGB, setCoordinateValueGB] = useState({
        oilTempGearboxPort: {
            coordinate_desc: "Oil Temp Gearbox Port",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "MEP",
            coordinate_number: 20,
            reading_value: "1319",
            received_date: "2024-09-01T15:16:48.572Z"
        },
        oilPressGearboxPort: {
            coordinate_desc: "Oil Pressure Gearbox Port",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "D",
            coordinate_number: 0,
            reading_value: "1",
            received_date: "2024-09-01T15:16:48.572Z"
        },
        gearboxStarboardVibration: {
            coordinate_desc: "Gearbox Stbd Vibration",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "D",
            coordinate_number: 5,
            reading_value: "20",
            received_date: "2024-09-01T15:16:48.572Z"
        },
        oilTempGearboxStarboard: {
            coordinate_desc: "Oil Temp Gearbox Stbd",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "MES",
            coordinate_number: 20,
            reading_value: "4095",
            received_date: "2024-09-01T15:16:48.572Z"
        },
        oilPressGearboxStarboard: {
            coordinate_desc: "Oil Pressure Gearbox Stbd",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "D",
            coordinate_number: 2,
            reading_value: "1",
            received_date: "2024-09-01T15:16:48.572Z"
        },
        rpmPropStarboard: {
            coordinate_desc: "RPM Prop Stbd",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "D",
            coordinate_number: 17,
            reading_value: "0",
            received_date: "2024-09-01T15:16:48.572Z",
            gb_direction: "NEUTRAL"
        },
        rpmPropPort: {
            coordinate_desc: "RPM Prop Port",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "D",
            coordinate_number: 16,
            reading_value: "0",
            received_date: "2024-09-01T15:16:48.572Z",
            gb_direction: "NEUTRAL"
        },
        gearboxPortVibration: {
            coordinate_desc: "Gearbox Port Vibration",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "D",
            coordinate_number: 4,
            reading_value: "28",
            received_date: "2024-09-01T15:16:48.572Z"
        }
    });

    const prevCoordinateValueGB = useRef({
        oilTempGearboxPort: {
            coordinate_desc: "Oil Temp Gearbox Port",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "MEP",
            coordinate_number: 20,
            reading_value: "1319",
            received_date: "2024-09-01T15:16:48.572Z"
        },
        oilPressGearboxPort: {
            coordinate_desc: "Oil Pressure Gearbox Port",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "D",
            coordinate_number: 0,
            reading_value: "1",
            received_date: "2024-09-01T15:16:48.572Z"
        },
        gearboxStarboardVibration: {
            coordinate_desc: "Gearbox Stbd Vibration",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "D",
            coordinate_number: 5,
            reading_value: "20",
            received_date: "2024-09-01T15:16:48.572Z"
        },
        oilTempGearboxStarboard: {
            coordinate_desc: "Oil Temp Gearbox Stbd",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "MES",
            coordinate_number: 20,
            reading_value: "4095",
            received_date: "2024-09-01T15:16:48.572Z"
        },
        oilPressGearboxStarboard: {
            coordinate_desc: "Oil Pressure Gearbox Stbd",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "D",
            coordinate_number: 2,
            reading_value: "1",
            received_date: "2024-09-01T15:16:48.572Z"
        },
        rpmPropStarboard: {
            coordinate_desc: "RPM Prop Stbd",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "D",
            coordinate_number: 17,
            reading_value: "0",
            received_date: "2024-09-01T15:16:48.572Z",
            gb_direction: "NEUTRAL"
        },
        rpmPropPort: {
            coordinate_desc: "RPM Prop Port",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "D",
            coordinate_number: 16,
            reading_value: "0",
            received_date: "2024-09-01T15:16:48.572Z",
            gb_direction: "NEUTRAL"
        },
        gearboxPortVibration: {
            coordinate_desc: "Gearbox Port Vibration",
            value_unit: "",
            is_alarm: false,
            coordinate_type: "D",
            coordinate_number: 4,
            reading_value: "28",
            received_date: "2024-09-01T15:16:48.572Z"
        }
    });


     const durationOption = [
        {
          label: "1 Jam",
          value: 1,
        },
        {
          label: "1 Hari",
          value: 2,
        },
        {
          label: "1 Minggu",
          value: 3,
        },
    ];
    // useEffect(() => {
    //     if (!refreshChecked){
    //         // console.log("refreshedchcked", refreshChecked);
    //         loadCoordinate();
    //         setRefreshChecked(true);
    //     }
    // }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            window.addEventListener('error', e => {
                if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                    const resizeObserverErrDiv = document.getElementById(
                        'webpack-dev-server-client-overlay-div'
                    );
                    const resizeObserverErr = document.getElementById(
                        'webpack-dev-server-client-overlay'
                    );
                    if (resizeObserverErr) {
                        resizeObserverErr.setAttribute('style', 'display: none');
                    }
                    if (resizeObserverErrDiv) {
                        resizeObserverErrDiv.setAttribute('style', 'display: none');
                    }
                }
            });
            //console.log("page refreshed", refreshChecked);
            //console.log('SMS Serial Number: ', smsSerialNumber);
            loadCoordinate();
            loadVibrationValuePort();
            loadVibrationValueStarboard();
        }, 250);
        return () => clearInterval(interval);
    }, []);

    // useEffect(() => {
    //     window.addEventListener('error', e => {
    //         if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
    //             const resizeObserverErrDiv = document.getElementById(
    //                 'webpack-dev-server-client-overlay-div'
    //             );
    //             const resizeObserverErr = document.getElementById(
    //                 'webpack-dev-server-client-overlay'
    //             );
    //             if (resizeObserverErr) {
    //                 resizeObserverErr.setAttribute('style', 'display: none');
    //             }
    //             if (resizeObserverErrDiv) {
    //                 resizeObserverErrDiv.setAttribute('style', 'display: none');
    //             }
    //         }
    //     });
    // }, []);

    // const memoizedDataShowPort = useMemo(() => dataShowPort, [dataShowPort]);
    // const memoizedDataShowSTBD = useMemo(() => dataShowSTBD, [dataShowSTBD]);


    useEffect(() => {
        setLoading(false);
        console.log("GEARBOX ", coordinateValueGB);
        if (!coordinateValueGB) {
            prevCoordinateValueGB.current = coordinateValueGB;
            
            // loadAheadAsternValue();
        }
    }, [coordinateValueGB]);

    useEffect( () => {
        console.log('coordinateAheadAsternValueGB',coordinateAheadAsternValueGB);
    }, [coordinateAheadAsternValueGB])

    useEffect( () => {
        console.log('datashowport',dataShowPort);
    }, [dataShowPort])

    useEffect( () => {
        console.log('datashowstbd',dataShowSTBD);
    }, [dataShowSTBD])


    const GBComponent = ({ gbValue }) => {
        const memoizedChild = useMemo(() => {
            return <div className="GearBoxNewRes">
                <div className="gearBoxNewResLeft-2">
                    <div className="gaugeComponentContainerNewGB">
                        <div style={{ width: '500px' }}><NewGaugeComponent value={parseInt(gbValue.rpmPropPort.reading_value)} unit={'RPM'} /></div>
                    </div>
                    <div className="componentsUnderGaugeGearbox">
                        <div className="componentsUnderGaugeGearboxLeft">
                            <div className="progressBarContainerInnerGB">
                                <ProgressBar
                                    text="L. O Pressure"
                                    value={gbValue.oilPressGearboxPort ? parseFloat(gbValue.oilPressGearboxPort.reading_value).toFixed(2) : '0'}
                                    min={0}
                                    max={6}
                                    redRange={{ min: 1.90, max: 6 }}
                                    yellowRange={{ min: 1.65, max: 1.89 }}
                                    greenRange={{ min: 0, max: 1.64 }}
                                    unit=" MPA"
                                    highValue="0"
                                    lowValue="0"
                                />
                            </div>
                            {/* <div className="progressBarContainerInnerGB">
                                <ProgressBar
                                    text="L. O Temperature"
                                    value={parseFloat(gbValue.oilTempGearboxPort.reading_value).toFixed(2)}
                                    min={0}
                                    max={120}
                                    redRange={{ min: 81.0, max: 120 }}
                                    yellowRange={{ min: 75.0, max: 80.9 }}
                                    greenRange={{ min: 0, max: 74.9 }}
                                    unit=" °C"
                                    highValue="0"
                                    lowValue="0"
                                />
                            </div> */}
                        </div>
                        <div className="componentsUnderGaugeGearboxRight">
                            <div className="graphContainerMotorPumpGB">
                                <h5 style={{ color: 'white', fontFamily: 'Disket Mono', marginTop: '5px'}}>{'VIBRATION'}</h5>
                                <DynamicLineChart
                                    graphTitle={'Vibration'}
                                    dataToShow={dataShowPort ? dataShowPort : []}
                                    minValue={0}
                                    maxValue={100}
                                    stepSize={25}
                                    lastValue={dataShowPort[0] ? dataShowPort[dataShowPort.length-1].reading_value : ''}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="aheadAsternContainerGB">
                        {
                            <h1 className="aheadAsternTextOnGB">{gbValue.rpmPropPort.gb_direction}</h1>
                        }
                        {/* {
                            gbValue.gbPortClutchAstern['reading_value'] === "true" ? <h1 className="aheadAsternTextOnGB">{'ASTERN'}</h1> : <h1 className="aheadAsternTextOnGB">{'NEUTRAL'}</h1>
                        } */}
                    </div>
                    <div className="lastReceivedDateContainerNew">
                        <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDatePort}</p>
                    </div>
                </div>
                <div className="gearBoxNewResRight-2">
                    <div className="gaugeComponentContainerNewGB">
                        <div style={{ width: '500px' }}><NewGaugeComponent value={parseInt(gbValue.rpmPropStarboard.reading_value)} unit={'RPM'} /></div>
                    </div>
                    <div className="componentsUnderGaugeGearbox">
                        <div className="componentsUnderGaugeGearboxLeft">
                            <div className="progressBarContainerInnerGB">
                                <ProgressBar
                                    text="L. O Pressure"
                                    value={gbValue.oilPressGearboxStarboard ? parseFloat(gbValue.oilPressGearboxStarboard.reading_value).toFixed(2) : '0'}
                                    min={0}
                                    max={6}
                                    redRange={{ min: 1.90, max: 6 }}
                                    yellowRange={{ min: 1.65, max: 1.89 }}
                                    greenRange={{ min: 0, max: 1.64 }}
                                    unit=" MPA"
                                    highValue="0"
                                    lowValue="0"
                                />
                            </div>
                            {/* <div className="progressBarContainerInnerGB">
                                <ProgressBar
                                    text="L. O Temperature"
                                    value={parseFloat(gbValue.oilTempGearboxStarboard.reading_value).toFixed(2)} 
                                    min={0}
                                    max={120}
                                    redRange={{ min: 81.0, max: 120 }}
                                    yellowRange={{ min: 75.0, max: 80.9 }}
                                    greenRange={{ min: 0, max: 74.9 }}
                                    unit=" °C"
                                    highValue="0"
                                    lowValue="0"
                                />
                            </div> */}
                        </div>
                        <div className="componentsUnderGaugeGearboxRight">
                            <div className="graphContainerMotorPumpGB">
                                <h5 style={{ color: 'white', fontFamily: 'Disket Mono', marginTop: '5px'}}>{'VIBRATION'}</h5>
                                <DynamicLineChart
                                    graphTitle={'Vibration'}
                                    dataToShow={dataShowSTBD}
                                    minValue={0}
                                    maxValue={100}
                                    stepSize={25}
                                    lastValue={dataShowSTBD[0] ? dataShowSTBD[dataShowSTBD.length-1].reading_value : ''}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="aheadAsternContainerGB">
                    {
                           <h1 className="aheadAsternTextOnGB">{gbValue.rpmPropStarboard.gb_direction}</h1>
                        }
                    </div>
                    <div className="lastReceivedDateContainerNew">
                        <p className="txtforLastReceivedData">Last Received Data on {lastReceivedDateStarboard}</p>
                    </div>
                </div>
            </div>;

        }, [gbValue]);

        return <div>
            {memoizedChild}
        </div>;
    }

    const loadVibrationValuePort = async () => {
        try {
            let intervalTime = '30 Seconds';
            let coordinateType = 'D';
            let coordinateNumber = 4;
            let responseReading = await getPLCReadingValueForGearboxVibration(cookies.token, smsSerialNumber, coordinateType, coordinateNumber, vesselId, intervalTime);
            console.log('responsePORT', responseReading);
            if (responseReading) {
                setDataShowPort(responseReading);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadVibrationValueStarboard = async () => {
        try {
            let intervalTime = '30 Seconds';
            let coordinateType = 'D';
            let coordinateNumber = 5;
            let responseReading = await getPLCReadingValueForGearboxVibration(cookies.token, smsSerialNumber, coordinateType, coordinateNumber, vesselId, intervalTime);
            console.log('responseSTBD', responseReading);
            if (responseReading){
                setDataShowSTBD(responseReading);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadAheadAsternValue = async () => {
        try {
            let idCoordinateGroup = [25];
            let coordinateType = 'M';
            let responseReading = await getPLCReadingValue(cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId);
            console.log('responseReadingAheadAstern',responseReading);
            let coordinateAheadAsternValueGBTmp = coordinateAheadAsternValueGB;
            for (let count in coordinateAheadAsternValueGB) {
                let find = responseReading.find(p=>p.coordinate_type === "M" && p.coordinate_number === +coordinateAheadAsternValueGB[count].coordinate_number)
                if (find) {
                    coordinateAheadAsternValueGBTmp[count].value_unit = find.value_unit;
                    coordinateAheadAsternValueGBTmp[count].reading_value = find.reading_value;
                    coordinateAheadAsternValueGBTmp[count].received_date = find.received_date;
                }
            }
            setCooordinateAheadAsternValueGB(coordinateAheadAsternValueGBTmp);
            // let getVibrationStarboard = responseReading.filter(p => p.coordinate_number === 5);
            // setDataShowSTBD(getVibrationStarboard[0].value);

        } catch (exception) {
            console.log(exception);
        }
    }

    const loadCoordinate = async () => {
        try{
            let responseGB = await getPLCReadingValueForGearboxNew(cookies.token, smsSerialNumber, vesselId);
            
            if (responseGB) {
                    let coordValueObjGB = {};
                    coordValueObjGB.oilTempGearboxPort = responseGB.find(p => p.coordinate_type == 'D' && p.coordinate_number === 1);
                    coordValueObjGB.oilPressGearboxPort = responseGB.find(p => p.coordinate_number == 0);
                    coordValueObjGB.gearboxStarboardVibration = responseGB.find(p => p.coordinate_type === 'D' && p.coordinate_number == 5);
                    coordValueObjGB.oilTempGearboxStarboard = responseGB.find(p =>  p.coordinate_type == 'D' && p.coordinate_number == 3);
                    coordValueObjGB.oilPressGearboxStarboard = responseGB.find(p => p.coordinate_number == 2);
                    coordValueObjGB.rpmPropStarboard = responseGB.find(p => p.coordinate_type === 'MES' && p.coordinate_number == 10);
                    coordValueObjGB.rpmPropPort = responseGB.find(p => p.coordinate_type === 'MEP' && p.coordinate_number == 10);
                    coordValueObjGB.gearboxPortVibration = responseGB.find(p => p.coordinate_type === 'D' && p.coordinate_number == 4);
                    coordValueObjGB.gbPortDirection = responseGB.find(p => p.coordinate_type == 'MEP').gb_direction;
                    coordValueObjGB.gbStarboardDirection = responseGB.find(p => p.coordinate_type == 'MES').gb_direction;

                    setCoordinateValueGB(coordValueObjGB);
                    let receivedDatePort = moment(responseGB[0].received_date).format("yyyy-MM-DD HH:mm:ss");
                    let receivedDateStarboard = moment(responseGB[0].received_date).format("yyyy-MM-DD HH:mm:ss");
                    setLastReceivedDatePort(receivedDatePort);
                    setLastReceivedDateStarboard(receivedDateStarboard);
                    // if (coordValueObjGB.batteryVoltage !== undefined) {
                    // }
                
            }
            
        } catch (exception) {
            console.log(exception);
        }
    }

    // const loadCoordinate = async () => {
    //     try{
    //         setLoading(true);
    //         let coordinateTypePort = 'D';
    //         let coordinateTypeSTBD = 'D';
    //         let responseGB = await getPLCReadingValueForEngines(cookies.token, smsSerialNumber, vesselId, coordinateTypePort, coordinateTypeSTBD);
    //         console.log('responseGB', responseGB);
    //         if (responseGB !== undefined) {
    //             if (responseGB != []) {
    //                 let coordValueObjGB = {};
    //                 coordValueObjGB.oilTempGearboxPort = responseGB.filter(p => p.coordinate_number == 1)[0];
    //                 coordValueObjGB.oilPressGearboxPort = responseGB.filter(p => p.coordinate_number == 0)[0];
    //                 coordValueObjGB.gearboxStarboardVibration = responseGB.filter(p => p.coordinate_number == 5)[0];
    //                 coordValueObjGB.oilTempGearboxStarboard = responseGB.filter(p => p.coordinate_number == 3)[0];
    //                 coordValueObjGB.oilPressGearboxStarboard = responseGB.filter(p => p.coordinate_number == 2)[0];
    //                 coordValueObjGB.rpmPropStarboard = responseGB.filter(p => p.coordinate_number == 17)[0];
    //                 coordValueObjGB.rpmPropPort = responseGB.filter(p => p.coordinate_number == 16)[0];
    //                 coordValueObjGB.gearboxPortVibration = responseGB.filter(p => p.coordinate_number == 4)[0];
    //                 setCoordinateValueGB(coordValueObjGB);
    //                 let receivedDatePort = moment(responseGB[0].received_date).format("yyyy-MM-DD HH:mm:ss");
    //                 let receivedDateStarboard = moment(responseGB[0].received_date).format("yyyy-MM-DD HH:mm:ss");
    //                 setLastReceivedDatePort(receivedDatePort);
    //                 setLastReceivedDateStarboard(receivedDateStarboard);
    //                 // if (coordValueObjGB.batteryVoltage !== undefined) {
    //                 // }
    //             }
    //         }
            
    //     } catch (exception) {
    //         console.log(exception);
    //     }
    // }

    return(
        <>
        
            <GBComponent gbValue={coordinateValueGB}  />
        
        </>
    )
}