import './index.css';
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Chart from 'react-apexcharts';

export default function GaugeComponent({ value, size, minValue, maxValue, valueUnit }) {
    const [series, setSeries] = useState([0]);

    const options = {
        chart: {
            height: 350,
            type: 'radialBar',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                    margin: 0,
                    size: '70%',
                    background: '#e6edf9',
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.5,
                    },
                },
                track: {
                    background: '#fff',
                    strokeWidth: '67%',
                    dropShadow: {
                        enabled: true,
                        top: -3,
                        left: 0,
                        blur: 4,
                        opacity: 0.35,
                    },
                },
                dataLabels: {
                    show: true,
                    name: {
                        offsetY: -10,
                        color: '#888',
                        fontSize: '17px',
                    },
                    value: {
                        formatter: (val) => parseInt(val),
                        color: '#111',
                        fontSize: '36px',
                    },
                },
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#2563EB'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [parseFloat(minValue), parseFloat(maxValue)],
            },
        },
        stroke: {
            lineCap: 'square',
        },
        labels: [valueUnit],
    };

    useEffect(() => {
        const sanitizedValue = isNaN(value) || value == null ? 0 : value;
        setSeries([sanitizedValue]);
    }, [value]);

    return (
        // <Box
        //     sx={{
        //         display: 'flex',
        //         alignItems: 'center',
        //         justifyContent: 'center',
        //         borderRadius: '50%',
        //         background: 'linear-gradient(135deg, #A3BFFA, #2563EB)',
        //         padding: '10px',
        //         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        //         width: size + 20, 
        //         height: size + 20,
        //     }}
        // >
            <Box
                sx={{
                    borderRadius: '50%',
                    padding: '0px',
                    width: size, 
                    height: size,
                }}
            >
                <Chart options={options} series={series} type="radialBar" height={size} />
            </Box>
        // </Box>
    );
}