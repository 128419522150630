import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import { useState, useEffect } from "react";
import moment from "moment";


export default function DynamicLineChartDoubleAxis({
  chartData: { firstDataset, secondDataset },
  xAxisLabels,
  chartLimits: {
    y1: { min: y1Min, max: y1Max, stepSize: y1StepSize },
    y2: { min: y2Min, max: y2Max, stepSize: y2StepSize },
  },
  labels: { firstLabel, secondLabel },
}) {

  const [dataFirst, setDataFirst] = useState([]);
  const [dataSecond, setDataSecond] = useState([]);

  useEffect(() => setDataFirst(firstDataset), [firstDataset]);
  useEffect(() => setDataSecond(secondDataset), [secondDataset]);
  
  const y1Data = dataFirst;
  const y2Data = dataSecond;

  const chartData = {
    labels: xAxisLabels,
    datasets: [
      {
        label: firstLabel,
        data: y1Data,
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderColor: "#ff6384",
        borderWidth: 2,
        yAxisID: "y1",
      },
      {
        label: secondLabel,
        data: y2Data,
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderColor: "#16a34a",
        borderWidth: 2,
        yAxisID: "y2",
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#031e67",
        },
      },
      y1: {
        min: y1Min,
        max: y1Max,
        ticks: {
          stepSize: y1StepSize,
          color: "#ff6384",
        },
        position: "left",
      },
      y2: {
        min: y2Min,
        max: y2Max,
        ticks: {
          stepSize: y2StepSize,
          color: "#16a34a",
        },
        position: "right",
      },
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
    animation: false,
  };

  return <Line data={chartData} options={chartOptions} />;
}
