import axios from "axios";
import Config from "../Config/Config";

export const getInventoryType = async (token, languageId,extCustomerId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllType`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getInventoryTypeSubItem = async (token, languageId,extCustomerId, offset = 0, limit = 100000, orderBy = "inventory_type_name", descending = false, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllSubItemType`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}


export const getInventoryTypeById = async (token, inventoryTypeId, langId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findTypeById`, {
            params: {
                id: inventoryTypeId,
                language_id: langId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateInventoryType = async (token, inventoryType) => {
    const payload = {
        id: inventoryType.id,
        inventory_type_name: inventoryType.inventory_type_name,
        inventory_type_code:inventoryType.inventory_type_code,
        inventory_type_description: inventoryType.inventory_type_description,
        language_id: inventoryType.language_id,
        type: inventoryType.type,
        ext_customer_id: inventoryType.ext_customer_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/insertUpdateType`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteInventoryType = async (token, id) => {
    const payload = {
        id: id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/deleteType`, {
            id: id
        }, {
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const getInventoryPeriod = async (token, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllPeriod`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getDivision = async (token, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllDivision`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}



export const getInventoryCondition = async (token, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllCondition`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getInventoryStatus = async (token, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllStatus`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getSelectedInventorySparepart = async(token, inventoryId) =>{
    try{
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getSelectedInventorySparepart`, {
            headers: {
                token: token
            },
            params: {
                id: inventoryId
            }
        });
        console.log(response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch(exception){

    }

}

export const getInventoryMaster = async (token, languageId,extCustomerId,division="", offset = 0, limit = 100000, orderBy = "inventory.created_date", descending = true, searchQuery, detailedSearch = false, invTmp) => {
    try {
        console.log(invTmp);
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllInventoryMaster`, {
            headers: {
                token: token
            },
            params: {
                division_name: division,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId,
                ext_customer_id: extCustomerId,
                inventory_group_id: invTmp ? invTmp.inventory_group_id : undefined,
                inventory_subgroup_id: invTmp ? invTmp.inventory_subgroup_id : undefined,
                inventory_material_id: invTmp ? invTmp.inventory_material_id : undefined,
                inventory_brand_id: invTmp ? invTmp.inventory_brand_id : undefined,
            }
        });
        console.log(response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const findAllInventoryMasterFromInventoryMasterList = async (token, languageId,extCustomerId,division="", offset = 0, limit = 100000, orderBy = "inventory.created_date", descending = true, searchQuery, detailedSearch = false, invTmp) => {
    try {
        console.log(invTmp);
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllInventoryMasterFromInventoryMasterList`, {
            headers: {
                token: token
            },
            params: {
                division_name: division,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId,
                ext_customer_id: extCustomerId,
                inventory_group_id: invTmp ? invTmp.inventory_group_id : undefined,
                inventory_subgroup_id: invTmp ? invTmp.inventory_subgroup_id : undefined,
                inventory_material_id: invTmp ? invTmp.inventory_material_id : undefined,
                inventory_brand_id: invTmp ? invTmp.inventory_brand_id : undefined,
            }
        });
        console.log(response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getinventoryListToPrint = async (token, languageId,extCustomerId, division="", orderBy = "inventory.created_date", descending = true, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findListInventoryToPrint`, {
            headers: {
                token: token
            },
            params: {
                division_name: division,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                language_id: languageId,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}



export const getInventoryMasterById = async (token, inventoryMasterId, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findInventoryMasterById`, {
            params: {
                id: inventoryMasterId,
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            console.log(response.data.data);
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInventoryMasterCount = async (token, languageId, extCustomerId, searchQuery, detailedSearch, division) => {
    try {
        
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getInventoryMasterCount`, {
            headers: {
                token: token
            },
            params: {
                division_name: division,
                language_id: languageId,
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        console.log("Count", response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getInventoryMasterCountFromInventoryMasterList = async (token, languageId, extCustomerId, searchQuery, detailedSearch, division) => {
    try {
        
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getInventoryMasterCountFromInventoryMasterList`, {
            headers: {
                token: token
            },
            params: {
                division_name: division,
                language_id: languageId,
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        console.log("Count", response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}


export const deleteInventoryMaster = async (token, id) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/deleteInventoryMaster`, {
            id: id
        }, {
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}


export const insertUpdateInventoryMaster = async (token, inventory) => {
    const payload = {
        id: inventory.id,
        inventory_master_name: inventory.inventory_master_name,
        unit: inventory.unit,
        reference_price: inventory.reference_price,
        brand: inventory.brand,
        model: inventory.model,
        quantity: inventory.quantity,
        inventory_master_description: inventory.inventory_master_description,
        inventory_master_note: inventory.inventory_master_note,
        inventory_unit_id: inventory.inventory_unit_id,
        language_id: inventory.language_id,
        ext_customer_id: inventory.ext_customer_id,
        is_consumeable: inventory.is_consumeable,
        lead_time: inventory.lead_time,
        buffer_stock: inventory.buffer_stock,
        inventory_brand_id: inventory.inventory_brand_id,
        inventory_material_id: inventory.inventory_material_id,
        inventory_master_alias: inventory.inventory_master_alias,
        inventory_group_id: inventory.inventory_group_id,
        inventory_subgroup_id: inventory.inventory_subgroup_id,
        is_production_material: inventory.is_production_material,
        unit_name: inventory.unit_name,
        unit_code: inventory.unit_code,
        inventory_group_name: inventory.inventory_group_name,
        inventory_group_code: inventory.inventory_group_code,
        inventory_subgroup_name: inventory.inventory_subgroup_name,
        inventory_subgroup_code: inventory.inventory_subgroup_code,
        inventory_material_name: inventory.inventory_material_name,
        inventory_material_code: inventory.inventory_material_code,
        brand_name: inventory.brand_name,
        brand_code: inventory.brand_code,
        part_number: inventory.part_number,
        weight: inventory.weight || 0,
        parameters: inventory.parameters
    }
    console.log('payload', payload);
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryMaster/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateInventoryPhoto = async (token, inventory) => {
    const payload = {
        id: inventory.id,
        base64_image: inventory.base64_image,
        label: inventory.label,
        is_active: inventory.is_active,
        created_date: inventory.created_date,
        created_by: inventory.created_by,
        updated_date: inventory.updated_date,
        updated_by: inventory.updated_by,
        inventory_id: inventory.inventory_id,
        inventory_master_name:inventory.inventory_master_name
    }
    console.log('payload', payload);
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryPhoto/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        console.log('response', response); 
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateStockPhoto = async (token, inventory) => {
    const payload = {
        id: inventory.id,
        base64_image: inventory.base64_image,
        label: inventory.label,
        is_active: inventory.is_active,
        created_date: inventory.created_date,
        created_by: inventory.created_by,
        updated_date: inventory.updated_date,
        updated_by: inventory.updated_by,
        inventory_stock_id: inventory.inventory_stock_id,
    }
    console.log('payload', payload);
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryStockPhoto/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        console.log('response', response); 
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInventoryPhotoByCount = async (token, inventoryId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryPhoto/count`, {
            headers: {
                token: token
            },
            params: {
                id: inventoryId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getStockPhotoByCount = async (token, inventoryStockId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryStockPhoto/count`, {
            headers: {
                token: token
            },
            params: {
                id: inventoryStockId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getInventoryPhotoById= async (token, inventoryId, offset = 0, limit = 100000, orderBy, descending = true) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryPhoto/findById`, {
            params: {
                id: inventoryId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getStockPhotoById= async (token, inventoryStockId, offset = 0, limit = 100000, orderBy, descending = true) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryStockPhoto/findById`, {
            params: {
                id: inventoryStockId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteInventoryPhoto = async (token, id) => {
    const payload = {
        id: id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryPhoto/deleteInventory`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const deleteStockPhoto = async (token, id) => {
    const payload = {
        id: id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryStockPhoto/deleteInventory`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const getInventoryDocumentByCount = async (token, inventoryId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryMasterDocument/count`, {
            headers: {
                token: token
            },
            params: {
                id: inventoryId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getInventoryDocumentById= async (token, inventoryId, offset = 0, limit = 100000, orderBy, descending = true) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryMasterDocument/findById`, {
            params: {
                id: inventoryId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInventoryDocumentByIdNew= async (token, inventoryId, offset = 0, limit = 100000, orderBy, descending = true) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryMasterDocument/findByIdNew`, {
            params: {
                id: inventoryId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSelectedDocumentById = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryMasterDocument/findSelectedById`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getSelectedDocumentByIdNew = async (token, documentId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryMasterDocument/findSelectedByIdNew`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const insertUpdateInventoryDocument = async (token, inventory) => {
    const payload = {
        id: inventory.id,
        document_base64: inventory.document_base64,
        document_name: inventory.document_name,
        description: inventory.description,
        inventory_master_id: inventory.inventory_master_id,
        is_active: inventory.is_active,
        created_date: inventory.created_date,
        created_by: inventory.created_by,
        updated_date: inventory.updated_date,
        updated_by: inventory.updated_by,
        reference_number: inventory.reference_number
    }
    console.log('payload', payload);
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventorymasterDocument/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        console.log('response', response); 
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteInventoryDocument = async (token, id) => {
    const payload = {
        id: id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryMasterDocument/delete`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}


export const getInventoryStock = async (token, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery = undefined, isConsumeabe, isProductionMaterial) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllStock`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                is_consumeable: isConsumeabe,
                is_production_material: isProductionMaterial
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }


}

export const getInventoryStockById = async (token, inventoryStockId, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findStockById`, {
            params: {
                id: inventoryStockId,
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInventoryStockCount = async (token, languageId, searchQuery, isConsumeable, isProductionmaterial) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getStockCount`, {
            headers: {
                token: token
            },
            params: {
                // inventory_master_id: inventoryMasterId,
                language_id: languageId,
                search_query: searchQuery,
                is_consumeable: isConsumeable,
                is_production_material: isProductionmaterial
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const checkBarcodeNumber = async (token, barcodeNumber) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/checkBarcodeNumber`, {
            headers: {
                token: token
            },
            params: {
                barcode_number: barcodeNumber
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}


export const deleteInventoryStock = async (token, id) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/deleteStock`,
            {
                id: id
            },
            {
                headers: {
                    token: token
                }
            });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}


export const insertUpdateInventoryStock = async (token, inventory) => {
    // console.log(inventory);
    const payload = {
        id: inventory.id,
        inventory_master_id: inventory.inventory_master_id,
        inventory_code: inventory.inventory_code,
        purchase_date: inventory.purchase_date,
        received_date: inventory.received_date,
        inventory_period_id: inventory.inventory_period_id,
        period_number: inventory.period_number,
        purchase_price: inventory.purchase_price,
        currency_id: inventory.currency_id,
        currency_value_idr: inventory.currency_value_idr,
        staff_id: inventory.staff_id,
        inventory_status_id: inventory.inventory_status_id,
        removed_date: inventory.removed_date,
        inventory_condition_id: inventory.inventory_condition_id,
        depreciation_rate: inventory.depreciation_rate,
        inventory_detail_description: inventory.inventory_detail_description,
        inventory_detail_note: inventory.inventory_detail_note,
        created_date: inventory.created_date,
        updated_date: inventory.updated_date,
        created_by: inventory.created_by,
        updated_by: inventory.updated_by,
        is_active: inventory.is_active,
        is_production_material: inventory.is_production_material,
        is_consumeable: inventory.is_consumeable,
        is_handed_over: inventory.is_handed_over,
        last_checked_date: inventory.last_checked_date,
        barcode_number: inventory.barcode_number,
        calibration_date: inventory.calibration_date,
        rack_coordinate_id: inventory.rack_coordinate_id,
        depreciation_group_id: inventory.depreciation_group_id,
        warehouse_id: inventory.warehouse_id,
        estimation_return_date: inventory.estimation_return_date,
        division_id: inventory.division_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/insertUpdateStock`, payload, {
            headers: {
                token: token,
            }
        });
        console.log(response);
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getListHandoverDetail = async (token, languageId,extCustomerId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, inventoryMasterId, staffId, handedOver) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/listHandoverInventory`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                inventory_master_id: inventoryMasterId,
                staff_id: staffId,
                handed_over: handedOver,
                language_id: languageId,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }

}
export const getHandover = async (token, extCustomerId, offset = 0, limit = 100000, orderBy = "staff_givenby", descending = true, searchQuery, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Handover/all`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }


}


export const getHandoverCount = async (token, extCustomerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Handover/count`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}


export const insertUpdateHandover = async (token, handover, listHandover) => {
    const payload = {
        id: handover.id,
        form_number: handover.form_number,
        document_master_id: handover.document_master_id,
        from_division_id: handover.from_division_id,
        to_division_id: handover.to_division_id,
        handover_date: handover.handover_date,
        given_by_staff_id: handover.given_by_staff_id,
        received_by_staff_id: handover.received_by_staff_id,
        document_base64: handover.document_base64,
        handover_description: handover.handover_description,
        listItem: listHandover,
        ext_customer_id: handover.ext_customer_id,
        known_by_staff_id: handover.known_by_staff_id,
        project_id: handover.project_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/insertUpdateHandover`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getHandoverById = async (token, handoverId, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findHandoverById`, {
            params: {
                id: handoverId,
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteHandover = async (token, id, staffReceived) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Handover/deleteHandover`, {
            params: {
                id: id,

            },
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const getHandoverDetail = async (token, offset = 0, limit = 100000, orderBy = "handover_id", descending = true, searchQuery, detailedSearch = false, handoverId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/HandoverInventory/all`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                handover_id: handoverId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }


}


export const insertUpdateHandoverInventory = async (token, handoverInventory) => {
    const payload = {
        id: handoverInventory.id,
        handover_id: handoverInventory.handover_id,
        inventory_stock_id: handoverInventory.inventory_stock_id,
        inventory_condition_id: handoverInventory.inventory_condition_id,
        handover_inventory_description: handoverInventory.handover_inventory_description,
        received_by_staff_id: handoverInventory.received_by_staff_id,
        from_division_id: handoverInventory.from_division_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/HandoverInventory/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getHandoverDetailCount = async (token, handoverId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/HandoverInventory/count`, {
            headers: {
                token: token
            },
            params: {
                handover_id: handoverId

            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getHandoverDetailById = async (token, handoverDetailId, languageId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findHandoverDetailById`, {
            params: {
                id: handoverDetailId,
                language_id: languageId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
          
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteHandoverInventory = async (token, id, inventoryStockId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/HandoverInventory/deleteinventory`, {
            params: {
                id: id,
                inventory_stock_id: inventoryStockId
            },
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const getReturn = async (token,extCustomerId, offset = 0, limit = 100000, orderBy = "staff_givenbyname", descending = true, searchQuery, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllReturn`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }


}


export const getReturnCount = async (token,extCustomerId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getReturnCount`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateReturn = async (token, returnInv, listReturnItem) => {
    const payload = {
        id: returnInv.id,
        form_number: returnInv.form_number,
        document_master_id: returnInv.document_master_id,
        division_id: returnInv.division_id,
        return_date: returnInv.return_date,
        given_by_staff_id: returnInv.given_by_staff_id,
        known_by_staff_id: returnInv.known_by_staff_id,
        received_by_staff_id: returnInv.received_by_staff_id,
        document_base64: returnInv.document_base64,
        return_note: returnInv.return_note,
        listReturnItem: listReturnItem,
        ext_customer_id: returnInv.ext_customer_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/insertUpdateReturn`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getReturnById = async (token, returnId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findReturnById`, {
            params: {
                id: returnId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteReturn = async (token, id, staffReceived) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/deleteReturn`, {
            id: id,
            staff_received: staffReceived
        }, {
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const getDepreciationGroup = async (token, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllDepreciationGroup`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getInventoryRequisition = async (token,extCustomerId, offset = 0, limit = 100000, orderBy = "inventoryRequisition.created_date", descending, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllInventoryRequisition`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getInventoryRequisitionById = async (token, inventoryRequisitionId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findInventoryRequisitionById`, {
            params: {
                id: inventoryRequisitionId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInventoryRequisitionCount = async (token, extCustomerId, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getCountInventoryRequisition`, {
            headers: {
                token: token
            },
            params: {
                ext_customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const insertUpdateInventoryRequisition = async (token, inventoryRequisition) => {
    const payload = {
        id: inventoryRequisition.id,
        requesting_staff_id: inventoryRequisition.requesting_staff_id,
        request_date: inventoryRequisition.request_date,
        status_id: inventoryRequisition.status_id,
        approved_date: inventoryRequisition.approved_date,
        approved_by: inventoryRequisition.approved_by,
        list_requisition: inventoryRequisition.list_requisition,
        from_division_id: inventoryRequisition.from_division_id,
        to_division_id: inventoryRequisition.to_division_id,
        ext_customer_id: inventoryRequisition.ext_customer_id
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/insertUpdateInventoryRequisition`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const deleteInventoryRequisition = async (token, inventoryRequisitionId) => {
    const payload = {
        id: inventoryRequisitionId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/deleteInventoryRequisition`, payload, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getRequisitionStatus = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getInventoryRequisitionstatus`, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const insertUpdateInventoryTypeDetail = async (token, inventoryTypeDetail) => {
    const payload = {
        id: inventoryTypeDetail.id,
        inventory_type_name: inventoryTypeDetail.inventory_type_name,
        inventory_subtype_code:inventoryTypeDetail.inventory_subtype_code,
        inventory_type_description: inventoryTypeDetail.inventory_type_description,
        language_id: inventoryTypeDetail.language_id,
        inventory_type_id: inventoryTypeDetail.inventory_type_id,
        ext_customer_id: inventoryTypeDetail.ext_customer_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/insertUpdateTypeDetail`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInventoryTypeDetail = async (token, languageId, inventoryTypeId, extCustomerId ,offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllInventoryTypeDetail`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                inventory_type_id: inventoryTypeId,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getInventoryTypeDetailById = async (token, langId, InventoryTypeDetailId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findInventoryTypeDetailById`, {
            params: {
                id: InventoryTypeDetailId,
                language_id: langId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteInventoryTypeDetail = async (token, id) => {
    const payload = {
        id: id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/deleteTypeDetail`, {
            id: id
        }, {
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const getInventoryTypeDetailCount = async (token, inventoryTypeId,extCustomerId,  searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getCountInventoryTypeDetail`, {
            headers: {
                token: token
            },
            params: {
                inventory_type_id: inventoryTypeId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getInventoryUnitType = async (token, offset = 0, limit = 100000, orderBy = "unit_name", descending = true, searchQuery = undefined, detailedSearch = false) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllInventoryUnitType`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const insertUpdateInventoryUnitType = async (token, inventory) => {
    const payload = {
        unit_name: inventory.unit_name,
        unit_code: inventory.unit_code,
        id: inventory.id,
    }

    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/insertUpdateInventoryUnitType`, payload, {
            headers: {
                token: token
            },
        });
        if (response.data.error_code === 0) {
            return (response.data);
        } else {
            return ([]);
        }
    } catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInventoryUnitTypeById = async (token, unitTypeId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findByIdUnitType`, {
            params: {
                id: unitTypeId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInventoryUnitTypeCount = async (token, searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getCountUnitType`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const deleteInventoryTypeUnit = async (token, unitTypeId) => {
    const payload = {
        id: unitTypeId
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/Inventory/deleteInventoryUnitType`, payload, {
            headers: {
                token: token,
            },
        })
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getInventoryHandoverList = async (token, inventoryRequisitionId) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findInventoryHandoverList`, {
            params: {
                id: inventoryRequisitionId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDataForCsv = async (token) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getCsvData`, {
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const getDueDateInventory = async (token, offset = 0, limit = 100000, orderBy = "handoverInv.created_date", descending = true, searchQuery = undefined, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getDueDateInventory`, {
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch
            },
            headers: {
                token: token
            }

        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}


export const getInventoryBufferStock = async (token,  offset = 0, limit = 100000, orderBy = "inventory.inventory_master_name", descending = true, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllInventoryBufferStock`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([])
        }
    } catch (exception) {
        return ([]);
    }
}

export const getInvenBufferStockCount = async (token,  searchQuery, detailedSearch) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/getInventoryBufferStockCount`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
                detailed_search: detailedSearch
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return (0);
        }
    }
    catch (exception) {
        console.log(exception);
        return (0);
    }
}

export const getInventoryMaterial = async (token, offset = 0, limit = 100000, orderBy = "inventory_material_name", descending = false, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryMaterial/all`, {
            headers: {
                token: token
            },
            params: {
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getInventoryMaterialCount = async (token, languageId, offset = 0, limit = 100000, orderBy = "inventory_material_name", descending = false, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryMaterial/count`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0].count);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const checkMaterialCodeExists = async (token, searchQuery) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryMaterial/checkCode`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
            }
        });
        if (response.data.error_code === 0) {
            if(response.data.data.length > 0){
                return(response.data.data[0].inventory_material_name);
            }
            return (null);
        }
        else {
            return (null);
        }

    }
    catch (exception) {
        console.log(exception);
        return(null);
    }
}

export const insertUpdateInventoryMaterial = async (token, inventoryMaterial) => {
    const payload = {
        id: inventoryMaterial.id,
        inventory_material_name: inventoryMaterial.inventory_material_name,
        inventory_material_code: inventoryMaterial.inventory_material_code,
        inventory_material_desc: inventoryMaterial.inventory_material_desc,
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryMaterial/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteInventoryMaterial = async (token, id) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryMaterial/delete`, {
            id: id
        }, {
            headers: {
                token: token
            }
        });
        console.log(response);
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const getInventoryGroup = async (token, languageId, offset = 0, limit = 100000, orderBy = "inventory_group_name", descending = false, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryGroup/all`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}


export const getInventoryGroupById = async (token, languageId, inventoryGroupId) => {
    try {
        console.log(inventoryGroupId);
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryGroup/findById`, {
            headers: {
                token: token
            },
            params: {
                id: inventoryGroupId,
                language_id: languageId,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0]);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getInventoryGroupCount = async (token, languageId, offset = 0, limit = 100000, orderBy = "inventory_group_name", descending = false, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryGroup/count`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data[0].count);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const checkGroupCodeExists = async (token, searchQuery) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventoryGroup/checkCode`, {
            headers: {
                token: token
            },
            params: {
                search_query: searchQuery,
            }
        });
        if (response.data.error_code === 0) {
            if(response.data.data.length > 0){
                return(response.data.data[0].inventory_group_name);
            }
            return (null);
        }
        else {
            return (null);
        }

    }
    catch (exception) {
        console.log(exception);
        return(null);
    }
}

export const insertUpdateInventoryGroup = async (token, inventoryGroup) => {
    const payload = {
        id: inventoryGroup.id,
        inventory_group_name: inventoryGroup.inventory_group_name,
        inventory_group_code: inventoryGroup.inventory_group_code,
        inventory_group_desc: inventoryGroup.inventory_group_desc,
        language_id: inventoryGroup.language_id,
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryGroup/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteInventoryGroup = async (token, id) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventoryGroup/deleteInventory`, {
            id: id
        }, {
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const getInventorySubGroup = async (token, languageId,extCustomerId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/Inventory/findAllType`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                ext_customer_id: extCustomerId
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getInventorySubGroupByGroupId = async (token, languageId, extCustomerId, groupId, offset = 0, limit = 100000, orderBy = "created_date", descending = true, searchQuery, detailedSearch = false) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventorySubGroup/findByGroupId`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                inventory_group_id: groupId,
                offset: offset * limit,
                limit: limit,
                order_by: orderBy,
                descending: descending,
                search_query: searchQuery,
                detailed_search: detailedSearch,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getInventorySubGroupByGroupIdCount = async (token, languageId, extCustomerId, groupId, searchQuery) => {
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/InventorySubGroup/countByGroupId`, {
            headers: {
                token: token
            },
            params: {
                language_id: languageId,
                inventory_group_id: groupId,
                search_query: searchQuery
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const insertUpdateInventorySubGroup = async (token, inventorySubGroup) => {
    console.log(inventorySubGroup);
    const payload = {
        id: inventorySubGroup.id,
        inventory_group_id: inventorySubGroup.inventory_group_id,
        inventory_subgroup_name: inventorySubGroup.inventory_subgroup_name,
        inventory_subgroup_code: inventorySubGroup.inventory_subgroup_code,
        inventory_subgroup_desc: inventorySubGroup.inventory_subgroup_desc,
        language_id: inventorySubGroup.language_id,
        ext_customer_id: inventorySubGroup.ext_customer_id
    }
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventorySubGroup/insertUpdate`, payload, {
            headers: {
                token: token,
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.error_code)
        } else {
            return ([])
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }
}

export const deleteInventorySubGroup = async (token, id) => {
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/InventorySubGroup/delete`, {
            id: id
        }, {
            headers: {
                token: token
            }
        });
        if (response) {
            return (response.data);
        } else {
            return ([]);
        }
    }
    catch (exception) {
        console.log(exception);
        return ([]);
    }

}

export const findMasterDocumentByIdForVesselxEquipmentDocument = async (token, inventoryMasterId, offset = 0, limit = 100000, orderBy, descending) =>{
    try{
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselxEquipmentPhoto/findMasterDocumentByIdForVesselxEquipmentDocument`, {
            params: {
                id: inventoryMasterId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }

    } catch (exception){
        console.log(exception);
        return ([]);
    }
}

export const findEquipmentDocumentByIdForVesselxEquipmentDocument = async (token, vesselEquipmentId, offset = 0, limit = 100000, orderBy, descending) =>{
    try{
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselxEquipmentPhoto/findEquipmentDocumentByIdForVesselxEquipmentDocument`, {
            params: {
                id: vesselEquipmentId,
                offset: offset * limit,
                limit: limit,
                orderBy: orderBy,
                descending: descending,
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }

    } catch (exception){
        console.log(exception);
        return ([]);
    }
}

export const findBase64EquipmentDocumentByIdForVesselxEquipmentDocument = async (token, documentId) =>{
    try{
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselxEquipmentPhoto/findBase64EquipmentDocumentByIdForVesselxEquipmentDocument`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }

    } catch (exception){
        console.log(exception);
        return ([]);
    }
}

export const findBase64MasterDocumentByIdForVesselxEquipmentDocument = async (token, documentId) =>{
    try{
        let response = await axios.get(`${Config.API_ENDPOINT}/api/VesselxEquipmentPhoto/findBase64MasterDocumentByIdForVesselxEquipmentDocument`, {
            params: {
                id: documentId
            },
            headers: {
                token: token
            }
        });
        if (response.data.error_code === 0) {
            return (response.data.data);
        } else {
            return ([]);
        }

    } catch (exception){
        console.log(exception);
        return ([]);
    }
}