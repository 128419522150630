import * as React from 'react';
import {
    AppBar, Box, Toolbar, IconButton, Typography, List, Divider, ListItem, ListItemButton,
    ListItemIcon, ListItemText,
    Menu, Container, Avatar, Button, Tooltip, MenuItem, CssBaseline, Drawer
} from "@mui/material";
import { AdbIcon, NotificationsNoneOutlined, InfoOutlined, MenuOutlined, Inbox, Mail } from "@mui/icons-material"
import Icon from '@mui/material/Icon';
import { useCookies } from "react-cookie";
import { useNavigate, NavLink, Navigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Notifications } from "@mui/icons-material";
import Clock from 'react-live-clock';
import { isTokenValid, getCompanyLogo } from '../../../Helpers/ApplicationHelpers';
import { getAuthorizedMenuV2 } from '../../../Helpers/AccountHelpers';
import qLogo from "./../../../Assets/Images/logo-quantum-white.png";

// const pages = ['Dashboard', 'Summary', 'Maintenance', 'Vessel', 'Crew', 'Inventory', 'Analytics'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [cookies, setCookie] = useCookies(["token", "userId", "userName", "userEmail"]);
    const [pages, setPages] = useState([]);
    const [listMenu, setListMenu] = useState([]);
    const [settings, setSettings] = useState(['Profile', 'Account', 'Dashboard', 'Logout']);
    const [companyLogo, setCompanyLogo] = useState();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        loadCompanyLogo();
        loadAuthorizedMenu();
    }, []);



    useEffect(() => {
        console.log('listmenu', listMenu);
        if (listMenu) {
            let menuArr = [];
            for (let i = 0; i < listMenu.length; i++) {
                menuArr.push(listMenu[i].menu_name);
            }
            setPages(menuArr);
        }
    }, [listMenu])

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };


    const loadAuthorizedMenu = async () => {
        try {
            let result = await getAuthorizedMenuV2(cookies.token, cookies.userId);
            setListMenu(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadCompanyLogo = async () => {
        try {
            let res = await getCompanyLogo(cookies.token, cookies.extCustomerId);
            console.log(res);
            setCompanyLogo(res.logo_image);
        }
        catch (exception) {
            console.log(exception);
        }
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            Tesdt
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <img
                            src={qLogo}
                            alt="Quantum Logo"
                            style={{
                                display: { xs: 'none', md: 'flex' },
                                width: 40,
                                height: 40,
                                marginRight: 8,
                            }}
                        />
                        <img
                            src={companyLogo}
                            alt="Quantum Logo"
                            style={{
                                display: { xs: 'none', md: 'flex' },
                                width: 60,
                                // height: 40,
                                marginRight: 8,
                            }}
                        />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'Inter',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            FLEET MANAGEMENT SYSTEM
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuOutlined />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{ display: { xs: 'block', md: 'none' } }}
                            >
                                {pages && pages.map((page) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <Typography sx={{ textAlign: 'center' }}>{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {/* {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))} */}
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <Typography
                                variant="h6"
                                noWrap
                                // component="a"
                                href="#app-bar-with-responsive-menu"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'Inter',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    fontSize: "small"
                                }}
                            >
                                <Clock
                                    format={'YYYY/MM/DD hh:mm A'}
                                    ticking={true}
                                    timezone={'Asia/Jakarta'}
                                />
                            </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <NotificationsNoneOutlined />
                            </IconButton>
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <InfoOutlined />
                            </IconButton>
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <Typography
                                variant="h6"
                                noWrap
                                href="#app-bar-with-responsive-menu"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'Inter',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    fontSize: "small"
                                }}
                            >
                                {cookies.userName}
                            </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>

                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar sx={{
                                        bgcolor: stringToColor(cookies.userName),
                                    }} alt="Remy Sharp">{cookies.userName.slice(0, 1)}</Avatar>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: 100,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: 100, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {
                            listMenu && listMenu.map((menu, index) => {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: 98,
                                            height: 98,
                                            borderRadius: 1,
                                            bgcolor: 'primary.white',
                                            '&:hover': {
                                                bgcolor: 'primary.dark',
                                            },
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            // setOpen(true);
                                            navigate(`/FMS${menu.menu_path}`) 
                                        }}
                                    >
                                        <Icon size={40} color="#64748B">{menu.menu_icon}</Icon>
                                      
                                        {/* <div dangerouslySetInnerHTML={{__html: `<${menu.menu_icon} size={40} color="#64748B" />`}} /> */}
                                        {/* {
                                            menu.menu_name === "Dashboard" ? (<AlignLeft size={40} color="#64748B" />) :
                                                (menu.menu_name === "Vessel" ? (<Sailboat size={40} color="#64748B" />) :
                                                    (menu.menu_name === "Summary" ? (<ChartLine size={40} color="#64748B" />) :
                                                        (menu.menu_name === "Maintenance" ? (<ClipboardText size={40} color="#64748B" />) :
                                                            (menu.menu_name === "Crew" ? (<UsersThree size={40} color="#64748B" />) :
                                                                (menu.menu_name === "Inventory" ? (<Archive size={40} color="#64748B" />) :
                                                                    (menu.menu_name === "Analytics" ? (<ChartLine size={40} color="#64748B" />) : (<></>)))))))
                                        } */}
                                        {/* {text.iconElement} */}
                                        {/* <{text.iconElement} size={40} color="#64748B"/> */}
                                        <Typography
                                            variant="h6"
                                            noWrap
                                            sx={{
                                                textAlign: "center",
                                                display: { xs: 'none', md: 'flex' },
                                                fontFamily: 'Inter',
                                                color: '#64748B',
                                                '&:hover': {
                                                    color: 'primary.white',
                                                },
                                                textDecoration: 'none',
                                                fontSize: "small"
                                            }}
                                        >
                                            {menu.menu_name}
                                        </Typography>
                                    </Box>
                                )
                            })
                        }
                    </List>
                    <Divider />

                </Box>
            </Drawer>
            <Drawer
                open={open} onClose={toggleDrawer(false)} disableScrollLock ModalProps={{
                    hideBackdrop: true,
                }}
                sx={{
                    width: 100,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: 100, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {
                            listMenu && listMenu.map((menu, index) => {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: 98,
                                            height: 98,
                                            borderRadius: 1,
                                            bgcolor: 'primary.white',
                                            '&:hover': {
                                                bgcolor: 'primary.dark',
                                            },
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setOpen(false);
                                            // navigate(`/FMS${text.menu_path}`) 
                                        }}
                                    >
                                        <Icon size={40} color="#64748B">{menu.menu_icon}</Icon>
                                        {/* {
                                            menu.menu_name === "Dashboard" ? (<div dangerouslySetInnerHTML={{__html: `<${menu.menu_icon} size={40} color="#64748B" />`}} />) :
                                                (menu.menu_name === "Vessel" ? (<Sailboat size={40} color="#64748B" />) :
                                                    (menu.menu_name === "Summary" ? (<ChartLine size={40} color="#64748B" />) :
                                                        (menu.menu_name === "Maintenance" ? (<ClipboardText size={40} color="#64748B" />) :
                                                            (menu.menu_name === "Crew" ? (<UsersThree size={40} color="#64748B" />) :
                                                                (menu.menu_name === "Inventory" ? (<Archive size={40} color="#64748B" />) :
                                                                    (menu.menu_name === "Analytics" ? (<ChartLine size={40} color="#64748B" />) : (<></>)))))))
                                        } */}
                                        <Typography
                                            variant="h6"
                                            noWrap
                                            sx={{
                                                textAlign: "center",
                                                display: { xs: 'none', md: 'flex' },
                                                fontFamily: 'Inter',
                                                color: '#64748B',
                                                '&:hover': {
                                                    color: 'primary.white',
                                                },
                                                textDecoration: 'none',
                                                fontSize: "small"
                                            }}
                                        >
                                            {menu.menu_name}
                                        </Typography>
                                    </Box>
                                )
                            })
                        }
                    </List>
                    <Divider />

                </Box>
            </Drawer>
        </Box>




    );
}
export default ResponsiveAppBar;
