// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer-container{
    filter: drop-shadow(0px 0px 14px #000000);

}`, "",{"version":3,"sources":["webpack://./src/Components/v2/FMSAdminNavBarMUI/index.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;;AAE7C","sourcesContent":[".drawer-container{\n    filter: drop-shadow(0px 0px 14px #000000);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
