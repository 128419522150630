
import React, { useEffect, useState, useRef } from "react";
import "../../../../AppV2.css";

export default function TestPage() {
    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS'>
                    <h2>CUSTOMER</h2>
                </div>
            </div>
        </>
    );
}