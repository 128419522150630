import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const FMSAuthenticate = ({ children, params = {} }) => {
    const navigate = useNavigate();
    const [isAuthenticationFinish, setIsAuthenticationFinish] = useState(false);

    const {
        validationFunc,
        validationData,
        navigateURL = "/FMS",
    } = params;

    useEffect(() => {
        const checkAuthentication = async () => {
            if (validationFunc) {
                const isValidated = await validationFunc(validationData);
                if (!isValidated) {
                    console.log("User is not authenticated!");
                    navigate(navigateURL);
                }
                setIsAuthenticationFinish(true);
            } else {
                console.warn("Validation function is not provided!");
            }
        };

        checkAuthentication();
    }, [validationFunc, validationData, navigateURL, navigate]);

    return (
        <div>
            {isAuthenticationFinish && children}
        </div>
    );
};

export default FMSAuthenticate;