import React, { useState, useEffect } from "react";
import "./indexGS.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import CustomerNavigatonBar from "../../Components/CustomerNavBar";
import TextBox from "../../Components/Textbox";
import { Container, Button, Form } from 'react-bootstrap';
import Clock from 'react-live-clock';
import ContainerBox from "../../Components/ContainerBox";
import { CameraFill, CheckAll, Download, EyeFill, FileCheckFill, FilePlusFill, PencilFill, PrinterFill, Check2Circle
    ,XCircle} from "react-bootstrap-icons";
import Quantum from "../../../src/Assets/Images/tugboatdamen.png";
import Loading from "../../Components/Loading";
import { Page } from 'react-pdf';
import Sidebar from "../../Components/NewSideBarForCustomerPage/NewSideBar";
import Modals from "../../Components/modal";
import QPD from "../../Assets/Images/logo-qpd (2).png";
import WMI from "../../Assets/Images/CustomerDashboard/WMI_logo.png";
import GA from "../../Assets/Images/GENERAL ARRANGEMENT -Model-1_page-0001.jpg";
import PRIME201_1 from "../../Assets/Images/CustomerDashboard/prime201_1.jpeg";
import PRIME201_2 from "../../Assets/Images/CustomerDashboard/prime201_2.jpeg";
import PRIME201_3 from "../../Assets/Images/CustomerDashboard/prime201_3.jpeg";
import { isTokenValid, findAllVesselTechSpecificationCategory, findAllByVesselTechSpecCategoryId, findVesselTechSpecFirstCategory, updateCustomerShipParticularPerCategory,
    findVesselByIdForCustomerDashboard, insertUpdateCustomerGeneralTechnicalSpecification, findVesselTechSpecFromFirstCategoryId } from "../../Helpers/ApplicationHelpers";
import { Trans, useTranslation } from "react-i18next";
import { triggerBase64Download } from "../../Helpers/Base64Downloader";
import { set } from "date-fns";
// import { triggerBase64Download } from "../../../../Helpers/Base64Downloader";
// import Navbar from "../../Components/NavBar";

export default function CustomerGeneralSpecificationPage() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [clickedImg, setClickedImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [zoomFactor, setZoomFactor] = useState(0.5);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [smsSerialNumber, setSmsSerialNumber] = useState('SMS-000-000001');
    const [vesselId, setVesselId] = useState('2d965d55-1311-4214-8383-4b9fb2ee0516');
    const [vesselTechSpecCategoryId, setVesselTechSpecCategoryId] = useState("");
    const [listSteelMaterials, setListSteelMaterials] = useState([]);
    const [listPipingSystem, setListPipingSystem] = useState([]);
    const [cookies, setCookie] = useCookies(["token"]);
    const [listVesselTechSpecCategory, setListVesselTechSpecCategory] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditMachineryAndPropulsion, setIsEditMachineryAndPropulsion] = useState(false);
    const [isEditLifeSavingAppliance, setIsEditLifeSavingAppliance] = useState(false);
    const [isEditNavigational, setIsEditNavigational] = useState(false);
    const [isEditAccomodation, setIsEditAccomodation] = useState(false);
    const [isEditCargo, setIsEditCargo] = useState(false);
    const [isEditShipParticular, setIsEditShipParticular] = useState(false);
    const [shipParticularData, setShipParticularData] = useState({});
    const [accomodationData, setAccomodationData] = useState();
    const [cargoCapacitiesData, setCargoCapacitiesData] = useState();
    const [liveSavingApplianceData, setLiveSavingApplianceData] = useState();
    const [machineryAndPropulsionData, setMachineryAndPropulsionData] = useState();
    const [navigationalAndCommunicationData, setNavigationalAndCommunicationData] = useState();
    const [detailVesselData, setDetailVesselData] = useState({
        id : '',
	    vessel_name : '',
	    company_id: '',
	    vessel_type_id: '',
	    imo_number: '',
	    serial_number: '',
	    built_date: '',
	    built_shipyard: '',
	    created_date : '',
	    updated_date: '',
	    created_by: '',
	    updated_by: '',
	    is_active : '',
	    vessel_code: '',
	    vessel_project_counter : '',
	    gross_register_tonnage : '',
	    deadweight_tonnage : '',
	    lbp : '',
	    bmld : '',
	    hmld : '',
	    flag: '',
	    callsign: '',
	    class_register_number: '',
	    customer_id: '',
	    ext_customer_id: '',
	    sms_serial_number: '',
	    shift_session_id: '',
	    part_number: '',
	    length_over_all : '',
	    draft_extreme : '',
	    fuel_tank_capacities : '',
	    fresh_water_tank_capacities : '',
	    power: '',
	    speed: '',
	    bollard_pull : '',
	    complement: '',
    });
    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                loadDetailVesselData();
                loadVesselTechSpecCategory();
            }
        }
        checkCookies();
        console.log(cookies)
    }, []);

    useEffect(() => {
        console.log(listVesselTechSpecCategory);
        loadShipParticular();
    }, [listVesselTechSpecCategory])

    useEffect(() => {
        console.log(listPipingSystem);
    }, [listPipingSystem])

    useEffect(() => {
        console.log(listSteelMaterials);
    }, [listSteelMaterials])

    useEffect(() => {
        console.log("-------DETAILVESSELDATA--------",detailVesselData);
    }, [detailVesselData])

    useEffect(() => {
        if (isPrint) {
            printGeneralSpecification();
        }
    }, [isPrint])

    useEffect(() => {
        console.log('machineryAndPropulsionData', machineryAndPropulsionData);
    }, [machineryAndPropulsionData])

    const loadVesselTechSpecCategory = async () => {
        try {
            setLoading(true);
            let response = await findVesselTechSpecFirstCategory(cookies.token, cookies.languageId);
            setListVesselTechSpecCategory(response);
        } catch (exception){
            console.log(exception);
        }
    }

    const downloadImg = (imgSrc) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; 
    
        img.onload = function() {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            console.log(dataURL);
            triggerBase64Download(dataURL, detailVesselData.vessel_name + ' GENERAL ARRANGEMENT');
        };
        img.src = imgSrc;
    };

    const handleClick = (imgSrc) => {
        setClickedImg(imgSrc);
    };

    

    const loadDetailVesselData = async () => {
        try {
            let response = await findVesselByIdForCustomerDashboard(cookies.token, vesselId);
            setDetailVesselData({...detailVesselData,
                vessel_type_name: response[0].vessel_type_name,
                country_name: response[0].country_name,
                customer_name: response[0].customer_name,
                id: response[0].id,
                vessel_name: response[0].vessel_name,
                company_id: response[0].company_id,
                vessel_type_id: response[0].vessel_type_id,
                imo_number: response[0].imo_number,
                serial_number: response[0].serial_number,
                built_date: response[0].built_date,
                built_shipyard: response[0].built_shipyard,
                created_date: response[0].created_date,
                updated_date: response[0].updated_date,
                created_by: response[0].created_by,
                updated_by: response[0].updated_by,
                is_active: response[0].is_active,
                vessel_code: response[0].vessel_code,
                vessel_project_counter: response[0].vessel_project_counter,
                gross_register_tonnage: response[0].gross_register_tonnage,
                deadweight_tonnage: response[0].deadweight_tonnage,
                lbp: response[0].lbp,
                bmld: response[0].bmld,
                hmld: response[0].hmld,
                flag: response[0].flag,
                callsign: response[0].callsign,
                class_register_number: response[0].class_register_number,
                customer_id: response[0].customer_id,
                ext_customer_id: response[0].ext_customer_id,
                sms_serial_number: response[0].sms_serial_number,
                shift_session_id: response[0].shift_session_id,
                part_number: response[0].part_number,
                length_over_all: response[0].length_over_all,
                draft_extreme: response[0].draft_extreme,
                fuel_tank_capacities: response[0].fuel_tank_capacities,
                fresh_water_tank_capacities: response[0].fresh_water_tank_capacities,
                power: response[0].power,
                speed: response[0].speed,
                bollard_pull: response[0].bollard_pull,
                complement: response[0].complement
            });
        } catch (exception){
            console.log(exception);
        }
    }

    // const loadSteelMaterial = async () => {
    //     try {
    //         let response = await findAllByVesselTechSpecCategoryId(cookies.token, cookies.languageId, 'd30c0be7-6078-4a68-b6e5-bbd307bd8c1b', vesselId);
    //         setListSteelMaterials(response);
    //     } catch (exception){
    //         console.log(exception);
    //     }
    // }

    // const loadPipingSystem = async () => {
    //     try {
    //         let response = await findAllByVesselTechSpecCategoryId(cookies.token, cookies.languageId, 'cae24720-c69a-4b94-9bff-35b92ca41d1c', vesselId);
    //         setListPipingSystem(response);
    //     } catch (exception){
    //         console.log(exception);
    //     }
    // }

    const printGeneralSpecification = () => {
        //console.log('print');
        var prtContent = document.getElementById("printGeneralSpecification");
        var style = "<style>";

        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: left;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"

        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        // var logo = document.getElementById('radian-img');
        // logo.src = require("../../Assets/Images/logo-qpd (2).png");
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `General Specification`;
        WinPrint.print();
        // WinPrint.close();
        // logo.src = require("../../Assets/Images/quantum_logo.png");
        setLoading(false);
        setIsPrint(false);
    }

    const saveData = async () => {
        try{
            
            let detailVesselDataTmp = detailVesselData;
            // detailVesselDataTmp.fuel_tank_capacities = Number(detailVesselDataTmp.fuel_tank_capacities);
            console.log("data To save", detailVesselData);
        let response = await insertUpdateCustomerGeneralTechnicalSpecification(cookies.token, detailVesselData);
        if (response.error_code === 0) {
            alert("Berhasil Menyimpan Data");
            // navigate("/Vessel/InventoryMaster");
        }
        else {
            alert("Gagal Menyimpan Data")
        }
    } catch (exception){
        console.log(exception);
    }
    }

    const saveShipParticularDataMachinery = async () => {
        try{
        let response = await updateCustomerShipParticularPerCategory(cookies.token, machineryAndPropulsionData);
        if (response.error_code === 0) {
            alert("Berhasil Menyimpan Data");
            // navigate("/Vessel/InventoryMaster");
        }
        else {
            alert("Gagal Menyimpan Data")
        }
    } catch (exception){
        console.log(exception);
    }
    }

    const saveShipParticularDataLifeSaving = async () => {
        try{
        let response = await updateCustomerShipParticularPerCategory(cookies.token, liveSavingApplianceData);
        if (response.error_code === 0) {
            alert("Berhasil Menyimpan Data");
            // navigate("/Vessel/InventoryMaster");
        }
        else {
            alert("Gagal Menyimpan Data")
        }
    } catch (exception){
        console.log(exception);
    }
    }

    const saveShipParticularDataNavigational = async () => {
        try{
        let response = await updateCustomerShipParticularPerCategory(cookies.token, navigationalAndCommunicationData);
        if (response.error_code === 0) {
            alert("Berhasil Menyimpan Data");
            // navigate("/Vessel/InventoryMaster");
        }
        else {
            alert("Gagal Menyimpan Data")
        }
    } catch (exception){
        console.log(exception);
    }
    }

    const saveShipParticularDataAccomodation = async () => {
        try{
        let response = await updateCustomerShipParticularPerCategory(cookies.token, accomodationData);
        if (response.error_code === 0) {
            alert("Berhasil Menyimpan Data");
            // navigate("/Vessel/InventoryMaster");
        }
        else {
            alert("Gagal Menyimpan Data")
        }
    } catch (exception){
        console.log(exception);
    }
    }

    const saveShipParticularDataCargo = async () => {
        try{
        let response = await updateCustomerShipParticularPerCategory(cookies.token, cargoCapacitiesData);
        if (response.error_code === 0) {
            alert("Berhasil Menyimpan Data");
            // navigate("/Vessel/InventoryMaster");
        }
        else {
            alert("Gagal Menyimpan Data")
        }
    } catch (exception){
        console.log(exception);
    }
    }

    const loadShipParticular = async () => {
        try{
            for (let cat = 0; cat < listVesselTechSpecCategory.length; cat++) {
                let response = await findVesselTechSpecFromFirstCategoryId (cookies.token, cookies.languageId, vesselId, listVesselTechSpecCategory[cat].id);
                console.log(cat, response);
                if (listVesselTechSpecCategory[cat].id === 'bc5cb061-46b1-4272-b92a-3fd442b81abf'){
                    setAccomodationData(response);
                }
                else if (listVesselTechSpecCategory[cat].id === '026ed435-341a-4013-bbe7-c9d928348582'){
                    setCargoCapacitiesData(response);
                }
                else if (listVesselTechSpecCategory[cat].id === '2d6a0168-3ae9-41fa-8121-24a4f66d9167'){
                    setLiveSavingApplianceData(response);
                }
                else if (listVesselTechSpecCategory[cat].id === 'e6433e68-21bc-456f-a5da-c42df83fff08'){
                    setMachineryAndPropulsionData(response);
                }
                else if (listVesselTechSpecCategory[cat].id === 'ab5c2b02-de08-4712-b599-a55b4abc4cae'){
                    setNavigationalAndCommunicationData(response);
                }
            }
            setLoading(false);
        }
        catch (exception) {
            console.log(exception);
        }
    }


    return(
        <div>
            <div className="main">
                <Sidebar companyName={detailVesselData.customer_name} smsSerialNumber={detailVesselData.sms_serial_number} vesselId={detailVesselData.id} vesselName={detailVesselData.vessel_name} customerName={cookies.userName}/>
                <Container fluid className="mainContainerGS">
                    {/* <div className="welcomeUser">
                        <div style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
                            <h2 style={{ fontSize: '20px', color: 'white' }}>Welcome, {cookies.userName}</h2>
                        </div>
                        <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                            <Clock
                                style={{ fontSize: '20px', color: 'white' }}
                                format={'dddd, DD MMMM YYYY, hh:mm:ss'}
                                ticking={true}
                                timezone={'Asia/Jakarta'} />
                        </div>
                    </div> */}
                    <div className="childMainContainerGS">
                        <ContainerBox
                            titleCaption={'GENERAL TECHNICAL SPECIFICATIONS'}
                            useActionContainer={true}
                            actionContainerChild={
                                <div>
                                    {/* <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => { }}>
                                        <div className="btnContainer">
                                            <div className="btnContainerLeft"><FileCheckFill size={32} /></div>
                                            <div className="btnContainerRight">{'Equipment'}</div>
                                        </div>
                                    </Button> */}
                                    {/* <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => { }}>
                                        <div className="btnContainer">
                                            <div className="btnContainerLeft"><CameraFill size={32} /></div>
                                            <div className="btnContainerRight">{'Gallery'}</div>
                                        </div>
                                    </Button> */}
                                    <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                        setLoading(true);
                                        setIsPrint(true);
                                    }}>
                                        <div className="btnContainer">
                                            <div className="btnContainerLeft"><PrinterFill size={32} /></div>
                                            <div className="btnContainerRight">{'Print'}</div>
                                        </div>
                                    </Button>
                                    {
                                        isEdit === false ? (
                                            <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                setIsEdit(true);
                                            }}>
                                                <div className="btnContainer">
                                                    <div className="btnContainerLeft"><PencilFill size={32} /></div>
                                                    <div className="btnContainerRight">{'Edit'}</div>
                                                </div>
                                            </Button>
                                        ) : (
                                                <>
                                                    <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                        setIsEdit(false);
                                                        saveData();
                                                    }}>
                                                        <div className="btnContainer">
                                                            <div className="btnContainerLeft"><Check2Circle size={32} /></div>
                                                            <div className="btnContainerRight">{'Save'}</div>
                                                        </div>
                                                    </Button>
                                                    <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                        setIsEdit(false);
                                                    }}>
                                                        <div className="btnContainer">
                                                            <div className="btnContainerLeft"><XCircle size={32} /></div>
                                                            <div className="btnContainerRight">{'Cancel'}</div>
                                                        </div>
                                                    </Button>
                                                </>
                                        )
                                    }
                                </div>
                            }
                            childContent={
                                <>
                                
                                <div className="firstChildContentGS">
                                    {/* <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        saveData();
                                    }} > */}
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type='text' disabled={true} value={detailVesselData.vessel_name} placeholder=""
                                                caption={"Name"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, vessel_name: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Owner</Form.Label>
                                            <Form.Control type='text' disabled={true} value={detailVesselData.customer_name} placeholder=""
                                                caption={"Owner"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, customer_name: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Vessel Type</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={"FPP Tug Boat"} placeholder=""
                                                caption={"Vessel Type"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, vessel_code: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Length O.A (M)</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.length_over_all} placeholder=""
                                                caption={"Length O.A"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, length_over_all: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Breadth Moulded (M)</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.bmld} placeholder=""
                                                caption={"Breadth Moulded (B)"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, bmld: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Depth Moulded (M)</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.hmld} placeholder=""
                                                caption={"Depth Moulded (H)"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, hmld: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Draft Extreme (M)</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.draft_extreme} placeholder=""
                                                caption={"Draft Extreme (T)"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, draft_extreme: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Fuel Tanks Capacities (Cub. M)</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.fuel_tank_capacities} placeholder=""
                                                caption={"Fuel Tanks Capacities"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, fuel_tank_capacities: e.target.value })
                                                }}/>
                                        </Form.Group>

                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Fresh Water Tank Capacities (Cub. M)</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.fresh_water_tank_capacities} placeholder=""
                                                caption={"Fresh Water Tank Capacities"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, fresh_water_tank_capacities: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Power (PS)</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.power} placeholder=""
                                                caption={"Power"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, power: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Speed (Knots)</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.speed} placeholder=""
                                                caption={"Speed"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, speed: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Bollard Pull (Ton)</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.bollard_pull} placeholder=""
                                                caption={"Bollard Pull"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, bollard_pull: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Complement (Persons)</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.complement} placeholder=""
                                                caption={"Complement"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, complement: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Deadweight (Ton)</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.deadweight_tonnage} placeholder=""
                                                caption={"Deadweight (DWT)"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, deadweight_tonnage: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Gross Tonnage (Ton)</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.gross_register_tonnage} placeholder=""
                                                caption={"Gross Tonnage (GT)"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, gross_register_tonnage: e.target.value })
                                                }}/>
                                        </Form.Group>
                                        <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                            <Form.Label>Class</Form.Label>
                                            <Form.Control type='text' disabled={!isEdit} value={detailVesselData.class_register_number} placeholder=""
                                                caption={"Class"}
                                                req={true} 
                                                onChange={(e) => {
                                                    setDetailVesselData({ ...detailVesselData, class_register_number:e.target.value })
                                                }}/>
                                        </Form.Group>
                                    {/* </Form> */}
                                </div>
                                </>
                            }
                        />
                        <div style={{ marginTop: '20px'}}></div>
                        <ContainerBox
                            titleCaption={'PHOTOS'}
                            useActionContainer={true}
                            actionContainerChild={
                                <div>
                                    {/* <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                        setLoading(true);
                                        setIsPrint(true);
                                    }}>
                                        <div className="btnContainer">
                                            <div className="btnContainerLeft"><PrinterFill size={32} /></div>
                                            <div className="btnContainerRight">{'Print / Download'}</div>
                                        </div>
                                    </Button> */}
                                </div>
                            }
                            childContent={
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '90%'}}>
                                    <div style={{ padding: '5px' }}>
                                        <img className="titleImg" style={{ width: '60%', height: '100%' }} src={PRIME201_1} onClick={() => {
                                            handleClick(PRIME201_1);
                                        }} />
                                    </div>
                                    <div style={{ padding: '5px' }}>
                                        <img className="titleImg" style={{ width: '60%', height: '100%' }} src={PRIME201_2} onClick={() => {
                                            handleClick(PRIME201_2);
                                        }} />
                                    </div>
                                    <div style={{ padding: '5px' }}>
                                        <img className="titleImg" style={{ width: '60%', height: '100%' }} src={PRIME201_3} onClick={() => {
                                            handleClick(PRIME201_3);
                                        }} />
                                    </div>
                                </div>
                                </div >
                            }
                        />
                        <div style={{ marginTop: '20px'}}></div>
                        <ContainerBox
                            titleCaption={detailVesselData.vessel_name + ' SHIP PARTICULARS'}
                            childContent={
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',padding: '10px', flexWrap: 'wrap' }}>

                                            <div style={{ display: "flex", justifyContent:'space-between', alignItems: 'center', width: '90%'}}>
                                                <h5 className="textForH5">MACHINERY / PROPULSION SYSTEM</h5>
                                                <div style={{margin: '5px'}}>
                                                {
                                                        isEditMachineryAndPropulsion === false ? (
                                                            <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                setIsEditMachineryAndPropulsion(true);
                                                            }}>
                                                                <div className="btnContainer">
                                                                    <div className="btnContainerLeft"><PencilFill size={20} /></div>
                                                                    <div className="btnContainerRight">{'Edit'}</div>
                                                                </div>
                                                            </Button>
                                                        ) : (
                                                            <>
                                                                <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                    setIsEditMachineryAndPropulsion(false);
                                                                    saveShipParticularDataMachinery();
                                                                }}>
                                                                    <div className="btnContainer">
                                                                        <div className="btnContainerLeft"><Check2Circle size={20} /></div>
                                                                        <div className="btnContainerRight">{'Save'}</div>
                                                                    </div>
                                                                </Button>
                                                                <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                    setIsEditMachineryAndPropulsion(false);
                                                                }}>
                                                                    <div className="btnContainer">
                                                                        <div className="btnContainerLeft"><XCircle size={20} /></div>
                                                                        <div className="btnContainerRight">{'Cancel'}</div>
                                                                    </div>
                                                                </Button>
                                                            </>
                                                        )
                                                    }
                                                    </div>
                                            </div>
                                            <div className="table-container" style={{ width: '90%' }}>
                                                <div className="table-header">
                                                    <div style={{ flex: 1 }} className="table-header-content"></div>
                                                    <div style={{ flex: 3 }} className="table-header-content">DESCRIPTION</div>
                                                    <div style={{ flex: 3 }} className="table-header-content">BRAND/MODEL</div>
                                                </div>
                                                {
                                                    machineryAndPropulsionData && machineryAndPropulsionData.map((data, index) => {
                                                        return (
                                                        <div key={index} className="table-body">
                                                            <div style={{ flex: 1 }} className="table-body-content">{data.vtssc_name}</div>
                                                            <div style={{ flex: 3 }} className="table-body-content">
                                                                    <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                                                        <Form.Control type='text' disabled={!isEditMachineryAndPropulsion} value={data.description} placeholder=""
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                const newValue = e.target.value;
                                                                                setMachineryAndPropulsionData(p =>
                                                                                    p.map((item, i) =>
                                                                                        i === index ? { ...item, description: newValue } : item
                                                                                    )
                                                                                );
                                                                            }} />
                                                                    </Form.Group>
                                                            </div>
                                                            <div style={{ flex: 3 }} className="table-body-content">
                                                                    <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                                                        <Form.Control type='text' disabled={!isEditMachineryAndPropulsion} value={data.brand_model_type} placeholder=""
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                const newValue = e.target.value;
                                                                                setMachineryAndPropulsionData(p =>
                                                                                    p.map((item, i) =>
                                                                                        i === index ? { ...item, brand_model_type: newValue } : item
                                                                                    )
                                                                                );
                                                                            }} />
                                                                    </Form.Group>
                                                            </div>
                                                        </div>)
                                                    }
                                                    )
                                                }
                                            </div>

                                            <div style={{ margin: '10px 0' }}></div>

                                            <div style={{ display: "flex", justifyContent:'space-between', alignItems: 'center', width: '90%'}}>
                                                <h5 className="textForH5">Life SAVING APPLIANCE (LSA) & FIRE FIGHTING EQUIPMENT (FFE)</h5>
                                                <div style={{margin: '5px'}}>
                                                {
                                                        isEditLifeSavingAppliance === false ? (
                                                            <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                setIsEditLifeSavingAppliance(true);
                                                            }}>
                                                                <div className="btnContainer">
                                                                    <div className="btnContainerLeft"><PencilFill size={20} /></div>
                                                                    <div className="btnContainerRight">{'Edit'}</div>
                                                                </div>
                                                            </Button>
                                                        ) : (
                                                            <>
                                                                <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                    setIsEditLifeSavingAppliance(false);
                                                                    saveShipParticularDataLifeSaving();
                                                                }}>
                                                                    <div className="btnContainer">
                                                                        <div className="btnContainerLeft"><Check2Circle size={20} /></div>
                                                                        <div className="btnContainerRight">{'Save'}</div>
                                                                    </div>
                                                                </Button>
                                                                <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                    setIsEditLifeSavingAppliance(false);
                                                                }}>
                                                                    <div className="btnContainer">
                                                                        <div className="btnContainerLeft"><XCircle size={20} /></div>
                                                                        <div className="btnContainerRight">{'Cancel'}</div>
                                                                    </div>
                                                                </Button>
                                                            </>
                                                        )
                                                    }
                                                    </div>
                                            </div>
                                            <div className="table-container" style={{ width: '90%' }}>
                                                
                                                <div className="table-header">
                                                    <div style={{ flex: 1 }} className="table-header-content"></div>
                                                    <div style={{ flex: 3 }} className="table-header-content">DESCRIPTION</div>
                                                    <div style={{ flex: 3 }} className="table-header-content">BRAND/MODEL</div>
                                                </div>
                                                {
                                                    liveSavingApplianceData && liveSavingApplianceData.map((data, index) => {
                                                        return (
                                                        <div key={index} className="table-body">
                                                            <div style={{ flex: 1 }} className="table-body-content">{data.vtssc_name}</div>
                                                            <div style={{ flex: 3 }} className="table-body-content">
                                                                    <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                                                        <Form.Control type='text' disabled={!isEditLifeSavingAppliance} value={data.description} placeholder=""
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                const newValue = e.target.value;
                                                                                setLiveSavingApplianceData(p =>
                                                                                    p.map((item, i) =>
                                                                                        i === index ? { ...item, description: newValue } : item
                                                                                    )
                                                                                );
                                                                            }} />
                                                                    </Form.Group>
                                                            </div>
                                                            <div style={{ flex: 3 }} className="table-body-content">
                                                                    <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                                                        <Form.Control type='text' disabled={!isEditLifeSavingAppliance} value={data.brand_model_type} placeholder=""
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                const newValue = e.target.value;
                                                                                setLiveSavingApplianceData(p =>
                                                                                    p.map((item, i) =>
                                                                                        i === index ? { ...item, brand_model_type: newValue } : item
                                                                                    )
                                                                                );
                                                                            }} />
                                                                    </Form.Group>
                                                            </div>
                                                        </div>)
                                                    }
                                                    )
                                                }
                                            </div>

                                            <div style={{ margin: '10px 0' }}></div>

                                            <div style={{ display: "flex", justifyContent:'space-between', alignItems: 'center', width: '90%'}}>
                                                <h5 className="textForH5">NAVIGATIONAL & COMMUNICATION</h5>
                                                <div style={{margin: '5px'}}>
                                                {
                                                        isEditNavigational === false ? (
                                                            <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                setIsEditNavigational(true);
                                                            }}>
                                                                <div className="btnContainer">
                                                                    <div className="btnContainerLeft"><PencilFill size={20} /></div>
                                                                    <div className="btnContainerRight">{'Edit'}</div>
                                                                </div>
                                                            </Button>
                                                        ) : (
                                                            <>
                                                                <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                    setIsEditNavigational(false);
                                                                    saveShipParticularDataNavigational();
                                                                }}>
                                                                    <div className="btnContainer">
                                                                        <div className="btnContainerLeft"><Check2Circle size={20} /></div>
                                                                        <div className="btnContainerRight">{'Save'}</div>
                                                                    </div>
                                                                </Button>
                                                                <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                    setIsEditNavigational(false);
                                                                }}>
                                                                    <div className="btnContainer">
                                                                        <div className="btnContainerLeft"><XCircle size={20} /></div>
                                                                        <div className="btnContainerRight">{'Cancel'}</div>
                                                                    </div>
                                                                </Button>
                                                            </>
                                                        )
                                                    }
                                                    </div>
                                            </div>
                                            <div className="table-container" style={{ width: '90%' }}>
                                                
                                                <div className="table-header">
                                                    <div style={{ flex: 1 }} className="table-header-content"></div>
                                                    <div style={{ flex: 3 }} className="table-header-content">DESCRIPTION</div>
                                                    <div style={{ flex: 3 }} className="table-header-content">BRAND/MODEL</div>
                                                </div>
                                                {
                                                    navigationalAndCommunicationData && navigationalAndCommunicationData.map((data, index) => {
                                                        return (
                                                        <div key={index} className="table-body">
                                                            <div style={{ flex: 1 }} className="table-body-content">{data.vtssc_name}</div>
                                                            <div style={{ flex: 3 }} className="table-body-content">
                                                                    <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                                                        <Form.Control type='text' disabled={!isEditNavigational} value={data.description} placeholder=""
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                const newValue = e.target.value;
                                                                                setNavigationalAndCommunicationData(p =>
                                                                                    p.map((item, i) =>
                                                                                        i === index ? { ...item, description: newValue } : item
                                                                                    )
                                                                                );
                                                                            }} />
                                                                    </Form.Group>
                                                            </div>
                                                            <div style={{ flex: 3 }} className="table-body-content">
                                                                    <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                                                        <Form.Control type='text' disabled={!isEditNavigational} value={data.brand_model_type} placeholder=""
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                const newValue = e.target.value;
                                                                                setNavigationalAndCommunicationData(p =>
                                                                                    p.map((item, i) =>
                                                                                        i === index ? { ...item, brand_model_type: newValue } : item
                                                                                    )
                                                                                );
                                                                            }} />
                                                                    </Form.Group>
                                                            </div>
                                                        </div>)
                                                    }
                                                    )
                                                }
                                            </div>

                                            <div style={{ margin: '10px 0' }}></div>

                                            <div style={{ display: "flex", justifyContent:'space-between', alignItems: 'center', width: '90%'}}>
                                                <h5 className="textForH5">ACCOMODATION</h5>
                                                <div style={{margin: '5px'}}>
                                                {
                                                        isEditAccomodation === false ? (
                                                            <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                setIsEditAccomodation(true);
                                                            }}>
                                                                <div className="btnContainer">
                                                                    <div className="btnContainerLeft"><PencilFill size={20} /></div>
                                                                    <div className="btnContainerRight">{'Edit'}</div>
                                                                </div>
                                                            </Button>
                                                        ) : (
                                                            <>
                                                                <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                    setIsEditAccomodation(false);
                                                                    saveShipParticularDataAccomodation();
                                                                }}>
                                                                    <div className="btnContainer">
                                                                        <div className="btnContainerLeft"><Check2Circle size={20} /></div>
                                                                        <div className="btnContainerRight">{'Save'}</div>
                                                                    </div>
                                                                </Button>
                                                                <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                    setIsEditAccomodation(false);
                                                                }}>
                                                                    <div className="btnContainer">
                                                                        <div className="btnContainerLeft"><XCircle size={20} /></div>
                                                                        <div className="btnContainerRight">{'Cancel'}</div>
                                                                    </div>
                                                                </Button>
                                                            </>
                                                        )
                                                    }
                                                    </div>
                                            </div>
                                            <div className="table-container" style={{ width: '90%' }}>
                                                
                                                <div className="table-header">
                                                    <div style={{ flex: 1 }} className="table-header-content"></div>
                                                    <div style={{ flex: 3 }} className="table-header-content">DESCRIPTION</div>
                                                    <div style={{ flex: 3 }} className="table-header-content">BRAND/MODEL</div>
                                                </div>
                                                {
                                                    accomodationData && accomodationData.map((data, index) => {
                                                        return (
                                                        <div key={index} className="table-body">
                                                            <div style={{ flex: 1 }} className="table-body-content">{data.vtssc_name}</div>
                                                            <div style={{ flex: 3 }} className="table-body-content">
                                                                    <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                                                        <Form.Control type='text' disabled={!isEditAccomodation} value={data.description} placeholder=""
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                const newValue = e.target.value;
                                                                                setAccomodationData(p =>
                                                                                    p.map((item, i) =>
                                                                                        i === index ? { ...item, description: newValue } : item
                                                                                    )
                                                                                );
                                                                            }} />
                                                                    </Form.Group>
                                                            </div>
                                                            <div style={{ flex: 3 }} className="table-body-content">
                                                                    <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                                                        <Form.Control type='text' disabled={!isEditAccomodation} value={data.brand_model_type} placeholder=""
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                const newValue = e.target.value;
                                                                                setAccomodationData(p =>
                                                                                    p.map((item, i) =>
                                                                                        i === index ? { ...item, brand_model_type: newValue } : item
                                                                                    )
                                                                                );
                                                                            }} />
                                                                    </Form.Group>
                                                            </div>
                                                        </div>)
                                                    }
                                                    )
                                                }
                                            </div>

                                            <div style={{ margin: '10px 0' }}></div>

                                            <div style={{ display: "flex", justifyContent:'space-between', alignItems: 'center', width: '90%'}}>
                                                <h5 className="textForH5">TANK CAPACITIES</h5>
                                                <div style={{margin: '5px'}}>
                                                {
                                                        isEditCargo === false ? (
                                                            <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                setIsEditCargo(true);
                                                            }}>
                                                                <div className="btnContainer">
                                                                    <div className="btnContainerLeft"><PencilFill size={20} /></div>
                                                                    <div className="btnContainerRight">{'Edit'}</div>
                                                                </div>
                                                            </Button>
                                                        ) : (
                                                            <>
                                                                <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                    setIsEditCargo(false);
                                                                    saveShipParticularDataCargo();
                                                                }}>
                                                                    <div className="btnContainer">
                                                                        <div className="btnContainerLeft"><Check2Circle size={20} /></div>
                                                                        <div className="btnContainerRight">{'Save'}</div>
                                                                    </div>
                                                                </Button>
                                                                <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                                                    setIsEditCargo(false);
                                                                }}>
                                                                    <div className="btnContainer">
                                                                        <div className="btnContainerLeft"><XCircle size={20} /></div>
                                                                        <div className="btnContainerRight">{'Cancel'}</div>
                                                                    </div>
                                                                </Button>
                                                            </>
                                                        )
                                                    }
                                                    </div>
                                            </div>
                                            <div className="table-container" style={{ width: '90%' }}>
                                                
                                                <div className="table-header">
                                                    <div style={{ flex: 1 }} className="table-header-content"></div>
                                                    <div style={{ flex: 3 }} className="table-header-content">DESCRIPTION</div>
                                                    <div style={{ flex: 3 }} className="table-header-content">BRAND/MODEL</div>
                                                </div>
                                                {
                                                    cargoCapacitiesData && cargoCapacitiesData.map((data, index) => {
                                                        return (
                                                        <div key={index} className="table-body">
                                                            <div style={{ flex: 1 }} className="table-body-content">{data.vtssc_name}</div>
                                                            <div style={{ flex: 3 }} className="table-body-content">
                                                                    <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                                                        <Form.Control type='text' disabled={!isEditCargo} value={data.description} placeholder=""
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                const newValue = e.target.value;
                                                                                setCargoCapacitiesData(p =>
                                                                                    p.map((item, i) =>
                                                                                        i === index ? { ...item, description: newValue } : item
                                                                                    )
                                                                                );
                                                                            }} />
                                                                    </Form.Group>
                                                            </div>
                                                            <div style={{ flex: 3 }} className="table-body-content">
                                                                    <Form.Group style={{ width: "600px", paddingLeft: 5 }} className="mb-3">
                                                                        <Form.Control type='text' disabled={!isEditCargo} value={data.brand_model_type} placeholder=""
                                                                            req={true}
                                                                            onChange={(e) => {
                                                                                const newValue = e.target.value;
                                                                                setCargoCapacitiesData(p =>
                                                                                    p.map((item, i) =>
                                                                                        i === index ? { ...item, brand_model_type: newValue } : item
                                                                                    )
                                                                                );
                                                                            }} />
                                                                    </Form.Group>
                                                            </div>
                                                        </div>)
                                                    }
                                                    )
                                                }
                                            </div>

                                    </div>


                                </>
                            }
                        />
                        <div style={{ marginTop: '20px'}}></div>
                        <ContainerBox
                            titleCaption={detailVesselData.vessel_name + ' GENERAL ARRANGEMENT'}
                            useActionContainer={true}
                            actionContainerChild={
                                <div>
                                    <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                        downloadImg(GA);
                                    }}>
                                        <div className="btnContainer">
                                            <div className="btnContainerLeft"><Download size={32} /></div>
                                            <div className="btnContainerRight">{'Download'}</div>
                                        </div>
                                    </Button>
                                </div>
                            }
                            childContent={
                                <div className="firstChildContent">
                                    <div style={{ padding: '10px' }}>
                                        <img className="titleImg" style={{ width: '100%', height: '100%' }} src={GA} onClick={() => {
                                            handleClick(GA);
                                        }} />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div id="printGeneralSpecification" style={{ display: 'none', padding: 10 }}>
                        <div style={{
                            textAlign: "center",
                            width: '100%',
                            padding: '10px'
                        }}>
                            <div style={{display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center', padding: '5px'}}>
                                <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <img className="titleImg" style={{ width: '60%' }} src={WMI} />
                                </div>
                                <div style={{flex: 3, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', padding: '5px'}}>
                                    <p style={{margin: '0px'}}>{detailVesselData.customer_name}</p>
                                    <p style={{margin: '0px'}}>{detailVesselData.vessel_name}</p>
                                </div>
                            </div>
                            <h3>{"GENERAL TECHNICAL SPECIFICATIONS"} </h3>
                        </div>
                        <div style={{ paddingBottom: 5 }}></div>

                        {/* <table className="table-print" style={{ border: "1px solid black", collapse: "collapse" }}> */}
                        <table >
                            <tr style={{ fontWeight: "bold" }}>
                                <td style={{ width: '5%' }}>{"General Informations"} </td>
                                <td style={{ width: '15%' }}>{""} </td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Vessel Type</td>
                                <td style={{ width: '15%' }}>{detailVesselData.vessel_type_name}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Length Over All (LOA)</td>
                                <td style={{ width: '15%' }}>{detailVesselData.length_over_all}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Breadth Moulded (B)</td>
                                <td style={{ width: '15%' }}>{detailVesselData.bmld}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Depth Moulded (H)</td>
                                <td style={{ width: '15%' }}>{detailVesselData.hmld}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Draft Extreme (T)</td>
                                <td style={{ width: '15%' }}>{detailVesselData.draft_extreme}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Fuel Tanks Capacities</td>
                                <td style={{ width: '15%' }}>{detailVesselData.fuel_tank_capacities}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Fresh Water Tank Capacities</td>
                                <td style={{ width: '15%' }}>{detailVesselData.fresh_water_tank_capacities}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Power</td>
                                <td style={{ width: '15%' }}>{detailVesselData.power}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Speed</td>
                                <td style={{ width: '15%' }}>{detailVesselData.speed}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Bollard Pull</td>
                                <td style={{ width: '15%' }}>{detailVesselData.bollard_pull}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Complement</td>
                                <td style={{ width: '15%' }}>{detailVesselData.complement}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Deadweight (DWT)</td>
                                <td style={{ width: '15%' }}>{detailVesselData.deadweight_tonnage}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Gross Tonnage (GT)</td>
                                <td style={{ width: '15%' }}>{detailVesselData.gross_register_tonnage}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '5%' }}>Class</td>
                                <td style={{ width: '15%' }}>{detailVesselData.class_register_number}</td>
                            </tr>
                        </table>

                        <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            borderStyle: "solid",
                            borderWidth: 1
                        }}>
                        </div>


                    </div>
                </Container>
            </div>
            {clickedImg && (
                <>
                    <Modals
                        clickedImg={clickedImg}
                        setClickedImg={setClickedImg}
                        image={clickedImg}
                    />
                    {/* <div style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        overflow: "scroll",
                    }}>
                        <Page scale={zoomFactor} pageNumber={pageNumber} />
                        {(pageNumber + 1) < numPages &&
                            <Page scale={zoomFactor} pageNumber={pageNumber + 1} />
                        }

                    </div> */}
                </>
            )
            }
            <Loading
                    loading={loading}
                    loadingText={`${t("loading_component.text")}`}
                />
        </div>
    )
}