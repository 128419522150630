import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, findAllVesselById } from "../../../../Helpers/ApplicationHelpers";
import "../../../AppV2.css";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTheme from "../../../FMSTheme";
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../../Components/v2/FMSNavBarMUI";
import { Box, TextField, Typography, Modal } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import noImg from "../../../../Assets/Images/FMS/vessel_fms_icon.png";

export default function FMSVesselDetailPage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    let location = useLocation();
    const [vesselId, setVesselId] = useState();
    const [open, setOpen] = useState(false);
    const [imgToShow, setImgToShow] = useState();
    const [detailVesselData, setDetailVesselData] = useState({
        vessel_id: "",
        vessel_name: "",
        customer_name: "",
        vessel_type_name: "",
        imo_number: "",
        loa: "",
        bmo: "",
        dmo: "",
        draft_extream: "",
        fuel_tank_capacities: "",
        fresh_water_tank_capacities: "",
        power: "",
        speed: "",
        bollard: "",
        complement: "",
        deadweight: "",
        gross_tonnage: "",
        image_list: []
    });

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                setVesselId(location.state.vesselId);
            }
        }
        checkCookies();
    }, []);

    // useEffect(() => {
    //     if (imgToShow){
    //         if (imgToShow.base64_image){
    //             console.log('IMAGE')
    //             setOpen(true);
    //         }
    //     }
    // }, [imgToShow])

    useEffect(() => {
        if (vesselId) {
            loadVesselDetail();
        }
    }, [vesselId])

    useEffect(() => {
        if (detailVesselData) {
        }
    }, [detailVesselData])


    const loadVesselDetail = async () => {
        try {
            let results = await findAllVesselById(cookies.token, vesselId);
            if (results) {
                console.log(results)
                setDetailVesselData({
                    ...detailVesselData,
                    vessel_id: results.id,
                    vessel_name: results.vessel_name,
                    customer_name: results.customer_name,
                    vessel_type_name: results.vessel_type_name,
                    imo_number: results.imo_number,
                    loa: results.length_over_all,
                    bmo: results.bmld,
                    dmo: results.hmld,
                    draft_extream: results.draft_extream,
                    fuel_tank_capacities: results.fuel_tank_capacities,
                    fresh_water_tank_capacities: results.fresh_water_tank_capacities,
                    power: results.power,
                    speed: results.speed,
                    bollard: results.bollard_pull,
                    complement: results.complement,
                    deadweight: results.deadweight_tonnage,
                    gross_tonnage: results.gross_register_tonnage,
                    image_list: results.image_list,
                })
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    // const style = {
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 400,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    //   };




    return (
        <>
            <ThemeProvider theme={FMSTheme}>
                <div className='mainContainerFMS'>
                    <NavBar />
                    <div className='childMainContainerFMS'>
                        <div className='childMainContainerFMS-TOP'>
                            <p className='ptitleFMSDashboard'>VESSEL DETAIL</p>
                        </div>

                        <div className='childMainContainerFMS-BODY'>
                            <Box component="fieldset" sx={{ p: 2, border: '1px solid #1E3A8A', borderRadius: '5px', width: '100%' }}>
                                <legend>
                                    <Typography variant="h5" color='black'>
                                        General Specification
                                    </Typography>
                                </legend>
                                <Box
                                    component="form"
                                    sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        disabled
                                        value={detailVesselData.vessel_name}
                                        label="Name"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, vessel_name: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                    <TextField
                                        disabled
                                        value={detailVesselData.customer_name}
                                        id="outlined-basic"
                                        label="Owner"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, customer_name: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                    <TextField
                                        disabled
                                        value={detailVesselData.vessel_type_name}
                                        id="outlined-basic"
                                        label="Type"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, vessel_type_name: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                    <TextField
                                        disabled
                                        value={detailVesselData.imo_number}
                                        id="outlined-basic"
                                        label="IMO Number"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, imo_number: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                </Box>

                                <Box
                                    component="form"
                                    sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        disabled
                                        value={detailVesselData.loa}
                                        id="outlined-basic"
                                        label="Length O.A (M)"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, loa: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                    <TextField
                                        disabled
                                        value={detailVesselData.bmo}
                                        id="outlined-basic"
                                        label="Breadth Moulded (M)"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, bmo: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                    <TextField
                                        disabled
                                        value={detailVesselData.dmo}
                                        id="outlined-basic"
                                        label="Depth Moulded (M)"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, dmo: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                    <TextField
                                        disabled
                                        value={detailVesselData.draft_extream}
                                        id="outlined-basic"
                                        label="Draft Extreme (M)"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, draft_extream: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                </Box>

                                <Box
                                    component="form"
                                    sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        disabled
                                        value={detailVesselData.fuel_tank_capacities}
                                        id="outlined-basic"
                                        label="Fuel Tanks Capacities (Cub. M)"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, fuel_tank_capacities: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                    <TextField
                                        disabled
                                        value={detailVesselData.fresh_water_tank_capacities}
                                        id="outlined-basic"
                                        label="Fresh Water Tank Capacities (Cub. M)"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, fresh_water_tank_capacities: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                    <TextField
                                        disabled
                                        value={detailVesselData.power}
                                        id="outlined-basic"
                                        label="Power (PS)"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, power: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                    <TextField
                                        disabled
                                        value={detailVesselData.speed}
                                        id="outlined-basic"
                                        label="Speed (Knots)"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, speed: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                </Box>

                                <Box
                                    component="form"
                                    sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        disabled
                                        value={detailVesselData.bollard}
                                        id="outlined-basic"
                                        label="Bollard Pull (Ton)"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, bollard: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                    <TextField
                                        disabled
                                        value={detailVesselData.complement}
                                        id="outlined-basic"
                                        label="Complement (Persons)"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, complement: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                    <TextField
                                        disabled
                                        value={detailVesselData.deadweight}
                                        id="outlined-basic"
                                        label="Deadweight (Ton)"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, deadweight: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                    <TextField
                                        disabled
                                        value={detailVesselData.gross_tonnage}
                                        id="outlined-basic"
                                        label="Gross Tonnage (Ton)"
                                        onChange={(e) => {
                                            setDetailVesselData({ ...detailVesselData, gross_tonnage: e.target.value });
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                            <div style={{ margin: '10px' }}></div>
                            <Box component="fieldset" sx={{ p: 2, border: '1px solid #1E3A8A', borderRadius: '5px', width: '100%' }}>
                                <legend>
                                    <Typography variant="h5" color='black'>
                                        Pictures
                                    </Typography>
                                </legend>

                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}>
                                    {
                                        detailVesselData && detailVesselData.image_list && detailVesselData.image_list.map((img, index) => {
                                            return (
                                                <div
                                                    style={{ width: '200px', height: '200px', border: '1px #bebebe solid', margin: '5px', borderRadius: '5px' }}
                                                // onClick={setImgToShow(img)}
                                                >
                                                    <LazyLoadImage
                                                        src={img.base64_image ? img.base64_image : noImg}
                                                        style={{
                                                            display: { xs: 'none', md: 'flex' },
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                            padding: 5
                                                        }}
                                                    />
                                                </div>


                                            )
                                        })
                                    }
                                </div>

                            </Box>
                            <div style={{ margin: '10px' }}></div>
                            <Box component="fieldset" sx={{ p: 2, border: '1px solid #1E3A8A', borderRadius: '5px', width: '100%' }}>
                                <legend>
                                    <Typography variant="h5" color='black'>
                                        Ship Particulars
                                    </Typography>
                                </legend>
                                <Box
                                    component="form"
                                    sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Box
                                        component="form"
                                        sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                        noValidate
                                        autoComplete="off"
                                    ></Box>
                                    <Box
                                        component="form"
                                        sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                        noValidate
                                        autoComplete="off"
                                    ></Box>
                                    <Box
                                        component="form"
                                        sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                        noValidate
                                        autoComplete="off"
                                    ></Box>
                                    <Box
                                        component="form"
                                        sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                        noValidate
                                        autoComplete="off"
                                    ></Box>
                                </Box>
                            </Box>

                        </div>
                    </div>
                </div>
                {/* <Modal
                    open={open}
                    onClose={setOpen(false)}
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {detailVesselData.vessel_name}
                        </Typography>
                        <LazyLoadImage
                                                        src={imgToShow && imgToShow.base64_image}
                                                        style={{
                                                            display: { xs: 'none', md: 'flex' },
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                            padding: 5
                                                        }}
                                                   />
                    </Box>
                </Modal> */}
            </ThemeProvider>
        </>
    );
}