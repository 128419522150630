import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

export default createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#1E3A8A',
            light: '#9fa5f6',
            dark: '#0f30d3',
            white: '#FFFFFF'
        },
        secondary: {
            main: '#d66d00',
        },
    },
});