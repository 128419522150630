import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, } from "../../../Helpers/ApplicationHelpers";
import "../../AppV2.css";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTheme from "../../FMSTheme";
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../Components/v2/FMSNavBarMUI";
import Loading from "../../../Components/v2/FMSLoading/index";
import FMSVesselDashboardContainer from '../../../Components/v2/FMSVesselDashboardContainer';

export default function FMSMaintenancePage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }

            }
        }
        checkCookies();
    }, []);



    return (
        <>
            <ThemeProvider theme={FMSTheme}>
                <div className='mainContainerFMS'>
                    <NavBar />
                    <div className='childMainContainerFMS'>
                        <div className='childMainContainerFMS-TOP'>
                            <p className='ptitleFMSDashboard'>DASHBOARD</p>
                        </div>
                        <div className='childMainContainerFMS-BODY'>
                            {/* <FMSVesselDashboardContainer/> */}
                        </div>
                    </div>
                </div>

            </ThemeProvider>
            <Loading
                loading={loading}
                loadingText={`LOADING`}
            />
        </>
    );
}