import React, { useEffect, useState, Children } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap, rotatedMarker } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L, { marker } from "leaflet";
import vesselImg from "../../../Assets/Images/new_arrow_pointer.png";
// import vesselImg from "../Assets/Images/ship-solid-red-resized.png";
// import { Button } from "react-bootstrap";
import { EyeFill } from "react-bootstrap-icons";
import 'leaflet-rotatedmarker';
import 'leaflet-ant-path';
import { Box, Card, Button, Typography } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

export default function FMSOpenSeaMap({ markerChild, isTracking = false }) {
    const [vesselName, setVesselName] = useState();
    const [companyName, setCompanyName] = useState();
    const [vesselType, setVesselType] = useState();
    const [locationData, setLocationData] = useState();
    const defaultPosition = [-6.12747410563145, 106.85523275294094];
    const [mostRecentWeatherMap, setMostRecentWeatherMap] = useState();
    const [cog, setCog] = useState(0);
    const [, forceUpdate] = React.useReducer(x => x + 1, 0)

    const RADAR_MAPS_URL = "https://api.rainviewer.com/public/weather-maps.json";

    useEffect(() => {
        console.log('cog', cog);

    }, [cog]);

    useEffect(() => {
        getMostRecentWeatherMap();
    }, [])
    //     (async () => {
    //       const path = await getMostRecentWeatherMap();
    //       setMostRecentWeatherMap(path);
    //     })();
    //   });

    let vesselIcon = L.icon({
        iconUrl: vesselImg,
        iconRetinaUrl: vesselImg,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });

    const getMostRecentWeatherMap = async () => {
        const res = await fetch(RADAR_MAPS_URL);
        // console.log(res);
        // const resJson = (await res.json());
        // return resJson.radar.nowcast[0].path;
    };

    const AntPathComponent = ({ marker }) => {
        if (isTracking) {
            // console.log(marker);
            const map = useMap();
            let route = marker.map(marker => marker.props.position);
            useEffect(() => {
                const path = L.polyline.antPath(route, {
                    delay: 800,
                    dashArray: [20, 20],
                    weight: 2,
                    color: "#0000FF",
                    pulseColor: "#FFFFFF",
                    paused: false,
                    reverse: false,
                    hardwareAccelerated: true
                });

                path.addTo(map);
                //   map.fitBounds(path.getBounds());

                return () => {
                    map.removeLayer(path);
                };

            }, [map, route]);
        } else {
            console.log(marker);
            marker.forEach((markerEl) => {
                const map = useMap();
                let route = markerEl.map(marker => marker.props.position);
                useEffect(() => {
                    const path = L.polyline.antPath(route, {
                        delay: 800,
                        dashArray: [20, 20],
                        weight: 2,
                        color: "#0000FF",
                        pulseColor: "#FFFFFF",
                        paused: false,
                        reverse: false,
                        hardwareAccelerated: true
                    });

                    path.addTo(map);
                    //   map.fitBounds(path.getBounds());

                    return () => {
                        map.removeLayer(path);
                    };

                }, [map, route]);
            });

        }

        return null;
    };

    const ZoomToMarkerButton = ({ position }) => {
        const map = useMap();

        const zoomToMarker = () => {

            map.setView([position.latitude, position.longitude], 20);
        };

        return (
            <Button variant="contained" onClick={zoomToMarker}><EyeFill /></Button>
            
        );
    };

    const renderListMarker = () => {
        if (markerChild) {
            if (markerChild.listMarker) {
                return (markerChild.listMarker);
            }
        }
    }

    const renderAnPath = () => {

        // console.log(markerChild);
        if (markerChild) {
            if (markerChild.listLine) {
                return <AntPathComponent marker={markerChild.listLine} />
            }
        }
    }


    const renderListVessel = () => {
        if (markerChild) {
            if (markerChild.listVessel) {
                let listVessel = [];
                markerChild.listVessel.map((vessel, index) => {
                    console.log("VESSEL", vessel);
                    let vesselItem =
                        <div key={index}
                            style={{
                                position: "absolute",
                                bottom: 100,
                                left: 20,
                                width: '16%',
                                height: '80%',
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                padding: '10px',
                                borderRadius: "5px",
                                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
                                zIndex: 1000,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                        >

                            <Card sx={{ width: '100%' }}>
                                <CardContent>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        {vessel.customer_name}
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {vessel.vessel_name}
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{vessel.vessel_type_name}</Typography>
                                    <Typography variant="body2"></Typography>
                                    <ZoomToMarkerButton position={{ latitude: vessel.latitude, longitude: vessel.longitude }} />
                                </CardContent>
                                <CardActions>
                                </CardActions>
                            </Card>

                            {/* <div style={{ border: '1px rgb(189, 189, 189) solid', width: '100%', height: '0.5px' }}></div> */}
                        </div>
                    listVessel.push(vesselItem);
                });

                return (listVessel);
            }
        }
    }

    return (
        <>
            <MapContainer style={{ flex: 1, width: '100%' }} center={defaultPosition} zoom={15} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {renderListMarker()}
                {/* {renderPolyline()} */}
                {renderListVessel()}

                {renderAnPath()}

                {/* <TileLayer
                    attribution="RainViewer.com"
                    url={`https://tilecache.rainviewer.com${mostRecentWeatherMap}/256/{z}/{x}/{y}/2/1_1.png`}
                    opacity={0.6}
                    zIndex={2}
                /> */}

            </MapContainer>
        </>
    );
}
