import './index.css';
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { isTokenValid, getVesselForCustomer} from "../../../Helpers/ApplicationHelpers";
import { FilePlusFill } from "react-bootstrap-icons";
import { Container, Button, Form, Modal } from 'react-bootstrap';
import ContainerBox from "../../../Components/ContainerBox";
import Sidebar from "../../../Components/NewSideBarForCustomerPage/NewSideBar";

export default function CustomerCorrectiveMaintenancePlanPage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                loadCustomerData();
            }
        }
        checkCookies();
    }, []);

    const loadCustomerData = async () => {
        try{
            let result = await getVesselForCustomer(cookies.token, cookies.extCustomerId);
            if (result) {
                setSmsSerialNumber(result[0].sms_serial_number);
                setVesselId(result[0].id);
                setVesselName(result[0].vessel_name);
                setCustomerName(result[0].customer_name);
            }

        } catch (exception){
            console.log(exception);
        }
    }

    return(
        <>
            <div className="mainCMDashboard">
                <Sidebar />
                <Container fluid className="mainContainerCMDashboard">
                    <div className="innerChildMainContainerCMDashboard">
                        <ContainerBox
                            titleCaption={'CORRECTIVE MAINTENANCE PLAN'}
                            useActionContainer={true}
                            actionContainerChild={
                                <div>
                                    <Button disabled={false} variant="secondary" style={{ marginRight: '10px' }} onClick={() => {
                                        navigate('/Customer/CorrectiveMaintenancePlan/Detail');
                                     }}>
                                        <div className="btnContainer">
                                            <div className="btnContainerLeft"><FilePlusFill size={32} /></div>
                                            <div className="btnContainerRight">{'New Maintenance Plan'}</div>
                                        </div>
                                    </Button>
                                </div>
                            }
                            childContent={
                                <>
                                </>
                            }
                        />
                    </div>
                </Container>
            </div>
        </>
    )
}