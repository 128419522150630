import { config } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";
import { json } from "react-router-dom";
import Config from "../Config/Config";
import qs from "qs";
import Resizer from "react-image-file-resizer";
import { id } from "date-fns/esm/locale";

export const countAllDueJob = async (token, extCustomerId, searchQuery, detailedSearch, detailedSearchedvalue = []) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/CustomerMaintenanceSearch/countAllDueJob`, {
            headers: {
                token: token
            },
            params: {
                customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                detailed_searched_value: detailedSearchedvalue
            }
        });
        console.log(response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const countAllMaintenancePlan = async (token, extCustomerId, searchQuery, detailedSearch, detailedSearchedvalue = []) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/CustomerMaintenanceSearch/countAllMaintenancePlan`, {
            headers: {
                token: token
            },
            params: {
                customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                detailed_searched_value: detailedSearchedvalue
            }
        });
        console.log(response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const findAllDueJob = async (token, extCustomerId, searchQuery, detailedSearch, detailedSearchedvalue = [], offset, limit, orderBy, descending) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/CustomerMaintenanceSearch/findAllDueJob`, {
            headers: {
                token: token
            },
            params: {
                customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                detailed_searched_value: detailedSearchedvalue,
                offset: offset,
                limit: limit,
                order_by: orderBy,
                descending: descending
            }
        });
        console.log(response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const findAllMaintenancePlan = async (token, extCustomerId, searchQuery, detailedSearch, detailedSearchedvalue = [], offset, limit, orderBy, descending) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/CustomerMaintenanceSearch/findAllMaintenancePlan`, {
            headers: {
                token: token
            },
            params: {
                customer_id: extCustomerId,
                search_query: searchQuery,
                detailed_search: detailedSearch,
                detailed_searched_value: detailedSearchedvalue,
                offset: offset,
                limit: limit,
                order_by: orderBy,
                descending: descending
            }
        });
        console.log(response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getCountDueJobPhoto = async (token, maintenanceDueJobId) => {
console.log(maintenanceDueJobId)
    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/CustomerMaintenancePhoto/getCountDueJobPhoto`, {
            headers: {
                token: token
            },
            params: {
                maintenance_due_job_id: maintenanceDueJobId
            }
        });
        // console.log(response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const findDueJobPhotoById = async (token, maintenanceDueJobId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/CustomerMaintenancePhoto/findDueJobPhotoById`, {
            headers: {
                token: token
            },
            params: {
                maintenance_due_job_id: maintenanceDueJobId
            }
        });
        // console.log(response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const getCountDueJobDocument = async (token, maintenanceDueJobId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/CustomerMaintenanceDocument/getCountDueJobDocument`, {
            headers: {
                token: token
            },
            params: {
                maintenance_due_job_id: maintenanceDueJobId
            }
        });
        // console.log(response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const findDueJobDocumentById = async (token, maintenanceDueJobId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/CustomerMaintenanceDocument/findDueJobDocumentById`, {
            headers: {
                token: token
            },
            params: {
                maintenance_due_job_id: maintenanceDueJobId
            }
        });
        // console.log(response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const findDueJobPhotoByPhotoId = async (token, documentId) => {

    try {
        let response = await axios.get(`${Config.API_ENDPOINT}/api/CustomerMaintenancePhoto/findDueJobPhotoByPhotoId`, {
            headers: {
                token: token
            },
            params: {
                document_id: documentId
            }
        });
        // console.log(response);
        if (response.data.error_code === 0) {
            return (response.data.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const insertUpdateDueJobPhoto = async (token, dueJobPhoto) => {
// console.log(maintenanceDueJob)
    try {
        let response = await axios.post(`${Config.API_ENDPOINT}/api/CustomerMaintenancePhoto/insertUpdateDueJobPhoto`, dueJobPhoto, {
            headers: {
                token: token
            }
        });
        console.log(response);
        if (response.data.error_code === 0) {
            return (response.data);
        }
        else {
            return ([]);
        }

    }
    catch (exception) {
        console.log(exception);
    }
}

export const insertUpdateDueJobDocument = async (token, dueJobDocument) => {
    // console.log(maintenanceDueJobId)
        try {
          
            let response = await axios.post(`${Config.API_ENDPOINT}/api/CustomerMaintenancePhoto/insertUpdateDueJobDocument`, dueJobDocument, {
                headers: {
                    token: token
                }
            });
            console.log(response);
            if (response.data.error_code === 0) {
                return (response.data);
            }
            else {
                return ([]);
            }
    
        }
        catch (exception) {
            console.log(exception);
        }
    }

    export const deleteDocumentPhoto = async (token, photoId) => {
            try {
              
                let response = await axios.post(`${Config.API_ENDPOINT}/api/CustomerMaintenancePhoto/delete`, photoId, {
                    headers: {
                        token: token
                    }
                });
                console.log(response);
                if (response.data.error_code === 0) {
                    return (response.data);
                }
                else {
                    return ([]);
                }
        
            }
            catch (exception) {
                console.log(exception);
            }
        }