import React, { useEffect, useState, Children } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap, rotatedMarker } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L, { marker } from "leaflet";
import vesselImg from "../../../Assets/Images/new_arrow_pointer.png";
import noImg from "../../../Assets/Images/FMS/noImg.jpg";
// import vesselImg from "../Assets/Images/ship-solid-red-resized.png";
// import { Button } from "react-bootstrap";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'leaflet-rotatedmarker';
import 'leaflet-ant-path';
import { Box, Card, Button, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import Collapse from '@mui/material/Collapse';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

export default function FMSOpenSeaMapTracking({ markerChild, isTracking = false }) {
    const [vesselName, setVesselName] = useState();
    const [companyName, setCompanyName] = useState();
    const [vesselType, setVesselType] = useState();
    const [locationData, setLocationData] = useState();
    const defaultPosition = [-6.12747410563145, 106.85523275294094];
    const [checked, setChecked] = useState(false);
    const [mostRecentWeatherMap, setMostRecentWeatherMap] = useState();
    const [cog, setCog] = useState(0);
    const [, forceUpdate] = React.useReducer(x => x + 1, 0)

    const RADAR_MAPS_URL = "https://api.rainviewer.com/public/weather-maps.json";

    useEffect(() => {
        console.log('cog', cog);

    }, [cog]);

    useEffect(() => {
        getMostRecentWeatherMap();
    }, [])
    //     (async () => {
    //       const path = await getMostRecentWeatherMap();
    //       setMostRecentWeatherMap(path);
    //     })();
    //   });

    let vesselIcon = L.icon({
        iconUrl: vesselImg,
        iconRetinaUrl: vesselImg,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });

    const getMostRecentWeatherMap = async () => {
        const res = await fetch(RADAR_MAPS_URL);
        // console.log(res);
        // const resJson = (await res.json());
        // return resJson.radar.nowcast[0].path;
    };

    const AntPathComponent = ({ marker }) => {
        if (isTracking) {
            // console.log(marker);
            const map = useMap();
            let route = marker.map(marker => marker.props.position);
            useEffect(() => {
                const path = L.polyline.antPath(route, {
                    delay: 800,
                    dashArray: [20, 20],
                    weight: 2,
                    color: "#0000FF",
                    pulseColor: "#FFFFFF",
                    paused: false,
                    reverse: false,
                    hardwareAccelerated: true
                });

                path.addTo(map);
                //   map.fitBounds(path.getBounds());

                return () => {
                    map.removeLayer(path);
                };

            }, [map, route]);
        } else {
            console.log(marker);
            marker.forEach((markerEl) => {
                const map = useMap();
                let route = marker.map(marker => marker.props.position);
                useEffect(() => {
                    const path = L.polyline.antPath(route, {
                        delay: 800,
                        dashArray: [20, 20],
                        weight: 2,
                        color: "#0000FF",
                        pulseColor: "#FFFFFF",
                        paused: false,
                        reverse: false,
                        hardwareAccelerated: true
                    });

                    path.addTo(map);
                    //   map.fitBounds(path.getBounds());

                    return () => {
                        map.removeLayer(path);
                    };

                }, [map, route]);
            });

        }

        return null;
    };


    const ZoomToMarkerButton = ({ position }) => {
        const map = useMap();

        const zoomToMarker = () => {

            map.setView([position.latitude, position.longitude], 20);
        };

        return (
            // <Box
            //                     sx={{
            //                         width: 40,
            //                         height: 40,
            //                         borderRadius: 1,
            //                         bgcolor: '#1E3A8A',
            //                         boxShadow: 5
            //                     }}
            //                 >
                                <IconButton aria-label="delete" size="medium" sx={{
                                    color: '#1E3A8A'
                                }} onClick={zoomToMarker}>
                                    <RemoveRedEyeIcon fontSize="10px" />
                                </IconButton>
                            // </Box>

        );
    };

    const renderListMarker = () => {
        if (markerChild) {
            if (markerChild.listMarker) {
                return (markerChild.listMarker);
            }
        }
    }

    const renderAnPath = () => {

        // console.log(markerChild);
        if (markerChild) {
            if (markerChild.listLine) {
                return <AntPathComponent marker={markerChild.listLine} />
            }
        }
    }

    const handleChange = () => {
        setChecked((prev) => !prev);
    };


    const renderListVessel = () => {
        if (markerChild) {
            if (markerChild.listVessel) {
                let listVessel = [];
                markerChild.listVessel.map((vessel, index) => {
                    console.log("VESSEL", vessel);
                    let vesselItem =
                        <div style={{ position: 'absolute', top: '80px', left: '3px', zIndex: 1000 }}>
                            <Box
                                sx={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: 1,
                                    bgcolor: '#1E3A8A',
                                    ml: 1,
                                    boxShadow: 5
                                }}
                            >
                                <IconButton aria-label="delete" size="medium" sx={{
                                    color: 'white'
                                }} onClick={handleChange}>
                                    <DirectionsBoatIcon fontSize="inherit" />
                                </IconButton>
                            </Box>


                            <div>
                                <Collapse in={checked}>
                                    <Box
                                        sx={{
                                            '& > :not(style)': {
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                height: 600,
                                                width: 350,
                                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                '& > :not(style)': {
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                    height: 600,
                                                    width: 350,
                                                },
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                    height: 120,
                                                    width: 350,
                                                    border: '1px solid #CBD5E1',
                                                    borderRadius: 5,
                                                    margin: '3px'
                                                }}>
                                                <div style={{ flex: 1, width: '100%', height: '100%',  }}>
                                                    <CardMedia
                                                        component="img"
                                                        image={noImg}
                                                        title="green iguana"
                                                        sx={{
                                                            width: 120,
                                                            height: 120,
                                                            padding: 1,
                                                            borderRadius: 2,
                                                        }}
                                                    />
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: '10px', }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                <ZoomToMarkerButton position={{ latitude: vessel.latitude, longitude: vessel.longitude }} />
                                                            </Typography>
                                                        </div>
                                                </div>
                                                <div style={{ flex: 3, width: '100%', height: '100%' }}>
                                                    <CardContent>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                {vessel.vessel_name}
                                                            </Typography>
                                                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                                                {vessel.vessel_type_name}
                                                            </Typography>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: '10px' }}>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                IMO Number: {vessel.imo_number}
                                                            </Typography>
                                                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                Crew: {vessel.complement}
                                                            </Typography>
                                                        </div>
                                                    </CardContent>
                                                </div>


                                            </div>
                                        </Box>
                                    </Box>
                                </Collapse>
                            </div>

                            {/* <Card sx={{ width: '100%' }}>
                                <CardContent>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        {vessel.customer_name}
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {vessel.vessel_name}
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{vessel.vessel_type_name}</Typography>
                                    <Typography variant="body2"></Typography>
                                    <ZoomToMarkerButton position={{ latitude: vessel.latitude, longitude: vessel.longitude }} />
                                </CardContent>
                                <CardActions>
                                </CardActions>
                            </Card> */}

                            {/* <div style={{ border: '1px rgb(189, 189, 189) solid', width: '100%', height: '0.5px' }}></div> */}
                        </div>
                    listVessel.push(vesselItem);
                });

                return (listVessel);
            }
        }
    }

    return (
        <>
            <MapContainer style={{ flex: 1, width: '100%' }} center={defaultPosition} zoom={15} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {renderListMarker()}
                {/* {renderPolyline()} */}
                {renderListVessel()}

                {renderAnPath()}

                {/* <TileLayer
                    attribution="RainViewer.com"
                    url={`https://tilecache.rainviewer.com${mostRecentWeatherMap}/256/{z}/{x}/{y}/2/1_1.png`}
                    opacity={0.6}
                    zIndex={2}
                /> */}

            </MapContainer>
        </>
    );
}
