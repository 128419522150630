import "./index.css";
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { isTokenValid, getReadingValueForEngineParameterFromMaterializedView, getHistorisEnginePerformanceFromMaterializedView, getReadingValueForFuelConsumptionFromMaterializedView, getReadingValueForFuelConsumptionAverageAndCurrentInterval } from "../../Helpers/ApplicationHelpers";
import { Container, Button, Form, Modal } from 'react-bootstrap';
import { FiletypePdf, FiletypeCsv, ClipboardData, Printer } from "react-bootstrap-icons";
import Loading from "../../Components/Loading";
import ContainerBox from "../../Components/ContainerBox";
import Sidebar from "../../Components/NewSideBarForCustomerPage/NewSideBar";
import { Trans, useTranslation } from "react-i18next";
import DropDownForm from "../../Components/DropDownForm";
import DynamicLineChart from "../../Components/DynamicLineChartForCurrentFuelUsage";
import DynamicLineChartDoubleAxis from "../../Components/DynamicLineChartForFuelConsumptionAvgAndCurrent"
import moment from "moment";
import { CSVLink } from "react-csv";
import WMI from "../../Assets/Images/CustomerDashboard/WMI_logo.png";
import { constructFrom } from "date-fns";

export default function EnginePerformancePage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [dataToShow, setDataToShow] = useState([]);
    const [isPrint, setIsPrint] = useState(false);
    const [compiledDataForReport, setCompiledDataForReport] = useState([]);
    const [isDataReady, setIsDataReady] = useState(false);
    const [shouldUpdatePageFromDB, setShouldUpdatePageFromDB] = useState(false);
    const [enginePerformanceData, setEnginePerformanceData] = useState({
        isDataReady: false,
        shouldUpdateFromDB: false
    });

    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
            .utc()
            .subtract(30, "minutes")
            .format("yyyy-MM-DD HH:mm:ss")
    );
    const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss")
    );

    const durationOption = [
        {
            label: "1 Hour Ago",
            value: 1,
        },
        {
            label: "1 Day Ago",
            value: 2,
        },
        {
            label: "1 Week Ago",
            value: 3,
        },
    ];

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                setSmsSerialNumber(searchParams.get("smsSerialnumber").replace(/'/g, ""));
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (smsSerialNumber) {
            setVesselId(searchParams.get("vesselId").replace(/'/g, ""));
            setVesselName(searchParams.get("vesselName").replace(/'/g, ""));
            setCustomerName(searchParams.get("customerName").replace(/'/g, ""));
        }
    }, [smsSerialNumber])

    useEffect(() => {
        if (enginePerformanceData.isDataReady) {
            console.log('enginePerformanceData', enginePerformanceData);
            setLoading(false);
        } else {
            if (enginePerformanceData.shouldUpdateFromDB) {
                loadEnginePerformance();
            }
        }

    }, [enginePerformanceData]);

    useEffect(() => {
        if (isPrint) {
            printFuelConsumptionData();
        }
    }, [isPrint])


    const loadEnginePerformance = async () => {
        try {
            setLoading(true);
            let monthStart = moment(selectStartDate).format("YYYY/MM").replace('/', '');
            let monthEnd = moment(selectEndDate).format("YYYY/MM").replace('/', '');
            console.log("START", selectStartDate);
            console.log("END", selectEndDate);
            let results = await getHistorisEnginePerformanceFromMaterializedView(cookies.token, smsSerialNumber, selectStartDate, selectEndDate, monthEnd, monthStart, vesselId);
            results.isDataReady = true;
            results.shouldUpdateFromDB = false;
            setEnginePerformanceData(results);

        }
        catch (exception) {
            setEnginePerformanceData({ isDataReady: false, shouldUpdateFromDB: false });
            setLoading(false);
            alert("Error Has Been Ocurred");
            console.log(exception);
        }
    }

    const generateEnginePerformanceCSV = () => {
        let csvHeaders = [
            "received_date",                                       ///0
            "main_engine_port_acceleratorPedalPosition",           ///1
            "main_engine_port_boostPressure",                      ///2
            "main_engine_port_freshWaterTemperature",              ///3
            "main_engine_port_fueltemperature",                    ///4
            "main_engine_port_batteryVoltage",                     ///5
            "main_engine_port_engineSpeed",                        ///6
            "main_engine_port_oilTemperature",                     ///7
            "main_engine_port_oilPressure",                        ///8
            "main_engine_stbd_acceleratorPedalPosition",           ///9
            "main_engine_stbd_boostPressure",                      ///10
            "main_engine_stbd_freshWaterTemperature",              ///11
            "main_engine_stbd_fueltemperature",                    ///12
            "main_engine_stbd_batteryVoltage",                     ///13
            "main_engine_stbd_engineSpeed",                        ///14
            "main_engine_stbd_oilTemperature",                     ///15
            "main_engine_stbd_oilPressure",                        ///16
            "aux_engine_port_engineSpeed",                         ///17
            "aux_engine_port_freshWaterTemperature",               ///18
            "aux_engine_port_oilTemperature",                      ///19
            "aux_engine_port_oilPressure",                         ///20
            "aux_engine_port_batteryVoltage",                      ///21
            "aux_engine_stbd_engineSpeed",                         ///22
            "aux_engine_stbd_freshWaterTemperature",               ///23
            "aux_engine_stbd_oilTemperature",                      ///24
            "aux_engine_stbd_oilPressure",                         ///25
            "aux_engine_stbd_batteryVoltage",                      ///26
        ]

        // let arrEnginePerformanceLength = [];
        // let enginePerformanceLength = [];
        let arrayLength;
        arrayLength = enginePerformanceData.MEP.acceleratorPedalPosition.length;
        let tmpArr = {
            MEP: enginePerformanceData.MEP,
            MES: enginePerformanceData.MES,
            AEP: enginePerformanceData.AEP,
            AES: enginePerformanceData.AEP,
        }
        let listCSV = [];
        listCSV.push(csvHeaders);
        for (let i = 0; i < arrayLength; i++) {
            let csvElement = [];
            csvElement.push(enginePerformanceData.MEP.acceleratorPedalPosition[i].received_date);
            for (let [key, value] of Object.entries(tmpArr)) {
                for (let [key2, value2] of Object.entries(value)) {
                    // console.log(value2[i]);
                    csvElement.push(value2[i].reading_value);
                }
            }
            csvElement[0] = moment(csvElement[0]).local().format("YYYY-MM-DD HH:mm:ss");
            listCSV.push(csvElement);
        }

        // console.log(listCSV);


        // console.log(listCSV);
        setCompiledDataForReport(listCSV);
    }

    const printEnginePerformanceData = () => {
        var prtContent = document.getElementById("printFuelConsumption");
        var style = "<style>";
        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: left;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"
        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `Fuel Consumption`;
        WinPrint.print();
        setLoading(false);
        setIsPrint(false);
    }
    return (
        <>
            <div className="mainFuelConsumptionPage">
                <Sidebar companyName={customerName} smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName} customerName={cookies.userName} />
                <Container fluid className="mainContainerFuelConsumption">
                    <div className="innerChildMainContainerFuelConsumption">
                        <ContainerBox
                            titleCaption={vesselName + ' ENGINE PERFORMANCE'}
                            childContent={
                                <>
                                    <div className="formDateForFuelConsumption">
                                        <Form.Group className="mb-3" style={{ margin: "10px" }}>
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control
                                                type="datetime-local"
                                                onChange={(e) => {
                                                    // setSelectStartDate(e.target.value);
                                                    setSelectStartDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                                    // setUseDropDown(false);
                                                }}
                                                value={
                                                    selectStartDate
                                                        ? moment(selectStartDate).format("yyyy-MM-DD HH:mm:ss")
                                                        : ""
                                                }
                                            ></Form.Control>
                                        </Form.Group>

                                        <Form.Group className="mb-3" style={{ margin: "10px" }}>
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control
                                                type="datetime-local"
                                                onChange={(e) => {
                                                    // setSelectEndDate(e.target.value);
                                                    setSelectEndDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                                    // setUseDropDown(false);
                                                }}
                                                value={
                                                    selectEndDate
                                                        ? moment(selectEndDate).format("yyyy-MM-DD HH:mm:ss")
                                                        : ""
                                                }
                                                min={moment(selectStartDate).format("yyyy-MM-DD HH:mm:ss")}
                                            ></Form.Control>
                                        </Form.Group>

                                        <Form.Group style={{ margin: "10px" }}>
                                            <DropDownForm
                                                caption={"Time"}
                                                placeholder={"Select Time ..."}
                                                listOption={durationOption}
                                                valueKey={"value"}
                                                labelKey={"label"}
                                                setValueFunction={(e) => {
                                                    console.log(e);
                                                    try {
                                                        let dateTime = new Date();
                                                        let startDate, endDate;

                                                        if (e.target.value === "1") {
                                                            console.log("1");
                                                            startDate = moment(dateTime).subtract(60, "minutes").toISOString();
                                                            endDate = moment(dateTime).toISOString();
                                                        } else if (e.target.value === "2") {
                                                            console.log("2");
                                                            startDate = moment(dateTime).subtract(1, "day").toISOString();
                                                            endDate = moment(dateTime).toISOString();
                                                        } else if (e.target.value === "3") {
                                                            console.log("3");
                                                            startDate = moment(dateTime).subtract(1, "week").toISOString();
                                                            endDate = moment(dateTime).toISOString();
                                                        }

                                                        setSelectStartDate(moment(startDate).format("yyyy-MM-DD HH:mm:ss"));
                                                        setSelectEndDate(moment(endDate).format("yyyy-MM-DD HH:mm:ss"));
                                                    } catch (exception) {
                                                        console.log(exception);
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Button
                                            style={{ width: '200px', height: "40px", margin: "10px", marginTop: "32px" }}
                                            onClick={() => {
                                                setEnginePerformanceData({ isDataReady: false, shouldUpdateFromDB: true });
                                            }}
                                        >
                                            <div className="btnContainer">
                                                <div className="btnContainerLeft" ><ClipboardData size={25} /></div>
                                                <div className="btnContainerRight">{'Show Data'}</div>
                                            </div>
                                        </Button>
                                        {/* <Button
                                            style={{ width: '200px', height: "40px", margin: "10px", marginTop: "32px" }}
                                            onClick={() => {
                                                printFuelConsumptionData();
                                            }}
                                        >
                                            <div className="btnContainer" >
                                                <div className="btnContainerLeft" ><Printer size={25} /></div>
                                                <div className="btnContainerRight" >{'Print Document'}</div>
                                            </div>
                                        </Button> */}
                                        {
                                            enginePerformanceData.isDataReady &&
                                            <CSVLink
                                                style={{ width: '200px', height: "40px", margin: "10px", marginTop: "32px" }}
                                                data={compiledDataForReport}
                                                separator={","}
                                                filename={"engine-performance-data.csv"}
                                                className="btn btn-primary"
                                                target="_blank"

                                            >
                                                <div className="btnContainer" onClick={() => {
                                                    generateEnginePerformanceCSV();
                                                }}>
                                                    <div className="btnContainerLeft" ><FiletypeCsv size={25} /></div>
                                                    <div className="btnContainerRight" >{'Export to CSV'}</div>
                                                </div>
                                            </CSVLink>
                                        }
                                        {/* <br/> */}
                                        <div className="summaryContainerFuelConsumption">
                                            <h4 className="txtFortankNameFuelConsumption">Engine Performance Summary</h4>
                                            {
                                                enginePerformanceData.isDataReady && (
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                        <div className="table-container" style={{ width: '100%' }}>
                                                            <div className="table-header">
                                                                <div style={{ flex: 2 }} className="table-header-content">{'Main Engine'}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{'Port'}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{'Starboard'}</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'Main Engine RPM'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMEP && isNaN(enginePerformanceData.avgAllMEP.engineSpeedAvg) ? 0 : enginePerformanceData.avgAllMEP.engineSpeedAvg } RPM</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMES && isNaN(enginePerformanceData.avgAllMES.engineSpeedAvg) ? 0 : enginePerformanceData.avgAllMES.engineSpeedAvg } RPM</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'Throttle'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMES && isNaN(enginePerformanceData.avgAllMEP.acceleratorPedalPosition) ? 0 : enginePerformanceData.avgAllMEP.acceleratorPedalPositionAvg} %</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMES && isNaN(enginePerformanceData.avgAllMES.acceleratorPedalPosition) ? 0 : enginePerformanceData.avgAllMES.acceleratorPedalPositionAvg} %</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'L.O Pressure'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMEP && isNaN(enginePerformanceData.avgAllMEP.oilPressureAvg) ? 0 : enginePerformanceData.avgAllMEP.oilPressureAvg } MPA</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMES && isNaN(enginePerformanceData.avgAllMES.oilPressureAvg) ? 0 : enginePerformanceData.avgAllMES.oilPressureAvg } MPA</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'Fuel Temperature'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMEP && isNaN(enginePerformanceData.avgAllMEP.fueltemperatureAvg) ? 0 : enginePerformanceData.avgAllMEP.fueltemperatureAvg } °C</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMES && isNaN(enginePerformanceData.avgAllMES.fueltemperatureAvg) ? 0 : enginePerformanceData.avgAllMES.fueltemperatureAvg } °C</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'L.O Temperature'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMEP && isNaN(enginePerformanceData.avgAllMEP.oilTemperatureAvg) ? 0 : enginePerformanceData.avgAllMEP.oilTemperatureAvg } °C</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMES && isNaN(enginePerformanceData.avgAllMES.oilTemperatureAvg) ? 0 : enginePerformanceData.avgAllMES.oilTemperatureAvg } °C</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'Boost Air Pressure'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMEP && isNaN(enginePerformanceData.avgAllMEP.boostPressureAvg) ? 0 : enginePerformanceData.avgAllMEP.boostPressureAvg } KPA</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMES && isNaN(enginePerformanceData.avgAllMES.boostPressureAvg) ? 0 : enginePerformanceData.avgAllMES.boostPressureAvg } KPA</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'Fresh Water Temperature'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMEP && isNaN(enginePerformanceData.avgAllMEP.freshWaterTemperatureAvg) ? 0 : enginePerformanceData.avgAllMEP.freshWaterTemperatureAvg } °C</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMES && isNaN(enginePerformanceData.avgAllMES.freshWaterTemperatureAvg) ? 0 : enginePerformanceData.avgAllMES.freshWaterTemperatureAvg } °C</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'Battery Voltage'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMEP && isNaN(enginePerformanceData.avgAllMEP.batteryVoltageAvg) ? 0 : enginePerformanceData.avgAllMEP.batteryVoltageAvg } V</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllMES && isNaN(enginePerformanceData.avgAllMES.batteryVoltageAvg) ? 0 : enginePerformanceData.avgAllMES.batteryVoltageAvg } V</div>
                                                            </div>
                                                        </div>
                                                        <div style={{ margin: '5px' }}></div>
                                                        <div className="table-container" style={{ width: '100%' }}>
                                                            <div className="table-header">
                                                                <div style={{ flex: 2 }} className="table-header-content">{'Auxiliary Engine'}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{'Port'}</div>
                                                                <div style={{ flex: 1 }} className="table-header-content">{'Starboard'}</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'Auxiliary Engine RPM'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllAEP && isNaN(enginePerformanceData.avgAllAEP.engineSpeedAvg) ? 0 : enginePerformanceData.avgAllAEP.engineSpeedAvg } RPM</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllAES && isNaN(enginePerformanceData.avgAllAES.engineSpeedAvg) ? 0 : enginePerformanceData.avgAllAES.engineSpeedAvg } RPM</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'L.O Pressure'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllAEP && isNaN(enginePerformanceData.avgAllAEP.oilPressureAvg) ? 0 : enginePerformanceData.avgAllAEP.oilPressureAvg } MPA</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllAES && isNaN(enginePerformanceData.avgAllAES.oilPressureAvg) ? 0 : enginePerformanceData.avgAllAES.oilPressureAvg } MPA</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'Fresh Water Temperature'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllAEP && isNaN(enginePerformanceData.avgAllAEP.freshWaterTemperatureAvg) ? 0 : enginePerformanceData.avgAllAEP.freshWaterTemperatureAvg } °C</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllAES && isNaN(enginePerformanceData.avgAllAES.freshWaterTemperatureAvg) ? 0 : enginePerformanceData.avgAllAES.freshWaterTemperatureAvg } °C</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'L.O Temperature'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllAEP && isNaN(enginePerformanceData.avgAllAEP.oilTemperatureAvg) ? 0 : enginePerformanceData.avgAllAEP.oilTemperatureAvg } °C</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllAES && isNaN(enginePerformanceData.avgAllAES.oilTemperatureAvg) ? 0 : enginePerformanceData.avgAllAES.oilTemperatureAvg } °C</div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 2 }} className="table-body-content">{'Battery Voltage'}</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllAEP && isNaN(enginePerformanceData.avgAllAEP.batteryVoltageAvg) ? 0 : enginePerformanceData.avgAllAEP.batteryVoltageAvg } V</div>
                                                                <div style={{ flex: 1 }} className="table-body-content">{enginePerformanceData && enginePerformanceData.avgAllAES && isNaN(enginePerformanceData.avgAllAES.batteryVoltageAvg) ? 0 : enginePerformanceData.avgAllAES.batteryVoltageAvg } V</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                    {
                                        enginePerformanceData.isDataReady &&
                                        <>
                                            <div className="outerBorderFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Main Engine</h4>
                                                <div className="lineChartContainerFuelConsumption">
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Main Engine RPM</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.MEP.engineSpeed}
                                                            secondData={enginePerformanceData.MES.engineSpeed}
                                                            minValue={0} maxValue={1300} stepSize={260}
                                                            minValueSecond={0} maxValueSecond={1300} stepSizeSecond={260}
                                                            firstTextLabel={'Main Engine Port RPM'}
                                                            secondTextLabel={'Main Engine STBD RPM'}
                                                        />
                                                    </div>
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Main Engine Throttle</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.MEP.acceleratorPedalPosition}
                                                            secondData={enginePerformanceData.MES.acceleratorPedalPosition}
                                                            minValue={0} maxValue={100} stepSize={20}
                                                            minValueSecond={0} maxValueSecond={100} stepSizeSecond={20}
                                                            firstTextLabel={'Throttle Port'}
                                                            secondTextLabel={'Throttle STBD'}
                                                        />
                                                    </div>
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Main Engine L.O Pressure</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.MEP.oilPressure}
                                                            secondData={enginePerformanceData.MES.oilPressure}
                                                            minValue={0} maxValue={1} stepSize={0.2}
                                                            minValueSecond={0} maxValueSecond={1} stepSizeSecond={0.2}
                                                            firstTextLabel={'L.O Pressure Port (MPA)'}
                                                            secondTextLabel={'L.O Pressure STBD (MPA)'}
                                                        />
                                                    </div>
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Main Engine Fuel Temperature</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.MEP.fueltemperature}
                                                            secondData={enginePerformanceData.MES.fueltemperature}
                                                            minValue={0} maxValue={100} stepSize={20}
                                                            minValueSecond={0} maxValueSecond={100} stepSizeSecond={20}
                                                            firstTextLabel={'Fuel Temperature Port (°C)'}
                                                            secondTextLabel={'Fuel Temperature STBD (°C)'}
                                                        />
                                                    </div>
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Main Engine L.O Temperature</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.MEP.oilTemperature}
                                                            secondData={enginePerformanceData.MES.oilTemperature}
                                                            minValue={0} maxValue={100} stepSize={20}
                                                            minValueSecond={0} maxValueSecond={100} stepSizeSecond={20}
                                                            firstTextLabel={'L.O Temperature Port (°C)'}
                                                            secondTextLabel={'L.O Temperature STBD (°C)'}
                                                        />
                                                    </div>
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Main Engine Boost Air Pressure</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.MEP.boostPressure}
                                                            secondData={enginePerformanceData.MES.boostPressure}
                                                            minValue={0} maxValue={500} stepSize={100}
                                                            minValueSecond={0} maxValueSecond={500} stepSizeSecond={100}
                                                            firstTextLabel={'Boost Pressure Port (KPA)'}
                                                            secondTextLabel={'Boost Pressure STBD (KPA)'}
                                                        />
                                                    </div>
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Main Engine Fresh Water Temperature</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.MEP.freshWaterTemperature}
                                                            secondData={enginePerformanceData.MES.freshWaterTemperature}
                                                            minValue={0} maxValue={100} stepSize={20}
                                                            minValueSecond={0} maxValueSecond={100} stepSizeSecond={20}
                                                            firstTextLabel={'FW Temperature Port (°C)'}
                                                            secondTextLabel={'FW Temperature STBD (°C)'}
                                                        />
                                                    </div>
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Main Engine Battery Voltage</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.MEP.batteryVoltage}
                                                            secondData={enginePerformanceData.MES.batteryVoltage}
                                                            minValue={0} maxValue={30} stepSize={6}
                                                            minValueSecond={0} maxValueSecond={30} stepSizeSecond={6}
                                                            firstTextLabel={'Battery Voltage Port (V)'}
                                                            secondTextLabel={'Battery Voltage STBD (V)'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="outerBorderFuelConsumption">
                                                <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine</h4>
                                                <div className="lineChartContainerFuelConsumption">
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine RPM</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.AEP.engineSpeed}
                                                            secondData={enginePerformanceData.AES.engineSpeed}
                                                            minValue={0} maxValue={1800} stepSize={360}
                                                            minValueSecond={0} maxValueSecond={1800} stepSizeSecond={360}
                                                            firstTextLabel={'Auxiliary Engine Port RPM'}
                                                            secondTextLabel={'Auxiliary Engine STBD RPM'}
                                                        />
                                                    </div>
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine L.O Pressure</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.AEP.oilPressure}
                                                            secondData={enginePerformanceData.AES.oilPressure}
                                                            minValue={0} maxValue={1} stepSize={0.2}
                                                            minValueSecond={0} maxValueSecond={1} stepSizeSecond={0.2}
                                                            firstTextLabel={'L.O Pressure Port (MPA)'}
                                                            secondTextLabel={'L.O Pressure STBD (MPA)'}
                                                        />
                                                    </div>
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Fresh Water Temperature</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.AEP.freshWaterTemperature}
                                                            secondData={enginePerformanceData.AES.freshWaterTemperature}
                                                            minValue={0} maxValue={100} stepSize={20}
                                                            minValueSecond={0} maxValueSecond={100} stepSizeSecond={20}
                                                            firstTextLabel={'FW Temperature Port (°C)'}
                                                            secondTextLabel={'FW Temperature STBD (°C)'}
                                                        />
                                                    </div>
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine L.O Temperature</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.AEP.oilTemperature}
                                                            secondData={enginePerformanceData.AES.oilTemperature}
                                                            minValue={0} maxValue={100} stepSize={20}
                                                            minValueSecond={0} maxValueSecond={100} stepSizeSecond={20}
                                                            firstTextLabel={'L.O Temperature Port (°C)'}
                                                            secondTextLabel={'L.O Temperature STBD (°C)'}
                                                        />
                                                    </div>
                                                    <div className="eachlineChartContainerFuelConsumption">
                                                        <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Battery Voltage</h4>
                                                        <DynamicLineChartDoubleAxis
                                                            firstData={enginePerformanceData.AEP.batteryVoltage}
                                                            secondData={enginePerformanceData.AES.batteryVoltage}
                                                            minValue={0} maxValue={30} stepSize={6}
                                                            minValueSecond={0} maxValueSecond={30} stepSizeSecond={6}
                                                            firstTextLabel={'Battery Voltage Port (V)'}
                                                            secondTextLabel={'Battery Voltage STBD (V)'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        />
                    </div>
                    <Loading
                        loading={loading}
                        loadingText={`${t("loading_component.text")}`}
                    />
                </Container>
                {/* <div id="printFuelConsumption" style={{ display: 'none', padding: 10 }}>
                    <div style={{ width: '100%', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '90%', border: '1px black solid', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px' }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ width: '50%' }} src={WMI} />
                            </div>
                            <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <p style={{ fontSize: 'large', fontWeight: 'bold' }}>{vesselName}</p>
                                <p style={{ fontSize: 'medium', fontWeight: 'lighter' }}>{customerName}</p>
                            </div>
                        </div>

                        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px' }}>
                            <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>FUEL CONSUMPTION SUMMARY</h2>
                        </div>

                        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '10px' }}>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <p style={{ fontSize: 'large', fontWeight: 'bold', margin: '0' }}>{'Start Date'}</p>
                                <p style={{ fontSize: 'medium', fontWeight: 'lighter', margin: '0' }}>{moment(selectStartDate).local().format("YYYY-MM-DD HH:mm")}</p>
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <p style={{ fontSize: 'large', fontWeight: 'bold', margin: '0' }}>{'End Date'}</p>
                                <p style={{ fontSize: 'medium', fontWeight: 'lighter', margin: '0' }}>{moment(selectEndDate).local().format("YYYY-MM-DD HH:mm")}</p>
                            </div>
                        </div>

                        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '10px' }}>
                            <table style={{ width: '100%', border: '1px black soild' }}>
                                <thead>
                                    <tr>
                                        <th style={{ padding: '8px', border: '1px black soild' }}>{''}</th>
                                        <th style={{ padding: '8px', border: '1px black soild' }}>{''}</th>
                                        <th style={{ padding: '8px', border: '1px black soild' }}>Total Fuel Consumption</th>
                                        <th style={{ padding: '8px', border: '1px black soild' }}>Average Fuel Usage</th>
                                        <th style={{ padding: '8px', border: '1px black soild' }}>Engine Load</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>Main Engine</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>Port</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{fuelMEP[fuelMEP.length - 1] ? fuelMEP[fuelMEP.length - 1].compensated_value + ' L' : "N/A"}</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{summaryAverageMEP ? summaryAverageMEP.toFixed(2) + ' L/H' : "N/A"}</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{''}</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>Starboard</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{fuelMES[fuelMES.length - 1] ? fuelMES[fuelMES.length - 1].compensated_value + ' L' : "N/A"}</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{summaryAverageMES ? summaryAverageMES.toFixed(2) + ' L/H' : "N/A"}</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>Aux Engine</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>Port</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{fuelAEP[fuelAEP.length - 1] ? fuelAEP[fuelAEP.length - 1].compensated_value + ' L' : "N/A"}</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{summaryAverageAEP ? summaryAverageAEP.toFixed(2) + ' L/H' : "N/A"}</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{summaryAverageEngineLoadAEP ? summaryAverageEngineLoadAEP.toFixed(2) + ' KW' : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{''}</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>Starboard</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{fuelAES[fuelAES.length - 1] ? fuelAES[fuelAES.length - 1].compensated_value + ' L' : "N/A"}</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{summaryAverageAES ? summaryAverageAES.toFixed(2) + ' L/H' : "N/A"}</td>
                                        <td style={{ padding: '8px', border: '1px black soild' }}>{summaryAverageEngineLoadAES ? summaryAverageEngineLoadAES.toFixed(2) + ' KW' : "N/A"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div> */}
            </div>
        </>
    )
}