import './index.css'
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getVesselTrackingData } from "../../../../Helpers/ApplicationHelpers";
import "../../../AppV2.css";
import { AccountCircle, Key, Login, Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTopBar from "../../../../Components/v2/FMSTopBar";
import FMSTheme from "../../../FMSTheme";
import FMSSidebar from '../../../../Components/v2/FMSSidebar';
import EnhancedTable from '../../../../Components/v2/FMSTable';
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../../Components/v2/FMSNavBarMUI";
import OpenSeaMap from "../../../../Components/v2/FMSOpenSeaMap/index";
import { parseNmeaSentence } from "nmea-simple";
import { MapContainer, Marker, Popup, TileLayer, useMap, rotatedMarker } from "react-leaflet";
import moment from "moment";
import DmsCoordinates from "dms-conversion";
import RotatedMarker from "../../../../Components/RotatedMarker";
import vesselImg from "../../../../Assets/Images/new_arrow_pointer.png";
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import DropDownForm from "../../../../Components/DropDownForm";
import { Form } from 'react-bootstrap';

export default function FMSVesselTrackingPage() {
    const { t, i18n } = useTranslation();
    let location = useLocation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const [gpsData, setGPSData] = useState([]);
    const [listNMEAData, setListNMEAData] = useState([]);
    const [engineAndLocationData, setEngineAndLocationData] = useState();
    const [selectedNMEAData, setSelectedNMEAData] = useState();
    const [listVesselData, setListVesselData] = useState();
    const [listLineData, setListLineData] = useState();
    const [markerData, setMarkerData] = useState({});
    const [vesselId, setVesselId] = useState("");
    const navigate = useNavigate();
    const [selectStartDate, setSelectStartDate] = useState();
    const [selectEndDate, setSelectEndDate] = useState();
    const [checked, setChecked] = useState(false);

    const durationOption = [
        {
            label: "1 Hour Ago",
            value: 1,
        },
        {
            label: "1 Day Ago",
            value: 2,
        },
        {
            label: "1 Week Ago",
            value: 3,
        },
    ];

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const icon = (
        <Paper sx={{ m: 1, width: 100, height: 100 }} elevation={4}>
            <svg>
                <Box
                    component="polygon"
                    points="0,100 50,00, 100,100"
                    sx={(theme) => ({
                        fill: theme.palette.common.white,
                        stroke: theme.palette.divider,
                        strokeWidth: 1,
                    })}
                />
            </svg>
        </Paper>
    );

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                setVesselId(location.state.vesselId);
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        console.log('selectEndDate', selectEndDate);
    }, [selectEndDate])

    useEffect(() => {
        console.log('selectStartDate', selectStartDate);
    }, [selectStartDate])

    const loadHistorisCoordinate = async () => {
        try {
            let response = await getVesselTrackingData(cookies.token, vesselId, selectEndDate, selectStartDate);
            // console.log(response);
            if (response){
                
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <ThemeProvider theme={FMSTheme}>
                <div className='mainContainerFMS'>
                    <NavBar />
                    <div className='childMainContainerFMS'>
                        <div className='childMainContainerFMS-TOP'>
                            <div className='childMainContainerFMS-TOP-Left'><p className='ptitleFMSDashboard'>VESSEL LOCATION</p></div>
                            <div className='childMainContainerFMS-TOP-Mid'></div>
                            <div className='childMainContainerFMS-TOP-Right'></div>
                        </div>
                        <div className='childMainContainerFMS-BODY' style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '150px', left: '10px', zIndex: 1000 }}>
                                <FormControlLabel
                                    control={<Switch checked={checked} onChange={handleChange} />}
                                    label="Show"
                                />
                                <Box
                                    sx={{
                                        '& > :not(style)': {
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            height: 120,
                                            width: 250,
                                        },
                                    }}
                                >
                                    <div>
                                        <Collapse in={checked}>
                                            <div className="collapseContainerVesselTracking">
                                                <div className='collapseContainerVesselTrackingBody'>
                                                    <Form.Group className="mb-3" style={{ width: '100%' }}>
                                                        <Form.Label>Start Date</Form.Label>
                                                        <Form.Control
                                                            type="datetime-local"
                                                            onChange={(e) => {
                                                                setSelectStartDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                                            }}
                                                            value={
                                                                selectStartDate
                                                                    ? moment(selectStartDate).format("yyyy-MM-DD HH:mm:ss")
                                                                    : ""
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" style={{ width: '100%' }}>
                                                        <Form.Label>End Date</Form.Label>
                                                        <Form.Control
                                                            type="datetime-local"
                                                            onChange={(e) => {
                                                                setSelectEndDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                                            }}
                                                            value={
                                                                selectEndDate
                                                                    ? moment(selectEndDate).format("yyyy-MM-DD HH:mm:ss")
                                                                    : ""
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>

                                                    <Form.Group style={{ width: '100%' }}>
                                                        <DropDownForm
                                                            caption={"Duration"}
                                                            placeholder={"Select Time ..."}
                                                            listOption={durationOption}
                                                            valueKey={"value"}
                                                            labelKey={"label"}
                                                            setValueFunction={(e) => {
                                                                console.log(e);
                                                                try {
                                                                    let dateTime = new Date();
                                                                    if (e.target.value === "1") {
                                                                        console.log("1");
                                                                        let startDate = moment(dateTime).subtract(60, "minutes").format("yyyy-MM-DD HH:mm:ss");
                                                                        let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                                        setSelectStartDate(startDate);
                                                                        setSelectEndDate(endDate);
                                                                    } else if (e.target.value === "2") {
                                                                        console.log("2");
                                                                        let startDate = moment(dateTime).subtract(1, "day").format("yyyy-MM-DD HH:mm:ss");
                                                                        let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                                        setSelectStartDate(startDate);
                                                                        setSelectEndDate(endDate);
                                                                        console.log("tes", startDate, endDate);
                                                                    } else if (e.target.value === "3") {
                                                                        console.log("3");
                                                                        let startDate = moment(dateTime).subtract(1, "week").format("yyyy-MM-DD HH:mm:ss");
                                                                        let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                                        setSelectStartDate(startDate);
                                                                        setSelectEndDate(endDate);
                                                                    }
                                                                } catch (exception) {
                                                                    console.log(exception);
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group style={{ width: '100%', marginTop: '10px' }}>
                                                        <Button style={{ width: '100%' }} variant="contained" 
                                                        onClick={() => {
                                                            loadHistorisCoordinate();
                                                        }}>Find Vesel Tracking Data</Button>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </Box>
                            </div>
                            <OpenSeaMap markerChild={markerData} isTracking={false} />
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </>
    );

}