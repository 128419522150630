import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isFMSAdminTokenValid } from "../../../../Helpers/ApplicationHelpers";
import "../../../AppV2.css";
import { AccountCircle, Key, Login, Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTopBar from "../../../../Components/v2/FMSTopBar";
import FMSTheme from "../../../FMSTheme";
import FMSSidebar from '../../../../Components/v2/FMSSidebar';
import EnhancedTable from '../../../../Components/v2/FMSTable';
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../../Components/v2/FMSAdminNavBarMUI";
import moment from "moment";
import DmsCoordinates from "dms-conversion";
import RotatedMarker from "../../../../Components/RotatedMarker";
import vesselImg from "../../../../Assets/Images/new_arrow_pointer.png";

export default function AdminDashboardPage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isFMSAdminTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/FMS");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
            }
        }
        checkCookies();
    }, []);

  

    return (
        <>
            <ThemeProvider theme={FMSTheme}>
                <div className='mainContainerFMS'>
                    <NavBar />
                    <div className='childMainContainerFMS'>
                        
                    </div>
                </div>
            </ThemeProvider>
        </>
    );
}