import React, { useState, useRef, useEffect } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow, Polyline } from "google-maps-react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { EyeFill, GeoFill, Geo } from "react-bootstrap-icons";
import ContainerBox from "../Components/ContainerBox";
import vesselIcon from "../Assets/Images/ship-solid-red-resized.png";

const GoogleMapContainer = ({ google, devices, vessel, onPointClick, selectedPoint }) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [polylineCoordinates, setPolylineCoordinates] = useState([]);
  const [vesselName, setVesselName] = useState();
  const [companyName, setCompanyName] = useState();
  const [vesselType, setVesselType] = useState();
  const mapRef = useRef(null);
  const degreeSymbol = "\u00b0";
  const navigate = useNavigate();

  const onMarkerClick = (props, marker) => {
    console.log('marker', marker);
    setActiveMarker(marker);
    setSelectedDevice(props.device);
    if (onPointClick) {
      onPointClick(props.device);
    }
  };

  const onClose = () => {
    setActiveMarker(null);
    setSelectedDevice(null);
  };


  const defaultCenter = {
    lat: -6.127283373056311,
    lng: 106.85529133081604,
  };

  useEffect(() => {
    const newCoordinates = devices.map(device => ({
      lat: device.latitude,
      lng: device.longitude
    }));
    setPolylineCoordinates(newCoordinates);
  }, [devices]);

  useEffect(() => {
    console.log(vessel);
    setVesselName(vessel.vessel_name);
    setCompanyName(vessel.customer_name);
    setVesselType(vessel.vessel_type_name);
  }, [vessel]);

  const zoomToMarker = (device) => {
    if (mapRef.current && mapRef.current.map) {
      const map = mapRef.current.map;
      const position = { lat: device.latitude, lng: device.longitude };
      map.setCenter(position);
      map.setZoom(18);
    } else {
      console.error("Map reference is not set correctly.");
    }
  };

  return (
    <div style={{ position: "relative", width: '100%', height: 900 }}>
      <Map
        containerStyle={{
          position: "relative",
          width: '100%',
          height: '100%',
        }}
        google={google}
        zoom={14}
        style={{
          position: "absolute",
          width: '100%',
          height: '100%',
        }}
        initialCenter={defaultCenter}
        ref={mapRef} 
      >
        {devices.map((device) => (
          <Marker
            key={device.id}
            position={{ lat: device.latitude, lng: device.longitude }}
            title={device.device_name}
            onClick={onMarkerClick}
            device={device}
            icon={vesselIcon}
          />
        ))}

        <InfoWindow
          marker={activeMarker}
          visible={activeMarker !== null}
          onClose={onClose}
          disableAutoPan={true}
          zIndex={1000}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: '200px',
              flexWrap: 'wrap'
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: 'center',
                borderBottomStyle: "solid",
              }}
            >
              {/* <div style={{ display: "flex", paddingRight: 2 }}>
                <Geo size={20} />
              </div> */}
              <div style={{ display: "flex", paddingLeft: 2 }}>
                <p style={{ color: 'black', fontWeight: 'lighter', fontSize: 'large' }}>{vesselName}</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                paddingTop: 10,
              }}
            >
              {selectedDevice && (
                
                <table style={{ padding: 5 }}>
                  <tbody>
                    <tr>
                      <td style={{ color: 'black', fontSize: '12px' }}>Last Seen: </td>
                    </tr>
                    <tr>
                      <td style={{ color: 'black', fontSize: '12px' }}>{selectedDevice.timestamp_gps}</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </InfoWindow>
        {polylineCoordinates.length > 1 && (
          <Polyline
            path={polylineCoordinates}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={2}
          />
        )}
      </Map>

      {/* Additional Information Div */}
      <div
        style={{
          position: "absolute",
          bottom: 100,
          left: 20,
          width: '15%',
          height: '80%',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: '10px',
          borderRadius: "12px",
          boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        {devices.map((device) => (
          <>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '5px' }}>
            <p style={{ fontSize: 'large', margin: '0px'}}>{vesselName}</p>
            <p style={{ margin: '0px'}}>Company: {companyName}</p>
            <p style={{ margin: '0px'}}>Type: {vesselType}</p>
            <p style={{ margin: '0px'}}>GPS: {device.latitude}, {device.longitude}</p>
            <p style={{ margin: '0px'}}>Time: {device.timestamp_gps}</p>
            <Button style={{ margin: '5px'}} onClick={() => zoomToMarker(device)}><EyeFill /></Button>
        </div>
        <div style={{border: '1px rgb(189, 189, 189) solid', width: '100%', height: '0.5px'}}></div>
        </>
          // <ContainerBox
          //   key={device.id} 
          //   titleCaption={vesselName}
          //   childContent={
          //     <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '5px' }}>
          //       <p>GPS: {device.latitude}, {device.longitude}</p>
          //       <p>Time: {device.timestamp_gps}</p>
          //       <Button onClick={() => zoomToMarker(device)}><EyeFill /></Button>
          //     </div>
          //   }
          // />
        ))}
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAiJLI_QqRdHV6SHSkfe4etYhTpi5LsJ9Q",
})(GoogleMapContainer);
