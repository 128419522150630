import "./NewBitLampMSBOn.css"

export default function BitLamp({ text, alarm, bitlampColor = "", buttonType }) {
    const renderBitlamp = () => {
        let elementToRender;
        if (buttonType === "off"){
            if (alarm === "true") {
                elementToRender = <div className="innerBitLampContainerLeftTrueForOffButton"></div>
            } else {
                elementToRender = <div className="innerBitLampContainerLeftFalseForOffButton" ></div>
            }
        } else if (buttonType === "on"){
            if (alarm === "true") {
                elementToRender = <div className="innerBitLampContainerLeftTrueForOnButton"></div>
            } else {
                elementToRender = <div className="innerBitLampContainerLeftFalseForOnButton" ></div>
            }
        }
        return elementToRender;
    }
    

return (
    // <div className="bitLamp">
    // {
    //     alarm === "true" ?
    //         <div className="bitLampOn"></div> : <div className="bitlampBlack" ></div>
    // }

    //     <div className="textBitLamp" style={{ fontSize }}>{text}</div>
    // </div>

    <div className="bitLampNewMSB">
        <div className="innerBitLampContainerLeft">
            {
                renderBitlamp()

            }
        </div>
        <div className="innerBitLampContainerRight">
            <p className="textBitLamp">{text}</p>
        </div>
    </div>
)
}