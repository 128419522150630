import "./index.css";
import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Container, Col, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import {
    isTokenValid, getUserAccessById, insertUpdateVesselXDocument, getCountMasterDocument, getCountEquipmentDocument,
    getDocumentBase64, findMasterDocumentById, findMasterDocumentByIdFixed, findSelectedById, deleteEquipmentDocument, getVesselXEquipmentPhotoCount,
    convertBase64, insertUpdateVesselxEquipmentPhoto, getVesselxEquipmentById, findSelectedByMasterId,
    deleteVesselEquipmentPhoto, getVesselxEquipmentPhotoById, checkIsCover, getParameter
} from "../../../Helpers/ApplicationHelpers";
import {getSelectedDocumentByIdNew } from "../../../Helpers/InventoryHelpers";
import { getSelectedDocumentById, deleteInventoryDocument } from "../../../Helpers/InventoryHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Paginations from "../../../Components/Pagination";
import Modals from "../../../Components/modal";
import {
    Trash, ArrowLeft, Download, FilePlusFill, EyeFill, XSquareFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill, Search, ArrowClockwise
} from "react-bootstrap-icons";
import ContainerBox from "../../../Components/ContainerBox";
import Loading from "../../../Components/Loading";
import TextBox from "../../../Components/Textbox";
import FormSelect from "../../../Components/FormSelect";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../Helpers/Base64Downloader";
import moment from 'moment';
import Sidebar from "../../../Components/NewSideBarForCustomerPage/NewSideBar";
import { omit } from 'lodash';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



export default function CustomerVesselEquipmentPartCatalogPage() {
    const inputFile = useRef(null);
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [disabledSelected, setDisabledSelected] = useState(false);
    const [listDocument, setListDocument] = useState([]);
    const [listDocumentMaster, setListDocumentMaster] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const [documentId, setDocumentId] = useState("");
    const [listDocumentSparepart, setListDocumentSparepart] = useState([]);
    const [uploadFIleImageError, setUploadFileImageError] = useState("");
    const [sparepartCatalogId, setSparepartCatalogId] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [descending, setDescending] = useState(false);
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState(false);
    const [vesselEquipmentDocumentId, setVesselEquipmentDocumentId] = useState("");
    const [sparepartCatalogDocumentId, setSparepartCatalogDocumentId] = useState("");
    const [downloadSparepartCatalogDocumentId, setdownloadSparepartCatalogDocumentId] = useState("")
    const [downloadVesselEquipmentDocumentId, setDownloadVesselEquipmentDocumentId] = useState("")
    const [listDocumentToBeView, setListDocumentToBeView] = useState([]);
    const [vesselEquipmentId, setVesselEquipmentId] = useState("")
    const [vesselName, setVesselName] = useState("")
    const [equipmentName, setEquipmentName] = useState("")
    const [equipmentCategoryId, setEquipmentCategoryId] = useState("")
    const [vesselId, setVesselId] = useState("")
    const [selectedDownloadIdMaster, setSelectedDownloadIdMaster] = useState();
    const [listinventoryMasterId, setListInventoryMasterId] = useState([]);
    const [selectedDocumentIdMaster, setSelectedDocumentIdMaster] = useState();
    const [selectedDocumentIdEquipment, setSelectedDocumentIdEquipment] = useState();
    const [selectedDocumentDownloadIdEquipment, setSelectedDocumentDownloadIdEquipment] = useState();
    const [selectedDeleteIdEquipment, setSelectedDeleteIdEquipment] = useState();
    const [zoomFactor, setZoomFactor] = useState(0.5);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [vesselEquipmentPhotoCount, setVesselEquipmentPhotoCount] = useState(0);
    const [listPhoto, setListPhoto] = useState([]);
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [description, setDescription] = useState(null);
    const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
    const [orderBy, setOrderBy] = useState({
        label: `Created Date`,
        value: "created_date"
    });
    const orderByList = [{
        label: "Created Date",
        value: "created_date"
    }];
    const [newDocument, setNewDocument] = useState({
        id: 0,
        document_base64: "",
        document_name: "",
        description: "",
        vessel_x_equipment_id: "",
        is_active: true,
        done: false,
        reference_number: ""
    });
    const [documentToBeViewed, setDocumentToBeViewed] = useState({});
    const [vesselPhotoEquipment, setvesselPhotoEquipment] = useState({
        vessel_x_equipment_id: searchParams.get("vesselEquipmentId"),
        vessel_id: searchParams.get("vesselId"),
        equipment_id: searchParams.get("equipmentId"),
        base64_img: '',
        title: '',
        description: '',
        taken_date: ''
    });
    const [fileImg, setFileImg] = useState("");
    const [vesselEquipmentPhotoId, setVesselEquipmentPhotoId] = useState("");
    const [listVesselEquipment, setListVesselEqipment] = useState([]);
    const [listParameter, setListParameter] = useState([]);
    const [removeId, setRemoveId] = useState([]);
    const [show, setShow] = useState(false);
    const showFormPhoto = () => setShow(true);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadAccess();
                // setShowDocumentDetailModal(false);
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        //loadPhoto();
        if (!showDocumentDetailModal) {
            setDocumentToBeViewed(null);
        }
    }, [showDocumentDetailModal])

    useEffect(() => {
        if (selectedDocumentDownloadIdEquipment !== ""){
            setLoading(true);
            downloadDataEqp();
        }
    }, [selectedDocumentDownloadIdEquipment])

    useEffect(() => {
        if (selectedDownloadIdMaster !== ""){
            downloadDataMaster();
        }
    }, [selectedDownloadIdMaster])

    useEffect(() => {
        forceUpdate();
        console.log('LIST DOCUMENT MASTER->', listDocumentMaster)
    }, [listDocumentMaster])

    useEffect(() => {
        forceUpdate();
        console.log('LIST DOCUMENT EQUIPMENT->', listDocument)
    }, [listDocument])

    useEffect(() => {
        console.log('LIST PHOTO->', listPhoto)
        loadDocumentData();
    }, [listPhoto]);

    useEffect(() => {
        //loadPhoto();
        if (numPages >= 1) {
            setIsDocumentLoaded(true);
            setLoading(false);
        }
    }, [numPages])


    useEffect(() => {
        console.log("DOCUMENT LOADED", isDocumentLoaded);
    }, [isDocumentLoaded])



    useEffect(() => {
        console.log(documentToBeViewed);
        if (documentToBeViewed) {
            if (documentToBeViewed.document_base64) {
                setShowDocumentDetailModal(true);
            }
        } else {
            setIsDocumentLoaded(false);
            setNumPages(0);
        }

    }, [documentToBeViewed]);

    const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.base64_img);
        setDescription(item.description)
    };

    const handelRotationRight = () => {
        const totalLength = listPhoto.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = listPhoto[0].base64_img;
            const descUrl = listPhoto[0].description;
            setClickedImg(newUrl);
            setDescription(descUrl)
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        });
        const descUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        })
        const newItem = newUrl[0].base64_img;
        const descNew = descUrl[0].description;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(descNew);
    };

    const handelRotationLeft = () => {
        const totalLength = listPhoto.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = listPhoto[totalLength - 1].base64_img;
            const descUrl = listPhoto[totalLength - 1].description;

            setClickedImg(newUrl);
            setDescription(descUrl);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        });
        const descUrl = listPhoto.filter((item) => {
            return listPhoto.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].base64_img;
        const descNew = descUrl[0].description;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(descNew);
    };

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, "/Vessel");
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadDocumentData = async () => {
        setLoading(true);
        try {
            // setShowDocumentDetailModal(false);
            // let countMaster = await getCountMasterDocument(cookies.token, searchParams.get("inventoryMasterId"));
            // console.log(countMaster);
            // let countEquipment = await getCountEquipmentDocument(cookies.token, searchParams.get("vesselEquipmentId"));
            // // console.log(countEquipment);
            // let count = countMaster + countEquipment
            // let totalPage = count / itemPerPage;
            // setTotalPage(totalPage);

            // if (countMaster > 0) {
            //     let responseMaster = await findMasterDocumentByIdFixed(cookies.token, searchParams.get("vesselEquipmentId"), searchParams.get("inventoryMasterId"), page, itemPerPage, orderBy.value, descending);
            //     console.log(responseMaster);
            //     setListDocumentMaster(responseMaster);
            // }
            // //let responseEqp = await findEquipmentDocumentById(cookies.token, searchParams.get("vesselEquipmentId"), page, itemPerPage, orderBy.value, descending);
            // // console.log(responseEqp);
            // setListDocument(responseEqp);

            let responseMaster = await findMasterDocumentByIdFixed(cookies.token, searchParams.get("vesselEquipmentId"), searchParams.get("inventoryMasterId"), page, itemPerPage, orderBy.value, descending);
            console.log(responseMaster);
            setListDocument(responseMaster);


            setLoading(false);
        } catch (exception) {
            setLoading(false);
            console.log(exception);
        }
    }



    const downloadDataEqp = async () => {
        try {
            let response = await findSelectedById(cookies.token, selectedDocumentDownloadIdEquipment);
            if (response){
                console.log('download data reeponse', response);
                triggerBase64Download(response.document_base64, response.document_name);
                setLoading(false);
                setSelectedDocumentDownloadIdEquipment("");
            } else {
                let response2 = await getSelectedDocumentByIdNew(cookies.token, selectedDocumentDownloadIdEquipment);
                if (response2){
                    console.log('download data reeponse', response2);
                    triggerBase64Download(response2.document_base64, response2.document_name);
                    setLoading(false);
                    setSelectedDocumentDownloadIdEquipment("");
                }
            }
        } catch (exception) {
            console.log(exception)
        }
    }

    const downloadDataMaster = async () => {
        try {
            let response = await findSelectedByMasterId(cookies.token, selectedDownloadIdMaster);
            console.log('download data reeponse', response);
            triggerBase64Download(response.document_base64, response.document_name);
            setSelectedDownloadIdMaster("");
        } catch (exception) {
            console.log(exception)
        }
    }
    // const removeDocument = async () => {
    //         let response = await deleteEquipmentDocument(cookies.token, selectedDeleteIdEquipment);
    //         if (response === 0) {
    //             // window.location.reload();
    //             // loadInventoryDocuments();
    //             loadDocumentData();
    //         }
    //     }

    // const removeInventoryDocument = async () => {
    //     let response = await deleteInventoryDocument(cookies.token, selectedDownloadIdMaster);
    //     if (response === 0) {
    //         // window.location.reload();
    //         loadDocumentData();
    //     }
    // }

    const loadPhoto = async () => {
        try {
            let vesselEquipmentPhotoCount = await getVesselXEquipmentPhotoCount(cookies.token, searchParams.get("vesselEquipmentId"));
            setVesselEquipmentPhotoCount(vesselEquipmentPhotoCount);
            let totalPage = vesselEquipmentPhotoCount / itemPerPage;
            setTotalPage(totalPage);
            let listPhoto = await getVesselxEquipmentPhotoById(cookies.token, searchParams.get("vesselEquipmentId"), page, itemPerPage, orderBy.value);
            setListPhoto(listPhoto);
        }


        catch (exception) {
            console.log(exception);
        }

    }

    useEffect(() => {
        if (vesselEquipmentPhotoId !== "") {
            setCoverEquipment();
            handleResets();
            loadPhoto();
        }

    }, [vesselEquipmentPhotoId]);

    useEffect(() => {
        // setvesselPhotoEquipment({...vesselPhotoEquipment, equipment_id: equipmentCategoryId, vessel_id: vesselId, vessel_x_equipment_id: vesselEquipmentId})
        if (vesselEquipmentId !== "" && vesselEquipmentId !== undefined) {
            initVesselEquipment()
            handleResets();
            loadPhoto();
        }


    }, [vesselEquipmentId])

    useEffect(() => {

        console.log(vesselPhotoEquipment);
    }, [vesselPhotoEquipment]);

    useEffect(() => {
        loadPhoto();
    }, [page]);

    useEffect(() => {
        loadPhoto();
    }, [itemPerPage]);

    useEffect(() => {
        loadPhoto();
    }, [orderBy]);

    // useEffect(() => {
    //     if (removeId !== "") {
    //         removeVesselPhotoEquipment();
    //     }
    // }, [removeId]);

    const initVesselEquipment = async () => {
        try {
            let response = await getVesselxEquipmentById(cookies.token, vesselEquipmentId);
            setListVesselEqipment(response);
            setvesselPhotoEquipment({
                ...vesselPhotoEquipment,
                vessel_x_equipment_id: response.id,
                vessel_id: response.vessel_id,
                equipment_id: response.equipment_id,
            });

            let parameter = await getParameter(cookies.token, vesselEquipmentId);
            setListParameter(parameter);

        } catch (exception) {
            console.log(exception);
        }
    }

    const addVesselEquipmentPhoto = async () => {
        try {
            let response = await insertUpdateVesselxEquipmentPhoto(cookies.token, vesselPhotoEquipment);
            if (response === 0) {
                alert(`${t("vessel_equipment_photo.alert_success_save_data")}`);
                setShow(false);
                handleResets();
                loadPhoto();
            }
            else {
            }
            setDisabledButton(false);
        } catch (exception) {
            console.log(exception);
        }
    }

    const handleResets = async () => {

        setvesselPhotoEquipment({
            base64_img: '',
            title: '',
            description: '',
            taken_date: ''

        });

        setFileImg("");

    }
    const setCoverEquipment = async () => {
        try {
            let response = await checkIsCover(cookies.token, vesselEquipmentPhotoId, vesselEquipmentId);
            if (response === 0) {
                alert(`${t("vessel_equipment_photo.alert_set_cover")}`);
                window.location.reload();
                loadPhoto();
            }
        } catch (exception) {
            console.loge(exception);
        }
    }
    // const removeVesselPhotoEquipment = async () => {
    //     let response = await deleteVesselEquipmentPhoto(cookies.token, removeId);
    //     if (response.error_code === 0) {
    //         // window.location.reload();
    //         loadPhoto();
    //         initVesselEquipment();
    //     }
    // }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    return (
        <>
            <div className="mainPartPartCatalogNew">
                <Sidebar companyName={searchParams.get("customerName")} smsSerialNumber={searchParams.get("smsSerialnumber")} vesselId={vesselId} vesselName={searchParams.get("vesselName")} customerName={cookies.userName} />
                <Container fluid className="mainContainerPartCatalogNew">
                    <div className="childMainContainerPartCatalogNew">
                        <ContainerBox
                            titleCaption={` ${searchParams.get("vesselName")} ${searchParams.get("vesselEquipmentName")} Part Catalog`}
                            useActionContainer={cookies.logingAs === 'Customer' && cookies.extCustomerId !== "null" ? false : true}
                            actionContainerChild={
                                <div>
                                    {/* <Button disabled={!updatedButton} variant="secondary" style={{

                            }} onClick={() => { setShowDocumentUploadModal(true) }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("vessel_equipment_document.add_button")}</div>
                                </div>
                            </Button> */}
                                </div>
                            }
                            childContent={
                                <>
                                    <div className="master-table-inner-container">
                                        <h4 style={{ color: 'black' }}>{t("vessel_equipment_document.text_title_pictures")}</h4>
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            padding: 5,
                                            width: "100%",
                                            flexWrap: "nowrap",
                                            border: '2px black solid',
                                            borderRadius: '5px'
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                                width: "100%",
                                                flexWrap: "nowrap",
                                                paddingRight: 5,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    paddingBottom: 5,
                                                    width: "100%",
                                                    flexWrap: "nowrap",
                                                }}>
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                    </div>

                                                    <br /><br />
                                                    <Col md={12}>
                                                        <Row>
                                                            {
                                                                listPhoto.map((photo, index) => {
                                                                    return (

                                                                        <Col md={3}>
                                                                            <div key={index} style={{
                                                                                display: "flex",
                                                                                flex: 1,
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                flexWrap: "nowrap",
                                                                                flexDirection: "column",
                                                                                padding: 10,
                                                                                borderWidth: 1,
                                                                                margin: 5,
                                                                                borderStyle: "solid",
                                                                                borderColor: "#2f71bd",
                                                                                borderRadius: 5,
                                                                                height: 400,

                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flex: 5,
                                                                                    alignContent: "flex-start",
                                                                                    alignItems: "flex-start",
                                                                                    justifyContent: "center",
                                                                                    alignSelf: "center",
                                                                                    flexWrap: "wrap",

                                                                                }}>
                                                                                    <img src={photo.base64_img} onClick={() => {
                                                                                        handleClick(photo, index)
                                                                                    }} style={{ maxWidth: "100%", maxHeight: 250, objectFit: "contain" }} />
                                                                                </div>
                                                                                <div style={{
                                                                                    flex: 1,
                                                                                    alignContent: "flex-end",
                                                                                    alignItems: "flex-end",
                                                                                    justifyContent: "flex-end",
                                                                                    alignSelf: "flex-end",
                                                                                    flexWrap: "nowrap",
                                                                                    flexDirection: "row",
                                                                                    width: "100%",
                                                                                }} >
                                                                                    <div style={{
                                                                                        flex: 1,
                                                                                        flexDirection: "column",
                                                                                        alignContent: "flex-end",
                                                                                        alignItems: "flex-end",
                                                                                        justifyContent: "flex-end",
                                                                                        alignSelf: "flex-end",
                                                                                        textAlign: "center"
                                                                                    }}><p>{!photo.title ? "" : photo.title}</p></div>
                                                                                    <div style={{
                                                                                        flex: 1,
                                                                                        flexDirection: "column",
                                                                                        alignContent: "flex-end",
                                                                                        alignItems: "flex-end",
                                                                                        justifyContent: "flex-end",
                                                                                        alignSelf: "flex-end",
                                                                                        textAlign: "center"
                                                                                    }}><p>{!photo.taken_date ? "" : photo.taken_date}</p>
                                                                                        <div style={{
                                                                                            flex: 1,
                                                                                            flexDirection: "column",
                                                                                            alignContent: "flex-end",
                                                                                            alignItems: "flex-end",
                                                                                            justifyContent: "flex-end",
                                                                                            alignSelf: "flex-end",
                                                                                            textAlign: "center"
                                                                                        }}>
                                                                                            {/* <Button disabled={cookies.logingAs=== 'Customer' && cookies.extCustomerId !== "null" ? true : !deletedButton} style={{}} onClick={() => {
                                                                                    if (window.confirm(`${t("vessel_equipment_photo.delete_confirmation")}?`)) {
                                                                                        setRemoveId(photo.photo_id)

                                                                                    }
                                                                                }} variant="danger"><Trash /></Button> */}

                                                                                        </div>

                                                                                    </div>

                                                                                    {/* {
                                                                            photo.is_cover === true ?

                                                                                <Form.Check onClick={() => {
                                                                                    setVesselEquipmentPhotoId(photo.photo_id);
                                                                                }} type="checkbox" defaultChecked={true} label={`${t("vessel_equipment_photo.cover_label")}`} />
                                                                                :
                                                                                <>
                                                                                    <input onClick={() => {
                                                                                        setVesselEquipmentPhotoId(photo.photo_id);
                                                                                    }} type="checkbox" />
                                                                                    <label>&nbsp; {t("vessel_equipment_photo.cover_label")}</label>
                                                                                </>
                                                                        } */}

                                                                                </div>

                                                                            </div>

                                                                        </Col>

                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    </Col><br /><br />
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div className="master-table-inner-container">
                                        <h4 style={{ color: 'black' }}>{t("vessel_equipment_document.text_title_documents")}</h4>
                                        <div className="table-container">
                                            <div className="table-header">
                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                <div style={{ flex: 4 }} className="table-header-content">{t("vessel_equipment_document.table_name1")}</div>
                                                <div style={{ flex: 3 }} className="table-header-content">{t("vessel_equipment_document.table_name2")}</div>
                                                <div style={{ flex: 3 }} className="table-header-content">{t("vessel_equipment_document.table_name3")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("vessel_equipment_document.table_name4")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("vessel_equipment_document.table_name5")}</div>
                                                {/* <div style={{ flex: 2 }} className="table-header-content">{t("vessel_equipment_document.table_name6")}</div> */}
                                            </div>
                                            {
                                                listDocument.map((document, index) => {
                                                    return (<div key={index} className="table-body">
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                                        <div style={{ flex: 4 }} className="table-body-content"><p>{document.document_name}</p></div>
                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{document.reference_number}</p></div>
                                                        <div style={{ flex: 3 }} className="table-body-content"><p>{moment(document.created_date).format("DD/MM/yyyy")}</p></div>
                                                        <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                            <div style={{ padding: 5 }}>
                                                                <Button variant="primary" onClick={async () => {
                                                                    setLoading(true);
                                                                    let docTobeViewed = document;
                                                                    let resp = await getDocumentBase64(cookies.token, document.document_id, document.document_source);
                                                                    docTobeViewed.document_base64 = resp;
                                                                    setDocumentToBeViewed(docTobeViewed);
                                                                    
                                                                }}><EyeFill />
                                                                </Button>
                                                            </div></div>

                                                        <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                            <div style={{ padding: 5 }}>
                                                                <Button variant="primary" onClick={() => {
                                                                    setSelectedDocumentDownloadIdEquipment(document.document_id);
                                                                }}><Download />
                                                                </Button>
                                                            </div></div>
                                                        {/* <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                        <div style={{ padding: 5 }}> */}
                                                        {/* <Button variant="danger" disabled={document.type === 'sparepart' ||  (cookies.extCustomerId !== "null" && cookies.logingAs === 'Customer') ? true : !deletedButton} onClick={() => {
                                                        if (window.confirm(`${t("vessel_equipment_document.delete_confirmation")}  ${document.document_name}?`)) {
                                                            let documentId = document.id
                                                            setSelectedDeleteIdEquipment(documentId);
                                                        }
                                                    }}><Trash />
                                                    </Button> */}
                                                        {/* </div></div> */}
                                                    </div>)
                                                }
                                                )
                                            }
                                            {
                                                listDocumentMaster.map((master, index) => {
                                                    return (
                                                        <div key={index} className="table-body">
                                                            <div style={{ flex: 1 }} className="table-body-content"><p>{listDocument.length + index + 1}</p></div>
                                                            <div style={{ flex: 4 }} className="table-body-content"><p>{master.document_name}</p></div>
                                                            <div style={{ flex: 3 }} className="table-body-content"><p>{master.reference_number}</p></div>
                                                            <div style={{ flex: 3 }} className="table-body-content"><p>{moment(master.created_date).format("DD/MM/yyyy")}</p></div>
                                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                <div style={{ padding: 5 }}>
                                                                    <Button variant="primary" onClick={() => {
                                                                        setSelectedDocumentIdMaster(master.id);
                                                                        setShowDocumentDetailModal(true);
                                                                    }}><EyeFill />
                                                                    </Button>
                                                                </div></div>

                                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                                <div style={{ padding: 5 }}>
                                                                    <Button variant="primary" onClick={() => {
                                                                        setSelectedDownloadIdMaster(master.document_id);
                                                                    }}><Download />
                                                                    </Button>
                                                                </div></div>
                                                            {/* <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                            <div style={{ padding: 5 }}> */}
                                                            {/* <Button disabled variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("vessel_equipment_document.delete_confirmation")}  ${document.document_name}?`)) {
                                                            setDocumentId(master.id);
                                                        }
                                                    }}><Trash />
                                                    </Button> */}
                                                            {/* </div></div> */}
                                                        </div>
                                                    )
                                                }
                                                )
                                            }

                                        </div>
                                    </div>
                                </>
                            }
                        />
                        {
                            <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                                // if (showDocumentDetailModal){
                                //     setShowDocumentDetailModal(false);
                                // }
                            }}>
                                {documentToBeViewed && <ContainerBox containerPos="inner" titleCaption={documentToBeViewed.document_name}
                                    useActionContainer={true}
                                    actionContainerChild={
                                        <div>
                                            <Button variant="secondary" style={{

                                            }} onClick={() => {
                                                setShowDocumentDetailModal(false);
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "row",
                                                    alignSelf: "center",
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        alignSelf: "center",
                                                        flex: 1,
                                                    }}><XSquareFill size={32} /></div>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 8,
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        alignSelf: "center",
                                                        paddingLeft: 10,
                                                        fontWeight: "bold",
                                                        fontSize: 18,
                                                    }}>{t("vessel_equipment_document.button_closed")}</div>
                                                </div>
                                            </Button>
                                        </div>
                                    }
                                    childContent={
                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column"
                                        }}>

                                            <div>

                                                <Document
                                                    file={documentToBeViewed.document_base64}
                                                    onLoadSuccess={onDocumentLoadSuccess}
                                                    loading={() => {
                                                        
                                                    }}
                                                ><div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "row",
                                                            justifyContent: "center",
                                                            alignContent: "center",
                                                            overflow: "scroll",
                                                        }}>
                                                            <Page scale={zoomFactor} pageNumber={pageNumber} />
                                                        </div>
                                                    </div>
                                                </Document>
                                            </div>
                                            {isDocumentLoaded &&

                                                <div>
                                                    <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "row",
                                                            justifyContent: "center",
                                                            alignContent: "center",
                                                        }}>
                                                            <p>
                                                                {t("vessel_equipment_document.page_text")} {pageNumber} {t("vessel_equipment_document.from_text")} {numPages}
                                                            </p>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "row",
                                                            justifyContent: "center",
                                                            alignContent: "center",
                                                            flexWrap: "wrap",
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                alignContent: "center",
                                                                padding: 5,
                                                            }}>
                                                                <Button
                                                                    variant="primary"
                                                                    type="button"
                                                                    disabled={zoomFactor <= 0.2}
                                                                    onClick={() => {
                                                                        setZoomFactor(zoomFactor * 0.8);
                                                                    }}
                                                                >

                                                                    <ZoomOut size={28} />
                                                                </Button>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                alignContent: "center",
                                                                padding: 5
                                                            }}>
                                                                <Button
                                                                    variant="primary"
                                                                    type="button"
                                                                    disabled={false}
                                                                    onClick={() => {
                                                                        setZoomFactor(0.5);
                                                                    }}
                                                                >
                                                                    <AspectRatioFill size={28} />
                                                                </Button>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                alignContent: "center",
                                                                padding: 5,
                                                                flexWrap: "wrap",
                                                            }}>
                                                                <Button
                                                                    variant="primary"
                                                                    type="button"
                                                                    disabled={zoomFactor >= 10}
                                                                    onClick={() => {
                                                                        setZoomFactor(zoomFactor * 2);
                                                                    }}
                                                                >
                                                                    <ZoomIn size={28} />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            flexDirection: "row",
                                                            justifyContent: "center",
                                                            alignContent: "center",
                                                            padding: 5
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                alignContent: "center",
                                                                padding: 5
                                                            }}>
                                                                <Button
                                                                    variant="primary"
                                                                    type="button"
                                                                    disabled={pageNumber <= 1}
                                                                    onClick={previousPage}
                                                                >

                                                                    <ChevronDoubleLeft size={28} />
                                                                </Button>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                alignContent: "center",
                                                                padding: 5
                                                            }}>
                                                                <Button
                                                                    variant="primary"
                                                                    type="button"
                                                                    disabled={pageNumber >= numPages}
                                                                    onClick={nextPage}
                                                                >
                                                                    <ChevronDoubleRight size={28} />
                                                                </Button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    } />
                                }

                            </Modal>
                        }
                    </div>
                </Container>
                {clickedImg && (
                    <Modals
                        clickedImg={clickedImg}
                        handelRotationRight={handelRotationRight}
                        setClickedImg={setClickedImg}
                        handelRotationLeft={handelRotationLeft}
                        image={listPhoto}
                        description={description}
                        setDescription={setDescription}
                    />
                )}
            </div>
            <Loading loading={loading} />
        </>
    )
}