// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainCMDashboard {
    display: flex;
}

.mainContainerCMDashboard {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.innerChildMainContainerCMDashboard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffffee;
    height: 100vh;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/CustomerPages/MaintenancePlan/CorrectiveMaintenancePlan/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,SAAO;IACP,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;AACjB","sourcesContent":[".mainCMDashboard {\n    display: flex;\n}\n\n.mainContainerCMDashboard {\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    padding: 10px;\n}\n\n.innerChildMainContainerCMDashboard {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    background-color: #ffffffee;\n    height: 100vh;\n    border-radius: 10px;\n    -webkit-border-radius: 10px;\n    -moz-border-radius: 10px;\n    -ms-border-radius: 10px;\n    -o-border-radius: 10px;\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
