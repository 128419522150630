// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../Assets/Images/Fonts/Inter-VariableFont_opsz,wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar-container{
    filter: drop-shadow(0px 0px 14px #000000);
    display: flex; 
    margin: 0;
    width: 100%;
    min-width: 100%;
    height: 8vh;
    flex-direction: row;
    background-color:#1E3A8A;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.topbar-container-left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    /* border: 1px black solid; */
}

.topbar-container-right{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 25%;
    /* border: 1px black solid; */
}

.topbar-container-padding{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.h3titleFMS{
    color: #FFFFFF;
    font-family: "Inter";
    font-size: medium;
}

.ptitleFMS{
    color: #FFFFFF;
    font-family: "Inter";
    font-size: medium;
}

@font-face {
    font-family: "Inter";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  }`, "",{"version":3,"sources":["webpack://./src/Components/v2/FMSTopBar/index.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,aAAa;IACb,SAAS;IACT,WAAW;IACX,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,wBAAwB;IACxB,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,UAAU;IACV,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,UAAU;IACV,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,4CAA0E;EAC5E","sourcesContent":[".topbar-container{\n    filter: drop-shadow(0px 0px 14px #000000);\n    display: flex; \n    margin: 0;\n    width: 100%;\n    min-width: 100%;\n    height: 8vh;\n    flex-direction: row;\n    background-color:#1E3A8A;\n    justify-content: space-between;\n    align-items: center;\n    padding: 5px;\n}\n\n.topbar-container-left{\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    width: 30%;\n    /* border: 1px black solid; */\n}\n\n.topbar-container-right{\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    width: 25%;\n    /* border: 1px black solid; */\n}\n\n.topbar-container-padding{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 5px;\n}\n\n.h3titleFMS{\n    color: #FFFFFF;\n    font-family: \"Inter\";\n    font-size: medium;\n}\n\n.ptitleFMS{\n    color: #FFFFFF;\n    font-family: \"Inter\";\n    font-size: medium;\n}\n\n@font-face {\n    font-family: \"Inter\";\n    src: url(\"../../../Assets/Images/Fonts/Inter-VariableFont_opsz\\,wght.ttf\");\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
