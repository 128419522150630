import './index.css';
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { isTokenValid, getPeriod } from "../../../Helpers/ApplicationHelpers";
import { Container, Button, Form, Modal } from 'react-bootstrap';
import {EyeFill} from "react-bootstrap-icons";
import Loading from "../../../Components/Loading";
import ContainerBox from "../../../Components/ContainerBox";
import Sidebar from "../../../Components/NewSideBarForCustomerPage/NewSideBar";
import { Trans, useTranslation } from "react-i18next";
import DropDownForm from "../../../Components/DropDownFormForMaintenancePlan";
import DropDownFormOld from "../../../Components/DropDownForm";

export default function CustomerMaintenancePlanDashboard() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [listPeriod, setListPeriod] = useState([]);

    const [durationOption, setDurationOption] = useState([
        {
            
            label: "Early",
            value: "status_early"
        },
        {
            
            label: "Pending",
            value: "status_pending"
        },
        {
            
            label: "Done",
            value: "status_done"
        },
        {
            
            label: "Not Performed",
            value: "status_not_performed"
        }
    ]);


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                setSmsSerialNumber(searchParams.get("smsSerialnumber").replace(/'/g, ""));
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (smsSerialNumber) {
            setVesselId(searchParams.get("vesselId").replace(/'/g, ""));
            setVesselName(searchParams.get("vesselName").replace(/'/g, ""));
            setCustomerName(searchParams.get("customerName").replace(/'/g, ""));
        }
    }, [smsSerialNumber])

    return(
        <>
            <div className="mainMPDashboard">
                <Sidebar companyName={customerName} smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName} customerName={cookies.userName} />
                <Container fluid className="mainContainerMPDashboard">
                    <div className="innerChildMainContainerMPDashboard">
                        <ContainerBox
                            titleCaption={'MAINTENANCE DUE'}
                            childContent={
                                <>
                                    <div className="master-table-inner-container">
                                        <div className="table-container">
                                            <div className="table-header">
                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_next_maintenance_date")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_equipment")}</div>
                                                <div style={{ flex: 4 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_name")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_count")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_period_long")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_running_hour")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_warning_at")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_description")}</div>
                                            </div>
                                            {
                                                <div className="table-body">
                                                    <div style={{ flex: 1 }} className="table-body-content">1</div>
                                                    <div style={{ flex: 2 }} className="table-body-content">{''}</div>
                                                    <div style={{ flex: 2 }} className="table-body-content">{''}</div>
                                                    <div style={{ flex: 4 }} className="table-body-content">{''}</div>
                                                    <div style={{ flex: 1 }} className="table-body-content">{t("")}</div>
                                                    <div style={{ flex: 1 }} className="table-body-content">{t("")}</div>
                                                    <div style={{ flex: 1 }} className="table-body-content">{t("")}</div>
                                                    <div style={{ flex: 1 }} className="table-body-content">{t("")}</div>
                                                    <div style={{ flex: 2 }} className="table-body-content">{t("")}</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        />
                        <div style={{ width: '100%', height: '10px' }}></div>
                        <ContainerBox
                            titleCaption={'MAINTENANCE HISTORY'}
                            childContent={
                                <>
                                    <div className='containerForDropDownMenu'>
                                        <Form.Group style={{ margin: '10px 15px', width: '20%' }}>
                                            <DropDownFormOld
                                                placeholder={"Select Maintenance Status"}
                                                listOption={durationOption}
                                                valueKey={"value"}
                                                labelKey={"label"}
                                                setValueFunction={(e) => {
                                                    console.log(e);
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="master-table-inner-container">
                                        <div className="table-container">
                                            <div className="table-header">
                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_next_maintenance_date")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_equipment")}</div>
                                                <div style={{ flex: 4 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_title_table")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_period")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_running_hour")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_warning_at")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_status")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_description")}</div>
                                            </div>
                                            <div className="table-body">
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{1}</p></div>
                                                <div style={{ flex: 2 }} className="table-body-content"><p>{""}</p></div>
                                                <div style={{ flex: 2 }} className="table-body-content"><p>{""}</p></div>
                                                <div style={{ flex: 4 }} className="table-body-content"><p>{""}</p></div>
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{""}</p></div>
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{""}</p></div>
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{""}</p></div>
                                                <div style={{ flex: 1 }} className="table-body-content">
                                                    <Form.Group>
                                                        <DropDownForm
                                                            placeholder={"Select ..."}
                                                            listOption={durationOption}
                                                            valueKey={"value"}
                                                            labelKey={"label"}
                                                            setValueFunction={(e) => { }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{""}</p></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </>
                            }
                        />

                        <div style={{ width: '100%', height: '10px' }}></div>
                        <ContainerBox
                            titleCaption={'MAINTENANCE SUMMARY'}
                            childContent={
                                <>
                                    <div className="master-table-inner-container">
                                        <div className="table-container">
                                            <div className="table-header">
                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_next_maintenance_date")}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_equipment")}</div>
                                                <div style={{ flex: 4 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_title_table")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_period")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_running_hour")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_warning_at")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_status")}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_description")}</div>
                                            </div>
                                            <div className="table-body">
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{1}</p></div>
                                                <div style={{ flex: 2 }} className="table-body-content"><p>{""}</p></div>
                                                <div style={{ flex: 2 }} className="table-body-content"><p>{""}</p></div>
                                                <div style={{ flex: 4 }} className="table-body-content"><p>{""}</p></div>
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{""}</p></div>
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{""}</p></div>
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{""}</p></div>
                                                <div style={{ flex: 1 }} className="table-body-content">
                                                    <Form.Group>
                                                        <DropDownForm
                                                            placeholder={"Select ..."}
                                                            listOption={durationOption}
                                                            valueKey={"value"}
                                                            labelKey={"label"}
                                                            setValueFunction={(e) => { }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div style={{ flex: 1 }} className="table-body-content"><p>{""}</p></div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </>
                            }
                        />
                    </div>
                </Container>
            </div>
        </>
    )
}