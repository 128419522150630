import "../App.css";
import {Form, option} from "react-bootstrap";

export default function DropDownFormForMaintenancePlan({
    caption,
    placeholder,
    value,
    setValueFunction,
    listOption = [],
    valueKey,
    labelKey,
    backgroundColor,
    disabledForm = false
}) {
    return (
        <div style={{ paddingBottom: 5 }}>
            <div style={{ fontWeight: "bold", paddingBottom: 5}}>{caption}</div>
            <Form.Select value={value}
                onChange={setValueFunction} disabled={disabledForm}>
                <option>{placeholder}</option>
                {
                    listOption.map((i, j) => {
                        return(
                            <option className="dropdown-option" key={j} value={i[valueKey]}>{i[labelKey]}</option>
                        )
                    })
                }
            </Form.Select>
        </div>
    )
};