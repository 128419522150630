import './index.css';
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation, useSearchParams, Link } from "react-router-dom";
import { isTokenValid, getPeriod, getAllDueJob, findAllDueJobInternalStatus } from "../../../Helpers/ApplicationHelpers";
import { countAllDueJob, findAllDueJob } from "../../../Helpers/CustomerMaintenanceHelpers";
import { Container, Button, Form, Modal } from 'react-bootstrap';
import { EyeFill, PencilFill, Search, Trash } from "react-bootstrap-icons";
import Loading from "../../../Components/Loading";
import ContainerBox from "../../../Components/ContainerBox";
import Sidebar from "../../../Components/NewSideBarForCustomerPage/NewSideBar";
import { Trans, useTranslation } from "react-i18next";
import DropDownForm from "../../../Components/DropDownFormForMaintenancePlan";
import DropDownFormOld from "../../../Components/DropDownForm";
import moment from "moment";
import Paginations from '../../../Components/Pagination';

export default function DueJobList() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [cookies, setCookie] = useCookies(["token"]);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [listDueJob, setListDueJob] = useState([]);
    const [isSearched, setIsSearched] = useState(0);
    const [detailedSearch, setDetailedSearch] = useState(false);
    const [isDetailSearched, setIsDetailSearched] = useState(false);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    // const [orderBySelection, setOrderBySelection] = useState(["Inventory Name", "Inventory Type", "Divisi", "Unit", "Reference Price", "Brand", "Model", "Quantity"]);
    const [descending, setDescending] = useState(false);
    const [checkOnDetailSearch, setCheckOnDetailSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [dueJobListCount, setDueJobListCount] = useState(0);
    const [listDueJobStatus, setListDueJobStatus] = useState([]);

    const [orderBy, setOrderBy] = useState({
        label: `Title`,
        value: "title"
    });

    const orderByList = [
        {
            label: `Title`,
            value: "title"
        },
    ]

    const [durationOption, setDurationOption] = useState([
        {

            label: "Early",
            value: "status_early"
        },
        {

            label: "Pending",
            value: "status_pending"
        },
        {

            label: "Done",
            value: "status_done"
        },
        {

            label: "Not Performed",
            value: "status_not_performed"
        }
    ]);

    const [dueJobDateSearchedStart, setDueJobDateSearchedStart] = useState({
        isSearched: false,
        label: "dueJobDateStart",
        value: ""
    });

    const [dueJobDateSearchedEnd, setDueJobDateSearchedEnd] = useState({
        isSearched: false,
        label: "dueJobDateEnd",
        value: ""
    });


    const [deadlineDateSearchedStart, setDeadlineDateSearchedStart] = useState({
        isSearched: false,
        label: "deadlineDateStart",
        value: ""
    });

    const [deadlineDateSearchedEnd, setDeadlineDateSearchedEnd] = useState({
        isSearched: false,
        label: "deadlineDateEnd",
        value: ""
    });


    const [vesselNameSearched, setVesselNameSearched] = useState({
        isSearched: false,
        label: "vesselName",
        value: ""
    });


    const [statusSearched, setStatusSearched] = useState({
        isSearched: false,
        label: "status",
        value: ""
    });


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                console.log(location);
                setSmsSerialNumber(location.state.smsSerialNumber);
                loadDueJobStatus();
                loadAllDueJob();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if(listDueJob){
            setLoading(false);
        }
    }, [listDueJob])

    useEffect(() => {
        setLoading(true);
        loadAllDueJob();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadAllDueJob();
        }
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadAllDueJob();
        }
    }, [orderBy]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadAllDueJob();
        }
    }, [descending]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadAllDueJob();
    }, [resetSearch]);

    useEffect(() => {
        if (smsSerialNumber) {
            setVesselId(location.state.vesselId);
            setVesselName(location.state.vesselName);
            setCustomerName(location.state.customerName);
        }
    }, [smsSerialNumber]);

    const loadAllDueJob = async () => {
        try {
            let detailedSearchValue = [];
            detailedSearchValue.push(dueJobDateSearchedStart, dueJobDateSearchedEnd, deadlineDateSearchedStart, deadlineDateSearchedEnd, vesselNameSearched, statusSearched);

            let newDetail = detailedSearchValue.filter(p => p.isSearched === true);
            console.log(newDetail);
            let isDetailSearchedTmp = isDetailSearched
            if (newDetail.length > 0) {
                isDetailSearchedTmp = true
                setIsDetailSearched(true);
            } else {
                isDetailSearchedTmp = false
                setIsDetailSearched(false);
            }

            console.log(cookies.token);
            console.log(cookies.extCustomerId,);
            console.log(searchQuery);
            console.log(isDetailSearchedTmp);
            console.log(detailedSearchValue);

            let listCount = await countAllDueJob(cookies.token, cookies.extCustomerId, searchQuery, isDetailSearchedTmp, detailedSearchValue);
            console.log(listCount);
            setDueJobListCount(listCount);
            let totalPage = listCount / itemPerPage;
            setTotalPage(totalPage);
            let tmpSearchQuery = undefined;
            if (searchQuery) {
                tmpSearchQuery = searchQuery;
            }


            let result = await findAllDueJob(cookies.token, cookies.extCustomerId, searchQuery, isDetailSearchedTmp, detailedSearchValue, page, itemPerPage, orderBy.value, descending);
            if (result) {
                console.log(result);
                setListDueJob(result);
                setResetSearch(0);
                setIsSearched(0);
            }
            
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadDueJobStatus = async () => {
        try {
            let result = await findAllDueJobInternalStatus(cookies.token);
            let selectStatus = result.map((a) => {
                return {
                    label: a.status_name,
                    value: a.id,
                };
            });
            setListDueJobStatus(selectStatus);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    return (
        <>
            <div className="mainMPDashboard">
                <Sidebar companyName={customerName} smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName} customerName={cookies.userName} />
                <Container fluid className="mainContainerMPDashboard">
                    <div className="innerChildMainContainerMPDashboard">
                        <ContainerBox
                            titleCaption={`${vesselName} MAINTENANCE DUE`}
                            childContent={
                                <>
                                    <div style={{ display: "flex", justifyContent: 'flex-end', marginTop: '10px', marginBottom: '0px', paddingRight: '20px', width: '100%' }}>
                                        <Button variant="primary" onClick={() => {
                                            setDetailedSearch(true);
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "row",
                                                alignSelf: "center",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    alignSelf: "center",
                                                    flex: 1,
                                                }}><Search size={20} /></div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 8,
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    alignSelf: "center",
                                                    paddingLeft: 5,
                                                    fontWeight: "bold",
                                                    fontSize: 18,
                                                }}> {"Detail Search"}</div>
                                            </div>
                                        </Button>
                                    </div>
                                    <div className="master-table-inner-container">
                                        <div className="table-container">
                                            <div className="table-header">
                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{"Due Job Date"}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{"Deadline"}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{"Vessel Name"}</div>
                                                <div style={{ flex: 3 }} className="table-header-content">{"Equipment Name"}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{"Equipment S/N"}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{"Equipment Pos."}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{"Due Job Status"}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{"At Running Hour"}</div>
                                                <div style={{ flex: 1 }} className="table-header-content">{"Edit"}</div>
                                                {/* <div style={{ flex: 1 }} className="table-header-content">{"Delete"}</div> */}
                                            </div>
                                            {
                                                listDueJob && listDueJob.map((dueJob, idx) => {
                                                    return (
                                                        <div key={idx} className="table-body">
                                                            <div style={{ flex: 1 }} className="table-body-content">{idx + 1}</div>
                                                            <div style={{ flex: 2 }} className="table-body-content">{moment(dueJob.due_job_start_date).format("YYYY-MM-DD")}</div>
                                                            <div style={{ flex: 2 }} className="table-body-content">{moment(dueJob.due_job_deadline).format("YYYY-MM-DD")}</div>
                                                            <div style={{ flex: 2 }} className="table-body-content">{dueJob.vessel_name}</div>
                                                            <div style={{ flex: 3 }} className="table-body-content">{dueJob.title}</div>
                                                            <div style={{ flex: 2 }} className="table-body-content">{dueJob.equipment_sn}</div>
                                                            <div style={{ flex: 1 }} className="table-body-content">{dueJob.equipment_position}</div>
                                                            <div style={{ flex: 1 }} className="table-body-content">{dueJob.status_name}</div>
                                                            <div style={{ flex: 1 }} className="table-body-content">{dueJob.due_job_running_hour}</div>
                                                            <div style={{ flex: 1 }} className="table-body-content"><Button variant="primary" onClick={() => {
                                                                navigate("/Customer/DueJob/Detail", { state: { dueJobId: dueJob.due_job_id } });
                                                            }}><PencilFill />
                                                            </Button></div>
                                                            {/* <div style={{ flex: 1 }} className="table-body-content">
                                                                <Button disabled={false} variant="danger" onClick={() => {
                                                                    let dueJobId = dueJob.due_job_id;
                                                                    if (window.confirm(`Are you sure to delete this due job? ${dueJob.title}?`))
                                                                        setRemoveId(dueJobId);
                                                                }}><Trash />
                                                                </Button></div> */}
                                                        </div>
                                                    )
                                                })

                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        />
                        <div style={{ width: '90%', height: '100px', padding: '5px' }}>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                        </div>
                    </div>
                </Container>
            </div>

            <Modal show={detailedSearch}
                size={"lg"}
                onHide={() => {
                    setDetailedSearch(false);
                }}>
                <div className="details m-2" >
                    <div className="detailscontent">
                        <h3>Maintenance Due Detail Search</h3>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: 10
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            padding: 5
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1
                            }}>
                                <Form.Check checked={dueJobDateSearchedStart.isSearched} onClick={(e) => {
                                    if (e.target.checked) {
                                        setDueJobDateSearchedStart({ ...dueJobDateSearchedStart, isSearched: true })
                                        setDueJobDateSearchedEnd({ ...dueJobDateSearchedEnd, isSearched: true })
                                    } else {
                                        setDueJobDateSearchedStart({ ...dueJobDateSearchedStart, isSearched: false, value: "" })
                                        setDueJobDateSearchedEnd({ ...dueJobDateSearchedEnd, isSearched: false, value: "" })
                                    }
                                }}></Form.Check>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 3,
                                paddingRight: 5
                            }}>
                                <Form.Control disabled value={'Due Job Date'}></Form.Control>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 3,
                                paddingLeft: 5
                            }}>
                                <Form.Control
                                    disabled={!dueJobDateSearchedStart.isSearched}
                                    type="datetime-local"
                                    onChange={(e) => {
                                        setDueJobDateSearchedStart({ ...dueJobDateSearchedStart, value: moment(e.target.value).format("yyyy-MM-DD HH:mm:ss") });
                                    }}
                                    value={dueJobDateSearchedStart.value}
                                />
                            </div>

                            <div style={{
                                display: "flex",
                                flex: 3,
                                paddingLeft: 5
                            }}>
                                <Form.Control
                                    disabled={!dueJobDateSearchedStart.isSearched}
                                    type="datetime-local"
                                    onChange={(e) => {
                                        setDueJobDateSearchedEnd({ ...dueJobDateSearchedEnd, value: moment(e.target.value).format("yyyy-MM-DD HH:mm:ss") });
                                    }}
                                    value={dueJobDateSearchedEnd.value}
                                />
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            padding: 5
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1
                            }}>
                                <Form.Check checked={deadlineDateSearchedStart.isSearched} onClick={(e) => {
                                    if (e.target.checked) {
                                        setDeadlineDateSearchedStart({ ...deadlineDateSearchedStart, isSearched: true })
                                        setDeadlineDateSearchedEnd({ ...deadlineDateSearchedEnd, isSearched: true })
                                    } else {
                                        setDeadlineDateSearchedStart({ ...deadlineDateSearchedStart, isSearched: false, value: "" })
                                        setDeadlineDateSearchedEnd({ ...deadlineDateSearchedEnd, isSearched: false, value: "" })
                                    }
                                }}></Form.Check>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 3,
                                paddingRight: 5
                            }}>
                                <Form.Control disabled value={'Deadline Date'}></Form.Control>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 3,
                                paddingLeft: 5
                            }}>
                                <Form.Control
                                    disabled={!deadlineDateSearchedStart.isSearched}
                                    type="datetime-local"
                                    onChange={(e) => {
                                        setDeadlineDateSearchedStart({ ...deadlineDateSearchedStart, value: moment(e.target.value).format("yyyy-MM-DD HH:mm:ss") });
                                    }}
                                    value={deadlineDateSearchedStart.value}
                                />
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 3,
                                paddingLeft: 5
                            }}>
                                <Form.Control
                                    disabled={!deadlineDateSearchedStart.isSearched}
                                    type="datetime-local"
                                    onChange={(e) => {
                                        setDeadlineDateSearchedEnd({ ...deadlineDateSearchedEnd, value: moment(e.target.value).format("yyyy-MM-DD HH:mm:ss") });
                                    }}
                                    value={deadlineDateSearchedEnd.value}
                                />
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            padding: 5
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1
                            }}>
                                <Form.Check checked={statusSearched.isSearched} onClick={(e) => {
                                    if (e.target.checked) {
                                        setStatusSearched({ ...statusSearched, isSearched: true })
                                    } else {
                                        setStatusSearched({ ...statusSearched, isSearched: false, value: "" })
                                    }
                                }}></Form.Check>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 3,
                                paddingRight: 5,
                                alignContent: 'flex-start',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start'
                            }}>
                                <Form.Control disabled value={'Status'}></Form.Control>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 8,
                                paddingLeft: 5
                            }}>
                                <Form.Select
                                    disabled={!statusSearched.isSearched}
                                    value={statusSearched.value}
                                    onChange={(e) => {
                                        setStatusSearched({ ...statusSearched, value: e.target.value });
                                    }}
                                >
                                    <option value="">Select Status ...</option>
                                    {listDueJobStatus.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: "column",
                            padding: 10,
                            width: "100%"
                        }}>
                            <Button onClick={() => {
                                loadAllDueJob();
                                setPage(0);
                            }}><Search size={30} /></Button>
                        </div>

                    </div>


                </div>

            </Modal>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    )
}