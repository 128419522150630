import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import {
    isTokenValid,
    getHistorisEnginePerformanceFromMaterializedView,
    getHistorisFuelConsumptionForVesselTracking,
} from "../../../Helpers/ApplicationHelpers";
import { useSearchParams, useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading";
import moment from "moment";
import WMI from "../../../Assets/Images/CustomerDashboard/WMI_logo.png";
import DynamicLineChart from "../../../Components/DynamicLineChartForFuelConsumption"
import DynamicLineChartCurrent from "../../../Components/DynamicLineChartForCurrentFuelUsage"
import DynamicLineChartDoubleAxis from "../../../Components/DynamicLineChartForFuelConsumptionAvgAndCurrent";

export default function CustomerPrintVesselTrackingPage() {
    const [cookies] = useCookies(["token"]);
    const [searchParams] = useSearchParams();
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [selectStartDate, setSelectStartDate] = useState("");
    const [selectEndDate, setSelectEndDate] = useState("");
    const [totalDistance, setTotalDistance] = useState(0);
    const [totalDuration, setTotalDuration] = useState();
    const [averageSpeed, setAverageSpeed] = useState(0);
    const [averageMEP, setAverageMEP] = useState(0);
    const [averageMES, setAverageMES] = useState(0);
    const [averageTP, setAverageTP] = useState(0);
    const [averageTS, setAverageTS] = useState(0);
    const [loading, setLoading] = useState(false);
    const [enginePerformanceData, setEnginePerformanceData] = useState({ isDataReady: false });
    const [fuelConsumptionData, setFuelConsumptionData] = useState({ isDataReady: false });
    const navigate = useNavigate();

    const a4Style = {
        width: '21cm',
        height: '29.7cm',
        margin: '0 auto',
        padding: '20px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        fontFamily: 'Arial, sans-serif',
        position: 'relative',
        overflow: 'hidden',
    };

    const mainContainer = {
        width: '100%',
        border: '1px black solid',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '10px'
    }

    const titleStyle = {
        fontSize: 'medium',
        fontWeight: 'bold',
        margin: '0',
        fontFamily: 'Arial'
    }

    const paragraphStyle = {
        fontSize: 'smaller',
        fontWeight: 'lighter',
        margin: '0',
        fontFamily: 'Arial'
    }

    const tableContainer = {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start'
    }

    const tableBorder = {
        width: '100%',
        border: '1px black solid'
    }

    const tableContent = {
        fontSize: 'smaller',
        fontWeight: 'lighter',
        fontFamily: 'Arial',
        padding: '2px',
        border: '1px black solid',
        color: 'black'
    }

    const enginePerformanceContainer = {
        width: '100%',
        margin: '10px 0px'
    }

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated) navigate("/Customer/Login");
            else {
                setVesselId(searchParams.get('vesselId'));
                setSmsSerialNumber(searchParams.get('smsSerialNumber'));
                setSelectStartDate(searchParams.get('selectStartDate').replace(/_/g, " "));
                setSelectEndDate(searchParams.get('selectEndDate').replace(/_/g, " "));
                setVesselName(searchParams.get('vesselName'));
                setCustomerName(searchParams.get('customerName'));
                setTotalDistance(searchParams.get('totalDistance'));
                setTotalDuration(searchParams.get('totalDuration'));
                setAverageSpeed(searchParams.get('averageSpeed'));
                setAverageMEP(searchParams.get('averageMEP'));
                setAverageMES(searchParams.get('averageMES'));
                setAverageTP(searchParams.get('averageTP'));
                setAverageTS(searchParams.get('averageTS'));
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (selectStartDate && selectEndDate) {
            loadEnginePerformanceSummary();
        }
    }, [selectStartDate]);

    useEffect(() => {
        if (enginePerformanceData.isDataReady === true) {
            loadFuelConsumptionSummary();
        } else {
            setLoading(true);
        }
    }, [enginePerformanceData]);

    useEffect(() => {
        if (fuelConsumptionData.isDataReady === true) {
            console.log('enginePerformanceData', enginePerformanceData);
            console.log('fuelConsumptionData', fuelConsumptionData);
            setLoading(false);
            window.print();
        } else {
            setLoading(true);
        }
    }, [fuelConsumptionData]);

    const loadEnginePerformanceSummary = async () => {
        try {
            let monthStart = moment(selectStartDate).format("YYYY/MM").replace('/', '');
            let monthEnd = moment(selectEndDate).format("YYYY/MM").replace('/', '');
            let results = await getHistorisEnginePerformanceFromMaterializedView(cookies.token, smsSerialNumber, selectStartDate, selectEndDate, monthEnd, monthStart, vesselId);
            results.isDataReady = true;
            setEnginePerformanceData(results);
        } catch (exception) {
            setLoading(false);
            console.log(exception);
        }
    };

    const loadFuelConsumptionSummary = async () => {
        try {
            let monthStart = moment(selectStartDate).format("YYYY/MM").replace('/', '');
            let monthEnd = moment(selectEndDate).format("YYYY/MM").replace('/', '');
            let results = await getHistorisFuelConsumptionForVesselTracking(cookies.token, smsSerialNumber, selectStartDate, selectEndDate, monthEnd, monthStart, vesselId);
            results.isDataReady = true;
            setFuelConsumptionData(results);
        } catch (exception) {
            setLoading(false);
            console.log(exception);
        }
    };

    return (
        <>
            <div className="loadingOverlay">
                <Loading loading={loading} />
            </div>
            {
                fuelConsumptionData.isDataReady === true && enginePerformanceData.isDataReady === true ? (
                    <>
                        <div style={a4Style}>
                            <div style={mainContainer}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img style={{ width: '50%' }} src={WMI} />
                                </div>
                                <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                    <p style={{ fontSize: 'large', fontWeight: 'bold' }}>{vesselName && vesselName}</p>
                                    <p style={{ fontSize: 'medium', fontWeight: 'lighter' }}>{customerName && customerName}</p>
                                </div>
                            </div>

                            <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px' }}>
                                <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>VESSEL TRACKING SUMMARY</h2>
                            </div>

                            <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '10px' }}>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                    <p style={titleStyle}>{'Start Date'}</p>
                                    <p style={paragraphStyle}>{moment(selectStartDate).local().format("YYYY-MM-DD HH:mm")}</p>
                                </div>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                    <p style={titleStyle}>{'End Date'}</p>
                                    <p style={paragraphStyle}>{moment(selectEndDate).local().format("YYYY-MM-DD HH:mm")}</p>
                                </div>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>

                                </div>
                            </div>

                            <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '10px' }}>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                    <p style={titleStyle}>{'Total Distance'}</p>
                                    <p style={paragraphStyle}>{totalDistance ? `${parseFloat(totalDistance).toFixed(1)} KM` : `N/A`} | {!isNaN(totalDistance) ? `${(parseFloat(totalDistance) / 1.852).toFixed(1)} NM` : `N/A`}</p>
                                </div>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                    <p style={titleStyle}>{'Average Speed'}</p>
                                    <p style={paragraphStyle}>{averageSpeed ? `${parseFloat(averageSpeed).toFixed(1)} KNOT` : `N/A`}</p>
                                </div>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                    <p style={titleStyle}>{'Duration'}</p>
                                    <p style={paragraphStyle}>{totalDuration ? totalDuration : '0'}</p>
                                </div>
                            </div>

                            <div style={enginePerformanceContainer}>
                                <div style={{ width: '1000%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>ENGINE PERFORMANCES</h2>
                                </div>

                                <div style={tableContainer}>
                                    <table style={tableBorder}>
                                        <thead>
                                            <tr>
                                                <th style={tableContent}><p style={titleStyle}>{'Main Engine Average'}</p></th>
                                                <th style={tableContent}><p style={titleStyle}>{'Port'}</p></th>
                                                <th style={tableContent}><p style={titleStyle}>{'Starboard'}</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Main Engine RPM'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{averageMEP ? `${parseFloat(averageMEP).toFixed(2)} RPM` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{averageMES ? `${parseFloat(averageMES).toFixed(2)} RPM` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Throttle Percentage'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{averageTP ? `${parseFloat(averageTP).toFixed(2)} %` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{averageTS ? `${parseFloat(averageTS).toFixed(2)} %` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'L.O Pressure'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllMEP.oilPressureAvg)} MPA` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllMES.oilPressureAvg)} MPA` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Fuel Temperature'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllMEP.fueltemperatureAvg)} °C` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllMES.fueltemperatureAvg)} °C` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'L.O Temperature'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllMEP.oilTemperatureAvg)} °C` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllMES.oilTemperatureAvg)} °C` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Boost Air Pressure'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllMEP.boostPressureAvg)} KPA` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllMES.boostPressureAvg)} KPA` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Fresh Water Temperature'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllMEP.freshWaterTemperatureAvg)} °C` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllMES.freshWaterTemperatureAvg)} °C` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Battery Voltage'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllMEP.batteryVoltageAvg)} V` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllMES.batteryVoltageAvg)} V` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ width: '100%', height: '20px' }}></div>

                                <div style={tableContainer}>
                                    <table style={tableBorder}>
                                        <thead>
                                            <tr>
                                                <th style={tableContent}><p style={titleStyle}>{'Aux Engine Average'}</p></th>
                                                <th style={tableContent}><p style={titleStyle}>{'Port'}</p></th>
                                                <th style={tableContent}><p style={titleStyle}>{'Starboard'}</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Aux Engine RPM'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllAEP.engineSpeedAvg)} RPM` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllAES.engineSpeedAvg)} RPM` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'L.O Pressure'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllAEP.oilPressureAvg)} MPA` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllAES.oilPressureAvg)} MPA` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'L.O Temperature'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllAEP.oilTemperatureAvg)} °C` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllAES.oilTemperatureAvg)} °C` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Fresh Water Temperature'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllAEP.freshWaterTemperatureAvg)} °C` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllAES.freshWaterTemperatureAvg)} °C` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Battery Voltage'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllAEP.batteryVoltageAvg)} V` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{enginePerformanceData.isDataReady ? `${parseFloat(enginePerformanceData.avgAllAES.batteryVoltageAvg)} V` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            {/* <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine RPM</h4>
                                <DynamicLineChartDoubleAxis
                                    firstData={enginePerformanceData.MEP.engineSpeed}
                                    secondData={enginePerformanceData.MES.engineSpeed}
                                    minValue={0} maxValue={1300} stepSize={260}
                                    minValueSecond={0} maxValueSecond={1300} stepSizeSecond={260}
                                    firstTextLabel={'Main Engine Port RPM'}
                                    secondTextLabel={'Main Engine STBD RPM'}
                                />
                            </div> */}
                        </div>

                        <div style={{ width: '100%', height: '20px' }}></div>

                        <div style={a4Style}>
                            <div style={enginePerformanceContainer}>
                                <div style={{ width: '1000%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>FUEL CONSUMPTION</h2>
                                </div>

                                <div style={tableContainer}>
                                    <table style={tableBorder}>
                                        <thead>
                                            <tr>
                                                <th style={tableContent}><p style={titleStyle}>{'Main Engine Average'}</p></th>
                                                <th style={tableContent}><p style={titleStyle}>{'Port'}</p></th>
                                                <th style={tableContent}><p style={titleStyle}>{'Starboard'}</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Total Fuel Usage'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{fuelConsumptionData.isDataReady ? `${parseFloat(fuelConsumptionData.avgAllMEP.totalFuelUsageAvg)} L` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{fuelConsumptionData.isDataReady ? `${parseFloat(fuelConsumptionData.avgAllMES.totalFuelUsageAvg)} L` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Average Fuel Consumption'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{fuelConsumptionData.isDataReady ? `${parseFloat(fuelConsumptionData.avgAllMEP.averageFuelConsumptionAvg)} L/H` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{fuelConsumptionData.isDataReady ? `${parseFloat(fuelConsumptionData.avgAllMES.averageFuelConsumptionAvg)} L/H` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ width: '100%', height: '20px' }}></div>

                                <div style={tableContainer}>
                                    <table style={tableBorder}>
                                        <thead>
                                            <tr>
                                                <th style={tableContent}><p style={titleStyle}>{'Aux Engine Average'}</p></th>
                                                <th style={tableContent}><p style={titleStyle}>{'Port'}</p></th>
                                                <th style={tableContent}><p style={titleStyle}>{'Starboard'}</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Total Fuel Usage'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{fuelConsumptionData.isDataReady ? `${parseFloat(fuelConsumptionData.avgAllAEP.totalFuelUsageAvg)} L` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{fuelConsumptionData.isDataReady ? `${parseFloat(fuelConsumptionData.avgAllAES.totalFuelUsageAvg)} L` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Average Fuel Consumption'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{fuelConsumptionData.isDataReady ? `${parseFloat(fuelConsumptionData.avgAllAEP.averageFuelConsumptionAvg)} L/H` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{fuelConsumptionData.isDataReady ? `${parseFloat(fuelConsumptionData.avgAllAES.averageFuelConsumptionAvg)} L/H` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td style={tableContent}><p style={paragraphStyle}>{'Engine Load'}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{fuelConsumptionData.isDataReady ? `${parseFloat(fuelConsumptionData.avgAllAEP.engineLoadAvg)} L/H` : `N/A`}</p></td>
                                                <td style={tableContent}><p style={paragraphStyle}>{fuelConsumptionData.isDataReady ? `${parseFloat(fuelConsumptionData.avgAllAES.engineLoadAvg)} L/H` : `N/A`}</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div style={{ width: '100%', height: '20px' }}></div>

                        <div style={a4Style}>
                            <div style={{ width: '1000%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>ENGINE PERFORMANCES</h2>
                            </div>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine RPM</h4>
                                <DynamicLineChartDoubleAxis
                                    firstData={enginePerformanceData.MEP.engineSpeed}
                                    secondData={enginePerformanceData.MES.engineSpeed}
                                    minValue={0} maxValue={1300} stepSize={260}
                                    minValueSecond={0} maxValueSecond={1300} stepSizeSecond={260}
                                    firstTextLabel={'Main Engine Port RPM'}
                                    secondTextLabel={'Main Engine STBD RPM'}
                                />
                            </div>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine Throttle</h4>
                                <DynamicLineChartDoubleAxis
                                    firstData={enginePerformanceData.MEP.acceleratorPedalPosition}
                                    secondData={enginePerformanceData.MES.acceleratorPedalPosition}
                                    minValue={0} maxValue={100} stepSize={20}
                                    minValueSecond={0} maxValueSecond={100} stepSizeSecond={20}
                                    firstTextLabel={'Throttle Port'}
                                    secondTextLabel={'Throttle STBD'}
                                />
                            </div>
                        </div>

                        <div style={{ width: '100%', height: '20px' }}></div>

                        <div style={a4Style}>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine L.O Pressure</h4>
                                <DynamicLineChartDoubleAxis
                                    firstData={enginePerformanceData.MEP.oilPressure}
                                    secondData={enginePerformanceData.MES.oilPressure}
                                    minValue={0} maxValue={1} stepSize={0.2}
                                    minValueSecond={0} maxValueSecond={1} stepSizeSecond={0.2}
                                    firstTextLabel={'L.O Pressure Port (MPA)'}
                                    secondTextLabel={'L.O Pressure STBD (MPA)'}
                                />
                            </div>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine Fuel Temperature</h4>
                                <DynamicLineChartDoubleAxis
                                    firstData={enginePerformanceData.MEP.fueltemperature}
                                    secondData={enginePerformanceData.MES.fueltemperature}
                                    minValue={0} maxValue={100} stepSize={20}
                                    minValueSecond={0} maxValueSecond={100} stepSizeSecond={20}
                                    firstTextLabel={'Fuel Temperature Port (°C)'}
                                    secondTextLabel={'Fuel Temperature STBD (°C)'}
                                />
                            </div>
                        </div>

                        <div style={{ width: '100%', height: '20px' }}></div>

                        <div style={a4Style}>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine L.O Temperature</h4>
                                <DynamicLineChartDoubleAxis
                                    firstData={enginePerformanceData.MEP.oilTemperature}
                                    secondData={enginePerformanceData.MES.oilTemperature}
                                    minValue={0} maxValue={100} stepSize={20}
                                    minValueSecond={0} maxValueSecond={100} stepSizeSecond={20}
                                    firstTextLabel={'L.O Temperature Port (°C)'}
                                    secondTextLabel={'L.O Temperature STBD (°C)'}
                                />
                            </div>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine Boost Air Pressure</h4>
                                <DynamicLineChartDoubleAxis
                                    firstData={enginePerformanceData.MEP.boostPressure}
                                    secondData={enginePerformanceData.MES.boostPressure}
                                    minValue={0} maxValue={500} stepSize={100}
                                    minValueSecond={0} maxValueSecond={500} stepSizeSecond={100}
                                    firstTextLabel={'Boost Pressure Port (KPA)'}
                                    secondTextLabel={'Boost Pressure STBD (KPA)'}
                                />
                            </div>
                        </div>

                        <div style={{ width: '100%', height: '20px' }}></div>

                        <div style={a4Style}>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine Fresh Water Temperature</h4>
                                <DynamicLineChartDoubleAxis
                                    firstData={enginePerformanceData.MEP.freshWaterTemperature}
                                    secondData={enginePerformanceData.MES.freshWaterTemperature}
                                    minValue={0} maxValue={100} stepSize={20}
                                    minValueSecond={0} maxValueSecond={100} stepSizeSecond={20}
                                    firstTextLabel={'FW Temperature Port (°C)'}
                                    secondTextLabel={'FW Temperature STBD (°C)'}
                                />
                            </div>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine Battery Voltage</h4>
                                <DynamicLineChartDoubleAxis
                                    firstData={enginePerformanceData.MEP.batteryVoltage}
                                    secondData={enginePerformanceData.MES.batteryVoltage}
                                    minValue={0} maxValue={30} stepSize={6}
                                    minValueSecond={0} maxValueSecond={30} stepSizeSecond={6}
                                    firstTextLabel={'Battery Voltage Port (V)'}
                                    secondTextLabel={'Battery Voltage STBD (V)'}
                                />
                            </div>
                        </div>

                        <div style={{ width: '100%', height: '20px' }}></div>

                        <div style={a4Style}>
                            <div style={{ width: '1000%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>FUEL CONSUMPTION</h2>
                            </div>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine Port</h4>
                                <DynamicLineChart
                                    dataFuel={fuelConsumptionData.MEP.totalFuelUsage}
                                    minValue={0}
                                    maxValue={fuelConsumptionData.MEP.totalFuelUsage.length > 0 ? parseFloat(fuelConsumptionData.MEP.totalFuelUsage[fuelConsumptionData.MEP.totalFuelUsage.length - 1].compensated_value) : 220}
                                    stepSize={fuelConsumptionData.MEP.totalFuelUsage.length > 0 ? parseFloat(fuelConsumptionData.MEP.totalFuelUsage[fuelConsumptionData.MEP.totalFuelUsage.length - 1].compensated_value) / 5 : 10}
                                />
                            </div>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine Starboard</h4>
                                <DynamicLineChart
                                    dataFuel={fuelConsumptionData.MES.totalFuelUsage}
                                    minValue={0}
                                    maxValue={fuelConsumptionData.MES.totalFuelUsage.length > 0 ? parseFloat(fuelConsumptionData.MES.totalFuelUsage[fuelConsumptionData.MES.totalFuelUsage.length - 1].compensated_value) : 220}
                                    stepSize={fuelConsumptionData.MES.totalFuelUsage.length > 0 ? parseFloat(fuelConsumptionData.MES.totalFuelUsage[fuelConsumptionData.MES.totalFuelUsage.length - 1].compensated_value) / 5 : 10}
                                />
                            </div>
                        </div>

                        <div style={{ width: '100%', height: '20px' }}></div>

                        <div style={a4Style}>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Port</h4>
                                <DynamicLineChart
                                    dataFuel={fuelConsumptionData.AEP.totalFuelUsage}
                                    minValue={0}
                                    maxValue={fuelConsumptionData.AEP.totalFuelUsage.length > 0 ? parseFloat(fuelConsumptionData.AEP.totalFuelUsage[fuelConsumptionData.AEP.totalFuelUsage.length - 1].compensated_value) : 220}
                                    stepSize={fuelConsumptionData.AEP.totalFuelUsage.length > 0 ? parseFloat(fuelConsumptionData.AEP.totalFuelUsage[fuelConsumptionData.MEP.totalFuelUsage.length - 1].compensated_value) / 5 : 10}
                                />
                            </div>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Starboard</h4>
                                <DynamicLineChart
                                    dataFuel={fuelConsumptionData.AES.totalFuelUsage}
                                    minValue={0}
                                    maxValue={fuelConsumptionData.AES.totalFuelUsage.length > 0 ? parseFloat(fuelConsumptionData.AES.totalFuelUsage[fuelConsumptionData.AES.totalFuelUsage.length - 1].compensated_value) : 220}
                                    stepSize={fuelConsumptionData.AES.totalFuelUsage.length > 0 ? parseFloat(fuelConsumptionData.AES.totalFuelUsage[fuelConsumptionData.AES.totalFuelUsage.length - 1].compensated_value) / 5 : 10}
                                />
                            </div>
                        </div>

                        <div style={{ width: '100%', height: '20px' }}></div>

                        <div style={a4Style}>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine Port</h4>
                                <DynamicLineChartCurrent
                                    dataFuel={fuelConsumptionData.MEP.currentFuelConsumption}
                                    minValue={0} maxValue={200} stepSize={20}
                                    textForLabel={'Current Fuel Consumption (L/H)'}
                                />
                            </div>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine Starboard</h4>
                                <DynamicLineChartCurrent
                                    dataFuel={fuelConsumptionData.MES.currentFuelConsumption}
                                    minValue={0} maxValue={200} stepSize={20}
                                    textForLabel={'Current Fuel Consumption (L/H)'}
                                />
                            </div>
                        </div>

                        <div style={{ width: '100%', height: '20px' }}></div>

                        <div style={a4Style}>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Port</h4>
                                <DynamicLineChartDoubleAxis
                                    firstData={fuelConsumptionData.AEP.currentFuelConsumption}
                                    secondData={fuelConsumptionData.AEP.engineLoad}
                                    minValue={0} maxValue={40} stepSize={4}
                                    minValueSecond={0} maxValueSecond={40} stepSizeSecond={4}
                                    firstTextLabel={'Current Fuel Consumption (L/H)'}
                                    secondTextLabel={'Engine Load (KW)'} />
                            </div>
                            <div className="eachlineChartContainerFuelConsumption">
                                <h4 className="txtFortankNameFuelConsumption">Main Engine Starboard</h4>
                                <DynamicLineChartCurrent
                                    dataFuel={fuelConsumptionData.MES.currentFuelConsumption}
                                    minValue={0} maxValue={200} stepSize={20}
                                    textForLabel={'Current Fuel Consumption (L/H)'}
                                />
                            </div>
                        </div>
                    </>
                ) : (<></>)
            }





        </>
    );
}
