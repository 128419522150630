import "./index.css";
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { isTokenValid, getReadingValueForFuelConsumption, getReadingValueForFuelConsumptionAverageCurrentLoadFromMaterializedView, getReadingValueForFuelConsumptionFromMaterializedView, getReadingValueForFuelConsumptionAverageAndCurrentInterval } from "../../Helpers/ApplicationHelpers";
import { Container, Button, Form, Modal } from 'react-bootstrap';
import { FiletypePdf, FiletypeCsv, ClipboardData, Printer } from "react-bootstrap-icons";
import Loading from "../../Components/Loading";
import ContainerBox from "../../Components/ContainerBox";
import Sidebar from "../../Components/NewSideBarForCustomerPage/NewSideBar";
import { Trans, useTranslation } from "react-i18next";
import DropDownForm from "../../Components/DropDownForm";
import DynamicLineChart from "../../Components/DynamicLineChartForFuelConsumption"
import DynamicLineChartCurrent from "../../Components/DynamicLineChartForCurrentFuelUsage"
import DynamicLineChartDoubleAxis from "../../Components/DynamicLineChartForFuelConsumptionAvgAndCurrent"
import moment from "moment";
import { CSVLink } from "react-csv";
import WMI from "../../Assets/Images/CustomerDashboard/WMI_logo.png";
import { constructFrom } from "date-fns";

export default function CustomerFuelConsumptionPage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");

    const [isPrint, setIsPrint] = useState(false);
    const [compiledDataForReport, setCompiledDataForReport] = useState([]);
    const [isDataReady, setIsDataReady] = useState(false);
    const [shouldUpdatePageFromDB, setShouldUpdatePageFromDB] = useState(false);
    const [fuelConsumptionData, setFuelConsumptionData] = useState({
        isDataReady: false
    });
    const [fuelConsumptionDataToShow, setFuelConsumptionDataToShow] = useState({
        isDataReady: false,
        shouldLoadTotalFuelData: false,
        shouldLoadCurrAvgFuelData: false
    });

    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
            .utc()
            .subtract(30, "minutes")
            .format("yyyy-MM-DD HH:mm:ss")
    );
    const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss")
    );

    const durationOption = [
        {
            label: "1 Hour Ago",
            value: 1,
        },
        {
            label: "1 Day Ago",
            value: 2,
        },
        {
            label: "1 Week Ago",
            value: 3,
        },
    ];

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                setSmsSerialNumber(searchParams.get("smsSerialnumber").replace(/'/g, ""));
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (smsSerialNumber) {
            setVesselId(searchParams.get("vesselId").replace(/'/g, ""));
            setVesselName(searchParams.get("vesselName").replace(/'/g, ""));
            setCustomerName(searchParams.get("customerName").replace(/'/g, ""));
        }
    }, [smsSerialNumber])

    useEffect(() => {
        if (shouldUpdatePageFromDB) {
            setLoading(true);
            loadFuelConsumption();
        }
    }, [shouldUpdatePageFromDB]);


    useEffect(() => {
        // loadFuelConsumption();
    }, [vesselId])

    useEffect(() => {
        if (isPrint) {
            printFuelConsumptionData();
        }
    }, [isPrint])

    useEffect(() => {
        console.log(fuelConsumptionData);
        if (fuelConsumptionData.isDataReady) {
            console.log("Step 2, Received Raw Data From DB");
            setFuelConsumptionDataToShow({ ...setFuelConsumptionDataToShow, shouldLoadTotalFuelData: true, shouldLoadCurrAvgFuelData: false, isDataReady: false })

        } else {
            setShouldUpdatePageFromDB(false);
        }
    }, [fuelConsumptionData]);

    useEffect(() => {
        console.log(fuelConsumptionData);
        if (fuelConsumptionDataToShow.shouldLoadTotalFuelData) {
            prepareTotalFuelConsumptionData();
        }
        if (fuelConsumptionDataToShow.shouldLoadCurrAvgFuelData) {
            prepareCurrAvgFuelConsumptionData();
        }
        if (fuelConsumptionDataToShow.isDataReady) {
            console.log("Step 5, Process Finished, Show Data on Page");
            setLoading(false);

        }
    }, [fuelConsumptionDataToShow])

    useEffect(() => {
        if (compiledDataForReport) {
            console.log(compiledDataForReport);
        }
    }, [compiledDataForReport]);


    const prepareTotalFuelConsumptionData = () => {
        console.log("Step 3, Process Total Fuel Consumed");
        let totalMEP = fuelConsumptionData.total.filter(p => p.coordinate_type === "MEP" && p.coordinate_number === 19);
        let totalMES = fuelConsumptionData.total.filter(p => p.coordinate_type === "MES" && p.coordinate_number === 19);
        let totalAEP = fuelConsumptionData.total.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 19);
        let totalAES = fuelConsumptionData.total.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 19);

        let tmpDataToShow = fuelConsumptionDataToShow;
        tmpDataToShow.totalMEP = totalMEP;
        tmpDataToShow.totalMES = totalMES;
        tmpDataToShow.totalAEP = totalAEP;
        tmpDataToShow.totalAES = totalAES;
        tmpDataToShow.shouldLoadTotalFuelData = false;
        tmpDataToShow.shouldLoadCurrAvgFuelData = true;
        tmpDataToShow.isDataReady = false;
        setFuelConsumptionDataToShow(tmpDataToShow);
    };

    const prepareCurrAvgFuelConsumptionData = () => {
        console.log("Step 4, Process Current, Avg Fuel Consumption");
        let fuelAvgMEP = fuelConsumptionData.curr_avg.filter(p => p.coordinate_type === "MEP" && p.coordinate_number === 17),
            summaryAvgMEP = fuelAvgMEP.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelAvgMEP.length;
        let fuelAvgMES = fuelConsumptionData.curr_avg.filter(p => p.coordinate_type === "MES" && p.coordinate_number === 17),
            summaryAvgMES = fuelAvgMES.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelAvgMES.length;
        let fuelAvgAEP = fuelConsumptionData.curr_avg.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 17),
            summaryAvgAEP = fuelAvgAEP.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelAvgAEP.length;
        let fuelAvgAES = fuelConsumptionData.curr_avg.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 17),
            summaryAvgAES = fuelAvgAES.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelAvgAES.length;

        let fuelCurrentMEP = fuelConsumptionData.curr_avg.filter(p => p.coordinate_type === "MEP" && p.coordinate_number === 13),
            summaryCurrMEP = fuelCurrentMEP.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelCurrentMEP.length;
        let fuelCurrentMES = fuelConsumptionData.curr_avg.filter(p => p.coordinate_type === "MES" && p.coordinate_number === 13),
            summaryCurrMES = fuelCurrentMES.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelCurrentMES.length;
        let fuelCurrentAEP = fuelConsumptionData.curr_avg.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 18),
            summaryCurrAEP = fuelCurrentAEP.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelCurrentAEP.length;
        let fuelCurrentAES = fuelConsumptionData.curr_avg.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 18),
            summaryCurrAES = fuelCurrentAES.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / fuelCurrentAES.length;

        let eLoadAEP = fuelConsumptionData.curr_avg.filter(p => p.coordinate_type === "AEP" && p.coordinate_number === 20),
            summaryAvgeLoadAEP = eLoadAEP.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / eLoadAEP.length;
        let eLoadAES = fuelConsumptionData.curr_avg.filter(p => p.coordinate_type === "AES" && p.coordinate_number === 20),
            summaryAvgeLoadAES = eLoadAES.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.reading_value), 0) / eLoadAES.length;

        let tmpDataToShow = fuelConsumptionDataToShow;
        tmpDataToShow.summaryAvgMEP = summaryAvgMEP;
        tmpDataToShow.summaryAvgMES = summaryAvgMES;
        tmpDataToShow.summaryAvgAEP = summaryAvgAEP;
        tmpDataToShow.summaryAvgAES = summaryAvgAES;
        tmpDataToShow.summaryCurrMEP = summaryCurrMEP;
        tmpDataToShow.summaryCurrMES = summaryCurrMES;
        tmpDataToShow.summaryCurrAEP = summaryCurrAEP;
        tmpDataToShow.summaryCurrAES = summaryCurrAES;
        tmpDataToShow.summaryAvgeLoadAEP = summaryAvgeLoadAEP;
        tmpDataToShow.summaryAvgeLoadAES = summaryAvgeLoadAES;
        tmpDataToShow.fuelAvgMEP = fuelAvgMEP;
        tmpDataToShow.fuelAvgMES = fuelAvgMES;
        tmpDataToShow.fuelAvgAEP = fuelAvgAEP;
        tmpDataToShow.fuelAvgAES = fuelAvgAES;
        tmpDataToShow.fuelCurrentMEP = fuelCurrentMEP;
        tmpDataToShow.fuelCurrentMES = fuelCurrentMES;
        tmpDataToShow.fuelCurrentAEP = fuelCurrentAEP;
        tmpDataToShow.fuelCurrentAES = fuelCurrentAES;
        tmpDataToShow.eLoadAEP = eLoadAEP;
        tmpDataToShow.eLoadAES = eLoadAES;
        tmpDataToShow.shouldLoadTotalFuelData = false;
        tmpDataToShow.shouldLoadCurrAvgFuelData = false;
        tmpDataToShow.isDataReady = true;
        setFuelConsumptionDataToShow(tmpDataToShow);
    };

    const loadFuelConsumption = async () => {
        try {
            console.log("Step 1, Load From DB");

            let monthStart = moment(selectStartDate).format("YYYY/MM").replace('/', '');
            let monthEnd = moment(selectEndDate).format("YYYY/MM").replace('/', '');
            let results = await getReadingValueForFuelConsumptionFromMaterializedView(cookies.token, smsSerialNumber, selectStartDate, selectEndDate, monthEnd, monthStart, vesselId);
            results.isDataReady = true;
            setFuelConsumptionData(results);

        }
        catch (exception) {
            setFuelConsumptionData({ isDataReady: false });
            setFuelConsumptionDataToShow({ isDataReady: false, shouldLoadTotalFuelData: false, shouldLoadCurrAvgFuelData: false });
            setShouldUpdatePageFromDB(false);
            setLoading(false);
            alert("Error Has Been Ocurred");
            console.log(exception);
        }
    }

    const generateFuelConsumptionCSV = () => {
        let fuelMEP = [];
        let fuelMES = [];
        let fuelAEP = [];
        let fuelAES = [];

        let fuelAvgMEP = [];
        let fuelAvgMES = [];
        let fuelAvgAEP = [];
        let fuelAvgAES = [];
        let fuelCurrentMEP = [];
        let fuelCurrentMES = [];
        let fuelCurrentAEP = [];
        let fuelCurrentAES = [];
        let eLoadAEP = [];
        let eLoadAES = [];
        let arrTotalFuelLength = [];
        let arrAvgCurrentFuelLength = [];

        let listAllFuelConsumptionData = [];

        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.totalMEP);
        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.totalMES);
        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.totalAEP);
        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.totalAES);
        arrTotalFuelLength = [fuelConsumptionDataToShow.totalMEP.length, fuelConsumptionDataToShow.totalMES.length, 
            fuelConsumptionDataToShow.totalAEP.length, fuelConsumptionDataToShow.totalAES.length];


        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.fuelAvgMEP);
        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.fuelAvgMES);
        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.fuelAvgAEP);
        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.fuelAvgAES);
        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.fuelCurrentMEP);
        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.fuelCurrentMES);
        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.fuelCurrentAEP);
        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.fuelCurrentAES);
        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.eLoadAEP);
        listAllFuelConsumptionData.push(fuelConsumptionDataToShow.eLoadAES);

        arrAvgCurrentFuelLength = [fuelConsumptionDataToShow.fuelAvgMEP.length, fuelConsumptionDataToShow.fuelAvgMES.length, fuelConsumptionDataToShow.fuelAvgAEP.length, fuelConsumptionDataToShow.fuelAvgAES.length,
        fuelConsumptionDataToShow.fuelCurrentMEP.length, fuelConsumptionDataToShow.fuelCurrentMES.length, fuelConsumptionDataToShow.fuelCurrentAEP.length, fuelConsumptionDataToShow.fuelCurrentAES.length, fuelConsumptionDataToShow.fuelCurrentAES.length,
        fuelConsumptionDataToShow.eLoadAEP.length, fuelConsumptionDataToShow.eLoadAES.length
        ];

        arrTotalFuelLength = arrTotalFuelLength.concat(arrAvgCurrentFuelLength);
        arrTotalFuelLength.sort(function (a, b) { return a.length - b.length });

        console.log(arrTotalFuelLength);
        arrTotalFuelLength.reverse();
        console.log('listAllFuelConsumptionData', listAllFuelConsumptionData);
        let tmpArrIdx = listAllFuelConsumptionData.findIndex(p => p.length === arrTotalFuelLength[0]);
        let tmpArr = listAllFuelConsumptionData[tmpArrIdx];

        let csvHeaders = ['received_date',
            'main_engine_port',
            'main_engine_stbd',
            'aux_engine_port',
            'aux_engine_stbd',
            'me_average_fuel_consumption_port',
            'me_average_fuel_consumption_stbd',
            'ae_average_fuel_consumption_port',
            'ae_average_fuel_consumption_stbd',
            'me_current_fuel_consumption_port',
            'me_current_fuel_consumption_stbd',
            'ae_current_fuel_consumption_port',
            'ae_current_fuel_consumption_stbd',
            'ae_engine_load_port',
            'ae_engine_load_stbd',
        ]
        let listCSV = [];
        listCSV.push(csvHeaders);
        for (let i = 0; i < tmpArr.length; i++) {
            let csvElement = [];
            csvElement.push(tmpArr[i].received_date);
            for (let j = 0; listAllFuelConsumptionData.length > j; j++) {
                let validValue = listAllFuelConsumptionData[j].find(p => p.received_date === csvElement[0]);
                if (validValue) {
                    if (validValue.coordinate_number === 19){
                        csvElement.push(validValue.compensated_value);
                    } else {
                    csvElement.push(validValue.reading_value);
                    }
                } else {
                    csvElement.push(null);
                }
            }
            csvElement[0] = moment(csvElement[0]).local().format("YYYY-MM-DD HH:mm:ss");
            listCSV.push(csvElement);
        }
        console.log(listCSV);
        setCompiledDataForReport(listCSV);
    }

    const printFuelConsumptionData = () => {
        var prtContent = document.getElementById("printFuelConsumption");
        var style = "<style>";
        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: left;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"
        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `Fuel Consumption`;
        WinPrint.print();
        setLoading(false);
        setIsPrint(false);
    }
    return (
        <>
            <div className="mainFuelConsumptionPage">
                <Sidebar companyName={customerName} smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName} customerName={cookies.userName} />
                <Container fluid className="mainContainerFuelConsumption">
                    <div className="innerChildMainContainerFuelConsumption">
                        <ContainerBox
                            titleCaption={vesselName + ' FUEL CONSUMPTION'}
                            childContent={
                                <>
                                    <div className="formDateForFuelConsumption">
                                        <Form.Group className="mb-3" style={{ margin: "10px" }}>
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control
                                                type="datetime-local"
                                                onChange={(e) => {
                                                    // setSelectStartDate(e.target.value);
                                                    setSelectStartDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                                    // setUseDropDown(false);
                                                }}
                                                value={
                                                    selectStartDate
                                                        ? moment(selectStartDate).format("yyyy-MM-DD HH:mm:ss")
                                                        : ""
                                                }
                                            ></Form.Control>
                                        </Form.Group>

                                        <Form.Group className="mb-3" style={{ margin: "10px" }}>
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control
                                                type="datetime-local"
                                                onChange={(e) => {
                                                    // setSelectEndDate(e.target.value);
                                                    setSelectEndDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                                    // setUseDropDown(false);
                                                }}
                                                value={
                                                    selectEndDate
                                                        ? moment(selectEndDate).format("yyyy-MM-DD HH:mm:ss")
                                                        : ""
                                                }
                                                min={moment(selectStartDate).format("yyyy-MM-DD HH:mm:ss")}
                                            ></Form.Control>
                                        </Form.Group>

                                        <Form.Group style={{ margin: "10px" }}>
                                            <DropDownForm
                                                caption={"Time"}
                                                placeholder={"Select Time ..."}
                                                listOption={durationOption}
                                                valueKey={"value"}
                                                labelKey={"label"}
                                                setValueFunction={(e) => {
                                                    console.log(e);
                                                    try {
                                                        let dateTime = new Date();
                                                        if (e.target.value === "1") {
                                                            console.log("1");
                                                            let startDate = moment(dateTime).subtract(60, "minutes").format("yyyy-MM-DD HH:mm:ss");
                                                            let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                            setSelectStartDate(startDate);
                                                            setSelectEndDate(endDate);
                                                        } else if (e.target.value === "2") {
                                                            console.log("2");
                                                            let startDate = moment(dateTime).subtract(1, "day").format("yyyy-MM-DD HH:mm:ss");
                                                            let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                            setSelectStartDate(startDate);
                                                            setSelectEndDate(endDate);
                                                            console.log("tes", startDate, endDate);
                                                        } else if (e.target.value === "3") {
                                                            console.log("3");
                                                            let startDate = moment(dateTime).subtract(1, "week").format("yyyy-MM-DD HH:mm:ss");
                                                            let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                            setSelectStartDate(startDate);
                                                            setSelectEndDate(endDate);
                                                        }
                                                    } catch (exception) {
                                                        console.log(exception);
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Button
                                            style={{ width: '200px', height: "40px", margin: "10px", marginTop: "32px" }}
                                            onClick={() => {
                                                setFuelConsumptionData({ isDataReady: false });
                                                setFuelConsumptionDataToShow({ isDataReady: false, shouldLoadTotalFuelData: false, shouldLoadCurrAvgFuelData: false });
                                                setShouldUpdatePageFromDB(true);
                                            }}
                                        >
                                            <div className="btnContainer">
                                                <div className="btnContainerLeft" ><ClipboardData size={25} /></div>
                                                <div className="btnContainerRight">{'Show Data'}</div>
                                            </div>
                                        </Button>
                                        {/* <Button
                                            style={{ width: '200px', height: "40px", margin: "10px", marginTop: "32px" }}
                                            onClick={() => {
                                                printFuelConsumptionData();
                                            }}
                                        >
                                            <div className="btnContainer" >
                                                <div className="btnContainerLeft" ><Printer size={25} /></div>
                                                <div className="btnContainerRight" >{'Print Document'}</div>
                                            </div>
                                        </Button> */}
                                        {
                                            fuelConsumptionDataToShow.isDataReady &&
                                            <CSVLink
                                                style={{ width: '200px', height: "40px", margin: "10px", marginTop: "32px" }}
                                                data={compiledDataForReport}
                                                separator={","}
                                                filename={"fuel-consumption-data.csv"}
                                                className="btn btn-primary"
                                                target="_blank"

                                            >
                                                <div className="btnContainer" onClick={() => {
                                                    generateFuelConsumptionCSV();
                                                }}>
                                                    <div className="btnContainerLeft" ><FiletypeCsv size={25} /></div>
                                                    <div className="btnContainerRight" >{'Export to CSV'}</div>
                                                </div>
                                            </CSVLink>
                                        }


                                        {/* <br/> */}
                                        <div className="summaryContainerFuelConsumption">
                                            <h4 className="txtFortankNameFuelConsumption">Fuel Consumption Summary</h4>
                                            <div className="table-container" style={{ width: '100%' }}>
                                                <div className="table-header">
                                                    <div style={{ flex: 2 }} className="table-header-content">{''}</div>
                                                    <div style={{ flex: 2 }} className="table-header-content">{'Main Engine Total Fuel Usage'}</div>
                                                    <div style={{ flex: 2 }} className="table-header-content">{'Aux Engine Total Fuel Usage'}</div>
                                                    <div style={{ flex: 2 }} className="table-header-content">{'Main Engine Average Fuel Consumption'}</div>
                                                    <div style={{ flex: 2 }} className="table-header-content">{'Aux Engine Average Fuel Consumption'}</div>
                                                    <div style={{ flex: 2 }} className="table-header-content">{'Aux Engine Average Load'}</div>
                                                </div>
                                                <div className="table-header">
                                                    <div style={{ flex: 1 }} className="table-header-content">{'Start Date'}</div>
                                                    <div style={{ flex: 1 }} className="table-header-content">{'End Date'}</div>
                                                    <div style={{ flex: 1 }} className="table-header-content">{'Port'}</div>
                                                    <div style={{ flex: 1 }} className="table-header-content">{'Starboard'}</div>
                                                    <div style={{ flex: 1 }} className="table-header-content">{'Port'}</div>
                                                    <div style={{ flex: 1 }} className="table-header-content">{'Starboard'}</div>
                                                    <div style={{ flex: 1 }} className="table-header-content">{'Port'}</div>
                                                    <div style={{ flex: 1 }} className="table-header-content">{'Starboard'}</div>
                                                    <div style={{ flex: 1 }} className="table-header-content">{'Port'}</div>
                                                    <div style={{ flex: 1 }} className="table-header-content">{'Starboard'}</div>
                                                    <div style={{ flex: 1 }} className="table-header-content">{'Port'}</div>
                                                    <div style={{ flex: 1 }} className="table-header-content">{'Starboard'}</div>

                                                </div>
                                                {fuelConsumptionDataToShow.isDataReady &&
                                                    <div className="table-body">
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{selectStartDate}</p></div>
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{selectEndDate}</p></div>
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{fuelConsumptionDataToShow.totalMEP[fuelConsumptionDataToShow.totalMEP.length - 1] ? fuelConsumptionDataToShow.totalMEP[fuelConsumptionDataToShow.totalMEP.length - 1].compensated_value + ' L' : "N/A"} </p></div>
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{fuelConsumptionDataToShow.totalMES[fuelConsumptionDataToShow.totalMES.length - 1] ? fuelConsumptionDataToShow.totalMES[fuelConsumptionDataToShow.totalMES.length - 1].compensated_value + ' L' : "N/A"} </p></div>
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{fuelConsumptionDataToShow.totalAEP[fuelConsumptionDataToShow.totalAEP.length - 1] ? fuelConsumptionDataToShow.totalAEP[fuelConsumptionDataToShow.totalAEP.length - 1].compensated_value + ' L' : "N/A"} </p></div>
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{fuelConsumptionDataToShow.totalAES[fuelConsumptionDataToShow.totalAES.length - 1] ? fuelConsumptionDataToShow.totalAES[fuelConsumptionDataToShow.totalAES.length - 1].compensated_value + ' L' : "N/A"} </p></div>
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{fuelConsumptionDataToShow.summaryCurrMEP ? fuelConsumptionDataToShow.summaryCurrMEP.toFixed(2) + ' L/H' : "N/A"} </p></div>
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{fuelConsumptionDataToShow.summaryCurrMES ? fuelConsumptionDataToShow.summaryCurrMES.toFixed(2) + ' L/H' : "N/A"} </p></div>
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{fuelConsumptionDataToShow.summaryCurrAEP ? fuelConsumptionDataToShow.summaryCurrAEP.toFixed(2) + ' L/H' : "N/A"} </p></div>
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{fuelConsumptionDataToShow.summaryCurrAES ? fuelConsumptionDataToShow.summaryCurrAES.toFixed(2) + ' L/H' : "N/A"} </p></div>
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{fuelConsumptionDataToShow.summaryAvgeLoadAEP ? fuelConsumptionDataToShow.summaryAvgeLoadAEP.toFixed(2) + ' KW' : "N/A"} </p></div>
                                                        <div style={{ flex: 1 }} className="table-body-content"><p>{fuelConsumptionDataToShow.summaryAvgeLoadAES ? fuelConsumptionDataToShow.summaryAvgeLoadAES.toFixed(2) + ' KW' : "N/A"} </p></div>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                        {/* <Form.Label style={{ color: 'white' }}>
                                            <input
                                                style={{ marginTop: "30px" }}
                                                type="checkbox"
                                                checked={refreshChecked}
                                                onChange={(e) => {
                                                    setRefreshChecked(e.target.checked);
                                                }}
                                            />
                                            Auto Refresh
                                        </Form.Label> */}
                                    </div>
                                    {fuelConsumptionDataToShow.isDataReady &&
                                        <div className="outerBorderFuelConsumption">
                                            <h4 className="txtFortankNameFuelConsumption">Total Fuel Usage</h4>
                                            <div className="lineChartContainerFuelConsumption">
                                                <div className="eachlineChartContainerFuelConsumption">
                                                    <h4 className="txtFortankNameFuelConsumption">Main Engine Port</h4>
                                                    <DynamicLineChart dataFuel={fuelConsumptionDataToShow.totalMEP} minValue={0} maxValue={fuelConsumptionDataToShow.totalMEP.length > 0 ? parseFloat(fuelConsumptionDataToShow.totalMEP[fuelConsumptionDataToShow.totalMEP.length - 1].compensated_value) : 220} stepSize={fuelConsumptionDataToShow.totalMEP.length > 0 ? parseFloat(fuelConsumptionDataToShow.totalMEP[fuelConsumptionDataToShow.totalMEP.length - 1].compensated_value) / 5 : 10} />
                                                </div>
                                                <div className="eachlineChartContainerFuelConsumption">
                                                    <h4 className="txtFortankNameFuelConsumption">Main Engine Starboard</h4>
                                                    <DynamicLineChart dataFuel={fuelConsumptionDataToShow.totalMES} minValue={0} maxValue={fuelConsumptionDataToShow.totalMES.length > 0 ? parseFloat(fuelConsumptionDataToShow.totalMES[fuelConsumptionDataToShow.totalMES.length - 1].compensated_value) : 220} stepSize={fuelConsumptionDataToShow.totalMES.length > 0 ? parseFloat(fuelConsumptionDataToShow.totalMES[fuelConsumptionDataToShow.totalMES.length - 1].compensated_value) / 5 : 10} />
                                                </div>
                                                <div className="eachlineChartContainerFuelConsumption">
                                                    <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Port</h4>
                                                    <DynamicLineChart dataFuel={fuelConsumptionDataToShow.totalAEP} minValue={0} maxValue={fuelConsumptionDataToShow.totalAEP.length > 0 ? parseFloat(fuelConsumptionDataToShow.totalAEP[fuelConsumptionDataToShow.totalAEP.length - 1].compensated_value) : 220} stepSize={fuelConsumptionDataToShow.totalAEP.length > 0 ? parseFloat(fuelConsumptionDataToShow.totalAEP[fuelConsumptionDataToShow.totalAEP.length - 1].compensated_value) / 5 : 10} />
                                                </div>
                                                <div className="eachlineChartContainerFuelConsumption">
                                                    <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Starboard</h4>
                                                    <DynamicLineChart dataFuel={fuelConsumptionDataToShow.totalAES} minValue={0} maxValue={fuelConsumptionDataToShow.totalAES.length > 0 ? parseFloat(fuelConsumptionDataToShow.totalAES[fuelConsumptionDataToShow.totalAES.length - 1].compensated_value) : 220} stepSize={fuelConsumptionDataToShow.totalAES.length > 0 ? parseFloat(fuelConsumptionDataToShow.totalAES[fuelConsumptionDataToShow.totalAES.length - 1].compensated_value) / 5 : 10} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {fuelConsumptionDataToShow.isDataReady &&
                                        <div className="outerBorderFuelConsumption">
                                            <h4 className="txtFortankNameFuelConsumption">Average & Current Fuel Consumption</h4>
                                            <div className="lineChartContainerFuelConsumption">
                                                <div className="eachlineChartContainerFuelConsumption">
                                                    <h4 className="txtFortankNameFuelConsumption">Main Engine Port</h4>
                                                    <DynamicLineChartCurrent dataFuel={fuelConsumptionDataToShow.fuelCurrentMEP} minValue={0} maxValue={200} stepSize={20} textForLabel={'Current Fuel Consumption (L/H)'}/>
                                                </div>
                                                <div className="eachlineChartContainerFuelConsumption">
                                                    <h4 className="txtFortankNameFuelConsumption">Main Engine Starboard</h4>
                                                    <DynamicLineChartCurrent dataFuel={fuelConsumptionDataToShow.fuelCurrentMES} minValue={0} maxValue={200} stepSize={20} textForLabel={'Current Fuel Consumption (L/H)'}/>
                                                </div>
                                                <div className="eachlineChartContainerFuelConsumption">
                                                    <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Port</h4>
                                                    <DynamicLineChartDoubleAxis firstData={fuelConsumptionDataToShow.fuelCurrentAEP} secondData={fuelConsumptionDataToShow.eLoadAEP}
                                                        minValue={0} maxValue={40} stepSize={4} minValueSecond={0} maxValueSecond={40} stepSizeSecond={4} firstTextLabel={'Current Fuel Consumption (L/H)'} secondTextLabel={'Engine Load (KW)'}/>
                                                </div>
                                                <div className="eachlineChartContainerFuelConsumption">
                                                    <h4 className="txtFortankNameFuelConsumption">Auxiliary Engine Starboard</h4>
                                                    <DynamicLineChartDoubleAxis firstData={fuelConsumptionDataToShow.fuelCurrentAES} secondData={fuelConsumptionDataToShow.eLoadAES}
                                                        minValue={0} maxValue={40} stepSize={4} minValueSecond={0} maxValueSecond={40} stepSizeSecond={4} firstTextLabel={'Current Fuel Consumption (L/H)'} secondTextLabel={'Engine Load (KW)'}/>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        />
                    </div>
                    <Loading
                        loading={loading}
                        loadingText={`${t("loading_component.text")}`}
                    />
                </Container>
                <div id="printFuelConsumption" style={{ display: 'none', padding: 10 }}>
                    <div style={{ width: '100%', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '90%', border: '1px black solid', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px' }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ width: '50%' }} src={WMI} />
                            </div>
                            <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <p style={{ fontSize: 'large', fontWeight: 'bold' }}>{vesselName}</p>
                                <p style={{ fontSize: 'medium', fontWeight: 'lighter' }}>{customerName}</p>
                            </div>
                        </div>

                        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px' }}>
                            <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>FUEL CONSUMPTION SUMMARY</h2>
                        </div>

                        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '10px' }}>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <p style={{ fontSize: 'large', fontWeight: 'bold', margin: '0' }}>{'Start Date'}</p>
                                <p style={{ fontSize: 'medium', fontWeight: 'lighter', margin: '0' }}>{moment(selectStartDate).local().format("YYYY-MM-DD HH:mm")}</p>
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <p style={{ fontSize: 'large', fontWeight: 'bold', margin: '0' }}>{'End Date'}</p>
                                <p style={{ fontSize: 'medium', fontWeight: 'lighter', margin: '0' }}>{moment(selectEndDate).local().format("YYYY-MM-DD HH:mm")}</p>
                            </div>
                        </div>
                        {fuelConsumptionDataToShow.isDataReady &&
                            <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '10px' }}>
                                <table style={{ width: '100%', border: '1px black solid' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ padding: '8px', border: '1px black solid' }}>{''}</th>
                                            <th style={{ padding: '8px', border: '1px black solid' }}>{''}</th>
                                            <th style={{ padding: '8px', border: '1px black solid' }}>Total Fuel Consumption</th>
                                            <th style={{ padding: '8px', border: '1px black solid' }}>Average Fuel Usage</th>
                                            <th style={{ padding: '8px', border: '1px black solid' }}>Engine Load</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>Main Engine</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>Port</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{fuelConsumptionDataToShow.totalMEP[fuelConsumptionDataToShow.totalMEP.length - 1] ? fuelConsumptionDataToShow.totalMEP[fuelConsumptionDataToShow.totalMEP.length - 1].compensated_value + ' L' : "N/A"}</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{fuelConsumptionDataToShow.summaryAverageMEP ? fuelConsumptionDataToShow.summaryAverageMEP.toFixed(2) + ' L/H' : "N/A"}</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{''}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{''}</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>Starboard</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{fuelConsumptionDataToShow.totalMES[fuelConsumptionDataToShow.totalMES.length - 1] ? fuelConsumptionDataToShow.totalMES[fuelConsumptionDataToShow.totalMES.length - 1].compensated_value + ' L' : "N/A"}</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{fuelConsumptionDataToShow.summaryAverageMES ? fuelConsumptionDataToShow.summaryAverageMES.toFixed(2) + ' L/H' : "N/A"}</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{''}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>Aux Engine</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>Port</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{fuelConsumptionDataToShow.totalAEP[fuelConsumptionDataToShow.totalAEP.length - 1] ? fuelConsumptionDataToShow.totalAEP[fuelConsumptionDataToShow.totalAEP.length - 1].compensated_value + ' L' : "N/A"}</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{fuelConsumptionDataToShow.summaryAverageAEP ? fuelConsumptionDataToShow.summaryAverageAEP.toFixed(2) + ' L/H' : "N/A"}</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{fuelConsumptionDataToShow.summaryAverageEngineLoadAEP ? fuelConsumptionDataToShow.summaryAverageEngineLoadAEP.toFixed(2) + ' KW' : "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{''}</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>Starboard</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{fuelConsumptionDataToShow.totalAES[fuelConsumptionDataToShow.totalAES.length - 1] ? fuelConsumptionDataToShow.totalAES[fuelConsumptionDataToShow.totalAES.length - 1].compensated_value + ' L' : "N/A"}</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{fuelConsumptionDataToShow.summaryAverageAES ? fuelConsumptionDataToShow.summaryAverageAES.toFixed(2) + ' L/H' : "N/A"}</td>
                                            <td style={{ padding: '8px', border: '1px black solid' }}>{fuelConsumptionDataToShow.summaryAverageEngineLoadAES ? fuelConsumptionDataToShow.summaryAverageEngineLoadAES.toFixed(2) + ' KW' : "N/A"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}