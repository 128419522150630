import "./NewTankLevel.css";
import ReloadPict from "../../Assets/Images/reload-pict.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import BitLamp from "../BitLamp/BitLamp";
import NewNavLightContainer from "../NewNavLightContainerPosition/NewNavLightContainer";

const CustomProgressBar = ({ now, variant }) => {
    const progressBarStyle = {
        height: '1rem',
        // backgroundColor: 'blue',
        borderRadius: '0.25rem',
        position: 'relative',
    };

    const fillStyle = {
        height: '100%',
        width: `${now}%`,
        backgroundColor: variant,
    };

    return (
        <div style={progressBarStyle}>
            <div style={fillStyle}></div>
        </div>
    );
};

export default function TankLevel({ text, value, unit, wireBreak, shortError = false, volume, highValue, lowvalue, m3Value, heightReading }) {
    return (

        
        <div className="mainProgressbarNewTank">
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '25px'}}>
                <p className="txtforBoxWithText">{text}</p>
                <p className="txtforBoxWithText">{m3Value + ' m^3'}</p>
            </div>
            <div className="customProgressBarTank">
                <CustomProgressBar now={value} variant={value < 10 ? '#e11d48' : value < 25 ? '#f59e0b' : '#008000'} />
                <p className="txtforBoxWithTextTank">{value}{'%'}</p>
            </div>
            <div className="wireBreakContainer">
                <p className="txtforBoxWithText" style={{fontWeight: 'bold', margin: '10px 30px'}}>{heightReading + ' cm'}</p>
                {
                    wireBreak === true ?
                        <div className="wireBreakContainerExtOn">
                            <NewNavLightContainer text={'Wire Break'} alarm={String(wireBreak)} />
                        </div> :
                        <div className="wireBreakContainerExtOff">
                            <NewNavLightContainer text={'Wire Break'} alarm={String(wireBreak)} />
                        </div>
                }
                

            </div>
           
            {/* <div className="rightSide">
                {
                    highValue ? <div className="hiContainerOn"><p className="txtforBoxWithText">HI</p></div> : <div className="hiContainerOff"><p className="txtforBoxWithText"></p></div>
                }
                {
                    lowvalue ? <div className="loContainerOn"><p className="txtforBoxWithText">LO</p></div> : <div className="loContainerOff"><p className="txtforBoxWithText"></p></div>
                }
            </div> */}

        </div>
    );
}