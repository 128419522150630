import React, { useEffect, useState, useRef } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, Link } from "@mui/material";
import axios from "axios";
import config from "../../../Config/Config";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getVesselV2, getVesselLocationFromNMEA, getVesselEngineDataForVesselLocation, getVesselForCustomerV2 } from "../../../Helpers/ApplicationHelpers";
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import "../../AppV2.css";
import { AccountCircle, Key, Login, Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTopBar from "../../../Components/v2/FMSTopBar";
import FMSTheme from "../../FMSTheme";
import FMSSidebar from '../../../Components/v2/FMSSidebar';
import EnhancedTable from '../../../Components/v2/FMSTable';
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../Components/v2/FMSNavBarMUI";
import OpenSeaMap from "../../../Components/v2/FMSOpenSeaMap/index";
import {parseNmeaSentence} from "nmea-simple";
import { MapContainer, Marker, Popup, TileLayer, useMap, rotatedMarker } from "react-leaflet";
import moment from "moment";
import DmsCoordinates from "dms-conversion";
import RotatedMarker from "../../../Components/RotatedMarker";
import vesselImg from "../../../Assets/Images/new_arrow_pointer.png";
import Loading from "../../../Components/v2/FMSLoading/index";


export default function FMSMapPage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const [gpsData, setGPSData] = useState([]);
    const [listNMEAData, setListNMEAData] = useState([]);
    const [engineAndLocationData, setEngineAndLocationData] = useState();
    const [selectedNMEAData, setSelectedNMEAData] = useState();
    const [listVesselData, setListVesselData] = useState();
    const [listLineData, setListLineData] = useState();
    const [markerData, setMarkerData] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadDetailVesselData();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (listVesselData) {
            if (listVesselData.length > 0) {
                console.log(listVesselData);
                let listLineData = Array(listVesselData.length);
                for(let i=0;i<listVesselData.length; i++){
                    listLineData[i] = [];
                }
                setListLineData(listLineData);
                loadEngineVesselData();
            }
        }
    }, [listVesselData]);

    useEffect(() => {
        if (markerData) {
            if (markerData.listMarker) {
                if (markerData.listMarker.length > 0) {
                    setLoading(false);
                }
            }
        }
    }, [markerData])

    useEffect(() => {
        if (gpsData){
            console.log('gpsData',gpsData);
            if (gpsData.length > 0) {
                let listMarker = [];
                let listVessel = listVesselData;

                // let listTimestamp = [];
                // const currentTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                let idx = 0;
                let listLineData2 = listLineData.slice();

                gpsData.forEach(data => {
                    const dmsCoords = new DmsCoordinates(parseFloat(data.latitude), parseFloat(data.longitude));

                    let markerEl =
                        <RotatedMarker key={idx} position={[data.latitude, data.longitude]} timestamp_gps={data.timestamp_gps} icon={vesselIcon} rotationAngle={
                            (parseFloat(data.course) + parseFloat(data.cogCorrection)).toFixed(1) > 359.9 ? ((parseFloat(data.course) + parseFloat(data.cogCorrection)) - 359.9).toFixed(1)
                                : (parseFloat(data.course) + parseFloat(data.cogCorrection)).toFixed(1)
                        } rotationOrigin='center'>
                            <Popup>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                                    <div style={{ border: '1px white solid', width: '250px', height: '1px', backgroundColor: 'white', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%' }}>
                                        <p style={{ fontSize: 'large', margin: '0px', fontWeight: 'bold' }}>{data.vesselName}</p>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%' }}>
                                        <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Position'}</p>
                                        <p style={{ fontSize: 'smaller', margin: '0px' }}>{dmsCoords.latitude.toString()}</p>
                                        <p style={{ fontSize: 'smaller', margin: '0px' }}>{dmsCoords.longitude.toString()}</p>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%' }}>
                                        <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Time'}</p>
                                        <p style={{ fontSize: 'smaller', margin: '0px' }}>{data.timestamp_gps}</p>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Speed'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.speed)).toFixed(1)} knot</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'COG'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>
                                                {
                                                    (parseFloat(data.course) + parseFloat(data.cogCorrection)).toFixed(1) > 359.9 ? ((parseFloat(data.course) + parseFloat(data.cogCorrection)) - 359.9).toFixed(1)
                                                        : (parseFloat(data.course) + parseFloat(data.cogCorrection)).toFixed(1)
                                                } °
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Main Engine'}</p>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Port'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.rpmPort)).toFixed(1)}</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Starboard'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.rpmStarboard)).toFixed(1)}</p>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Propeller'}</p>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Port'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{parseFloat(data.rpmAEPort).toFixed(1)}</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Starboard'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{parseFloat(data.rpmAEStarboard).toFixed(1)}</p>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Throttle Port'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.throttlePort)).toFixed(1)}%</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Throttle Starboard'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.throttleStarboard)).toFixed(1)}%</p>
                                        </div>
                                    </div>
                                </div>
                            </Popup>
                        </RotatedMarker>;
                        try {
                            listVessel.find(p => p.vessel_id === data.vessel_id).latitude = data.latitude;
                            listVessel.find(p => p.vessel_id === data.vessel_id).longitude = data.longitude;
                        }
                        catch (exception) {
                            listVessel.find(p => p.vessel_id === data.vessel_id).latitude = 0;
                            listVessel.find(p => p.vessel_id === data.vessel_id).longitude = 0;
                        }

                        console.log(markerEl.props);
                    if (listLineData2) {
                        let listLineDataIdx = listVessel.findIndex(p => p.vessel_id === data.vessel_id);
                        let listNewLineData = listLineData2[listLineDataIdx].slice();
                        if (listLineData2[listLineDataIdx]) {
                            if (listLineData2[listLineDataIdx].length > 0) {
                                let listExpiredLine = listLineData2[listLineDataIdx].filter(p => moment.duration(moment(new Date()).diff(moment(p.props.timestamp_gps))).asMinutes() > 10);
                                if (listExpiredLine) {
                                    listExpiredLine.forEach((line, idx) => {
                                        let toBeRemovedIdx = listNewLineData.findIndex(p =>p.props.timestamp_gps === line.props.timestamp_gps);
                                        if (toBeRemovedIdx > -1) {
                                            listNewLineData.splice(toBeRemovedIdx, 1);
                                        }
                                    });
                                }
                                listLineData2[listLineDataIdx] = listNewLineData;
                                listLineData2[listLineDataIdx].push(markerEl);
                            }else {
                                listLineData2[listLineDataIdx].push(markerEl);
                            }
                        } 
                    }
                    // listLine.push(markerEl);
                    // listTimestamp.push(timeStampGPS);
                    listMarker.push(markerEl);
                    idx++;
                })

                setListLineData(listLineData2);
                let markerObj = {
                    listVessel: listVessel,
                    listMarker: listMarker,
                    listLine: listLineData2,
                }
                setMarkerData(markerObj);
            }
        }
    }, [gpsData])

    useEffect(() => {
        if (listNMEAData){
            console.log(listNMEAData);
            let newArr = [];
            for (let i = 0; i < listNMEAData.length; i++){
                listNMEAData[i].nmeaData.forEach((data) => {
                    const line = data.nmea_sentence;
                    try {
                        const packet = parseNmeaSentence(line.replace("\r", "\r\n"));
                        if (packet.sentenceId === "RMC" && packet.status === "valid") {
                            console.log("Got location via RMC packet:", packet.latitude, packet.longitude);
                            listNMEAData[i].latitude = packet.latitude;
                            listNMEAData[i].longitude = packet.longitude;
                        }
                    } catch (error) {
                        console.error("Got bad packet:", line, error);
                    }
                })

                newArr.push(listNMEAData[i]);
            }
            setGPSData(newArr);
        }

    }, [listNMEAData])



    useEffect(() => {
        if (engineAndLocationData){
            console.log('engineAndLocationData', engineAndLocationData);
            if (engineAndLocationData.length > 0) {
                loadNMEAData();
            }
        }
    }, [engineAndLocationData])

    let vesselIcon = L.icon({
        iconUrl: vesselImg,
        iconRetinaUrl: vesselImg,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });

    const loadDetailVesselData = async () => {
        try {
            let response = await getVesselForCustomerV2(cookies.token, cookies.extCustomerId);
            console.log('vessel data',response);
            setListVesselData(response);
        } catch (exception) {
            console.log(exception);
        }
    }

    const loadNMEAData = async () => {
        try {
            let newArr = []
            for (let i = 0; i < engineAndLocationData.length; i++){
                let result = await getVesselLocationFromNMEA(cookies.token, cookies.extCustomerId, engineAndLocationData[i].vesselId);
                engineAndLocationData[i].nmeaData = result
                newArr.push(engineAndLocationData[i]);
            }
            setListNMEAData(newArr);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadEngineVesselData = async () => {
        try {
            let result = await getVesselEngineDataForVesselLocation(cookies.token, cookies.extCustomerId);
            if (result) {
                let gpsAndEngineData = result.map((a) => {
                    return {
                        timestamp_gps: moment(a.read_date).local().format("YYYY-MM-DD HH:mm:ss"),
                        vesselName: a.vessel_name,
                        vesselId: a.vessel_id,
                        rpmPort: a.mep_rpm,
                        rpmStarboard: a.mes_rpm,
                        throttlePort: a.mep_fuelrack,
                        throttleStarboard: a.mes_fuelrack,
                        rpmAEPort: a.aep_rpm,
                        rpmAEStarboard: a.aes_rpm,
                        course: 0,
                        cogCorrection: 0,
                        speed: 0
                    };
                });
                setEngineAndLocationData(gpsAndEngineData);
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }
    return (
        <>
            <ThemeProvider theme={FMSTheme}>
                <div className='mainContainerFMS'>
                    <NavBar />
                    <div className='childMainContainerFMS'>
                        <div className='childMainContainerFMS-TOP'>
                            <p className='ptitleFMSDashboard'>LOCATION</p>
                        </div>
                        <div className='childMainContainerFMS-BODY'>
                            <OpenSeaMap markerChild={markerData} isTracking={false}
                            />
                        </div>
                    </div>
                </div>
            </ThemeProvider>
            <Loading
                loading={loading}
                loadingText={`LOADING`}
            />
        </>
    );
                            
}