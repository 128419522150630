import "./index.css";
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { isTokenValid, getReadingValueForTankCapacityFromMaterializedView, findAllTrimLevelForTankLevel } from "../../Helpers/ApplicationHelpers";
import { Container, Button, Form, Modal } from 'react-bootstrap';
import Loading from "../../Components/Loading";
import ContainerBox from "../../Components/ContainerBox";
import Sidebar from "../../Components/NewSideBarForCustomerPage/NewSideBar";
import { Trans, useTranslation } from "react-i18next";
import DropDownForm from "../../Components/DropDownForm";
import DynamicLineChart from "../../Components/DynamicLineChartForTankCapacity"
import moment from "moment";
import { FiletypePdf, FiletypeCsv, ClipboardData, Printer } from "react-bootstrap-icons";
import { CSVLink } from "react-csv";
import WMI from "../../Assets/Images/CustomerDashboard/WMI_logo.png";

export default function CustomerTankCapacityPage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(["token"]);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [listTank, setListTank] = useState([]);
    const [listTankAndValue, setListTankAndValue] = useState([]);
    const [listfptBallast, setListfptBallast] = useState([]);
    const [listfwP, setListfwP] = useState([]);
    const [listfwS, setListfwS] = useState([]);
    const [listvoidTank, setListvoidTank] = useState([]);
    const [listcofferdam, setListcofferdam] = useState([]);
    const [listaptBallastP, setListaptBallastP] = useState([]);
    const [listaptBallastS, setListaptBallastS] = useState([]);
    const [compiledDataForReport, setCompiledDataForReport] = useState([]);
    const [listfotNo1P, setListfotNo1P] = useState([]);
    const [listfotNo1C, setListfotNo1C] = useState([]);
    const [listfotNo1S, setListfotNo1S] = useState([]);
    const [listfotNo2P, setListfotNo2P] = useState([]);
    const [listfotNo2S, setListfotNo2S] = useState([]);
    const [listfotNo3P, setListfotNo3P] = useState([]);
    const [listfotNo3S, setListfotNo3S] = useState([]);
    const [listfotNo4P, setListfotNo4P] = useState([]);
    const [listfotNo4C, setListfotNo4C] = useState([]);
    const [listfotNo4S, setListfotNo4S] = useState([]);
    const [listfodt, setListfodt] = useState([]);
    const [listlot, setListlot] = useState([]);
    const [listdot, setListdot] = useState([]);
    const [diffFptBallast, setDiffFptBallast] = useState();
    const [diffFwP, setDiffFwP] = useState();
    const [diffFwS, setDiffFwS] = useState();
    const [diffVoidTank, setDiffVoidTank] = useState();
    const [diffCofferdam, setDiffCofferdam] = useState();
    const [diffAptBallastP, setDiffAptBallastP] = useState();
    const [diffAptBallastS, setDiffAptBallastS] = useState();
    const [diffFotNo1P, setDiffFotNo1P] = useState();
    const [diffFotNo1C, setDiffFotNo1C] = useState();
    const [diffFotNo1S, setDiffFotNo1S] = useState();
    const [diffFotNo2P, setDiffFotNo2P] = useState();
    const [diffFotNo2S, setDiffFotNo2S] = useState();
    const [diffFotNo3P, setDiffFotNo3P] = useState();
    const [diffFotNo3S, setDiffFotNo3S] = useState();
    const [diffFotNo4P, setDiffFotNo4P] = useState();
    const [diffFotNo4C, setDiffFotNo4C] = useState();
    const [diffFotNo4S, setDiffFotNo4S] = useState();
    const [diffFodt, setDiffFodt] = useState();
    const [diffLot, setDiffLot] = useState();
    const [diffDot, setDiffDot] = useState();
    const [maxfptBallast, setMaxfptBallast] = useState(0);
    const [maxfwP, setMaxfwP] = useState(0);
    const [maxfwS, setMaxfwS] = useState(0);
    const [maxvoidTank, setMaxvoidTank] = useState(0);
    const [maxcofferdam, setMaxcofferdam] = useState(0);
    const [maxaptBallastP, setMaxaptBallastP] = useState(0);
    const [maxaptBallastS, setMaxaptBallastS] = useState(0);
    const [maxfotNo1P, setMaxfotNo1P] = useState(0);
    const [maxfotNo1C, setMaxfotNo1C] = useState(0);
    const [maxfotNo1S, setMaxfotNo1S] = useState(0);
    const [maxfotNo2P, setMaxfotNo2P] = useState(0);
    const [maxfotNo2S, setMaxfotNo2S] = useState(0);
    const [maxfotNo3P, setMaxfotNo3P] = useState(0);
    const [maxfotNo3S, setMaxfotNo3S] = useState(0);
    const [maxfotNo4P, setMaxfotNo4P] = useState(0);
    const [maxfotNo4C, setMaxfotNo4C] = useState(0);
    const [maxfotNo4S, setMaxfotNo4S] = useState(0);
    const [maxfodt, setMaxfodt] = useState(0);
    const [maxlot, setMaxlot] = useState(0);
    const [maxdot, setMaxdot] = useState(0);
    const [isDataReady, setIsDataReady] = useState(false);
    const [listTrimLevel, setListTrimLevel] = useState([]);
    const [selectedTrimId, setSelectedTrimId] = useState(0);
    const [tankCapacityData, setTankCapacityData] = useState({
        isDataReady: false,
    })

    const [selectStartDate, setSelectStartDate] = useState(
        moment(new Date())
            .utc()
            .subtract(30, "minutes")
            .format("yyyy-MM-DD HH:mm:ss")
    );
    const [selectEndDate, setSelectEndDate] = useState(
        moment(new Date()).utc().format("yyyy-MM-DD HH:mm:ss")
    );

    const durationOption = [
        {
          label: "1 Hour Ago",
          value: 1,
        },
        {
          label: "1 Day Ago",
          value: 2,
        },
        {
          label: "1 Week Ago",
          value: 3,
        },
      ];

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                // console.log(searchParams.get("smsSerialnumber").replace(/'/g, ""));
                setSmsSerialNumber(searchParams.get("smsSerialnumber").replace(/'/g, ""));
                
            }
        }
        checkCookies();
        // console.log(cookies);
        // console.log(vesselId);
    }, []);

    useEffect(() => {
        if (smsSerialNumber) {
            setVesselId(searchParams.get("vesselId").replace(/'/g, ""));
            setVesselName(searchParams.get("vesselName").replace(/'/g, ""));
            setCustomerName(searchParams.get("customerName").replace(/'/g, ""));
            loadTrimLevel();
        }
    }, [smsSerialNumber])

    useEffect (() => {
        console.log('%c selectedTrimId : ','color:fuchsia;display:block;background-color:green', selectedTrimId, "anotherghetto");
    }, [selectedTrimId])


    useEffect(() => {
        console.log('fptBallast', listfptBallast);
        console.log('fwP', listfwP);
        console.log('fwS', listfwS);
        console.log('voidTank', listvoidTank);
        console.log('cofferdam', listcofferdam);
        console.log('aptBallastP', listaptBallastP);
        console.log('aptBallastS', listaptBallastS);
        console.log('fotNo1P', listfotNo1P);
        console.log('fotNo1C', listfotNo1C);
        console.log('fotNo1S', listfotNo1S);
        console.log('fotNo2P', listfotNo2P);
        console.log('fotNo2S', listfotNo2S);
        console.log('fotNo3P', listfotNo3P);
        console.log('fotNo3S', listfotNo3S);
        console.log('fotNo4P', listfotNo4P);
        console.log('fotNo4C', listfotNo4C);
        console.log('fotNo4S', listfotNo4S);
        console.log('fodt', listfodt);
        console.log('lot', listlot);
        console.log('dot', listdot);

    }, [listdot])

    useEffect(() => {
        // console.log('fptBallast', diffFptBallast);
        // console.log('fwP', diffFwP);
        // console.log('fwS', diffFwS);
        // console.log('voidTank', diffVoidTank);
        // console.log('cofferdam', diffCofferdam);
        // console.log('aptBallastP', diffAptBallastP);
        // console.log('aptBallastS', diffAptBallastS);
        // console.log('fotNo1P', diffFotNo1P);
        // console.log('fotNo1C', diffFotNo1C);
        // console.log('fotNo1S', diffFotNo1S);
        // console.log('fotNo2P', diffFotNo2P);
        // console.log('fotNo2S', diffFotNo2S);
        // console.log('fotNo3P', diffFotNo3P);
        // console.log('fotNo3S', diffFotNo3S);
        // console.log('fotNo4P', diffFotNo4P);
        // console.log('fotNo4C', diffFotNo4C);
        // console.log('fotNo4S', diffFotNo4S);
        // console.log('fodt', diffFodt);
        // console.log('lot', diffLot);
        // console.log('dot', diffDot);
        if (listdot.length > 0){
            setIsDataReady(true);
        }
        

    }, [diffDot])

     useEffect(() => {
        console.log('listTank and Value -->', listTankAndValue);
        setLoading(false);
        if (listTankAndValue){
            let fptBallast = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 0),
            countFptBallast = fptBallast[0] ? (fptBallast[fptBallast.length - 1].capacity_m3 - fptBallast[0].capacity_m3).toFixed(2) : "";
            let maxValfptBallast = fptBallast && fptBallast.length > 0 ? Math.max(...fptBallast.map(p => p.capacity_m3)) : 20;

            let fwP= listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 1),
            countFwP = fwP[0] ? (fwP[fwP.length - 1].capacity_m3 - fwP[0].capacity_m3).toFixed(2) : "",
            maxValfwP = fwP && fwP.length > 0 && Math.max(...fwP.map(p => p.capacity_m3));

            let fwS = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 2),
            countFwS = fwS[0] ? (fwS[fwS.length - 1].capacity_m3 - fwS[0].capacity_m3).toFixed(2) : "",
            maxValfwS = fwS && fwS.length > 0 && Math.max(...fwS.map(p => p.capacity_m3));

            let voidTank = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 3),
            countVoidTank = voidTank[0] ? (voidTank[voidTank.length - 1].capacity_m3 - voidTank[0].capacity_m3).toFixed(2) : "",
            maxValvoidTank = voidTank && voidTank.length > 0 && Math.max(...voidTank.map(p => p.capacity_m3));

            let cofferdam= listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 4),
            countCofferdam = cofferdam[0] ? (cofferdam[cofferdam.length - 1].capacity_m3 - cofferdam[0].capacity_m3).toFixed(2) : "",
            maxValcofferdam = cofferdam && cofferdam.length > 0 && Math.max(...cofferdam.map(p => p.capacity_m3));

            let aptBallastP = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 23),
            countAptBallastP = aptBallastP[0] ? (aptBallastP[aptBallastP.length - 1].capacity_m3 - aptBallastP[0].capacity_m3).toFixed(2) : "";
            let maxValaptBallastP = aptBallastP && aptBallastP.length > 0 ? Math.max(...aptBallastP.map(p => p.capacity_m3)) : 20;

            let aptBallastS = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 22),
            countAptBallastS = aptBallastS[0] ? (aptBallastS[aptBallastS.length - 1].capacity_m3 - aptBallastS[0].capacity_m3).toFixed(2) : "",
            maxValaptBallastS = aptBallastS && aptBallastS.length > 0 && Math.max(...aptBallastS.map(p => p.capacity_m3));

            let fotNo1P = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 8),
            countFotNo1P = fotNo1P[0] ? (fotNo1P[fotNo1P.length - 1].capacity_m3 - fotNo1P[0].capacity_m3).toFixed(2) : "",
            maxValfotNo1P = fotNo1P && fotNo1P.length > 0 && Math.max(...fotNo1P.map(p => p.capacity_m3));

            let fotNo1C = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 9),
            countFotNo1C = fotNo1C[0] ? (fotNo1C[fotNo1C.length - 1].capacity_m3 - fotNo1C[0].capacity_m3).toFixed(2) : "",
            maxValfotNo1C = fotNo1C && fotNo1C.length > 0 && Math.max(...fotNo1C.map(p => p.capacity_m3));

            let fotNo1S = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 10),
            countFotNo1S = fotNo1S[0] ? (fotNo1S[fotNo1S.length - 1].capacity_m3 - fotNo1S[0].capacity_m3).toFixed(2) : "",
            maxValfotNo1S = fotNo1S && fotNo1S.length > 0 && Math.max(...fotNo1S.map(p => p.capacity_m3));

            let fotNo2P = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 11),
            countFotNo2P = fotNo2P[0] ? (fotNo2P[fotNo2P.length - 1].capacity_m3 - fotNo2P[0].capacity_m3).toFixed(2) : "",
            maxValfotNo2P = fotNo2P && fotNo2P.length > 0 && Math.max(...fotNo2P.map(p => p.capacity_m3));

            let fotNo2S = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 12),
            countFotNo2S = fotNo2S[0] ? (fotNo2S[fotNo2S.length - 1].capacity_m3 - fotNo2S[0].capacity_m3).toFixed(2) : "",
            maxValfotNo2S = fotNo2S && fotNo2S.length > 0 && Math.max(...fotNo2S.map(p => p.capacity_m3));

            let fotNo3P = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 13),
            countFotNo3P = fotNo3P[0] ? (fotNo3P[fotNo3P.length - 1].capacity_m3 - fotNo3P[0].capacity_m3).toFixed(2) : "",
            maxValfotNo3P = fotNo3P && fotNo3P.length > 0 && Math.max(...fotNo3P.map(p => p.capacity_m3));

            let fotNo3S = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 14),
            countFotNo3S = fotNo3S[0] ? (fotNo3S[fotNo3S.length - 1].capacity_m3 - fotNo3S[0].capacity_m3).toFixed(2) : "",
            maxValfotNo3S = fotNo3S && fotNo3S.length > 0 && Math.max(...fotNo3S.map(p => p.capacity_m3));

            let fotNo4P = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 16),
            countFotNo4P = fotNo4P[0] ? (fotNo4P[fotNo4P.length - 1].capacity_m3 - fotNo4P[0].capacity_m3).toFixed(2) : "",
            maxValfotNo4P = fotNo4P && fotNo4P.length > 0 && Math.max(...fotNo4P.map(p => p.capacity_m3));

            let fotNo4C = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 17),
            countFotNo4C = fotNo4C[0] ? (fotNo4C[fotNo4C.length - 1].capacity_m3 - fotNo4C[0].capacity_m3).toFixed(2) : "",
            maxValfotNo4C = fotNo4C && fotNo4C.length > 0 && Math.max(...fotNo4C.map(p => p.capacity_m3));

            let fotNo4S= listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 18),
            countFotNo4S = fotNo4S[0] ? (fotNo4S[fotNo4S.length - 1].capacity_m3 - fotNo4S[0].capacity_m3).toFixed(2) : "",
            maxValfotNo4S = fotNo4S && fotNo4S.length > 0 && Math.max(...fotNo4S.map(p => p.capacity_m3));

            let fodt= listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 19),
            countFodt = fodt[0] ? (fodt[fodt.length - 1].capacity_m3 - fodt[0].capacity_m3).toFixed(2) : "",
            maxValfodt = fodt && fodt.length > 0 && Math.max(...fodt.map(p => p.capacity_m3));

            let lot= listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 20),
            countLot = lot[0] ? (lot[lot.length - 1].capacity_m3 - lot[0].capacity_m3).toFixed(2) : "",
            maxVallot = lot && lot.length > 0 && Math.max(...lot.map(p => p.capacity_m3));

            let dot= listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 21),
            countDot = dot[0] ? (dot[dot.length - 1].capacity_m3 - dot[0].capacity_m3).toFixed(2) : "",
            maxValdot = dot && dot.length > 0 && Math.max(...dot.map(p => p.capacity_m3));


            setListfptBallast(fptBallast);
            setListfwP(fwP);
            setListfwS(fwS);
            setListvoidTank(voidTank);
            setListcofferdam(cofferdam);
            setListaptBallastP(aptBallastP);
            setListaptBallastS(aptBallastS);
            setListfotNo1P(fotNo1P);
            setListfotNo1C(fotNo1C);
            setListfotNo1S(fotNo1S);
            setListfotNo2P(fotNo2P);
            setListfotNo2S(fotNo2S);
            setListfotNo3P(fotNo3P);
            setListfotNo3S(fotNo3S);
            setListfotNo4P(fotNo4P);
            setListfotNo4C(fotNo4C);
            setListfotNo4S(fotNo4S);
            setListfodt(fodt);
            setListlot(lot);
            setListdot(dot);

            setMaxfptBallast(maxValfptBallast);
            setMaxfwP(maxValfwP)
            setMaxfwS(maxValfwS)
            setMaxvoidTank(maxValvoidTank)
            setMaxcofferdam(maxValcofferdam)
            setMaxaptBallastP(maxValaptBallastP)
            setMaxaptBallastS(maxValaptBallastS)
            setMaxfotNo1P(maxValfotNo1P)
            setMaxfotNo1C(maxValfotNo1C)
            setMaxfotNo1S(maxValfotNo1S)
            setMaxfotNo2P(maxValfotNo2P)
            setMaxfotNo2S(maxValfotNo2S)
            setMaxfotNo3P(maxValfotNo3P)
            setMaxfotNo3S(maxValfotNo3S)
            setMaxfotNo4P(maxValfotNo4P)
            setMaxfotNo4C(maxValfotNo4C)
            setMaxfotNo4S(maxValfotNo4S)
            setMaxfodt(maxValfodt)
            setMaxlot(maxVallot)
            setMaxdot(maxValdot)

            setDiffFptBallast(countFptBallast);
            setDiffFwP(countFwP);
            setDiffFwS(countFwS);
            setDiffVoidTank(countVoidTank);
            setDiffCofferdam(countCofferdam);
            setDiffAptBallastP(countAptBallastP);
            setDiffAptBallastS(countAptBallastS);
            setDiffFotNo1P(countFotNo1P);
            setDiffFotNo1C(countFotNo1C);
            setDiffFotNo1S(countFotNo1S);
            setDiffFotNo2P(countFotNo2P);
            setDiffFotNo2S(countFotNo2S);
            setDiffFotNo3P(countFotNo3P);
            setDiffFotNo3S(countFotNo3S);
            setDiffFotNo4P(countFotNo4P);
            setDiffFotNo4C(countFotNo4C);
            setDiffFotNo4S(countFotNo4S);
            setDiffFodt(countFodt);
            setDiffLot(countLot);
            setDiffDot(countDot);


        }
     }, [listTankAndValue])


    // const loadTankCapacityData = async () => {
    //     try {
    //         console.log('start date:', selectStartDate);
    //         console.log('end date', selectEndDate);
    //     }
    //     catch (exception){
    //         console.log(exception);
    //     }

    // }

    const loadListTank = async () => {
        try {
            setLoading(true);
            let monthStart = moment(selectStartDate).format("YYYY/MM").replace('/', '');
            let monthEnd = moment(selectEndDate).format("YYYY/MM").replace('/', '');
            // let results = await getConvertedTanksReadingValuesForTankCapacity(cookies.token, smsSerialNumber, vesselId, selectStartDate, selectEndDate);
            let resultsTank = await getReadingValueForTankCapacityFromMaterializedView(cookies.token, smsSerialNumber, selectStartDate, selectEndDate, monthEnd, monthStart, vesselId, selectedTrimId);
            console.log(resultsTank);
            // setLoading(false);
            setListTankAndValue(resultsTank);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadTrimLevel = async () => {
        try {
            let response = await findAllTrimLevelForTankLevel(cookies.token);
            let trimLevel = response.map((a) => {
                return {
                    label: a.trim_level,
                    value: a.trim_order,
                    id: a.trim_id
                };
            });
            setListTrimLevel(trimLevel);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const printFuelConsumptionData = () => {
        var prtContent = document.getElementById("printFuelConsumption");
        var style = "<style>";
        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #000; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: left;}";
        style = style + "img {width: 400px; text-align: right; margin-left: calc(-20px); }";
        style = style + "h3 {text-align: center;}";
        style = style + ".tableClass  {border-style:none !important; border: 0 !important; padding:5px !important; text-align:left}"
        style = style + "</style>";
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        WinPrint.document.write(style);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.document.title = `Tank Capacity`;
        WinPrint.print();
        setLoading(false);
        setIsPrint(false);
    }

    const generateFuelConsumptionCSV = () => {
        let listAllTankData = [];
        let arrAllTankDataLength = [];
        let arrTotalTankLength = [];
        if (listTankAndValue) {
            if (listTankAndValue.length > 0){
                let fptBallast = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 0)
                let fwP= listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 1)
                let fwS = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 2)
                let voidTank = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 3)
                let cofferdam= listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 4)
                let aptBallastP = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 23)
                let aptBallastS = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 22)
                let fotNo1P = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 8)
                let fotNo1C = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 9)
                let fotNo1S = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 10)
                let fotNo2P = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 11)
                let fotNo2S = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 12)
                let fotNo3P = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 13)
                let fotNo3S = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 14)
                let fotNo4P = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 16)
                let fotNo4C = listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 17)
                let fotNo4S= listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 18)
                let fodt= listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 19)
                let lot= listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 20)
                let dot= listTankAndValue.filter(p => p.coordinate_type === "AI" && p.coordinate_number === 21)

                listAllTankData.push(fptBallast, fwP, fwS, voidTank, cofferdam, aptBallastP, aptBallastS, fotNo1P, fotNo1C, fotNo1S, fotNo2P, fotNo2S, fotNo3P, fotNo3S, fotNo4P, fotNo4C, fotNo4S, fodt, lot, dot);
                arrAllTankDataLength = [fptBallast.length, fwP.length, fwS.length, voidTank.length, cofferdam.length, aptBallastP.length, aptBallastS.length, fotNo1P.length, fotNo1C.length, fotNo1S.length, fotNo2P.length, fotNo2S.length, fotNo3P.length, fotNo3S.length, fotNo4P.length, fotNo4C.length, fotNo4S.length, fodt.length, lot.length, dot.length];
            }
            
        }
        arrTotalTankLength = arrTotalTankLength.concat(arrAllTankDataLength);
        arrTotalTankLength.sort(function(a, b){return a.length - b.length});

        console.log(arrTotalTankLength);
        arrTotalTankLength.reverse();
        console.log('listAllTankData',listAllTankData);
        let tmpArrIdx = listAllTankData.findIndex(p=>p.length===arrTotalTankLength[0]);
        let tmpArr = listAllTankData[tmpArrIdx];

        let csvHeaders = ['received_date',
                        'fpt_ballast_tank',
                        'apt_ p_ ballast_tank',
                        'apt_s_ballast_tank',
                        'fuel_oil_tank_no1_p',
                        'fuel_oil_tank_no1_c',
                        'fuel_oil_tank_no1_s',
                        'fuel_oil_tank_no2_p',
                        'fuel_oil_tank_no2_s',
                        'fuel_oil_tank_no3_p',
                        'fuel_oil_tank_no3_s',
                        'fuel_oil_tank_no4_p',
                        'fuel_oil_tank_no4_c',
                        'fuel_oil_tank_no4_s',
                        'fuel_oil_daily_tank',
                        'l_o_t_lube_oil_tank',
                        'd_o_t',
                        'fresh_water_tank_p',
                        'fresh_water_tank_s',
                        'cofferdam',
                        'void',
                    ]
        
        let listCSV = [];
        listCSV.push(csvHeaders);
        for(let i=0;i<tmpArr.length;i++){
            let csvElement = [];
            csvElement.push(tmpArr[i].received_date);
            for(let j=0;listAllTankData.length>j;j++){
                let validValue = listAllTankData[j].find(p=>p.received_date === csvElement[0]);
                if(validValue){
                    if (validValue.capacity_m3 !== null){
                        csvElement.push(validValue.capacity_m3);
                    } else {
                        csvElement.push(0);
                    }
                } else{
                    csvElement.push(null);
                }
            }
            csvElement[0] = moment(csvElement[0]).local().format("YYYY-MM-DD HH:mm:ss");
            listCSV.push(csvElement);
        }
        console.log(listCSV);
        setCompiledDataForReport(listCSV);
    }


    return (
        <>
            <div className="mainTankCapacityPage">
                <Sidebar companyName={customerName} smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName} customerName={cookies.userName} />
                <Container fluid className="mainContainerTankCapacity">
                    <div className="innerChildMainContainerTankCapacity">
                        <ContainerBox
                            titleCaption={vesselName + ' TANK CAPACITY'}
                            childContent={
                                <>
                                    <div className="formDateForTankCapacity">
                                        <Form.Group className="mb-3" style={{ margin: "10px" }}>
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control
                                                type="datetime-local"
                                                onChange={(e) => {
                                                    console.log("%c MOMENT : ","color:blue",moment("2024-09-01").format("YYYY/MM"));
                                                    if (moment(e.target.value).format("YYYY-MM-DD HH:mm:ss") < moment("2024-10-01 00:00:00").format("YYYY-MM-DD HH:mm:ss"))
                                                        setSelectStartDate(moment("2024-10-01 00:00:00").format("YYYY-MM-DD HH:mm:ss"));
                                                    else
                                                        setSelectStartDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                                }}
                                                value={
                                                    selectStartDate
                                                        ? moment(selectStartDate).format("yyyy-MM-DD HH:mm:ss")
                                                        : ""
                                                }
                                            ></Form.Control>
                                        </Form.Group>

                                        <Form.Group className="mb-3" style={{ margin: "10px" }}>
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control
                                                type="datetime-local"
                                                onChange={(e) => {
                                                    setSelectEndDate(moment(e.target.value).format("yyyy-MM-DD HH:mm:ss"));
                                                }}
                                                value={
                                                    selectEndDate
                                                        ? moment(selectEndDate).format("yyyy-MM-DD HH:mm:ss")
                                                        : ""
                                                }
                                            ></Form.Control>
                                        </Form.Group>

                                        <Form.Group style={{ margin: "10px"}}>
                                            <DropDownForm
                                                caption={"Duration"}
                                                placeholder={"Select Time ..."}
                                                listOption={durationOption}
                                                valueKey={"value"}
                                                labelKey={"label"}
                                                setValueFunction={(e) => {
                                                    console.log(e);
                                                    try {
                                                        let dateTime = new Date();
                                                        if (e.target.value === "1") {
                                                            console.log("1");
                                                            let startDate = moment(dateTime).subtract(60, "minutes").format("yyyy-MM-DD HH:mm:ss");
                                                            let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                            setSelectStartDate(startDate);
                                                            setSelectEndDate(endDate);
                                                        } else if (e.target.value === "2") {
                                                            console.log("2");
                                                            let startDate = moment(dateTime).subtract(1, "day").format("yyyy-MM-DD HH:mm:ss");
                                                            let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                            setSelectStartDate(startDate);
                                                            setSelectEndDate(endDate);
                                                            console.log("tes", startDate, endDate);
                                                        } else if (e.target.value === "3") {
                                                            console.log("3");
                                                            let startDate = moment(dateTime).subtract(1, "week").format("yyyy-MM-DD HH:mm:ss");
                                                            let endDate = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
                                                            setSelectStartDate(startDate);
                                                            setSelectEndDate(endDate);
                                                        }
                                                    } catch (exception) {
                                                        console.log(exception);
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group style={{ margin: "10px"}}>
                                            <DropDownForm
                                                caption={"Trim Level"}
                                                placeholder={"Select Trim ..."}
                                                listOption={listTrimLevel}
                                                valueKey={"id"}
                                                labelKey={"label"}
                                                setValueFunction={(e) => {
                                                    setSelectedTrimId(e.target.value);
                                                }}
                                            />
                                        </Form.Group>

                                        <Button
                                            style={{ width: '200px', height: "40px", margin: "10px", marginTop: "32px" }}
                                            onClick={() => {
                                                loadListTank();
                                            }}
                                        >
                                            <div className="btnContainer">
                                                <div className="btnContainerLeft" ><ClipboardData size={25} /></div>
                                                <div className="btnContainerRight">{'Show Data'}</div>
                                            </div>
                                        </Button>
                                        {/* <Button
                                            style={{ width: '200px', height: "40px", margin: "10px", marginTop: "32px" }}
                                            onClick={() => {
                                                printFuelConsumptionData();
                                            }}
                                        >
                                            <div className="btnContainer" >
                                            <div className="btnContainerLeft" ><Printer size={25} /></div>
                                            <div className="btnContainerRight" >{'Print Document'}</div>
                                            </div>
                                        </Button> */}
                                        {
                                            isDataReady &&
                                            <CSVLink
                                                style={{ width: '200px', height: "40px", margin: "10px", marginTop: "32px" }}
                                                data={compiledDataForReport}
                                                separator={","}
                                                filename={"engine-performance-data.csv"}
                                                className="btn btn-primary"
                                                target="_blank"

                                            >
                                                <div className="btnContainer" onClick={() => {
                                                    generateFuelConsumptionCSV();
                                                }}>
                                                    <div className="btnContainerLeft" ><FiletypeCsv size={25} /></div>
                                                    <div className="btnContainerRight" >{'Export to CSV'}</div>
                                                </div>
                                            </CSVLink>
                                        }
                                        <div className="summaryContainerTankCapacityOuter">
                                        <h4 className="txtFortankNameFuelConsumption">Tank Capacity Summary</h4>
                                        <div className="summaryContainerTankCapacity">
                                            {
                                                isDataReady ? (
                                                    <>
                                                        <div className="table-container" style={{width: '380px', margin: '3px'}}>
                                                            <div className="table-header">
                                                                <div style={{ flex: 3 }} className="table-header-content"><p>{'Tank Name'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-header-content"><p>{'Start Capacity (m3)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-header-content"><p>{'End Capacity (m3)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-header-content"><p>{'Status'}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'FPT Ballast Tank'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfptBallast && listfptBallast[0] ? listfptBallast[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfptBallast && listfptBallast[listfptBallast.length - 1] ? listfptBallast[listfptBallast.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFptBallast < 0 ? "Drained" : diffFptBallast > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'APT Ballast Tank (P)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listaptBallastP && listaptBallastP[0] ? listaptBallastP[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listaptBallastP && listaptBallastP[listaptBallastP.length - 1] ? listaptBallastP[listaptBallastP.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffAptBallastP < 0 ? "Drained" : diffAptBallastP > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'APT Ballast Tank (S)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listaptBallastS && listaptBallastS[0] ? listaptBallastS[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listaptBallastS && listaptBallastS[listaptBallastS.length - 1] ? listaptBallastS[listaptBallastS.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffAptBallastS < 0 ? "Drained" : diffAptBallastS > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 1 (P)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo1P && listfotNo1P[0] ? listfotNo1P[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo1P && listfotNo1P[listfotNo1P.length - 1] ? listfotNo1P[listfotNo1P.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo1P < 0 ? "Drained" : diffFotNo1P > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 1 (C)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo1C && listfotNo1C[0] ? listfotNo1C[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo1C && listfotNo1C[listfotNo1C.length - 1] ? listfotNo1C[listfotNo1C.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo1C < 0 ? "Drained" : diffFotNo1C > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                        </div>

                                                        <div className="table-container" style={{width: '380px', margin: '3px'}}>
                                                            <div className="table-header">
                                                                <div style={{ flex: 3 }} className="table-header-content"><p>{'Tank Name'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-header-content"><p>{'Start Capacity (m3)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-header-content"><p>{'End Capacity (m3)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-header-content"><p>{'Status'}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 1 (S)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo1S && listfotNo1S[0] ? listfotNo1S[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo1S && listfotNo1S[listfotNo1S.length - 1] ? listfotNo1S[listfotNo1S.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo1S < 0 ? "Drained" : diffFotNo1S > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 2 (P)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo2P && listfotNo2P[0] ? listfotNo2P[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo2P && listfotNo2P[listfotNo2P.length - 1] ? listfotNo2P[listfotNo2P.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo2P < 0 ? "Drained" : diffFotNo2P > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 2 (S)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo2S && listfotNo2S[0] ? listfotNo2S[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo2S && listfotNo2S[listfotNo2S.length - 1] ? listfotNo2S[listfotNo2S.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo2S < 0 ? "Drained" : diffFotNo2S > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 3 (P)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo3P && listfotNo3P[0] ? listfotNo3P[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo3P && listfotNo3P[listfotNo3P.length - 1] ? listfotNo3P[listfotNo3P.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo3P < 0 ? "Drained" : diffFotNo1P > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 3 (S)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo3S && listfotNo3S[0] ? listfotNo3S[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo3S && listfotNo3S[listfotNo3S.length - 1] ? listfotNo3S[listfotNo3S.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo3S < 0 ? "Drained" : diffFotNo3S > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                        </div>

                                                        <div className="table-container" style={{width: '380px', margin: '3px'}}>
                                                            <div className="table-header">
                                                                <div style={{ flex: 3 }} className="table-header-content"><p>{'Tank Name'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-header-content"><p>{'Start Capacity (m3)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-header-content"><p>{'End Capacity (m3)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-header-content"><p>{'Status'}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 4 (P)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo4P && listfotNo4P[0] ? listfotNo4P[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo4P && listfotNo4P[listfotNo4P.length - 1] ? listfotNo4P[listfotNo4P.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo4P < 0 ? "Drained" : diffFotNo4P > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 4 (C)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo4C && listfotNo4C[0] ? listfotNo4C[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo4C && listfotNo4C[listfotNo4C.length - 1] ? listfotNo4C[listfotNo4C.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo4C < 0 ? "Drained" : diffFotNo4C > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 4 (S)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo4S && listfotNo4S[0] ? listfotNo4S[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfotNo4S && listfotNo4S[listfotNo4S.length - 1] ? listfotNo4S[listfotNo4S.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo4S < 0 ? "Drained" : diffFotNo4S > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'F.O. Daily Tank'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfodt && listfodt[0] ? listfodt[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfodt && listfodt[listfodt.length - 1] ? listfodt[listfodt.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFodt < 0 ? "Drained" : diffFodt > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'Lube Oil Tank'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listlot && listlot[0] ? listlot[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listlot && listlot[listlot.length - 1] ? listlot[listlot.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffLot < 0 ? "Drained" : diffLot > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                        </div>

                                                        <div className="table-container" style={{width: '380px', margin: '3px'}}>
                                                            <div className="table-header">
                                                                <div style={{ flex: 3 }} className="table-header-content"><p>{'Tank Name'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-header-content"><p>{'Start Capacity (m3)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-header-content"><p>{'End Capacity (m3)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-header-content"><p>{'Status'}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'Dirty Oil Tank'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listdot && listdot[0] ? listdot[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listdot && listdot[listdot.length - 1] ? listdot[listdot.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffDot < 0 ? "Drained" : diffDot > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'Fresh Water Tank (P)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfwP && listfwP[0] ? listfwP[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfwP && listfwP[listfwP.length - 1] ? listfwP[listfwP.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFwP < 0 ? "Drained" : diffFwP > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'Fresh Water Tank (S)'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfwS && listfwS[0] ? listfwS[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listfwS && listfwS[listfwS.length - 1] ? listfwS[listfwS.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffFwS < 0 ? "Drained" : diffFwS > 0 ? "Refueled" : "Constant"}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'Cofferdam'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listcofferdam && listcofferdam[0] ? listcofferdam[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listcofferdam && listcofferdam[listcofferdam.length - 1] ? listcofferdam[listcofferdam.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffCofferdam < 0 ? "Drained" : diffCofferdam > 0 ? "Refueled" : ""}</p></div>
                                                            </div>
                                                            <div className="table-body">
                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{'Void'}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listvoidTank && listvoidTank[0] ? listvoidTank[0].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{listvoidTank && listvoidTank[listvoidTank.length - 1] ? listvoidTank[listvoidTank.length - 1].capacity_m3 : 0}</p></div>
                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{diffVoidTank < 0 ? "Drained" : diffVoidTank > 0 ? "Refueled" : ""}</p></div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (<></>)
                                            }
                                        </div>
                                        </div>
                                        
                                    </div>

                                    <div className="outerBorderTankCapacity">
                                        <h4 className="txtFortankNameFuelConsumption">Tank Capacity</h4>
                                        <div className="lineChartContainerTankCapacity">
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">FPT Ballast Tank</h4>
                                                <DynamicLineChart dataTank={listfptBallast} minValue={0} maxValue={listfptBallast && listfptBallast[0] ? listfptBallast.max_capacity : 20} stepSize={listfptBallast && listfptBallast[0] ? listfptBallast.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">APT Ballast Tank(P)</h4>
                                                <DynamicLineChart dataTank={listaptBallastP} minValue={0} maxValue={listaptBallastP && listaptBallastP[0] ? listaptBallastP.max_capacity : 20} stepSize={listaptBallastP && listaptBallastP[0] ? listaptBallastP.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">APT Ballast Tank(S)</h4>
                                                <DynamicLineChart dataTank={listaptBallastS} minValue={0} maxValue={listaptBallastS && listaptBallastS[0] ? listaptBallastS.max_capacity : 20} stepSize={listaptBallastS && listaptBallastS[0] ? listaptBallastS.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">F.O Tank No. 1 (P)</h4>
                                                <DynamicLineChart dataTank={listfotNo1P} minValue={0} maxValue={listfotNo1P && listfotNo1P[0] ? listfotNo1P.max_capacity : 20} stepSize={listfotNo1P && listfotNo1P[0] ? listfotNo1P.max_capacity / 5 : 5 } />
                                            </div>

                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">F.O Tank No. 1 (C)</h4>
                                                <DynamicLineChart dataTank={listfotNo1C} minValue={0} maxValue={listfotNo1C && listfotNo1C[0] ? listfotNo1C.max_capacity : 20} stepSize={listfotNo1C && listfotNo1C[0] ? listfotNo1C.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">F.O Tank No. 1 (S)</h4>
                                                <DynamicLineChart dataTank={listfotNo1S} minValue={0} maxValue={listfotNo1S && listfotNo1S[0] ? listfotNo1S.max_capacity : 20} stepSize={listfotNo1S && listfotNo1S[0] ? listfotNo1S.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">F.O Tank No. 2 (P)</h4>
                                                <DynamicLineChart dataTank={listfotNo2P} minValue={0} maxValue={listfotNo2P && listfotNo2P[0] ? listfotNo2P.max_capacity : 20} stepSize={listfotNo2P && listfotNo2P[0] ? listfotNo2P.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">F.O Tank No. 2 (S)</h4>
                                                <DynamicLineChart dataTank={listfotNo2S} minValue={0} maxValue={listfotNo2S && listfotNo2S[0] ? listfotNo2S.max_capacity : 20} stepSize={listfotNo2S && listfotNo2S[0] ? listfotNo2S.max_capacity / 5 : 5 } />
                                            </div>

                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">F.O Tank No. 3 (P)</h4>
                                                <DynamicLineChart dataTank={listfotNo3P} minValue={0} maxValue={listfotNo3P && listfotNo3P[0] ? listfotNo3P.max_capacity : 20} stepSize={listfotNo3P && listfotNo3P[0] ? listfotNo3P.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">F.O Tank No. 3 (S)</h4>
                                                <DynamicLineChart dataTank={listfotNo3S} minValue={0} maxValue={listfotNo3S && listfotNo3S[0] ? listfotNo3S.max_capacity : 20} stepSize={listfotNo3S && listfotNo3S[0] ? listfotNo3S.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">F.O Tank No. 4 (P)</h4>
                                                <DynamicLineChart dataTank={listfotNo4P} minValue={0} maxValue={listfotNo4P && listfotNo4P[0] ? listfotNo4P.max_capacity : 20} stepSize={listfotNo4P && listfotNo4P[0] ? listfotNo4P.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">F.O Tank No. 4 (C)</h4>
                                                <DynamicLineChart dataTank={listfotNo4C} minValue={0} maxValue={listfotNo4C && listfotNo4C[0] ? listfotNo4C.max_capacity : 20} stepSize={listfotNo4C && listfotNo4C[0] ? listfotNo4C.max_capacity / 5 : 5 } />
                                            </div>

                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">F.O Tank No. 4 (S)</h4>
                                                <DynamicLineChart dataTank={listfotNo4S} minValue={0} maxValue={listfotNo4S && listfotNo4S[0] ? listfotNo4S.max_capacity : 20} stepSize={listfotNo4S && listfotNo4S[0] ? listfotNo4S.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">F.O. Daily Tank</h4>
                                                <DynamicLineChart dataTank={listfodt} minValue={0} maxValue={listfodt && listfodt[0] ? listfodt.max_capacity : 20} stepSize={listfodt && listfodt[0] ? listfodt.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">Lube Oil Tank</h4>
                                                <DynamicLineChart dataTank={listlot} minValue={0} maxValue={listlot && listlot[0] ? listlot.max_capacity : 20} stepSize={listlot && listlot[0] ? listlot.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">Dirty Oil Tank</h4>
                                                <DynamicLineChart dataTank={listdot} minValue={0} maxValue={listdot && listdot[0] ? listdot.max_capacity : 20} stepSize={listdot && listdot[0] ? listdot.max_capacity / 5 : 5 } />
                                            </div>

                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">Fresh Water Tank (P)</h4>
                                                <DynamicLineChart dataTank={listfwP} minValue={0} maxValue={listfwP && listfwP[0] ? listfwP.max_capacity : 20} stepSize={listfwP && listfwP[0] ? listfwP.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">Fresh Water Tank (S)</h4>
                                                <DynamicLineChart dataTank={listfwS} minValue={0} maxValue={listfwS && listfwS[0] ? listfwS.max_capacity : 20} stepSize={listfwS && listfwS[0] ? listfwS.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">Cofferdam</h4>
                                                <DynamicLineChart dataTank={listcofferdam} minValue={0} maxValue={listcofferdam && listcofferdam[0] ? listcofferdam.max_capacity : 20} stepSize={listcofferdam && listcofferdam[0] ? listcofferdam.max_capacity / 5 : 5 } />
                                            </div>
                                            <div className="eachlineChartContainerTankCapacity">
                                                <h4 className="txtFortankNameFuelConsumption">Void</h4>
                                                <DynamicLineChart dataTank={listvoidTank} minValue={0} maxValue={listvoidTank && listvoidTank[0] ? listvoidTank.max_capacity : 20} stepSize={listvoidTank && listvoidTank[0] ? listvoidTank.max_capacity / 5 : 5 } />
                                            </div>


                                        </div>
                                    </div>
                                </>
                            }
                        />
                    </div>
                    <Loading
                    loading={loading}
                    loadingText={`${t("loading_component.text")}`}
                />
                </Container>
                {
                    isDataReady ? (
                        <div id="printFuelConsumption" style={{ display: 'none', padding: 10 }}>
                    <div style={{ width: '100%', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '90%', border: '1px black solid', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px' }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ width: '50%' }} src={WMI} />
                            </div>
                            <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <p style={{ fontSize: 'large', fontWeight: 'bold' }}>{vesselName}</p>
                                <p style={{ fontSize: 'medium', fontWeight: 'lighter' }}>{customerName}</p>
                            </div>
                        </div>

                        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px' }}>
                            <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>TANK CAPACITY SUMMARY</h2>
                        </div>

                        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '10px' }}>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <p style={{ fontSize: 'large', fontWeight: 'bold', margin: '0' }}>{'Start Date'}</p>
                                <p style={{ fontSize: 'medium', fontWeight: 'lighter', margin: '0' }}>{moment(selectStartDate).local().format("YYYY-MM-DD HH:mm")}</p>
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <p style={{ fontSize: 'large', fontWeight: 'bold', margin: '0' }}>{'End Date'}</p>
                                <p style={{ fontSize: 'medium', fontWeight: 'lighter', margin: '0' }}>{moment(selectEndDate).local().format("YYYY-MM-DD HH:mm")}</p>
                            </div>
                        </div>

                        <div style={{ width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                            {/* <table>
                                <thead>
                                    <th style={{ flex: 3 }} className="table-header-content"><p>{'Tank Name'}</p></th>
                                    <th style={{ flex: 2 }} className="table-header-content"><p>{'Start Capacity'}</p></th>
                                    <th style={{ flex: 2 }} className="table-header-content"><p>{'End Capacity'}</p></th>
                                    <th style={{ flex: 2 }} className="table-header-content"><p>{'Status'}</p></th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'FPT Ballast Tank'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfptBallast[0].capacity_m3}{" m3"}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfptBallast[listfptBallast.length - 1].capacity_m3}{" m3"}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFptBallast < 0 ? "Drained" : diffFptBallast > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'APT Ballast Tank (P)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listaptBallastP[0].capacity_m3}{" m3"}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listaptBallastP[listaptBallastP.length - 1].capacity_m3}{" m3"}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffAptBallastP < 0 ? "Drained" : diffAptBallastP > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'APT Ballast Tank (S)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listaptBallastS[0].capacity_m3}{" m3"}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listaptBallastS[listaptBallastS.length - 1].capacity_m3}{" m3"}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffAptBallastS < 0 ? "Drained" : diffAptBallastS > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 1 (P)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo1P[0].capacity_m3}{" m3"}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo1P[listfotNo1P.length - 1].capacity_m3}{" m3"}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo1P < 0 ? "Drained" : diffFotNo1P > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 1 (C)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo1C[0].capacity_m3}{" m3"}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo1C[listfotNo1C.length - 1].capacity_m3}{" m3"}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo1C < 0 ? "Drained" : diffFotNo1C > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 1 (S)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo1S[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo1S[listfotNo1S.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo1S < 0 ? "Drained" : diffFotNo1S > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 2 (P)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo2P[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo2P[listfotNo2P.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo2P < 0 ? "Drained" : diffFotNo2P > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 2 (S)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo2S[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo2S[listfotNo2S.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo2S < 0 ? "Drained" : diffFotNo2S > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 3 (P)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo3P[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo3P[listfotNo3P.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo3P < 0 ? "Drained" : diffFotNo1P > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 3 (S)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo3S[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo3S[listfotNo3S.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo3S < 0 ? "Drained" : diffFotNo3S > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 4 (P)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo4P[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo4P[listfotNo4P.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo4P < 0 ? "Drained" : diffFotNo4P > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 4 (C)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo4C[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo4C[listfotNo4C.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo4C < 0 ? "Drained" : diffFotNo4C > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'F.O Tank No. 4 (S)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo4S[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfotNo4S[listfotNo4S.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFotNo4S < 0 ? "Drained" : diffFotNo4S > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'F.O. Daily Tank'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfodt[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfodt[listfodt.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFodt < 0 ? "Drained" : diffFodt > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'Lube Oil Tank'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listlot[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listlot[listlot.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffLot < 0 ? "Drained" : diffLot > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'Dirty Oil Tank'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listdot[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listdot[listdot.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffDot < 0 ? "Drained" : diffDot > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'Fresh Water Tank (P)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfwP[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfwP[listfwP.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFwP < 0 ? "Drained" : diffFwP > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'Fresh Water Tank (S)'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfwS[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listfwS[listfwS.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffFwS < 0 ? "Drained" : diffFwS > 0 ? "Refueled" : "Constant"}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'Cofferdam'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listcofferdam[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listcofferdam[listcofferdam.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffCofferdam < 0 ? "Drained" : diffCofferdam > 0 ? "Refueled" : ""}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ flex: 3 }} className="table-body-content"><p>{'Void'}</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listvoidTank[0].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{listvoidTank[listvoidTank.length - 1].capacity_m3}m3</p></td>
                                        <td style={{ flex: 2 }} className="table-body-content"><p>{diffVoidTank < 0 ? "Drained" : diffVoidTank > 0 ? "Refueled" : ""}</p></td>
                                    </tr>
                                </tbody>

                            </table> */}
                        </div>

                    </div>
                </div>
                    ) : (<></>)
                }
                
            </div>
        </>
    )
}