import "./index.css";
// import "../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Button } from "react-bootstrap";
import BoxSwitchToggle from "../../../Components/BoxSwitchToggle/BoxSwitchToggle";
import React, { useEffect, useState } from "react"
import { getPLCCoordinate, isTokenValid, getPlcValue, getPLCReadingValue } from "../../../Helpers/ApplicationHelpers";
import Loading from "../../../Components/Loading";
// import Navbar from "../../../Components/NavBar";
// import LampNavButton from "../../../Components/LampNavButton/LampNavButton";
// import BitLamp from "../../../Components/BitLamp/BitLamp";
import BitLamp from "../../../Components/NewNavLightBitLamp/NewNavLightBitLamp";
import NavLightContainer from "../../../Components/NewNavLightContainerPosition/NewNavLightContainer"
import moment from "moment";

export default function CustomerSMSNavLightpage(props) {
    const { smsSerialNumber, vesselId } = props;
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    //const [bitlampList, setBitlampList] = useState([]);
    const [idCoordinateGroup, setIdCoordinateGroup] = useState([3]);
    const [coordinateType, setCoordinateType] = useState('M');
    const [lastReceivedDate, setLastReceivedDate] = useState();
    const [loading, setLoading] = useState(true);
    //const [navlightValue, setNavlightValue] = useState()
    const [bitlampList, setBitlampList] = useState({

        M118:
        {
            text: "ANCHOR",
            alarm: true,
            coordinate: "M118",
            value: ""
        }, M106: {
            text: "NUC 1",
            alarm: false,
            coordinate: "M106",
            value: ""
        }, M108: {
            text: "NUC 2",
            alarm: true,
            coordinate: "M108",
            value: ""
        }, M110: {
            text: "NUC 3",
            alarm: true,
            coordinate: "M110",
            value: ""
        }, M112: {
            text: "SPARE 1",
            alarm: true,
            coordinate: "M112",
            value: ""
        }, M114: {
            text: "SPARE 2",
            alarm: true,
            coordinate: "M114",
            value: ""
        },
        M102: {
            text: "PORT",
            alarm: true,
            coordinate: "M102",
            value: ""
        },
        M96: {
            text: "MAST 1",
            alarm: true,
            coordinate: "M96",
            value: ""
        },
        M98: {
            text: "MAST 2",
            alarm: true,
            coordinate: "M98",
            value: ""
        },
        M100: {
            text: "MAST 3",
            alarm: true,
            coordinate: "M100",
            value: ""
        },
        M104: {
            text: "STBD",
            alarm: true,
            coordinate: "M104",
            value: ""
        },
        M120: {
            text: "TOWING",
            alarm: true,
            coordinate: "M120",
            value: ""
        },
        M116: {
            text: "STERN",
            alarm: true,
            coordinate: "M116",
            value: ""
        }
    });


    useEffect(() => {
        const interval = setInterval(async () => {
            //console.log("page refreshed", refreshChecked);
            //console.log('SMS Serial Number: ', smsSerialNumber);
            loadCoordinate();
        }, 250);
        return () => clearInterval(interval);
    }, []);

    const loadCoordinate = async () => {
        try {
            // console.log(bitlampList["M112"]);
            setLoading(true);
            const response = await getPLCReadingValue(cookies.token, smsSerialNumber, idCoordinateGroup, coordinateType, vesselId);
            if (response) {
                let receivedDate = moment(response[0].read_date).format("yyyy-MM-DD HH:mm:ss");
                setLastReceivedDate(receivedDate);
                let bitlampListTmp = bitlampList;
                for (let bl in bitlampList) {
                    let find = response.find(p => p.coordinate_type === "M" && p.coordinate_number === +bitlampList[bl].coordinate.slice(1))
                    if (find) {
                        bitlampListTmp[bl].text = find.coordinate_desc;
                        bitlampListTmp[bl].alarm = find.is_alarm;
                        bitlampListTmp[bl].value = find.reading_value;
                    }
                }
                // console.log(bitlampListTmp);
                setBitlampList(bitlampListTmp);
                console.log(bitlampList)
                setLoading(false);
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <div className="navLNewRes">
                <div className="mainPageNavL">
                        <div className="innercontainerBoxNavLightLeft">
                            <div className="innerInnercontainerBoxNavLightLeft">
                                <div className="innercontainerBoxNavLightLeftFirstRow" style={{ marginTop: '30px' }}>
                                    <NavLightContainer text={bitlampList["M118"].text} alarm={bitlampList["M118"].value} />
                                </div>
                                <div className="innercontainerBoxNavLightLeftSecondRow" style={{ marginTop: '50px' }}>
                                    <NavLightContainer text={bitlampList["M106"].text} alarm={bitlampList["M106"].value} />
                                    <NavLightContainer text={'CUSTOM 1'} alarm={bitlampList["M112"].value} />
                                </div>
                                <div className="innercontainerBoxNavLightLeftSecondRow" style={{ marginTop: '50px' }}>
                                    <NavLightContainer text={bitlampList["M108"].text} alarm={bitlampList["M108"].value} />
                                    <NavLightContainer text={'CUSTOM 2'} alarm={bitlampList["M114"].value} />
                                </div>
                                <div className="innercontainerBoxNavLightLeftFourthRow" style={{ marginTop: '50px' }}>
                                    <NavLightContainer text={bitlampList["M110"].text} alarm={bitlampList["M110"].value} />
                                </div>
                                <div className="innercontainerBoxNavLightLeftSecondRow" style={{ marginTop: '5px' }}>
                                    <NavLightContainer text={bitlampList["M102"].text} alarm={bitlampList["M102"].value} />
                                    <NavLightContainer text={bitlampList["M96"].text} alarm={bitlampList["M96"].value} />
                                    <NavLightContainer text={bitlampList["M104"].text} alarm={bitlampList["M104"].value} />
                                </div>
                                <div className="innercontainerBoxNavLightLeftFirstRow" style={{ marginTop: '5px' }}>
                                    <NavLightContainer text={bitlampList["M98"].text} alarm={bitlampList["M98"].value} />
                                </div>
                                <div className="innercontainerBoxNavLightLeftFirstRow" style={{ marginTop: '5px' }}>
                                    <NavLightContainer text={bitlampList["M100"].text} alarm={bitlampList["M100"].value} />
                                </div>
                                <div className="innercontainerBoxNavLightLeftFirstRow" style={{ marginTop: '5px' }}>
                                    <NavLightContainer text={bitlampList["M120"].text} alarm={bitlampList["M120"].value} />
                                </div>
                                <div className="innercontainerBoxNavLightLeftFirstRow" style={{ marginTop: '5px' }}>
                                    <NavLightContainer text={bitlampList["M116"].text} alarm={bitlampList["M116"].value} />
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className="innercontainerBoxNavLightRight">
                            <div className="innerInnercontainerBoxNavLightRight"> */}
                                {/* <BitLamp text={'ANCHOR'} bitlampColor="black" alarm={bitlampList["M118"].value} />
                                <BitLamp text={'PORT'} bitlampColor="black" alarm={bitlampList["M102"].value} />
                                <BitLamp text={'STBD'} bitlampColor="black" alarm={bitlampList["M104"].value} />
                                <BitLamp text={'STERN'} bitlampColor="black" alarm={bitlampList["M116"].value} />
                                <BitLamp text={'TOWING'} bitlampColor="black" alarm={bitlampList["M120"].value} />
                                <BitLamp text={'MAST 1'} bitlampColor="black" alarm={bitlampList["M96"].value} />
                                <BitLamp text={'MAST 2'} bitlampColor="black" alarm={bitlampList["M98"].value} />
                                <BitLamp text={'MAST 3'} bitlampColor="black" alarm={bitlampList["M100"].value} />
                                <BitLamp text={'SPARE 1'} bitlampColor="black" alarm={bitlampList["M112"].value} />
                                <BitLamp text={'SPARE 2'} bitlampColor="black" alarm={bitlampList["M114"].value} />
                                <BitLamp text={'MAST 1'} bitlampColor="black" alarm={bitlampList["M96"].value} />
                                <BitLamp text={'MAST 2'} bitlampColor="black" alarm={bitlampList["M98"].value} />
                                <BitLamp text={'MAST 3'} bitlampColor="black" alarm={bitlampList["M100"].value} />
                                <BitLamp text={'SPARE 1'} bitlampColor="black" alarm={bitlampList["M112"].value} />
                                <BitLamp text={'SPARE 2'} bitlampColor="black" alarm={bitlampList["M114"].value} />
                                <BitLamp text={'NUC 1'} bitlampColor="black" alarm={bitlampList["M106"].value} />
                                <BitLamp text={'NUC 2'} bitlampColor="black" alarm={bitlampList["M108"].value} />
                                <BitLamp text={'NUC 3'} bitlampColor="black" alarm={bitlampList["M110"].value} /> */}
                            {/* </div>
                        </div> */}
                    <div className="lastReceivedDateContainerNew">
                        <p className="txtforLastReceivedData" style={{margin: '20px'}}>Last Received Data on {lastReceivedDate}</p>
                    </div>
                </div>
            </div>
        </>
    );
}