import './index.css'
import React from 'react';
import { IconButton, InputAdornment, Link, Avatar, colors } from "@mui/material";
import { useCookies } from "react-cookie";
import { useNavigate, NavLink } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Notifications } from "@mui/icons-material";
import { isTokenValid } from '../../../Helpers/ApplicationHelpers';
import { useTranslation } from "react-i18next";
import Clock from 'react-live-clock';
import { getCompanyLogo } from '../../../Helpers/ApplicationHelpers';
import "./index.css";
import { InfoCircle } from 'react-bootstrap-icons';

export default function FMSTopBar(props) {
    const qLogo = require("./../../../Assets/Images/logo-quantum-white.png");
    const [cookies, setCookie] = useCookies(["token", "userId", "userName", "userEmail"]);
    const [companyLogo, setCompanyLogo] = useState();

    useEffect(() => {
        loadCompanyLogo();
    }, []);

    const loadCompanyLogo = async () => {
        try {
            let res = await getCompanyLogo(cookies.token, cookies.extCustomerId);
            console.log(res);
            setCompanyLogo(res.logo_image);
        }
        catch (exception) {
            console.log(exception);
        }
    };

    const stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    return (
        <div className="topbar-container">
            <div className='topbar-container-left'>
                <div className='topbar-container-padding'>
                    <img height={54} src={qLogo}></img>
                </div>
                <div className='topbar-container-padding'>
                    <img height={54} src={companyLogo}></img>
                </div>
                <div className='topbar-container-padding'></div>
                <div className='topbar-container-padding'>
                    <h3 className='h3titleFMS'>{'FLEET MANAGEMENT SYSTEM'}</h3>
                </div>

            </div>
            <div className='topbar-container-right'>
                <div className='topbar-container-padding' style={{marginTop: '10px'}}>
                    <h3 className="h3titleFMS">
                        <Clock
                            format={'YYYY/MM/DD hh:mm A'}
                            ticking={true}
                            timezone={'Asia/Jakarta'}
                        />
                    </h3>
                </div>
                <div className='topbar-container-padding'>
                    <IconButton sx={{ color: "white", height: 36, width: 36 }} aria-label="notification">
                        <Notifications sx={{
                            color: "white",
                            height: 36,
                            width: 36
                        }} />
                    </IconButton>
                </div>
                <div className='topbar-container-padding'>
                    <IconButton sx={ {color: "white", height: 40, width: 40 }} aria-label="notification">
                        <InfoCircle sx={{
                            color: "white",
                            height: 40,
                            width: 40
                        }} />
                    </IconButton>
                </div>
                <div className='topbar-container-padding' style={{marginTop: '15px'}}>
                    <p className='ptitleFMS'>{cookies.userName}</p>
                </div>
                <div className='topbar-container-padding'>
                    <Avatar sx={{
                        bgcolor: stringToColor(cookies.userName),
                        width: 56,
                        height: 56
                    }}>{cookies.userName.slice(0, 1)}</Avatar>
                </div>

            </div>
        </div>
    );
};