// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapseContainerVesselTracking{
    width: 350px;
    height: 400px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 10px;
}

.collapseContainerVesselTrackingBody{
    /* border: 1px black solid; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/v2/Pages/FMSVessel/VesselTracking/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,0CAA0C;IAC1C,2CAA2C;IAC3C,kBAAkB;IAClB,0BAA0B;IAC1B,uBAAuB;IACvB,sBAAsB;IACtB,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;AACvB","sourcesContent":[".collapseContainerVesselTracking{\n    width: 350px;\n    height: 400px;\n    background-color: rgba(255, 255, 255, 0.9);\n    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);\n    border-radius: 5px;\n    -webkit-border-radius: 5px;\n    -moz-border-radius: 5px;\n    -ms-border-radius: 5px;\n    -o-border-radius: 5px;\n    padding: 10px;\n}\n\n.collapseContainerVesselTrackingBody{\n    /* border: 1px black solid; */\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
