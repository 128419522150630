import './index.css'
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getVesselTrackingData, getDistanceFromLatLonInKm } from "../../../../Helpers/ApplicationHelpers";
import "../../../AppV2.css";
import { AccountCircle, Key, Login, Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTopBar from "../../../../Components/v2/FMSTopBar";
import FMSTheme from "../../../FMSTheme";
import FMSSidebar from '../../../../Components/v2/FMSSidebar';
import EnhancedTable from '../../../../Components/v2/FMSTable';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../../Components/v2/FMSNavBarMUI";
import OpenSeaMap from "../../../../Components/v2/FMSOpenSeaMapTracking/index";
// import OpenSeaMap from "../../../../Components/OpenSeaMapContainer";
import { parseNmeaSentence } from "nmea-simple";
import { MapContainer, Marker, Popup, TileLayer, useMap, rotatedMarker } from "react-leaflet";
import moment from "moment";
import DmsCoordinates from "dms-conversion";
import RotatedMarker from "../../../../Components/RotatedMarker";
import vesselImg from "../../../../Assets/Images/new_arrow_pointer.png";
import { Box, Card, Button, Typography } from "@mui/material";
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import DropDownForm from "../../../../Components/DropDownForm";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { Form } from 'react-bootstrap';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

export default function FMSVesselTrackingPage() {
    const { t, i18n } = useTranslation();
    let location = useLocation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const [gpsData, setGPSData] = useState([]);
    const [listNMEAData, setListNMEAData] = useState([]);
    const [engineAndLocationData, setEngineAndLocationData] = useState();
    const [selectedNMEAData, setSelectedNMEAData] = useState();
    const [listVesselData, setListVesselData] = useState();
    const [listLineData, setListLineData] = useState();
    const [markerData, setMarkerData] = useState([]);
    const [vesselId, setVesselId] = useState("");
    const navigate = useNavigate();
    const [selectStartDate, setSelectStartDate] = useState();
    const [selectEndDate, setSelectEndDate] = useState();
    const [checked, setChecked] = useState(false);
    const [checkedSummary, setCheckedSummary] = useState(false);
    const [processedData, setProcessedData] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [totalDuration, setTotalDuration] = useState();
    const [totalDistance, setTotalDistance] = useState();
    const [averageSpeed, setAverageSpeed] = useState();
    const [averageMEP, setAverageMEP] = useState();
    const [averageMES, setAverageMES] = useState();
    const [averageAEP, setAverageAEP] = useState();
    const [averageAES, setAverageAES] = useState();

    const durationOption = [
        {
            label: "1 Hour Ago",
            value: 1,
        },
        {
            label: "1 Day Ago",
            value: 2,
        },
        {
            label: "1 Week Ago",
            value: 3,
        },
    ];

    const handleChange = () => {
        setChecked((prev) => !prev);
        setCheckedSummary(false);
    };

    const handleChangeSummary = () => {
        setChecked(false);
        setCheckedSummary((prev) => !prev);
    };

    const icon = (
        <Paper sx={{ m: 1, width: 100, height: 100 }} elevation={4}>
            <svg>
                <Box
                    component="polygon"
                    points="0,100 50,00, 100,100"
                    sx={(theme) => ({
                        fill: theme.palette.common.white,
                        stroke: theme.palette.divider,
                        strokeWidth: 1,
                    })}
                />
            </svg>
        </Paper>
    );

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                if (location.state.vesselId) {
                    setVesselId(location.state.vesselId);
                } else {
                    navigate("/FMS");
                }
            }
        }
        checkCookies();
    }, []);


    useEffect(() => {
        console.log('selectEndDate', selectEndDate);
    }, [selectEndDate])

    useEffect(() => {
        console.log('selectStartDate', selectStartDate);
    }, [selectStartDate])

    useEffect(() => {
        console.log('processedData', processedData);
        if (processedData) {
            if (processedData.length > 0) {
                let listMarker = [];
                let lastMarker = null;
                let listLine = [];
                let mepRPMArray = [];
                let idxForMEPRPM = 0;
                let idxForMESRPM = 0;
                let idxForAEPRPM = 0;
                let idxForAESRPM = 0;
                let totalMEPRPM = 0;
                let totalMESRPM = 0;
                let totalAEPRPM = 0;
                let totalAESRPM = 0;
                let idxForDistance = 0;
                let idxForSpeed = 0;
                let totalDistance = 0;
                let totalSpeed = 0;
                let idx = 0;
                processedData.forEach((data) => {
                    const dmsCoords = new DmsCoordinates(parseFloat(data.latitude), parseFloat(data.longitude));

                    let markerEl =
                        <RotatedMarker key={idx} position={[data.latitude, data.longitude]} icon={vesselIcon} rotationAngle={
                            data.course > 359.9 ? (data.course - 359.9).toFixed(1) : data.course
                        } rotationOrigin='center' timseStamp={data.timestamp_gps} speed={parseFloat(data.speed)}
                            rpmME={[parseFloat(data.mep_rpm), parseFloat(data.mes_rpm)]}
                            rpmAE={[parseFloat(data.aep_rpm), parseFloat(data.aes_rpm)]}>
                            <Popup>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                                    <div style={{ border: '1px white solid', width: '250px', height: '1px', backgroundColor: 'white', margin: '5px 0' }}></div>
                                    {/* <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'large', margin: '0px', fontWeight: 'bold' }}>{detailVesselData.vessel_name}</p>
                                        </div> */}
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%' }}>
                                        <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Position'}</p>
                                        <p style={{ fontSize: 'smaller', margin: '0px' }}>{dmsCoords.latitude.toString()}</p>
                                        <p style={{ fontSize: 'smaller', margin: '0px' }}>{dmsCoords.longitude.toString()}</p>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%' }}>
                                        <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Time'}</p>
                                        <p style={{ fontSize: 'smaller', margin: '0px' }}>{data.timestamp_gps}</p>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Speed'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.speed)).toFixed(1)} knot</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'COG'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>
                                                {
                                                    data.course > 359.9 ? (data.course - 359.9).toFixed(1) : data.course
                                                } °
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Main Engine'}</p>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Port'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.mep_rpm)).toFixed(1)}</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Starboard'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.mes_rpm)).toFixed(1)}</p>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Aux Engine'}</p>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Port'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.aep_rpm)).toFixed(1)}</p>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'RPM Starboard'}</p>
                                            <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.aes_rpm)).toFixed(1)}</p>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px grey solid', width: '100%', height: '1px', backgroundColor: 'grey', margin: '5px 0' }}></div>
                                    {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ width: '100%' }}>
                                                <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Throttle Port'}</p>
                                                <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.throttlePort)).toFixed(1)}%</p>
                                            </div>
                                            <div style={{ width: '100%' }}>
                                                <p style={{ fontSize: 'small', margin: '0px', fontWeight: 'bold' }}>{'Throttle Starboard'}</p>
                                                <p style={{ fontSize: 'smaller', margin: '0px' }}>{(parseFloat(data.throttleStarboard)).toFixed(1)}%</p>
                                            </div>
                                        </div> */}
                                </div>
                            </Popup>
                        </RotatedMarker>;

                    if (lastMarker !== null) {
                        let distance = getDistanceFromLatLonInKm(lastMarker.props.position[0], lastMarker.props.position[1], markerEl.props.position[0], markerEl.props.position[1]);
                        let speed = lastMarker.props.speed;
                        let MEPrpm = lastMarker.props.rpmME[0];
                        let MESrpm = lastMarker.props.rpmME[1];
                        let AEPrpm = lastMarker.props.rpmAE[0];
                        let AESrpm = lastMarker.props.rpmAE[1];
                        console.log("Distance: ", distance);
                        if (!isNaN(distance)) {
                            totalDistance += distance;
                            idxForDistance++;
                        }
                        if (!isNaN(speed)) {
                            totalSpeed += speed;
                            idxForSpeed++;
                        }
                        if (!isNaN(MEPrpm)) {
                            totalMEPRPM += MEPrpm;
                            // mepRPMArray.push(MEPrpm);
                            idxForMEPRPM++;
                        }
                        if (!isNaN(MESrpm)) {
                            totalMESRPM += MESrpm;
                            idxForMESRPM++;
                        }
                        if (!isNaN(AEPrpm)) {
                            totalAEPRPM += AEPrpm;
                            idxForAEPRPM++;
                        }
                        if (!isNaN(AESrpm)) {
                            totalAESRPM += AESrpm;
                            idxForAEPRPM++;
                        }

                    }

                    listLine.push(markerEl);
                    console.log('markerE1', markerEl);
                    //console.log("MARKER", markerChild.listMarker[0].props);

                    if (!lastMarker) {
                        listMarker.push(markerEl);
                        lastMarker = markerEl;
                    } else {
                        console.log(lastMarker.props);
                        if (Math.abs(parseFloat(lastMarker.props.rotationAngle) - parseFloat(markerEl.props.rotationAngle)) > 10) {
                            listMarker.push(markerEl);
                        }
                    }

                    idx++;
                    lastMarker = markerEl;
                })
                console.log('PANJANG', listMarker);
                let lastIndexFromListMarker = listMarker.length - 1;
                let startTime = listMarker[0].props.timseStamp;
                let endTime = listMarker[lastIndexFromListMarker].props.timseStamp;
                let getDuration = moment(endTime).diff(moment(startTime))
                let duration = moment.duration(getDuration).format("d[d]  hh:mm:ss");
                let averageSpeed = totalSpeed / idxForSpeed + 1;
                let averageMEP = totalMEPRPM / idxForMEPRPM + 1;
                let averageMES = totalMESRPM / idxForMESRPM + 1;
                let averageAEP = totalAEPRPM / idxForAEPRPM + 1;
                let averageAES = totalAESRPM / idxForAESRPM + 1;
                console.log("Duration", duration);
                console.log("Total Distance: ", totalDistance);
                setStartTime(startTime);
                setEndTime(endTime);
                setTotalDuration(duration);
                setTotalDistance(totalDistance);
                setAverageSpeed(averageSpeed)
                setAverageMEP(averageMEP);
                setAverageMES(averageMES);
                setAverageAEP(averageAEP);
                setAverageAES(averageAES);

                let markerObj = {
                    listVessel: [],
                    listMarker: listMarker,
                    listLine: listLine
                }
                setMarkerData(markerObj);
                // setIsDataReady(true);
                // setLoading(false);
            } else {
                let listMarker = [];
                let markerObj = {
                    listVessel: [],
                    listMarker: listMarker,
                    listLine: []
                }
                setMarkerData(markerObj);
                // setIsDataReady(true);
                // setLoading(false);
            }
        }

    }, [processedData])


    useEffect(() => {
        if (engineAndLocationData) {
            console.log(engineAndLocationData);
            if (engineAndLocationData.length > 0) {
                const mappedData = engineAndLocationData.filter((item) => item.master_coordinate_id === 11201).map((mePRPMItem) => {
                    const { nmea_read_date, latitude, longitude } = mePRPMItem;
                    const meSRPM = engineAndLocationData.find((item) => item.master_coordinate_id === 12201 && item.nmea_read_date === nmea_read_date);
                    const aePRPM = engineAndLocationData.find((item) => item.master_coordinate_id === 21201 && item.nmea_read_date === nmea_read_date);
                    const aeSRPM = engineAndLocationData.find((item) => item.master_coordinate_id === 22201 && item.nmea_read_date === nmea_read_date);
                    return {
                        latitude,
                        longitude,
                        "timestamp_gps": nmea_read_date,
                        "mep_rpm": mePRPMItem.reading_value,
                        "mes_rpm": meSRPM ? meSRPM.reading_value : null,
                        "aep_rpm": aePRPM ? aePRPM.reading_value : null,
                        "aes_rpm": aeSRPM ? aeSRPM.reading_value : null,
                        "course": 0,
                        "speed": 0

                    };
                });

                setProcessedData(mappedData);
            }
        }
    }, [engineAndLocationData])

    const processEngineAndLocationData = (objData) => {
        try {
            const packet = parseNmeaSentence(objData.nmea_sentence.replace("\r", "\r\n"));

            if (packet.sentenceId === "RMC" && packet.status === "valid") {
                return {
                    ...objData,
                    latitude: packet.latitude,
                    longitude: packet.longitude
                };
            } else {
                console.error("Invalid or unsupported NMEA sentence:", data.nmea_sentence);
            }
        }
        catch (exception) {
            console.log(exception);
        }
        return objData;

    }

    let vesselIcon = L.icon({
        iconUrl: vesselImg,
        iconRetinaUrl: vesselImg,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });

    const loadHistorisCoordinate = async () => {
        try {
            let response = await getVesselTrackingData(cookies.token, vesselId, selectEndDate, selectStartDate);
            if (response) {
                let newArr = [];
                for (let i = 0; i < response.length; i++) {
                    const updatedData = processEngineAndLocationData(response[i]);
                    newArr.push(updatedData);
                }
                setEngineAndLocationData(newArr);
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <ThemeProvider theme={FMSTheme}>
                <div className='mainContainerFMS'>
                    <NavBar />
                    <div className='childMainContainerFMS'>
                        <div className='childMainContainerFMS-TOP'>
                            <p className='ptitleFMSDashboard'>VESSEL TRACKING</p>
                        </div>
                        <div className='childMainContainerFMS-BODY' style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '150px', left: '10px', zIndex: 1000 }}>
                                <Box
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: 1,
                                        bgcolor: '#1E3A8A',
                                        ml: 1,
                                        boxShadow: 5
                                    }}
                                >
                                    <IconButton aria-label="delete" size="medium" sx={{
                                        color: 'white'
                                    }} onClick={handleChange}>
                                        <SearchIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>

                                <Box
                                    sx={{
                                        '& > :not(style)': {
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            height: 120,
                                            width: 250,
                                        },
                                    }}
                                >
                                    <div style={{ marginLeft: '60px', marginTop: '50px' }}>
                                        <Collapse in={checked}>
                                            <div className="collapseContainerVesselTracking">
                                                <div className='collapseContainerVesselTrackingBody'>
                                                    <Form.Group className="mb-3" style={{ width: '100%' }}>
                                                        <Form.Label>Start Date</Form.Label>
                                                        <Form.Control
                                                            type="datetime-local"
                                                            onChange={(e) => {
                                                                const localDateTime = moment(e.target.value, "YYYY-MM-DD HH:mm:ss Z");
                                                                setSelectStartDate(localDateTime.format("YYYY-MM-DD HH:mm:ss Z"));
                                                                // setSelectStartDate(moment(e.target.value).format("YYYY-MM-DDTHH:mm"));
                                                            }}
                                                            value={
                                                                selectStartDate
                                                                    ? moment(selectStartDate).format("YYYY-MM-DD HH:mm:ss")
                                                                    : ""
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" style={{ width: '100%' }}>
                                                        <Form.Label>End Date</Form.Label>
                                                        <Form.Control
                                                            type="datetime-local"
                                                            onChange={(e) => {
                                                                const localDateTime = moment(e.target.value, "YYYY-MM-DD HH:mm:ss Z");
                                                                setSelectEndDate(localDateTime.format("YYYY-MM-DD HH:mm:ss Z"));
                                                                // setSelectEndDate(moment(e.target.value).format("YYYY-MM-DDTHH:mm"));
                                                            }}
                                                            value={
                                                                selectEndDate
                                                                    ? moment(selectEndDate).format("YYYY-MM-DD HH:mm:ss")
                                                                    : ""
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>

                                                    <Form.Group style={{ width: '100%' }}>
                                                        <DropDownForm
                                                            caption={"Duration"}
                                                            placeholder={"Select Time ..."}
                                                            listOption={durationOption}
                                                            valueKey={"value"}
                                                            labelKey={"label"}
                                                            setValueFunction={(e) => {
                                                                console.log(e);
                                                                try {
                                                                    let dateTime = new Date();
                                                                    if (e.target.value === "1") {
                                                                        console.log("1");
                                                                        let startDate = moment(dateTime).subtract(60, "minutes").format("YYYY-MM-DD HH:mm");
                                                                        let endDate = moment(dateTime).format("YYYY-MM-DD HH:mm");
                                                                        setSelectStartDate(startDate);
                                                                        setSelectEndDate(endDate);
                                                                    } else if (e.target.value === "2") {
                                                                        console.log("2");
                                                                        let startDate = moment(dateTime).subtract(1, "day").format("YYYY-MM-DD HH:mm");
                                                                        let endDate = moment(dateTime).format("YYYY-MM-DD HH:mm");
                                                                        setSelectStartDate(startDate);
                                                                        setSelectEndDate(endDate);
                                                                        console.log("tes", startDate, endDate);
                                                                    } else if (e.target.value === "3") {
                                                                        console.log("3");
                                                                        let startDate = moment(dateTime).subtract(1, "week").format("YYYY-MM-DD HH:mm");
                                                                        let endDate = moment(dateTime).format("YYYY-MM-DD HH:mm");
                                                                        setSelectStartDate(startDate);
                                                                        setSelectEndDate(endDate);
                                                                    }
                                                                } catch (exception) {
                                                                    console.log(exception);
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group style={{ width: '100%', marginTop: '10px' }}>
                                                        <Button style={{ width: '100%' }} variant="contained"
                                                            onClick={() => {
                                                                loadHistorisCoordinate();
                                                            }}>Find Vesel Tracking Data</Button>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </Box>
                            </div>

                            <div style={{ position: 'absolute', top: '200px', left: '10px', zIndex: 1000 }}>
                                <Box
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: 1,
                                        bgcolor: '#1E3A8A',
                                        ml: 1,
                                        boxShadow: 5
                                    }}
                                >
                                    <IconButton aria-label="delete" size="medium" sx={{
                                        color: 'white'
                                    }} onClick={handleChangeSummary}>
                                        <StickyNote2Icon fontSize="inherit" />
                                    </IconButton>
                                </Box>

                                <Box
                                    sx={{
                                        '& > :not(style)': {
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            height: 120,
                                            width: 250,
                                        },
                                    }}
                                >
                                    <div style={{ marginLeft: '60px', marginTop: '0px' }}>
                                        <Collapse in={checkedSummary}>
                                            <div className="collapseContainerVesselTracking">
                                                <div className='collapseContainerVesselTrackingBody'>
                                                    <div style={{ flex: 3, width: '100%', height: '100%' }}>
                                                        <CardContent>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 18 }}>
                                                                    Tracking Summary
                                                                </Typography>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    Start
                                                                </Typography>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    End
                                                                </Typography>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    {moment(startTime).format('YYYY-MM-DD HH:mm')}
                                                                </Typography>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    {moment(endTime).format('YYYY-MM-DD HH:mm')}
                                                                </Typography>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    Duration
                                                                </Typography>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    Distance
                                                                </Typography>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    {totalDuration}
                                                                </Typography>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    {parseFloat(totalDistance).toFixed(2)} KM
                                                                </Typography>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 18 }}>
                                                                    Average Main Engine
                                                                </Typography>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    Port
                                                                </Typography>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    Starboard
                                                                </Typography>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    {averageMEP !== null ? parseFloat(averageMEP).toFixed(2) : "N/A"} RPM
                                                                </Typography>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    {averageMES !== null ? parseFloat(averageMES).toFixed(2) : "N/A"} RPM
                                                                </Typography>
                                                            </div>

                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 18 }}>
                                                                    Average Auxiliary Engine
                                                                </Typography>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    Port
                                                                </Typography>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    Starboard
                                                                </Typography>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    {averageAEP !== null ? parseFloat(averageAEP).toFixed(2) : "N/A"} RPM
                                                                </Typography>
                                                                <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 14 }}>
                                                                    {averageAES !== null ? parseFloat(averageAES).toFixed(2) : "N/A"} RPM
                                                                </Typography>
                                                            </div>
                                                        </CardContent>
                                                    </div>
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </Box>
                            </div>


                            {
                                markerData && <OpenSeaMap markerChild={markerData} isTracking={false} />
                            }

                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </>
    );

}