import './index.css'
import { isTokenValid, getCompanyLogo } from '../../../Helpers/ApplicationHelpers';
import { getAuthorizedMenuV2 } from '../../../Helpers/AccountHelpers';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import Dashboard from "../../../Assets/Images/FMS/Dashboard.png";
import Vessel from "../../../Assets/Images/FMS/Vessel.png";
import Summary from "../../../Assets/Images/FMS/Summary.png";
import Maintenance from "../../../Assets/Images/FMS/Maintenance.png";
import Crew from "../../../Assets/Images/FMS/Crew.png";
import ShipInventory from "../../../Assets/Images/FMS/ShipInventory.png";
import Analytics from "../../../Assets/Images/FMS/Analytics.png";
import { Trans, useTranslation } from "react-i18next";

export default function FMSSidebar() {
    const { t, i18n } = useTranslation();
    const [listMenu, setListMenu] = useState([]);
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                loadAuthorizedMenu();
            }
        }
        checkCookies();
    }, []);


    const loadAuthorizedMenu = async () => {
        try {
            let listMenu = await getAuthorizedMenuV2(cookies.token, cookies.userId);
            setListMenu(listMenu);
        }
        catch (exception) {
            console.log(exception);
        }
    }
    return (
        <div className="main-container-sidebar">
            {
                listMenu && listMenu.map((menu, index) => {
                    const imagePath = `../../../Assets/Images/FMS/${menu.menu_name}.png`;
                    // console.log(menu.menu_name , imagePath);
                    return (
                        <div key={index} className='menuBox' onClick={() => {
                            let nav = "/FMS" + menu.menu_path;
                            navigate(nav);
                        }}>
                            <img height={40} src={imagePath} style={{ padding: '3px' }}></img>
                            <p className='ptitleFMSButton'>{menu.menu_name}</p>
                        </div>
                    )

                })
            }

            {/* <div className='menuBox' onClick={() => {
                navigate("/FMS/Vessel")
            }}>
                <img height={40} src={vesselLogo} style={{ padding: '3px' }}></img>
                <p className='ptitleFMSButton'>Vessel</p>
            </div>
            <div className='menuBox'>
                <img height={40} src={smsLogo} style={{ padding: '3px' }}></img>
                <p className='ptitleFMSButton'>Summary</p>
            </div>
            <div className='menuBox'>
                <img height={40} src={pmsLogo} style={{ padding: '3px' }}></img>
                <p className='ptitleFMSButton'>Maintenance</p>
            </div>
            <div className='menuBox'>
                <img height={40} src={crewLogo} style={{ padding: '3px' }}></img>
                <p className='ptitleFMSButton'>Crew</p>
            </div>
            <div className='menuBox'>
                <img height={40} src={shipLogo} style={{ padding: '3px' }}></img>
                <p className='ptitleFMSButton'>Ship Inventory</p>
            </div>
            <div className='menuBox'>
                <img height={40} src={analyticLogo} style={{ padding: '3px' }}></img>
                <p className='ptitleFMSButton'>Analytics</p>
            </div> */}
        </div>
    )
}