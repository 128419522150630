import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, convertBase64} from "../../../../../Helpers/ApplicationHelpers";
import { getInventoryDocumentByCount, getInventoryDocumentById, insertUpdateInventoryDocument, getSelectedDocumentByIdNew, getInventoryDocumentByIdNew,deleteInventoryDocument, getSelectedDocumentById } from "../../../../../Helpers/InventoryHelpers";
import { Modal, Button, Form, Container, Col, Row, Table } from 'react-bootstrap';
import { Trash, FilePlusFill, XSquareFill,
    AspectRatioFill, GearWideConnected, EyeFill, ArrowLeft, Download, ChevronDoubleRight, ChevronDoubleLeft,  ZoomIn, ZoomOut } from "react-bootstrap-icons";
import Navbar from "../../../../../Components/NavBar";
import Paginations from "../../../../../Components/Pagination";
import Modals from "../../../../../Components/modal";
import Loading from "../../../../../Components/Loading";
import ContainerBox from "../../../../../Components/ContainerBox";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";
import { Document, Page, pdfjs } from 'react-pdf';
import { triggerBase64Download } from "../../../../../Helpers/Base64Downloader";
import { omit } from 'lodash';

export default function InventoryDocumentPage () {
    const [cookies, setCookie] = useCookies(["token"]);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);
    // const [disabledCustomerForm, setDisabledCustomerForm] = useState(true);
    const [deletedButton, setDeletedButton] = useState("");
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(true);
    const [uploadFIleDocumentError, setUploadFileDocumentError] = useState("");
    const handleShow = () => setShow(true);
    const [page, setPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(4);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([4, 8, 12, 20, 40]);
    const [numPages, setNumPages] = useState(null);
    const [zoomFactor, setZoomFactor] = useState(0.4);
    const [backButton, setBackButton] = useState();
    const [orderBy, setOrderBy] = useState({
        label: `${t("index_inventory_document.order_name1")}`,
        value: "created_date"
    });
    const orderBySelection = [
        {
            label: `${t("index_inventory_document.order_name1")}`,
            value: "created_date"
        }
    ]
    const [documentToBeViewed, setDocumentToBeViewed] = useState({});
    const [errors, setErrors] = useState([]);
    const [deleteDataId, setDeleteDataId] = useState();
    const [downloadDataId, setDownloadDataId] = useState();
    const [documentFile, setDocumentFile] = useState("");
    const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false);
    const [showDocumentDetailModal, setShowDocumentDetailModal] = useState(false);
    const [listInventoryDocument, setListInventoryDocument] = useState([]);
    const [inventoryDocumentsId, setInventoryDocumentsId] = useState("");
    const [inventoryDocumentsName, setInventoryDocumentsName] = useState("");
    const [currentIndex, setCurrentIndex] = useState(null);
    const [inventoryDocumentCount, setInventoryDocumentCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isSearched, setIsSearched] = useState(0);
    const [queryString, setQueryString] = useState({});
    const [inventoryDocument, setInventoryDocument] = useState({
        id: 0,
        document_base64: "",
        document_name: "",
        description: "",
        inventory_master_id: "",
        is_active: true,
        created_date: "",
        created_by: "",
        updated_date: "",
        updated_by: "",
        reference_number: "",
    })
    const [fileDocument, setFileDocument] = useState("");
    // const [coverId, setCoverId] = useState("");

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {

                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                  } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                  }
                  setQueryString({
                    inventoryId: searchParams.get("inventoryId"),
                    inventoryName: searchParams.get("inventoryName"),
                    backButton: searchParams.get("backButton")
                  });
                  console.log(searchParams.get("inventoryName"));
            }
        }
        checkCookies();
        console.log(cookies);
    }, []);

    useEffect(() => {
        if (listInventoryDocument){
            console.log('listInventoryDocument', listInventoryDocument);
            setLoading(false);
        }
    }, [listInventoryDocument])

    useEffect(() => {
        if (inventoryDocumentsId !== "")
            loadDocumentById()
    }, [inventoryDocumentsId]);

    useEffect(()=>{
        if (queryString.inventoryId) {
            setBackButton(queryString.backButton);
            loadInventoryDocuments();
        }
    }, [queryString])

    useEffect(() => {
        if (documentToBeViewed){
            if (documentToBeViewed.document_base64){
                setShowDocumentDetailModal(true);
            }
        }
    }, [documentToBeViewed]);

    useEffect(() => {
        if (downloadDataId !== "")
            downloadData()
    }, [downloadDataId]);

    useEffect(() => {
        console.log('delete data id', deleteDataId);
        if (deleteDataId !== "")
            console.log('DELETEEEEE');
            removeInventoryDocument();
    }, [deleteDataId]);

    useEffect(() => {
        validationForm();
    }, [inventoryDocument])

    const loadDocumentById = async () => {
        try {
            let response = await getSelectedDocumentByIdNew(cookies.token, inventoryDocumentsId);
            console.log(response);
            setDocumentToBeViewed(response);
        } catch (exception) {
            console.log(exception)
        }
    }

    const loadInventoryDocuments = async () => {
        // setLoading(true);
        // setDisabledButton(true);
        let inventoryDocumentCount = await getInventoryDocumentByCount(cookies.token, queryString.inventoryId);
        setInventoryDocumentCount(inventoryDocumentCount);
        let totalPage = inventoryDocumentCount / itemPerPage;
        setTotalPage(totalPage);

        let response = await getInventoryDocumentByIdNew(cookies.token, queryString.inventoryId, page, itemPerPage, orderBy.value);
        // console.log(response);
        setListInventoryDocument(response);
        // if (response) {
        //     setInventoryPhoto({
        //         id: response[0].id,
        //         base64_image: response.base64_image,
        //         label: response.label,
        //         is_active: response.is_active,
        //         created_date: response.created_date,
        //         created_by: response.created_by,
        //         updated_date: response.updated_date,
        //         updated_by: response.updated_by,
        //         inventory_id: response.inventory_id,
        //         inventory_master_name: response.inventory_master_name
        //     });
        // }
        
        setIsSearched(0);
        // setLoading(false);
        // setDisabledButton(false);
    }

    const addDocument = async () => {
        try {
            // setDisabledButton(true);
            let inventoryDocumentTmp = inventoryDocument;
            inventoryDocumentTmp.created_by = cookies.userId;
            inventoryDocumentTmp.updated_by = cookies.userId;
            inventoryDocumentTmp.inventory_master_id = queryString.inventoryId;
            console.log(inventoryDocumentTmp);

            let response = await insertUpdateInventoryDocument(cookies.token, inventoryDocumentTmp);
            if (response === 0) {
                alert(`${t("index_inventory_document.alert_success_save_data")}`);
                setShowDocumentUploadModal(false);
                loadInventoryDocuments();
            }
            else {
                alert(`${t("index_inventory_document.alert_failed_save_data")}`);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const downloadData = async () => {
        try {
            let response = await getSelectedDocumentByIdNew(cookies.token, downloadDataId);
            triggerBase64Download(response.document_base64, response.document_name);
            setDownloadDataId("");
        } catch (exception) {
            console.log(exception)
        }
    }

    const removeInventoryDocument = async () => {
        let response = await deleteInventoryDocument(cookies.token, deleteDataId);
        console.log(response);
        if (response === 0) {
            (window.confirm(`${t("index_document_technician.delete_success_alert")}`))
            // window.location.reload();
            loadInventoryDocuments();
        } 
        // else {
        //     (window.confirm(`${t("index_document_technician.delete_failed_alert")}`))
        // }
    }

    // const handleClick = (item, index) =>{
    //     setCurrentIndex(index);
    //     setClickedImg(item.base64_image);
    // }

    const validationForm = async () => {
        try{
            if (!inventoryDocument.document_base64){
                setUploadFileDocumentError("*required .PDF file")
                setDisabledButton(true);
            } else if (!inventoryDocument.document_name){
                setErrors({
                    ...errors,
                    message: `${t("detail_inventory_master.error_empty_field")}`});
                setDisabledButton(true);
            } else if (!inventoryDocument.created_date){
                setErrors({
                    ...errors,
                    message: `${t("detail_inventory_master.error_empty_field")}`});
                setDisabledButton(true);
            }
            else {
                let errorMsg = omit(errors, "message", "divisionError");
                setErrors(errorMsg);
                setDisabledButton(false);
            }
        } catch(exception){
            console.log(exception);
        }
    }

    const handleResets = async () => {
        setInventoryDocument({
            ...inventoryDocument,
            id: 0,
            document_base64: "",
            document_name: "",
            description: "",
            inventory_master_id: "",
            is_active: true,
            created_date: "",
            created_by: "",
            updated_date: "",
            updated_by: "",
            reference_number: "",
        });

        setFileDocument("");

    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }


    return(
        <>
            <Navbar />
            <Container>
                <ContainerBox
                    titleCaption={searchParams.get("inventoryName")}
                    useActionContainer={true}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { 
                                    // navigate("/InventoryMaster/Detail", {state: {inventoryMasterId: location.state.inventoryId}}); 
                                    navigate(backButton, {state: {inventoryMasterId: queryString.inventoryId}}); 
                                    // {
                                    //     if (backButton === "/InventoryMaster/Detail") {
                                    //         navigate("/InventoryMaster/Detail", {state: {inventoryMasterId: location.state.inventoryId}}); 
                                    //     } else if (backButton === "/Sparepartcatalog") {
                                    //         navigate("/Sparepartcatalog"); 
                                    //     }
                                    // }
                                    }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    actionContainerChild={
                        <div>
                            <Button disabled={false} variant="secondary" style={{
                                marginLeft: 5, marginRight: 5
                            }} onClick={() => {
                                setShowDocumentUploadModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("index_inventory_document.button_add_document")}</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            <div className="table-container">
                        <div className="table-header">
                        <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("index_document_inventory.table_name1")}</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("index_document_inventory.table_name3")}</div>
                                    <div style={{ flex: 3 }} className="table-header-content">{t("index_document_inventory.table_name4")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_document_inventory.table_name7")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_document_inventory.table_name8")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("index_document_inventory.table_name9")}</div>
                        </div>
                            {
                            listInventoryDocument && listInventoryDocument.map((document, index) => {
                                return(
                                <div key={index} className="table-body">
                                    <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{document.document_name}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{document.reference_number}</p></div>
                                            <div style={{ flex: 3 }} className="table-body-content"><p>{moment(document.created_date).format("DD/MM/yyyy")}</p></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setInventoryDocumentsId(document.document_id);
                                                        setShowDocumentDetailModal(true);
                                                    }}><EyeFill />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button variant="primary" onClick={() => {
                                                        setDownloadDataId(document.document_id)
                                                    }}><Download />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={false} variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("index_document_technician.delete_confirmation")}  ${document.document_name} ?`)) {
                                                            console.log(document.document_id);
                                                            setDeleteDataId(document.document_id);
                                                        }
                                                    }}><Trash />
                                                    </Button>
                                                </div>
                                            </div>

                                </div>
                                )
                            })
                            }
                        </div>
                        </div>
                        
                    }
                
                />

                <Modal className="modal-xxl" show={showDocumentDetailModal} onHide={() => {
                    setShowDocumentDetailModal(false);
                }}>

                    <ContainerBox containerPos="inner" titleCaption={documentToBeViewed&&documentToBeViewed.document_name}
                        useActionContainer={true}
                        actionContainerChild={
                            <div>
                                <Button variant="secondary" style={{

                                }} onClick={() => {
                                    setShowDocumentDetailModal(false);
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><XSquareFill size={32} /></div>
                                        <div style={{
                                            display: "flex",
                                            flex: 8,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 18,
                                        }}>{t("index_document_inventory.close_button")}</div>
                                    </div>
                                </Button>
                            </div>
                        }
                        childContent={
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column"
                            }}>
                                <div>
                                    <Document
                                        file={documentToBeViewed&&documentToBeViewed.document_base64}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        loading={() => {

                                        }}
                                    ><div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                overflow: "scroll",
                                            }}>
                                                <Page scale={zoomFactor} pageNumber={pageNumber} />
                                            </div>
                                        </div>
                                    </Document>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}>
                                        <p>
                                        {t("index_document_technician.page_text")} {pageNumber} {t("index_document_technician.from_text")} {numPages}
                                        </p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        flexWrap: "wrap",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5,
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={zoomFactor <= 0.2}
                                                onClick={() => {
                                                    setZoomFactor(zoomFactor * 0.8);
                                                }}
                                            >

                                                <ZoomOut size={28} />
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={false}
                                                onClick={() => {
                                                    setZoomFactor(0.4);
                                                }}
                                            >
                                                <AspectRatioFill size={28} />
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5,
                                            flexWrap: "wrap",
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={zoomFactor >= 10}
                                                onClick={() => {
                                                    setZoomFactor(zoomFactor * 2);
                                                }}
                                            >
                                                <ZoomIn size={28} />
                                            </Button>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        padding: 5
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={pageNumber <= 1}
                                                onClick={previousPage}
                                            >

                                                <ChevronDoubleLeft size={28} />
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            padding: 5
                                        }}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={pageNumber >= numPages}
                                                onClick={nextPage}
                                            >
                                                <ChevronDoubleRight size={28} />
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        } />

                </Modal>
                <Modal show={showDocumentUploadModal}
                    size={"lg"}
                    onHide={() => {
                        handleResets();
                        setShowDocumentUploadModal(false);
                    }}>
                    <div className="details m-2" >
                        <div className="detailscontent">
                            <h3>{t("index_document_inventory.modal_upload_document")} </h3>
                        </div>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            handleResets();
                            addDocument();
                            // setLoading(true);
                            // setDisabledButton(true);
                            // setNewDocument({ ...newDocument, done: true });
                        }} style={{ padding: 10 }}>

                            
                                
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("index_document_inventory.field_document_file")}</Form.Label>
                                    <Form.Control onChange={async (e) => {
                                        // if (e.target.files[0].type === "application/pdf") {
                                        setDocumentFile(e.target.value)
                                        let base64Doc = await convertBase64(e.target.files[0]);
                                        setInventoryDocument({ ...inventoryDocument, document_base64: base64Doc.toString() });
                                        // setUploadFileImageError("");
                                        // setDisabledButton(false)

                                        // } else {
                                        //     setDocumentFile("")
                                        //     setNewDocument({ ...newDocument, document_base64: "" });
                                        //     setUploadFileImageError("Hanya Bisa File Pdf")
                                        //     setDisabledButton(true)
                                        // }

                                    }} type="file" required></Form.Control>
                                    {/* {
                                        uploadFIleImageError && <p style={{ color: "red" }}>{uploadFIleImageError}</p>
                                    } */}
                                </Form.Group>
                            



                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_document_inventory.field_document_name")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setInventoryDocument({ ...inventoryDocument, document_name: e.target.value })
                                }} value={inventoryDocument.document_name} type="text" placeholder="" required></Form.Control>
                            {
                                errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                            }
                            </Form.Group>


                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_document_inventory.table_name3")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setInventoryDocument({ ...inventoryDocument, reference_number: e.target.value })
                                }} value={inventoryDocument.reference_number} type="text" placeholder="" ></Form.Control>

                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_document_inventory.table_name4")}</Form.Label>
                                <Form.Control onChange={(e) => {
                                    setInventoryDocument({ ...inventoryDocument, created_date: e.target.value })
                                }} value={inventoryDocument.created_date} type="date" placeholder="" required></Form.Control>
                            {
                                errors.message && <p style={{ color: "red" }}>{errors.message}</p>
                            }
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t("index_document_inventory.field_description")}</Form.Label>

                                <Form.Control onChange={(e) => {
                                    setInventoryDocument({ ...inventoryDocument, description: e.target.value })
                                }} value={inventoryDocument.description} as="textarea" rows={3} placeholder=""></Form.Control>

                            </Form.Group>

                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-start",
                                flex: 1,
                            }}>
                                <Button style={{width:100}} variant="primary" type="submit" disabled={disabledButton}>
                                {t("index_document_technician.save_button")}
                                </Button>
                                <Button style={{width:100}} className="cancel" variant="danger" onClick={() => {
                                    handleResets();
                                    setShowDocumentUploadModal(false);
                                }}>
                                    {t("index_document_technician.cancel_button")}
                                </Button>
                            </div>
                        </Form>



                    </div>

                </Modal>
            </Container>
        </>
    )
}