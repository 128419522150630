// import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../../../AppV2.css";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTheme from "../../../FMSTheme";
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../../Components/v2/FMSNavBarMUI";
import FMSEnginePerformancePage from '../VesselSMS/EnginePerformance';
import TestPage from './Test';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Container} from 'react-bootstrap';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function FMSVesselSMSDashboard() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const [listVessel, setListVessel] = useState([]);
    const navigate = useNavigate();
    const [selectedTabIndex, setSelectedTabIndex] = useState();
    const [tabDisplay, setTabDisplay] = useState();

    
    const [renderTab, setRenderTab] = useState();


    useEffect(() => {
        setSelectedTabIndex("mainEnginePerformance");
    }, []);



    useEffect(() => {
        if (selectedTabIndex){
            switch (selectedTabIndex) {
                case "mainEnginePerformance":
                    setTabDisplay(<TestPage />);
                    break;
                default:
                    setTabDisplay(<TestPage />);
                    break;
            }
        }
    }, [selectedTabIndex]);

    useEffect(() => {
        if(tabDisplay){
            const toRender = <TabPanel value={selectedTabIndex} index={selectedTabIndex}>
                {tabDisplay}
            </TabPanel>
            setRenderTab(toRender);
        }
    }, [tabDisplay]);

    const handleChange = (event, newValue) => {
        setSelectedTabIndex(newValue);
    };

    

    return (
        <>
            <ThemeProvider theme={FMSTheme}>
                <div className='mainContainerFMS'>
                    <NavBar />
                    <div className='childMainContainerFMS'>
                        <div className='childMainContainerFMS-TOP'>
                            <div className='childMainContainerFMS-TOP-Left'><p className='ptitleFMSDashboard'>VESSEL DASHBOARD</p></div>
                            <div className='childMainContainerFMS-TOP-Mid'></div>
                            <div className='childMainContainerFMS-TOP-Right'></div>
                        </div>
                        <div className='childMainContainerFMS-BODY'>
                            <Box
                                    sx={{ bgcolor: 'background.paper', display: 'flex', width: '100%', border: 1, borderColor: 'divider' }}
                                >
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={selectedTabIndex}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                    sx={{ borderRight: 1, borderColor: 'divider' }}
                                >
                                    <Tab label="Main Engine Performance" {...a11yProps("mainEnginePerformance")} />
                                </Tabs>
                                <Container fluid={true} className="">
                                    {renderTab}
                                </Container>
                            </Box>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </>
    );
}