import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import FMSAdminAuthenticate from '../FMSAdminAuthenticate';
import { isFMSAdminTokenValid } from '../../../Helpers/ApplicationHelpers';
const FMSAdminAuthenticateToken = ({ children }) => {
    const [cookies] = useCookies(["token"]);

    const params = {
        validationFunc: isFMSAdminTokenValid, // Function to validate the token
        validationData: cookies.token, // Token retrieved from cookies
        navigateURL: "/FMS" // Redirect URL if validation fails
    };
    
    return (
        <FMSAdminAuthenticate params={params}>
            {children}
        </FMSAdminAuthenticate>
    );
};

export default FMSAdminAuthenticateToken;