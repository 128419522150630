import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, } from "../../../Helpers/ApplicationHelpers";
import "../../AppV2.css";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTheme from "../../FMSTheme";
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../Components/v2/FMSNavBarMUI";

export default function FMSInventoryPage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();

    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }

            }
        }
        checkCookies();
    }, []);

    

    return (
        <>
            <ThemeProvider theme={FMSTheme}>
                <div className='mainContainerFMS'>
                    <NavBar />
                    <div className='childMainContainerFMS'>
                        <div className='childMainContainerFMS-TOP'>
                            <div className='childMainContainerFMS-TOP-Left'><p className='ptitleFMSDashboard'>INVENTORY</p></div>
                            <div className='childMainContainerFMS-TOP-Mid'></div>
                            <div className='childMainContainerFMS-TOP-Right'></div>
                        </div>
                        <div className='childMainContainerFMS-BODY'>
                            
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </>
    );
}