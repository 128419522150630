import './index.css';
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate, useLocation, useSearchParams, Link } from "react-router-dom";
import { isTokenValid, getPeriod, getAllDueJob, findAllDueJobInternalStatus } from "../../Helpers/ApplicationHelpers";
import { countAllMaintenancePlan, findAllMaintenancePlan } from "../../Helpers/CustomerMaintenanceHelpers";
import { Container, Button, Form, Modal } from 'react-bootstrap';
import { EyeFill, PencilFill, Search, Trash } from "react-bootstrap-icons";
import Loading from "../../Components/Loading";
import ContainerBox from "../../Components/ContainerBox";
import Sidebar from "../../Components/NewSideBarForCustomerPage/NewSideBar";
import { Trans, useTranslation } from "react-i18next";
import DropDownForm from "../../Components/DropDownFormForMaintenancePlan";
import DropDownFormOld from "../../Components/DropDownForm";
import moment from "moment";
import Paginations from '../../Components/Pagination';

export default function CustomerMaintenancePlanPage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [cookies, setCookie] = useCookies(["token"]);
    const [smsSerialNumber, setSmsSerialNumber] = useState("");
    const [vesselId, setVesselId] = useState("");
    const [vesselName, setVesselName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [listMaintenancePlan, setListMaintenancePlan] = useState([]);
    const [isSearched, setIsSearched] = useState(0);
    const [detailedSearch, setDetailedSearch] = useState(false);
    const [isDetailSearched, setIsDetailSearched] = useState(false);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    // const [orderBySelection, setOrderBySelection] = useState(["Inventory Name", "Inventory Type", "Divisi", "Unit", "Reference Price", "Brand", "Model", "Quantity"]);
    const [descending, setDescending] = useState(false);
    const [checkOnDetailSearch, setCheckOnDetailSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [resetSearch, setResetSearch] = useState(0);
    const [MaintenanceListCount, setMaintenanceListCount] = useState(0);

    const [orderBy, setOrderBy] = useState({
        label: `Title`,
        value: "title"
    });

    const orderByList = [
        {
            label: `Equipment Name`,
            value: "vesselequipment.title"
        },
        {
            label: `Running Hour`,
            value: "maintenanceplan.running_hour_interval"
        },
        {
            label: `Contact Count`,
            value: "maintenanceplan.contact_count"
        },
        {
            label: `Next Maintenance Date`,
            value: "maintenanceplan.next_maintenance_date"
        },
    ]

    const [equipmentNameSearched, setEquipmentNameSearched] = useState({
        isSearched: false,
        label: "equipmentName",
        value: ""
    });

    const [equipmentSerialNumberSearched, setEquipmentSerialNumberSearched] = useState({
        isSearched: false,
        label: "equipmentSerialNumber",
        value: ""
    });


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                console.log(location);
                setSmsSerialNumber(location.state.smsSerialNumber);
                loadAllMaintenancePlan();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (smsSerialNumber) {
            setVesselId(location.state.vesselId);
            setVesselName(location.state.vesselName);
            setCustomerName(location.state.customerName);
        }
    }, [smsSerialNumber]);

    useEffect(()=> {
        if (listMaintenancePlan){
            setLoading(false);
        }
    }, [listMaintenancePlan])

    useEffect(() => {
        console.log(equipmentSerialNumberSearched);
    }, [equipmentSerialNumberSearched])

    useEffect(() => {
        setLoading(true);
        loadAllMaintenancePlan();
    }, [page]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadAllMaintenancePlan();
        }
    }, [itemPerPage]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadAllMaintenancePlan();
        }
    }, [orderBy]);

    useEffect(() => {
        if (isSearched !== 0) {
            setLoading(true);
            loadAllMaintenancePlan();
        }
    }, [descending]);

    useEffect(() => {
        if (resetSearch !== 0)
            loadAllMaintenancePlan();
    }, [resetSearch]);

    const loadAllMaintenancePlan = async () => {
        try {
            let detailedSearchValue = [];
            detailedSearchValue.push(equipmentNameSearched, equipmentSerialNumberSearched);

            let newDetail = detailedSearchValue.filter(p => p.isSearched === true);
            console.log(newDetail);
            let isDetailSearchedTmp = isDetailSearched
            if (newDetail.length > 0) {
                isDetailSearchedTmp = true
                setIsDetailSearched(true);
            } else {
                isDetailSearchedTmp = false
                setIsDetailSearched(false);
            }

            console.log(cookies.token);
            console.log(cookies.extCustomerId,);
            console.log(searchQuery);
            console.log(isDetailSearchedTmp);
            console.log(detailedSearchValue);

            let listCount = await countAllMaintenancePlan(cookies.token, cookies.extCustomerId, searchQuery, isDetailSearchedTmp, detailedSearchValue);
            console.log(listCount);
            setMaintenanceListCount(listCount);
            let totalPage = listCount / itemPerPage;
            setTotalPage(totalPage);
            let tmpSearchQuery = undefined;
            if (searchQuery) {
                tmpSearchQuery = searchQuery;
            }


            let result = await findAllMaintenancePlan(cookies.token, cookies.extCustomerId, searchQuery, isDetailSearchedTmp, detailedSearchValue, page, itemPerPage, orderBy.value, descending);
            if (result) {
                console.log(result);
                setListMaintenancePlan(result);
                setResetSearch(0);
                setIsSearched(0);
            }

        }
        catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <div className="mainMPDashboard">
                <Sidebar companyName={customerName} smsSerialNumber={smsSerialNumber} vesselId={vesselId} vesselName={vesselName} customerName={cookies.userName} />
                <Container fluid className="mainContainerMPDashboard">
                    <div className="innerChildMainContainerMPDashboard">
                        <ContainerBox
                            titleCaption={`${vesselName} MAINTENANCE PLAN`}
                            childContent={
                                <>
                                    <div style={{ display: "flex", justifyContent: 'flex-end', marginTop: '10px', marginBottom: '0px', paddingRight: '20px', width: '100%' }}>
                                        <Button variant="primary" onClick={() => {
                                            setDetailedSearch(true);
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "row",
                                                alignSelf: "center",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    alignSelf: "center",
                                                    flex: 1,
                                                }}><Search size={20} /></div>
                                                <div style={{
                                                    display: "flex",
                                                    flex: 8,
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    alignSelf: "center",
                                                    paddingLeft: 5,
                                                    fontWeight: "bold",
                                                    fontSize: 18,
                                                }}> {"Detail Search"}</div>
                                            </div>
                                        </Button>
                                    </div>
                                    <div className="master-table-inner-container">
                                        <div className="table-container">
                                            <div className="table-header">
                                                <div style={{ flex: 1 }} className="table-header-content">#</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{"Next Maintenance Date"}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{"Vessel Name"}</div>
                                                <div style={{ flex: 3 }} className="table-header-content">{"Equipment Name"}</div>
                                                <div style={{ flex: 3 }} className="table-header-content">{"Title"}</div>
                                                <div style={{ flex: 2 }} className="table-header-content">{"Equipment S/N"}</div>
                                                {/* <div style={{ flex: 1 }} className="table-header-content">{"At Running Hour"}</div> */}
                                                {/* <div style={{ flex: 1 }} className="table-header-content">{"Contact Count"}</div> */}
                                                <div style={{ flex: 1 }} className="table-header-content">{"Edit"}</div>
                                                {/* <div style={{ flex: 1 }} className="table-header-content">{"Delete"}</div> */}
                                            </div>
                                            {
                                                listMaintenancePlan && listMaintenancePlan.map((maintenancePlan, idx) => {
                                                    return (
                                                        <div key={idx} className="table-body">
                                                            <div style={{ flex: 1 }} className="table-body-content">{idx + 1}</div>
                                                            <div style={{ flex: 2 }} className="table-body-content">{maintenancePlan.next_maintenance_date !== null ? moment(maintenancePlan.next_maintenance_date).format("YYYY-MM-DD") : ""}</div>
                                                            <div style={{ flex: 2 }} className="table-body-content">{maintenancePlan.vessel_name}</div>
                                                            <div style={{ flex: 3 }} className="table-body-content">{maintenancePlan.title}</div>
                                                            <div style={{ flex: 3 }} className="table-body-content">{maintenancePlan.maintenance_plan_name}</div>
                                                            <div style={{ flex: 2 }} className="table-body-content">{maintenancePlan.equipment_sn}</div>
                                                            {/* <div style={{ flex: 1 }} className="table-body-content">{maintenancePlan.running_hour_interval}</div> */}
                                                            {/* <div style={{ flex: 1 }} className="table-body-content">{maintenancePlan.contact_count}</div> */}
                                                            <div style={{ flex: 1 }} className="table-body-content">
                                                            {/* <Link to={`/Customer/DueJob/Detail?maintenancePlanId=${maintenancePlan.id}`} target="_blank"> */}
                                                                <Button variant="primary" disabled={false} onClick={() => {
                                                                navigate("/Customer/DueJob/Detail", { state: { maintenancePlanId: maintenancePlan.id, vesselEquipmentId: maintenancePlan.vessel_equipment_id, dueJobId: '' } });
                                                            }}><PencilFill />
                                                            </Button>
                                                            {/* </Link> */}
                                                            </div>
                                                            {/* <div style={{ flex: 1 }} className="table-body-content">
                                                                <Button disabled={true} variant="danger" onClick={() => {
                                                                    let dueJobId = dueJob.due_job_id;
                                                                    if (window.confirm(`Are you sure to delete this due job? ${dueJob.title}?`))
                                                                        setRemoveId(dueJobId);
                                                                }}><Trash />
                                                                </Button></div> */}
                                                        </div>
                                                    )
                                                })

                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        />
                        <div style={{ width: '90%', height: '100px', padding: '5px' }}>
                            <Paginations
                                itemPerPage={itemPerPage}
                                totalPage={totalPage}
                                page={page}
                                setPage={setPage}
                                setItemPerPage={setItemPerPage}
                                itemPerPageSelection={itemPerPageSelection}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                orderBySelection={orderByList}
                                isDescActive={descending}
                                setIsDescActive={setDescending}
                                setIsSearched={setIsSearched}
                            />
                        </div>
                    </div>
                </Container>
            </div>

            <Modal show={detailedSearch}
                size={"lg"}
                onHide={() => {
                    setDetailedSearch(false);
                }}>
                <div className="details m-2" >
                    <div className="detailscontent">
                        <h3>Maintenance Due Detail Search</h3>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: 10
                    }}>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            padding: 5
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1
                            }}>
                                <Form.Check checked={equipmentNameSearched.isSearched} onClick={(e) => {
                                    if (e.target.checked) {
                                        setEquipmentNameSearched({ ...equipmentNameSearched, isSearched: true })
                                    } else {
                                        setEquipmentNameSearched({ ...equipmentNameSearched, isSearched: false, value: "" })
                                    }
                                }}></Form.Check>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 3,
                                paddingRight: 5,
                                alignContent: 'flex-start',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start'
                            }}>
                                <Form.Control disabled value={'Equipment Name'}></Form.Control>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 8,
                                paddingLeft: 5
                            }}>
                                <Form.Control type='text' disabled={!equipmentNameSearched.isSearched}
                                    value={equipmentNameSearched.value} placeholder=""
                                    caption={"Equipment Name"}
                                    req={true}
                                    onChange={(e) => {
                                        setEquipmentNameSearched({ ...equipmentNameSearched, value: e.target.value });
                                    }} />
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            padding: 5
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1
                            }}>
                                <Form.Check checked={equipmentSerialNumberSearched.isSearched} onClick={(e) => {
                                    if (e.target.checked) {
                                        setEquipmentSerialNumberSearched({ ...equipmentSerialNumberSearched, isSearched: true })
                                    } else {
                                        setEquipmentSerialNumberSearched({ ...equipmentSerialNumberSearched, isSearched: false, value: "" })
                                    }
                                }}></Form.Check>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 3,
                                paddingRight: 5,
                                alignContent: 'flex-start',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start'
                            }}>
                                <Form.Control disabled value={'Serial Number'}></Form.Control>
                            </div>
                            <div style={{
                                display: "flex",
                                flex: 8,
                                paddingLeft: 5
                            }}>
                                <Form.Control type='text' disabled={!equipmentSerialNumberSearched.isSearched}
                                    value={equipmentSerialNumberSearched.value} placeholder=""
                                    caption={"Equipment Name"}
                                    req={true}
                                    onChange={(e) => {
                                        setEquipmentSerialNumberSearched({ ...equipmentSerialNumberSearched, value: e.target.value });
                                    }} />
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: "column",
                            padding: 10,
                            width: "100%"
                        }}>
                            <Button onClick={() => {
                                loadAllMaintenancePlan();
                                setPage(0);
                            }}><Search size={30} /></Button>
                        </div>

                    </div>


                </div>

            </Modal>
            <Loading
                loading={loading}
                loadingText={`${t("loading_component.text")}`}
            />
        </>
    )
}